<template>
  <div class="popups__popup" :class="`${popupName} ${popupOpen && 'active'}`">
    <div class="popups__content" :class="{'video-content': isVideoPopup === true}">
      <slot></slot>
      <div class="popups__close close" @click="$emit('closePopup')">
        <img src="img/close.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  props: [
    'popupName', 
    'popupOpen',
    'isVideoPopup'
  ],

  emits: [
    'closePopup'
  ],

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  }
};
</script>

<style></style>
