<template>
  <header class="header">
    <div class="header__container">
      <router-link to="/" class="header__logo">
        <img src="img/logo.png" alt="" />
      </router-link>
      <div class="header__rightside_block">
        <div class="header__balance" v-if="isAuth">
          <span>{{t('header.balance')}}</span>
          <span>{{balance}} {{defaultAsset.assetName}}</span>
        </div>
        <div class="header__menu" v-if="isAuth">
          <router-link v-for="route in routs" :key="route.path"
            :to="route.path"
            class="header__menu_item"
            :class="{'active': $route.fullPath === route.path}">
            {{t(`menu["${route.name}"]`)}}
          </router-link>
        </div>
        <div class="header__menu" v-else>
          <button class="header__menu_item" @click="$emit('openPopup', {name: 'PopupAuth'})" v-html="t('welcome.signIn_signUp')">
          </button>
        </div>

        <LanguageSwitcher class="mob-hidden" :class="{'main-page': isAuth === false}" />
      </div>
    </div>
    <div class="header__mob_menu">
      <div id="burger" :class="{'open': menuActive}" @click="menuActive = !menuActive">
        <span> </span><span> </span><span> </span>
      </div>
      <nav class="nav" id="nav" :class="{'nav-open': menuActive}">
        <div class="nav__logo"><img src="img/logo.png" alt="" /></div>
        <li v-for="route in routs" :key="route.path">
          <router-link :to="route.path"
            @click="menuActive = false"
            :class="{'active': $route.fullPath === route.path}">
            {{t(`menu["${route.name}"]`)}}
          </router-link>
        </li>
        <li class="language">
          <LanguageSwitcher class="mob-hidden" :class="{'main-page': isAuth === false}" />
        </li>
      </nav>
    </div>
  </header>
</template>

<script>
import { auth } from "@/mixins/auth";
import LanguageSwitcher from '../Language/LanguageSwitcher.vue';
import { useI18n } from "vue-i18n";
import { mapState } from "vuex";
import { transactions } from "@/mixins/transactions";

export default {
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

    return { t };
  },

  components: { LanguageSwitcher },

  props: ["canIRerenderComponent"],

  mixins: [auth, transactions],

  data() {
    return {
      routs: [
        {
          name: "Dashboard",
          path: "/",
        },
        {
          name: "Wallet",
          path: "/wallet",
        },
        {
          name: "My plans",
          path: "/my-plans",
        },
        {
          name: "My team",
          path: "/my-team",
        },
        {
          name: "Binary",
          path: "/binary",
        },
        {
          name: "Career",
          path: "/career",
        },
        {
          name: "Promo",
          path: "/promo-materials",
        },
        {
          name: "Tokenza AI",
          path: "/tokenza-ai",
        },
        {
          name: "TKZ Sale",
          path: "/tkz-sale",
        },
        {
          name: "Settings",
          path: "/settings",
        },
      ],

      menuActive: false,
      isAuth: false,
      // isAuth: ,
    };
  },

  computed: {
    ...mapState({
      defaultAsset: (state) => state.asset.defaultAsset,
      walletAssetsList: (state) => state.finance.walletAssetsList,
      // asset: (state) => state.asset.asset,

      // обмен валют
      assetPairAll: state => state.asset_pair.assetPairAll
    }),

    balance() {
      // getBalance t.assetBalance
      const BTCresult = this.walletAssetsList.reduce((acc, t) => {
        return acc += t.assetId === 1 ? +this.exchangePrice(+t.assetBalance, t.assetId) : 0;
      }, 0);

      const assetBalance = this.walletAssetsList.find(a => a.assetId === this.defaultAsset?.assetId)?.assetBalance ?? 0;

      return this.numberWithSpaces(this.setDecimalPrice(Number(+BTCresult + +assetBalance)));
    },
  },

  methods: {
    rerenderComponent() {
      this.$forceUpdate();
    },

    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    exchangePrice(amount, assetId) {
      const assetPair = this.assetPairAll?.find(pair => assetId === pair.assetPair.sourceAssetId);

        // console.log(assetPair, assetId, this.defaultAsset.assetId);
      if (assetPair) {
        const rateSell = Number(assetPair.rateSell) === 0 ? 1 : assetPair?.rateSell;
        return amount*rateSell
      }

      return amount;
    },

    setFloor(number) {
      if (typeof number === 'number' && !Number.isNaN(number)) {
        return parseFloat(number.toFixed(8));
      } else {
        return +number; // Возвращаем исходное значение, если это не число с плавающей точкой
      }
    },

    changePrice(price) {
      return this.numberWithSpaces(this.setFloor(price));
    },
  },

  created() {
    this.isAuth = this.getAuthState();

    if (this.walletAssetsList.length === 0) {
      this.$store.dispatch("getWalletAssetsList");
    }
    this.$store.dispatch("getAssetPairList");
  },

  updated() {
    this.isAuth = this.getAuthState();
  },

  watch: {
    canIRerenderComponent(newValue) {
      if (newValue === true) {
        this.rerenderComponent();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  background: transparent;
  border: 0;
  cursor: pointer;
}

.header {
  &__menu {
    margin: 0 auto;
  }

  &__balance {
    margin-left: 0.475em;
    margin-right: 0.975em;
  }

  &__mob_menu {
    .nav {
      font-size: .11em;
    }
  }
}
</style>
