<template>
  <div class="binary">
    <div class="heading_block">
      <div class="heading_block__heading">
        <span>{{ t(`menu["Binary"]`) }}</span>
      </div>
    </div>
    <div class="container">
      
      <div class="user">
        <div class="user__block">
          <div class="user__name">
            <span>{{userInfo?.login}}</span>
          </div>
          <div class="user__status">
            <span>{{ currentRankTitle }}</span>
          </div>
        </div>
        <div class="user__btn_block">
          <router-link to="/wallet" class="button-main">
            <span>{{t('header.myWallet')}}</span>
            <div class="button-main__gradient"></div>
          </router-link>
          
          <ButtonRefferal />
        </div>
      </div>

      <div class="binary__main_block"> 
        <div class="binary__main_block_item"> 
          <div class="binary__main_login_block">
            <div class="binary__main_login_text_1">
              <span>{{mainPosition?.account?.login}}</span>
            </div>
            <div class="binary__main_login_rank"> 
              <div class="binary__main_login_rank_text_1"><span>{{t('home.rank')}}:</span></div>
              <div class="binary__main_login_rank_text_2">
                <span>{{ currentRankTitle }}</span>
              </div>
            </div>
            <div class="binary__main_login_rank"> 
              <div class="binary__main_login_rank_text_1"><span>{{t('binary.plan')}}:</span></div>
              <div class="binary__main_login_rank_text_2">
                <span>{{ currentPackageTitle }}</span>
              </div>
            </div>
          </div>
          <div class="binary__main_login_block_right">
            <div class="binary__main_login_text_2">
                <span v-if="mainPosition?.account?.referralLogin">
                  <template v-if="(mainPosition?.account?.relationship?.kind === 1 || mainPosition?.account?.relationship?.kind === 2)">
                    {{t('binary.sponsor')}} - {{ mainPosition?.account?.referralLogin }}
                  </template>
                  <template v-else>
                    Spillover
                  </template>
                </span>
              </div>
            <div class="binary__main_login_text_2">
              <span v-if="mainPosition?.account?.relationship?.level === 1">
                Email : {{ mainPosition?.account?.email }}
              </span>
            </div>
          </div>
        </div>
        <div class="binary__main_block_item right-side" v-if="binaryInfo?.positionsList" :class="{disabled: binaryInfo?.selfPositionId !== binaryInfo?.viewPositionId || mainPosition?.settings?.done !== true}">
          <div class="binary__main_info_block_left">
            <div class="binary__main_info_block_item_1"> <span>{{t('binary.Left')}}</span>
              <div class="binary__main_info_block_item_circle" :class="{active: mainPosition?.settings?.done === true ? mainPosition?.settings?.userSide === 1 : mainPosition?.settings?.systemSide === 1}" @click="setSettingSide(1)"></div>
            </div>
            <div class="binary__main_info_block_item_2">	
              <div class="binary__main_info_block_item_2_item">
                 <img src="img/binary/user.png" alt="">
                 <!-- {{getLineById(1).positionsQuantity}} -->
                 <span>{{getLineById(1)?.positionsQuantity}}</span>
              </div>
              <div class="binary__main_info_block_item_2_item">
                <img class="cash" src="img/binary/cash.png" alt="">
                <span>{{fixBalance(getLineById(1)?.balanceValue)}} $</span>
              </div>
            </div>
          </div>
          <div class="binary__main_info_block_right">
            <div class="binary__main_info_block_item_1"> <span>{{t('binary.Right')}}</span>
              <div class="binary__main_info_block_item_circle" :class="{active: mainPosition?.settings?.done === true ? mainPosition?.settings?.userSide === 2 : mainPosition?.settings?.systemSide === 2}" @click="setSettingSide(2)"></div>
            </div>
            <div class="binary__main_info_block_item_2">	
              <div class="binary__main_info_block_item_2_item">
                <img src="img/binary/user.png" alt="">
                 <span>{{getLineById(2)?.positionsQuantity}}</span>
              </div>
              <div class="binary__main_info_block_item_2_item">
                <img class="cash" src="img/binary/cash.png" alt="">
                <span>{{fixBalance(getLineById(2)?.balanceValue)}} $</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="binary__search_block"> 
        <form class="binary__form" action="">
          <div class="my-team__search_block binary">
            <input class="my-team__search" 
              v-model="search"
              @keypress.Enter.prevent="() => {}"
              type="text"
              :placeholder="t('base.search')">
            <img class="search-img" src="img/my-team/search.png" alt="">
          </div>
          <div class="my-team__search_results">
            <BinarySearchItem v-for="position in filteredPostiions" 
              :key="position.positionId"
              @click.prevent="() => !isActiveViewPosition(position.positionId) && getBinary(position.positionId)"
              :position="position" />
          </div>
        </form>
      </div>
      <div class="binary__pyramid_block" v-if="this.binaryInfo?.positionsList !== undefined && this.binaryInfo?.positionsList.length !== 0">
        <button class="binary__back_btn"
          :class="{nohover: isActiveViewPosition(binaryInfo.selfPositionId)}"
          @click.prevent="() => !isActiveViewPosition(binaryInfo.selfPositionId) && getBinary(binaryInfo.selfPositionId)">
          <img src="img/home/arr.png" alt="">
        </button>
        <button class="binary__back_btn up"
          :class="{nohover: isActiveViewPosition(binaryInfo.selfPositionId)}"
          @click.prevent="() => !isActiveViewPosition(binaryInfo.selfPositionId) && getBinary(mainPosition.positionRefId)">
          <img src="img/wallet/info-item-4.png" alt="">
        </button>

        <template v-for="(position, index) in positionsList" 
          :key="'1tr-'+position">
          <BinaryItem 
            v-if="position !== null && index === 0"
            @click.prevent="getBinary(position.positionId)"
            :position="position" 
            :itemIndex="index"
            :lineIndex="1" 
            class="nohover"
            :nohover="isActiveViewPosition(position.positionId)"/>
        </template>

        <div class="binary__pyramid_item_line_2">
          <template v-for="itemIndex in 14" :key="`itemIndex2-${itemIndex}`">
            <template v-if="itemIndex == 1">
              <BinaryItem 
                v-if="positionLevels['level0'] !== null"
                @click.prevent="getBinary(positionLevels['level0'][0]?.positionId)"
                :position="positionLevels['level0'][0]" 
                :itemIndex="itemIndex"
                :lineIndex="2" 
                :nohover="isActiveViewPosition(positionLevels['level0'][0]?.positionId)"/>
                <BinaryItem v-else
                  :nohover="true"
                  :position="null" 
                  :itemIndex="itemIndex"
                  :lineIndex="2" />
            </template>
            <template v-if="itemIndex == 2">
              <BinaryItem 
                v-if="positionLevels['level0'] !== null"
                @click.prevent="getBinary(positionLevels['level0'][1]?.positionId)"
                :position="positionLevels['level0'][1]" 
                :itemIndex="itemIndex"
                :lineIndex="2" 
                :nohover="isActiveViewPosition(positionLevels['level0'][1]?.positionId)"/>
                <BinaryItem v-else
                  :nohover="true"
                  :position="null" 
                  :itemIndex="itemIndex"
                  :lineIndex="2" />
            </template>
          </template>
        </div>

        <div class="binary__pyramid_item_line_3">
          <template v-for="itemIndex in 14" :key="`itemIndex3-${itemIndex}`">
            <template v-if="itemIndex == 3">
              <BinaryItem 
                v-if="positionLevels['level1'] !== null"
                @click.prevent="getBinary(positionLevels['level1'][0]?.positionId)"
                :position="positionLevels['level1'][0]" 
                :itemIndex="itemIndex"
                :lineIndex="3" 
                :nohover="isActiveViewPosition(positionLevels['level1'][0]?.positionId)"/>
                <BinaryItem v-else
                  class="nohover"
                  :position="null" 
                  :itemIndex="itemIndex"
                  :lineIndex="3" />
            </template>
            <template v-if="itemIndex == 4">
              <BinaryItem 
                v-if="positionLevels['level1'] !== null"
                @click.prevent="getBinary(positionLevels['level1'][1]?.positionId)"
                :position="positionLevels['level1'][1]" 
                :itemIndex="itemIndex"
                :lineIndex="3" 
                :nohover="isActiveViewPosition(positionLevels['level1'][1]?.positionId)"/>
                <BinaryItem v-else
                  :nohover="true"
                  :position="null" 
                  :itemIndex="itemIndex"
                  :lineIndex="3" />
            </template>
            <template v-if="itemIndex == 5">
              <BinaryItem 
                v-if="positionLevels['level2'] !== null"
                @click.prevent="getBinary(positionLevels['level2'][0]?.positionId)"
                :position="positionLevels['level2'][0]" 
                :itemIndex="itemIndex"
                :lineIndex="3" 
                :nohover="isActiveViewPosition(positionLevels['level2'][0]?.positionId)"/>
                <BinaryItem v-else
                  :nohover="true"
                  :position="null" 
                  :itemIndex="itemIndex"
                  :lineIndex="3" />
            </template>
            <template v-if="itemIndex == 6">
              <BinaryItem 
                v-if="positionLevels['level2'] !== null"
                @click.prevent="getBinary(positionLevels['level2'][1]?.positionId)"
                :position="positionLevels['level2'][1]" 
                :itemIndex="itemIndex"
                :lineIndex="3" 
                :nohover="isActiveViewPosition(positionLevels['level2'][1]?.positionId)"/>
                <BinaryItem v-else
                  :nohover="true"
                  :position="null" 
                  :itemIndex="itemIndex"
                  :lineIndex="3" />
            </template>
          </template>
        </div>
        <div class="binary__pyramid_item_line_4">
          <template v-for="itemIndex in 14" :key="`itemIndex4-${itemIndex}`">
            <template v-if="itemIndex == 7">
              <BinaryItem 
                v-if="positionLevels['level3'] !== null"
                @click.prevent="getBinary(positionLevels['level3'][0]?.positionId)"
                :position="positionLevels['level3'][0]" 
                :itemIndex="itemIndex"
                :lineIndex="4" 
                :nohover="isActiveViewPosition(positionLevels['level3'][0]?.positionId)"/>
                <BinaryItem v-else
                  :nohover="true"
                  :position="null" 
                  :itemIndex="itemIndex"
                  :lineIndex="4" />
            </template>
            <template v-if="itemIndex == 8">
              <BinaryItem 
                v-if="positionLevels['level3'] !== null"
                @click.prevent="getBinary(positionLevels['level3'][1]?.positionId)"
                :position="positionLevels['level3'][1]" 
                :itemIndex="itemIndex"
                :lineIndex="4" 
                :nohover="isActiveViewPosition(positionLevels['level3'][1]?.positionId)"/>
                <BinaryItem v-else
                  :nohover="true"
                  :position="null" 
                  :itemIndex="itemIndex"
                  :lineIndex="4" />
            </template>
            <template v-if="itemIndex == 9">
              <BinaryItem 
                v-if="positionLevels['level4'] !== null"
                @click.prevent="getBinary(positionLevels['level4'][0]?.positionId)"
                :position="positionLevels['level4'][0]" 
                :itemIndex="itemIndex"
                :lineIndex="4" 
                :nohover="isActiveViewPosition(positionLevels['level4'][0]?.positionId)"/>
                <BinaryItem v-else
                  :nohover="true"
                  :position="null" 
                  :itemIndex="itemIndex"
                  :lineIndex="4" />
            </template>
            <template v-if="itemIndex == 10">
              <BinaryItem 
                v-if="positionLevels['level4'] !== null"
                @click.prevent="getBinary(positionLevels['level4'][1]?.positionId)"
                :position="positionLevels['level4'][1]" 
                :itemIndex="itemIndex"
                :lineIndex="4" 
                :nohover="isActiveViewPosition(positionLevels['level4'][1]?.positionId)"/>
                <BinaryItem v-else
                  :nohover="true"
                  :position="null" 
                  :itemIndex="itemIndex"
                  :lineIndex="4" />
            </template>
            <template v-if="itemIndex == 11">
              <BinaryItem 
                v-if="positionLevels['level5'] !== null"
                @click.prevent="getBinary(positionLevels['level5'][0]?.positionId)"
                :position="positionLevels['level5'][0]" 
                :itemIndex="itemIndex"
                :lineIndex="4" 
                :nohover="isActiveViewPosition(positionLevels['level5'][0]?.positionId)"/>
                <BinaryItem v-else
                  :nohover="true"
                  :position="null" 
                  :itemIndex="itemIndex"
                  :lineIndex="4" />
            </template>
            <template v-if="itemIndex == 12">
              <BinaryItem 
                v-if="positionLevels['level5'] !== null"
                @click.prevent="getBinary(positionLevels['level5'][1]?.positionId)"
                :position="positionLevels['level5'][1]" 
                :itemIndex="itemIndex"
                :lineIndex="4" 
                :nohover="isActiveViewPosition(positionLevels['level5'][1]?.positionId)"/>
                <BinaryItem v-else
                  :nohover="true"
                  :position="null" 
                  :itemIndex="itemIndex"
                  :lineIndex="4" />
            </template>
            <template v-if="itemIndex == 13">
              <BinaryItem 
                v-if="positionLevels['level6'] !== null"
                @click.prevent="getBinary(positionLevels['level6'][0]?.positionId)"
                :position="positionLevels['level6'][0]" 
                :itemIndex="itemIndex"
                :lineIndex="4" 
                :nohover="isActiveViewPosition(positionLevels['level6'][0]?.positionId)"/>
                <BinaryItem v-else
                  :nohover="true"
                  :position="null" 
                  :itemIndex="itemIndex"
                  :lineIndex="4" />
            </template>
            <template v-if="itemIndex == 14">
              <BinaryItem 
                v-if="positionLevels['level6'] !== null"
                @click.prevent="getBinary(positionLevels['level6'][1]?.positionId)"
                :position="positionLevels['level6'][1]" 
                :itemIndex="itemIndex"
                :lineIndex="4" 
                :nohover="isActiveViewPosition(positionLevels['level6'][1]?.positionId)"/>
                <BinaryItem v-else
                  :nohover="true"
                  :position="null" 
                  :itemIndex="itemIndex"
                  :lineIndex="4" />
            </template>
          </template>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// import TabWallet from '@/components/Wallet/TabWallet'
// import TabWalletPool from '@/components/Wallet/TabWalletPool'
import {auth} from '@/mixins/auth'
import { mapState } from 'vuex';
import { useI18n } from 'vue-i18n'
import { transactions } from '@/mixins/transactions';
import { dataScrollUpdate } from '@/mixins/dataScrollUpdate';
import ButtonRefferal from '../../components/UI/ButtonRefferal.vue';
import BinaryItem from '../../components/Binary/BinaryItem.vue';
import BinarySearchItem from '../../components/Binary/BinarySearchItem.vue';

export default {
  mixins: [
    auth,
    transactions,
    dataScrollUpdate
  ],

  components: {
    ButtonRefferal,
    BinaryItem,
    BinarySearchItem,
  },

  data() {
    return {
      defaultStatus: '-',
      search: '',
      viewLevel: 14,

      levelUsers: {
        level0: [],
        level1: [],
        level2: [],
        level3: [],
        level4: [],
        level5: [],
        level6: [],
      }
    }
  },

  computed: {
    ...mapState({
      userInfo: state => state.account.user.info,
      network: state => state.network.network,
      asset: state => state.asset.asset,
      walletAsset: state => state.finance.walletAsset,
      walletTransactions: state => state.finance.walletTransactions,

      // poolAsset: state => state.finance.poolAsset,
      // pool: state => state.finance.pool,
      // ранг
      careerInfo: (state) => state.career.info,
      farmingPackages: (state) => state.farming.farmingPackages,
      // binary info
      binaryInfo: (state) => state.binary.info,
      // binary search
      binarySearchResults: state => state.binary.binarySearchResults,
    }),

    mainPosition() {
      return this.binaryInfo?.positionsList?.find(p => p.positionId === this.binaryInfo?.viewPositionId);
    }, 

    currentRankTitle() { 
      return this.careerInfo?.ranksList?.find(rank => this.mainPosition?.account?.currentRankId === rank.rankId)?.rankTitle ?? this.defaultStatus
    },

    currentPackageTitle() {
      return this.farmingPackages?.find(p => p.packageId === this.mainPosition?.account?.currentPackageId)?.packageTitle ?? '-';
    },

    positionLevels() {
      if (this.binaryInfo?.positionsList === undefined) return this.levelUsers;
      const myLevels = {...this.levelUsers};

      if (this.binaryInfo?.positionsList?.length) {
        const userLevel0 = this.binaryInfo.positionsList[0];

        // main
        const userLevelResults0 = this.getChildPositions(userLevel0.positionId);
        myLevels[`level${0}`][0] = userLevelResults0.find(p => p !== undefined && p.positionRefLine === 1);

        if (myLevels[`level${0}`][0] !== undefined) {
          // 1
  
          const userLevelResults1 = this.getChildPositions(myLevels[`level${0}`][0].positionId)

          // 3
          myLevels[`level${1}`][0] = userLevelResults1.find(p => p !== undefined && p.positionRefLine === 1);
          if (myLevels[`level${1}`][0] !== undefined) {

            const userLevelResults3 = this.getChildPositions(myLevels[`level${1}`][0].positionId)
            myLevels[`level${3}`][0] = userLevelResults3.find(p => p !== undefined && p.positionRefLine === 1);
            myLevels[`level${3}`][1] = userLevelResults3.find(p => p !== undefined && p.positionRefLine === 2);
          } else {
            myLevels[`level${3}`][0] = undefined;
            myLevels[`level${3}`][1] = undefined;
          }

          // 4
          myLevels[`level${1}`][1] = userLevelResults1.find(p => p !== undefined && p.positionRefLine === 2);
          if (myLevels[`level${1}`][1] !== undefined) {
  
            const userLevelResults4 = this.getChildPositions(myLevels[`level${1}`][1].positionId)
            myLevels[`level${4}`][0] = userLevelResults4.find(p => p !== undefined && p.positionRefLine === 1);
            myLevels[`level${4}`][1] = userLevelResults4.find(p => p !== undefined && p.positionRefLine === 2);
          } else {
            myLevels[`level${4}`][0] = undefined;
            myLevels[`level${4}`][1] = undefined;
          }
        } else {
          myLevels[`level${1}`][0] = undefined;
          myLevels[`level${1}`][1] = undefined;
          myLevels[`level${3}`][0] = undefined;
          myLevels[`level${3}`][1] = undefined;
          myLevels[`level${4}`][0] = undefined;
          myLevels[`level${4}`][1] = undefined;
        }
        
        // 
        // 
        // 

        myLevels[`level${0}`][1] = userLevelResults0.find(p => p !== undefined && p.positionRefLine === 2);
        if (myLevels[`level${0}`][1] !== undefined) {
          // 2
          
          const userLevelResults2 = this.getChildPositions(myLevels[`level${0}`][1].positionId)
          
          // 5
          myLevels[`level${2}`][0] = userLevelResults2.find(p => p !== undefined && p.positionRefLine === 1);
          if (myLevels[`level${2}`][0] !== undefined) {

            const userLevelResults5 = this.getChildPositions(myLevels[`level${2}`][0].positionId)
            myLevels[`level${5}`][0] = userLevelResults5.find(p => p !== undefined && p.positionRefLine === 1);
            myLevels[`level${5}`][1] = userLevelResults5.find(p => p !== undefined && p.positionRefLine === 2);
          } else {
            myLevels[`level${5}`][0] = undefined;
            myLevels[`level${5}`][1] = undefined;
          }
          
          // 6
          myLevels[`level${2}`][1] = userLevelResults2.find(p => p !== undefined && p.positionRefLine === 2);
          if (myLevels[`level${2}`][1] !== undefined) {

            const userLevelResults6 = this.getChildPositions(myLevels[`level${2}`][1].positionId)
            myLevels[`level${6}`][0] = userLevelResults6.find(p => p !== undefined && p.positionRefLine === 1);
            myLevels[`level${6}`][1] = userLevelResults6.find(p => p !== undefined && p.positionRefLine === 2);
          } else {
            myLevels[`level${6}`][0] = undefined;
            myLevels[`level${6}`][1] = undefined;
          }
        } else {
          myLevels[`level${2}`][0] = undefined;
          myLevels[`level${2}`][1] = undefined;
          myLevels[`level${5}`][0] = undefined;
          myLevels[`level${5}`][1] = undefined;
          myLevels[`level${6}`][0] = undefined;
          myLevels[`level${6}`][1] = undefined;
        }
        
        return myLevels;
      }

      return this.levelUsers;
    },

    positionsList() {
      const result = [];

      if (this.binaryInfo?.positionsList) {
        for (let i = 0; i <= this.viewLevel; i++) {
          result[i] = this.binaryInfo?.positionsList[i] !== undefined ? {...this.binaryInfo?.positionsList[i]} : null;
        }
      } else {
        for (let i = 0; i <= this.viewLevel; i++) {
          result[i] = null;
        }
      }

      return result;
    },

    filteredPostiions() {
      if (this.search === '') return [];

      return this.binarySearchResults?.length ? this.binarySearchResults : [];
    }
  },

  methods: {
    getChildPositions(positionId) {
      return this.binaryInfo?.positionsList?.filter(pos => {
        return pos.positionRefId === positionId && pos.positionRefId !== 0
      })
    },

    getLineById(id) {
      return this.mainPosition?.linesList.find(p => p.positionLine === id)
    },

    getPositionLevel(positionLevelIndex, positionId) {
      if (this.positionLevels[positionLevelIndex].length === 0) {
        return null
      }
      const result = this.positionLevels[positionLevelIndex].find(p => p !== undefined && p.positionId === positionId) ?? null;
      return result;
    },

    setSettingSide(userSide) {
      this.$store.dispatch('setSettingsSide', { userSide }).then(() => {
        this.$store.dispatch('getBinaryInfo', { viewLevel: 3 })
      })
    },

    isActiveViewPosition(viewPositionId) {
      return this.binaryInfo?.viewPositionId !== undefined && this.binaryInfo?.viewPositionId === viewPositionId;
    },

    getBinary(viewPositionId) {
      if (this.isActiveViewPosition(viewPositionId)) return;

      if (this.search.length) this.search = '';

      const binaryData = { viewLevel: 3 }
      if (viewPositionId) binaryData.viewPositionId = viewPositionId;

      this.$store.dispatch('getBinaryInfo', { ...binaryData })
    },

    fixBalance(number) {
      return Number(number).toFixed(2);
    }
  },

  created() {
    if (this.userInfo === undefined) this.$store.dispatch('getAccountInfo').then((response) => {
      if (Object.keys(this.network).length === 0) this.$store.dispatch('getPartnerTeamInfo', response.login)
    })
  
    this.$store.dispatch("getCareerInfo")
    this.$store.dispatch('getAllFarmingPackages')

    this.getBinary();
  },

  mounted() {
    this.$emit('pageUpdate', true)
  },

  unmounted() {
    this.$emit('pageUpdate', false)
  },

  watch: {
    search(value) {
      if (value.length > 0) {
        this.debounce(() => this.$store.dispatch('searchBinary', {loginPrefix: value}), 100)
      }
    }
  },
  
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  },

};
</script>

<style lang="scss">
.binary__main_block_item.disabled {
  pointer-events: none;
}
.wallet__sidebar {
  &_block {
    &.active {
      & .wallet__sidebar_tax_name span, 
      & .wallet__sidebar_tax_counter span {
        color: #26a17b !important;
      }
    }

    &_tax_block {
      cursor: pointer;
    }
  }
}

.binary__pyramid_item_1_img {
  background: #2d1f59;
  height: 10.1875em;
}

.binary__pyramid_item_2_img {
  background: #2d1f59;
  height: 7.4375em;
}

.binary__pyramid_item_3_img {
  height: 4.875em;
  background: #2d1f59;
}

.binary__pyramid_item_4_img {
  height: 3.9375em;
  background: #2d1f59;
}

.binary__pyramid_item_text .hide {
  opacity: 0;
}

.binary__back_btn {
  transform: rotate(180deg);
  background: #230e4f;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.5em;
  height: 4.5em;
  transition: opacity .2s ease-in-out;
  cursor: pointer;
  margin-bottom: 1em;

  position: absolute;
  left: 0;
  top: 0;

  &.up {
    top: 6em;

    img {
      width: 1.8em;
      transform: rotate(180deg);
      // filter: brightness(0);
    }
  }

  &:hover {
    opacity: .7;
  }

  img {
    width: 1em;
  }

  &.nohover {
    opacity: 0 !important;
    pointer-events: none;
  }

  @media (max-width: 1024px) {
    font-size: 2em;
  }
}

.my-team__search_results {
  position: absolute;
  z-index: 9;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: .3em;
  border-radius: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  background: linear-gradient(90deg, #2f196f 10%, #561271 100%);
  max-height: 20em;
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: em(8);
    width: em(8);
    transform: translateX(em(10));
  }
  &::-webkit-scrollbar-track {
    background: rgba(1, 255, 194, .2);
    border-radius: em(10);
    margin: em(5);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #01ffc2;
    border-radius: em(10);
    border: 0;
  }

  &::-webkit-scrollbar-corner {
    background: rgba(1, 255, 194, .2);
    background: transparent;
  }
}

.binary__main_block_item {
  height: 12.6em;
  flex-basis: 1px;
}

.binary__main_login_block_right {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.binary__main_login_text_2 {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  text-align: right !important;
}

@media (orientation: portrait) {
  .mobileScreen .binary__main_block_item {
    height: auto;
  }
  
  .binary__main_login_text_2 {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    text-align: left !important;
  }
}

.binary__main_info_block_item_2_item span {
  white-space: nowrap !important;
}

@media (orientation: landscape) {
  .binary__main_info_block {
    &_left {
      border-right: 0.0625em solid #01ffc2;
      margin-right: 1.5em;
      padding-right: 1.5em;
      height: 100%;
      align-items: center !important;
    }

    &_right {
      height: 100%;
      align-items: center !important;
    }

    &_item_1 {
      align-self: flex-start !important;  
      padding-top: .5em;

    }

    &_item_2_item {
      justify-content: flex-end !important;
    }
  }
}
</style>
