import store from './store'
// import router from './router/router'

function getAuthState() {
  return JSON.parse(localStorage.getItem('userAuth')) === true;
}

function resetUserAuth() {
  localStorage.setItem('userAuth', false);
  localStorage.setItem('access_token', null);

  // if (router.currentRoute.path !== '/') router.push('/');
  // else window.location.reload(true)
}

export const tokenValidate = () => {
  return new Promise((resp, resolve) => {
    // store.dispatch('setAuthStateChecked', false);

    store.dispatch('tokenValidate').then((response) => {
      if (response.validated === false) {
        if (getAuthState()) {
          resetUserAuth();
        }

        // if (router.currentRoute.path !== '/') router.push('/');
        // // else window.location.reload(true)
        
        resolve()
        return
      }

      switch (response.kind) {
        case 0:
          resetUserAuth()
          resolve()
          break;
        case 1:
          !getAuthState() && localStorage.setItem('userAuth', true)
          resp()
          break;
        case 2:
          resetUserAuth()
          resolve()
          break;
      }
    })
  })
}