/**
 * @fileoverview gRPC-Web generated client stub for mlmbox.client.farming.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.15.8
// source: mlmbox/client/farming/v1/farming.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var mlmbox_type_farming_pb = require('../../../../mlmbox/type/farming_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.mlmbox = {};
proto.mlmbox.client = {};
proto.mlmbox.client.farming = {};
proto.mlmbox.client.farming.v1 = require('./farming_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mlmbox.client.farming.v1.FarmingClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mlmbox.client.farming.v1.FarmingPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.type.Farming.Package.ID,
 *   !proto.mlmbox.type.Farming.Package>}
 */
const methodDescriptor_Farming_PackageGet = new grpc.web.MethodDescriptor(
  '/mlmbox.client.farming.v1.Farming/PackageGet',
  grpc.web.MethodType.UNARY,
  mlmbox_type_farming_pb.Farming.Package.ID,
  mlmbox_type_farming_pb.Farming.Package,
  /**
   * @param {!proto.mlmbox.type.Farming.Package.ID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_farming_pb.Farming.Package.deserializeBinary
);


/**
 * @param {!proto.mlmbox.type.Farming.Package.ID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.type.Farming.Package)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Farming.Package>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.farming.v1.FarmingClient.prototype.packageGet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.farming.v1.Farming/PackageGet',
      request,
      metadata || {},
      methodDescriptor_Farming_PackageGet,
      callback);
};


/**
 * @param {!proto.mlmbox.type.Farming.Package.ID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.type.Farming.Package>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.farming.v1.FarmingPromiseClient.prototype.packageGet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.farming.v1.Farming/PackageGet',
      request,
      metadata || {},
      methodDescriptor_Farming_PackageGet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.client.farming.v1.PackageBuyRequest,
 *   !proto.mlmbox.type.Farming.Contract>}
 */
const methodDescriptor_Farming_PackageBuy = new grpc.web.MethodDescriptor(
  '/mlmbox.client.farming.v1.Farming/PackageBuy',
  grpc.web.MethodType.UNARY,
  proto.mlmbox.client.farming.v1.PackageBuyRequest,
  mlmbox_type_farming_pb.Farming.Contract,
  /**
   * @param {!proto.mlmbox.client.farming.v1.PackageBuyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_farming_pb.Farming.Contract.deserializeBinary
);


/**
 * @param {!proto.mlmbox.client.farming.v1.PackageBuyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.type.Farming.Contract)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Farming.Contract>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.farming.v1.FarmingClient.prototype.packageBuy =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.farming.v1.Farming/PackageBuy',
      request,
      metadata || {},
      methodDescriptor_Farming_PackageBuy,
      callback);
};


/**
 * @param {!proto.mlmbox.client.farming.v1.PackageBuyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.type.Farming.Contract>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.farming.v1.FarmingPromiseClient.prototype.packageBuy =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.farming.v1.Farming/PackageBuy',
      request,
      metadata || {},
      methodDescriptor_Farming_PackageBuy);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.mlmbox.type.Farming.Package>}
 */
const methodDescriptor_Farming_PackagesAll = new grpc.web.MethodDescriptor(
  '/mlmbox.client.farming.v1.Farming/PackagesAll',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  mlmbox_type_farming_pb.Farming.Package,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_farming_pb.Farming.Package.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Farming.Package>}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.farming.v1.FarmingClient.prototype.packagesAll =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mlmbox.client.farming.v1.Farming/PackagesAll',
      request,
      metadata || {},
      methodDescriptor_Farming_PackagesAll);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Farming.Package>}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.farming.v1.FarmingPromiseClient.prototype.packagesAll =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mlmbox.client.farming.v1.Farming/PackagesAll',
      request,
      metadata || {},
      methodDescriptor_Farming_PackagesAll);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.mlmbox.type.Farming.Account>}
 */
const methodDescriptor_Farming_Info = new grpc.web.MethodDescriptor(
  '/mlmbox.client.farming.v1.Farming/Info',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  mlmbox_type_farming_pb.Farming.Account,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_farming_pb.Farming.Account.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.type.Farming.Account)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Farming.Account>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.farming.v1.FarmingClient.prototype.info =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.farming.v1.Farming/Info',
      request,
      metadata || {},
      methodDescriptor_Farming_Info,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.type.Farming.Account>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.farming.v1.FarmingPromiseClient.prototype.info =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.farming.v1.Farming/Info',
      request,
      metadata || {},
      methodDescriptor_Farming_Info);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.type.Farming.Contract.ID,
 *   !proto.mlmbox.type.Farming.Contract>}
 */
const methodDescriptor_Farming_ContractGet = new grpc.web.MethodDescriptor(
  '/mlmbox.client.farming.v1.Farming/ContractGet',
  grpc.web.MethodType.UNARY,
  mlmbox_type_farming_pb.Farming.Contract.ID,
  mlmbox_type_farming_pb.Farming.Contract,
  /**
   * @param {!proto.mlmbox.type.Farming.Contract.ID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_farming_pb.Farming.Contract.deserializeBinary
);


/**
 * @param {!proto.mlmbox.type.Farming.Contract.ID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.type.Farming.Contract)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Farming.Contract>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.farming.v1.FarmingClient.prototype.contractGet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.farming.v1.Farming/ContractGet',
      request,
      metadata || {},
      methodDescriptor_Farming_ContractGet,
      callback);
};


/**
 * @param {!proto.mlmbox.type.Farming.Contract.ID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.type.Farming.Contract>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.farming.v1.FarmingPromiseClient.prototype.contractGet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.farming.v1.Farming/ContractGet',
      request,
      metadata || {},
      methodDescriptor_Farming_ContractGet);
};


module.exports = proto.mlmbox.client.farming.v1;

