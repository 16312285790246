<template>
  <div class="cases">
    <div class="heading_block">
      <div class="heading_block__heading">
        <span>{{t('menu["My plans"]')}}</span>
      </div>
      
      <router-link to="/?buy" class="button-main plan-button">
        <span>{{t('home.buy_plans')}}</span>
        <div class="button-main__gradient"></div>
      </router-link>
      <router-link to="/wallet" class="button-main cases-button">
        <span>{{t('header.myWallet')}}</span>
        <div class="button-main__gradient"></div>
      </router-link>
    </div>
    <div class="container">
      <div class="cases__main_block"> 
        <div class="cases__main_passive_block"> 
          <div class="cases__main_passive_text_block"> 
            <div class="cases__main_passive_heading">
              <span>{{t('cases.monthly_income')}}</span>
            </div>
            <div class="cases__main_passive_count">
              
              <span>{{numberWithSpaces(monthIncome)}} $</span>
            </div>
          </div>
          <div class="cases__main_passive_img">
            <PlansBotRive />
            <!-- <img src="img/my-cases/image-1.png" alt=""> -->
          </div>
        </div>
        <div class="cases__main_stat_block">
          <div class="cases__main_stat_heading">
            <span>{{t('cases.case_statistics')}}</span>
          </div>
          <div class="cases__main_stat_items"> 
            <div class="cases__main_stat_item">
              <div class="cases__main_stat_item_text_1">
                <span>{{t('cases.total_invested')}}</span>
              </div>
              <div class="cases__main_stat_item_text_2">
                <span>{{numberWithSpaces(setDecimalPrice(totalInvestedValue ?? 0))}} $</span>
              </div>
            </div>
            <div class="cases__main_stat_item">
              <div class="cases__main_stat_item_text_1">
                <span>{{t('cases.total_earned')}}</span>
              </div>
              <div class="cases__main_stat_item_text_2">
                <span>{{numberWithSpaces(setDecimalPrice(passiveEarnedValue ?? 0))}} $</span>
              </div>
            </div>
          </div>
        </div>
        <div class="cases__main_dohod_block">
          <div class="cases__main_dohod_text_block"> 
            <div class="cases__main_dohod_heading">
              <span>{{t('cases.expected_income_year')}}</span>
            </div>
            <div class="cases__main_dohod_count">
              <span>{{numberWithSpaces(setDecimalPrice(monthIncome*12))}} $</span>
            </div>
          </div>
          <div class="cases__main_dohod_img">
            <PlansBotRiveRight />
            <!-- <img src="img/my-cases/image-2.png" alt=""> -->
          </div>
        </div>
      </div>
      <div class="cases__main_dohod_table_block" v-if="activeContracts?.length"> 
        <div class="cases__main_dohod_table_container">
          <div class="cases__main_dohod_headings">
            <div class="cases__main_dohod_headind_case">
              <span>{{t('cases.case')}}</span>
            </div>
            <div class="cases__main_dohod_headind_month">
              <span>{{t('cases.monthly_income')}}</span>
            </div>
            <div class="cases__main_dohod_headind_year">
              <span>{{t('cases.expected_income_year')}}</span>
            </div>
            <div class="cases__main_dohod_headind_earned">
              <span>{{t('cases.earned')}}</span>
            </div>
            <div class="cases__main_dohod_headind_date">
              <span>{{t('cases.purchase_date')}}</span>
            </div>
          </div>
          <div class="cases__main_dohod_text" v-for="contract in activeContracts"
            :key="contract.contractId">
            <div class="cases__main_dohod_text_case">
              <span>{{contract.packageTitle}}</span>
              <span>( {{setDecimalPrice(contract.lockupInitBodyValue)}} {{defaultAsset.assetName}} )</span>
            </div>
            <div class="cases__main_dohod_text_month">
              <span>{{farmingPercentToValute(contract, 30)}}</span>
              <span>{{defaultAsset.assetName}}</span>
            </div>
            <div class="cases__main_dohod_text_year">
              <span>{{farmingPercentToValute(contract, 365)}}</span>
              <span>{{defaultAsset.assetName}}</span>
            </div>
            <div class="cases__main_dohod_text_earned">
              <span>{{setDecimalPrice(contract.earnedValue)}}</span>
              <span>{{defaultAsset.assetName}}</span>
            </div>
            <div class="cases__main_dohod_text_date">
              <span>{{getDate(contract.createdAt)}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { useI18n } from 'vue-i18n'
import { transactions } from '@/mixins/transactions';
import PlansBotRive from '../../components/RiveAnimations/PlansBotRive.vue';
import PlansBotRiveRight from '../../components/RiveAnimations/PlansBotRiveRight.vue';

export default {
  components: { 
    PlansBotRive, 
    PlansBotRiveRight 
  },
  mixins: [transactions],

  computed: {
    ...mapState({
      farmingInfo: state => state.farming.info,
      defaultAsset: state => state.asset.defaultAsset,
    }),

    currentAssetInfo() {
      return this.farmingInfo?.assetsList?.find(a => a.assetId === this.defaultAsset.assetId);
    },

    monthIncome() {
      if (!this.activeContracts?.length) return 0;
      
      return this.setDecimalPrice(this.activeContracts?.reduce((sum, contract) => sum + Number(contract.incomePerDay)*30, 0))
    },

    yearIncome() {
      return this.setDecimalPrice(this.monthIncome*365)
    },

    contracts() {
      return this.currentAssetInfo?.contractsList;
    },

    activeContracts() {
      return this.contracts
      // return this.contracts?.filter(c => c.closed !== true)
    },

    passiveEarnedValue() {
      return this.currentAssetInfo?.state?.bonusesList.find(a => a.bonusId === 4)?.earnedValue;
    },

    totalInvestedValue() {
      return this.currentAssetInfo?.state?.lockupInitBodyTotalValue;
    }
  },

  methods: {
    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    farmingPercentToValute(p, days) {
      const koef = +p.farmingDuration / 86400;
      return +(+p.farmingPercent*koef*days*p.lockupInitBodyValue).toFixed(2);
    }
  },

  created() {
    // this.$store.dispatch('getWalletAssetsAll')
    this.$store.dispatch('getAllAssets')
    this.$store.dispatch('getFarmingInfo')
  },
  
  mounted() {
    this.$emit('pageUpdate', true)
  },

  unmounted() {
    this.$emit('pageUpdate', false)
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  },

};
</script>

<style lang="scss" scoped>
.cases__main_block {
  flex-basis: 1px;

  &>div {
    height: 100%;
  }
}

.cases__main_passive_block {
  padding-bottom: 1em;
}

.cases__main_stat {
  &_items {
    width: 100%;
    // display: grid;
    // grid-template-columns: 50% 50%;
    height: 100%;
  }

  &_item {
    height: 100%;
    width: 16.6875em;

    &:nth-child(2n+1) {
      justify-self: flex-start;
    }

    &:nth-child(2n) {
      justify-self: flex-end;
    }

    &_text_2 {
      height: 100%;
    }
  }

}
.mobileScreen {
  @media (max-width: 1024px) {
    .cases__main_stat {
      &_items {
        // grid-template-columns: 100%;
        display: flex;
      }

      &_item {
        &_text_2 {
          height: auto;
        }
      }
    }    
  }
}
</style>