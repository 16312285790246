<template>
  <div class="my-team">
    <div class="heading_block">
      <div class="heading_block__heading">
        <span>{{ t(`promo_materials`) }}</span>
      </div>
    </div>
    <div class="container">

      <div class="user">
        <div class="user__block">
          <div class="user__name">
            <span>{{userInfo?.login}}</span>
          </div>
          <div class="user__status">
            <span>{{ currentRankTitle }}</span>
          </div>
        </div>
        <div class="user__btn_block">
          <router-link to="/wallet" class="button-main">
            <span>{{t('header.myWallet')}}</span>
            <div class="button-main__gradient"></div>
          </router-link>
        </div>
      </div>
      <div class="my-team__main_section">
        <div class="my-team__statisticks_block">
          <PromoRive />
        </div>
        <div class="my-team__statisticks_block">
          <div class="my-team__statisticks_item">
            <div class="my-team__statisticks_item_heading">
              <span>{{ t(`menu["Promo"]`) }}</span>
            </div>
            <div class="my-team__statisticks_team_items">
              <div class="my-team__statisticks_team_item">
                <a :href="`presentations/Tokenza ${$i18n.locale}.pdf`" target="_blank" class="button-main">
                  <span>{{t('landing.item_16')}}</span>
                  <div class="button-main__gradient"></div>
                </a>
              </div>
            </div>
          </div>
          <div class="my-team__statisticks_item">
            <div class="my-team__statisticks_item_heading">
              <span>{{ t(`base["My Referral links"]`) }}</span>
            </div>
            <div class="my-team__statisticks_team_items" v-if="userInfo?.login">
              <div class="my-team__statisticks_team_item">
                <ButtonRefferal :linkBody="'https://tokenza.io/?ref='+userInfo?.login" linkTitle="Main page referral" />
              </div>
              <div class="my-team__statisticks_team_item">
                <ButtonRefferal :linkBody="'https://airdrop.tokenza.io/?ref='+userInfo?.login" linkTitle="TKZ Sale"/>
              </div>
              <div class="my-team__statisticks_team_item">
                <ButtonRefferal :linkBody="'https://tokenza.io/?login&ref='+userInfo?.login" linkTitle="Register referral"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapState } from 'vuex';
import { dataScrollUpdate } from '@/mixins/dataScrollUpdate'
import { transactions } from '@/mixins/transactions';
// import PartnersItem from '@/components/MyTeam/PartnersItem'
import ButtonRefferal from '../../components/UI/ButtonRefferal.vue';
import PromoRive from '../../components/RiveAnimations/PromoRive.vue';

export default {
  mixins: [ dataScrollUpdate, transactions ],

  components: {
    // PartnersItem,
    ButtonRefferal,
    PromoRive
  },

  data() {
    return {
      referralCopy: false,
      partnersActive: [],
      search: '',
      statisticPage: 1,
      statisticElemsCount: 6,
      searchFocus: false,

      partnerPage: 0,
      partnersList: [],

      defaultStatus: '-'
    }
  },

  computed: {
    ...mapState({
      userInfo: state => state.account.user.info,
      // ранг
      careerInfo: (state) => state.career.info,
    }),

    currentRankTitle() {
      return this.careerInfo?.ranksList?.find(rank => this.careerInfo.currentRankId === rank.rankId)?.rankTitle ?? this.defaultStatus
    },
  },

  methods: {
    copyReferralLink(refLink) {
      var Url = this.$refs.mylink;
      Url.value = window.location.origin+refLink;
      Url.select();
      const success = document.execCommand("copy");
      this.referralCopy = true

      if (success) {
        document.activeElement.blur();
        Url.value = refLink;
        setTimeout(() => this.referralCopy = false, 980)
      }
    },
  },

  created() {
    this.$store.dispatch('getAccountInfo')
    this.$store.dispatch("getCareerInfo")
  },

  mounted() {
    this.$emit('pageUpdate', true)
  },

  unmounted() {
    this.$emit('pageUpdate', false)
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  },
}
</script>

<style scoped lang="scss">
.user__btn_block {
  justify-content: flex-end;
}
.my-team__main_section {
  justify-content: center;
}

.my-team__statisticks_block {
  width: 26.125em;

  &:nth-child(1) {
    margin-right: 1em;
  }

  &:nth-child(2) {
    margin-left: 1em;
  }
}

.my-team__statisticks_team_items {
  margin-top: 2.125em !important;
  margin-bottom: 1.05em !important;
}
.my-team__statisticks_team_item {
  margin-right: 0;
  width: 100%;

  .button-main {
    width: 100%;
  }

  .button-refferal {
    width: 100% !important;
  }
}


@media (max-width: 1024px) {
  .mobileScreen {
    .my-team__statisticks_block {
      width: 26.125em;
      margin: 0;
    }

    .my-team__statisticks_item {
      margin-bottom: 1.5em;
    }

    .button-refferal {
      font-size: 1em;
    }
  }
}
</style>
