<template>
  <button :class="`item binary__pyramid_item_${lineIndex} ${hideElems && 'nohover'}`">
    <div :class="`binary__pyramid_item_${lineIndex}_img`" :style="{background: packageColors[currentPackageTitle]}">
      <img src="img/binary/user-icon.png" v-if="position?.account?.login" alt="">
    </div>
    <div class="binary__pyramid_item_text">
      <span :class="{hide: hideElems}">{{position?.account?.login }}</span>
      <span class="rank" :class="{hide: hideElems || !currentPackageTitle}">{{ currentPackageTitle ?? '-' }}</span>
      <span class="rank" :class="{hide: hideElems || !currentRankTitle}">{{ currentRankTitle ?? '-' }}</span>
    </div>
  </button>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: [
    'position',
    'itemIndex',
    'lineIndex',
    'nohover',
  ],

  computed: {
    ...mapState({
      // ранг
      ranksList: (state) => state.career.info?.ranksList,
      farmingPackages: (state) => state.farming.farmingPackages,
    }),

    currentRankTitle() {
      return this.ranksList?.find(r => r.rankId === this.position?.account?.currentRankId ?? -1)?.rankTitle;
    },

    currentPackageTitle() {
      return this.farmingPackages?.find(p => p.packageId === this.position?.account?.currentPackageId)?.packageTitle;
    },

    hideElems() {
      return this.position?.account?.login === undefined;
    }
  },
  
  data() {
    return {
      packageColors: {
        "Bronze": "#dfcb98",
        "Silver": "#8a8484",
        "Gold": "#dcc92d",
        "Platinum": "#c4c4c4",
        "Diamond": "#68d5f6",
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.item {
  transition: opacity .2s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: .7;
  }

  &.nohover {
    opacity: 1 !important;
    pointer-events: none;
  }
}

.binary__pyramid_item_1_img, .binary__pyramid_item_2_img, .binary__pyramid_item_3_img, .binary__pyramid_item_4_img {
  display: flex;
  align-items: center;
  justify-content: center;
  border: .1em solid #01ffc2;
  position: relative;
  z-index: 2;

  & img {
    width: 30%;
    border-radius: 0;
    object-fit: unset;
  }
}

.binary__pyramid_item {
  &_1 {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 10.8em;
      left: -10em;
      background-image: linear-gradient(to right, #68d5f6 33%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 2.5em 0.2em;
      background-repeat: repeat-x;
      width: 10.4em;
      height: 0.3em;
      transform: rotate(324deg);
    }

    &::after {
      content: '';
      position: absolute;
      top: 10.6em;
      left: 9em;
      background-image: linear-gradient(to right, #68d5f6 33%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 2.5em 0.2em;
      background-repeat: repeat-x;
      width: 10.5em;
      height: 0.3em;
      transform: rotate(41deg);
    }
  }

  &_2 {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 9.1em;
      left: -2.5em;
      background-image: linear-gradient(to right, #68d5f6 33%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 2.4em 0.2em;
      background-repeat: repeat-x;
      width: 7.2em;
      height: 0.3em;
      transform: rotate(302deg);
    }

    &::after {
      content: '';
      position: absolute;
      top: 10.5em;
      left: 7.9em;
      background-image: linear-gradient(to right, #68d5f6 33%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 2.4em 0.2em;
      background-repeat: repeat-x;
      width: 7.2em;
      height: 0.3em;
      transform: rotate(60deg);
    }
  }

  &_3 {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 6.76em;
      left: -4.1em;
      background-image: linear-gradient(to right, #68d5f6 33%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 2.5em 0.2em;
      background-repeat: repeat-x;
      width: 13.5em;
      height: 0.3em;
      transform: rotate(292deg);
    }

    &::after {
      content: '';
      position: absolute;
      top: 8.3em;
      left: 1.6em;
      background-image: linear-gradient(to right, #68d5f6 33%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 2.5em 0.2em;
      background-repeat: repeat-x;
      width: 11.5em;
      height: 0.3em;
      transform: rotate(70deg);
    }
  }
}

  .binary__pyramid_item_text span {
    font-size: 0.8375em;
  }

  .binary__pyramid_item_text span.rank {
    font-size: .8356em;
    background: rgb(45, 31, 89) !important;
    z-index: 2;
  }
</style>