export const transactions = {
  data() {
    return {
      transactionsList: [
        {
          transactionId: 1,
          kindId: 4,
          createdAt: 1675524111878,
          balance: "0.000"
        },
        {
          transactionId: 2,
          kindId: 5,
          createdAt: 1677511311878,
          balance: "0.000"
        },
      ],
      
      transactionsFilterValue: null,
      
      iconsValues: { 
        BTC: {
          0: {
            iconIndex: -1,
            iconTitle: "unknown"
          },
          3: {
            iconIndex: 4,
            iconTitle: "spent"
          },
          4: {
            iconIndex: 2,
            iconTitle: "deposit"
          },
          6: {
            iconIndex: 2,
            iconTitle: "deposit"
          },
          5: {
            iconIndex: 4,
            iconTitle: "withdrawal"
          },
          7: {
            iconIndex: 4,
            iconTitle: "withdrawal"
          },
        },
        TKZ: {
          0: {
            iconIndex: -1,
            iconTitle: "unknown"
          },
          2: {
            iconIndex: 2,
            iconTitle: "earned"
          },
          4: {
            iconIndex: 2,
            iconTitle: "deposit"
          },
          6: {
            iconIndex: 2,
            iconTitle: "deposit"
          },
          5: {
            iconIndex: 4,
            iconTitle: "withdrawal"
          },
          7: {
            iconIndex: 4,
            iconTitle: "withdrawal"
          },
        },
        USDT: {
          0: {
            iconIndex: -1,
            iconTitle: "unknown"
          },
          1: {
            iconIndex: 4,
            iconTitle: "canceled"
          },
          2: {
            iconIndex: 2,
            iconTitle: "earned"
          },
          3: {
            iconIndex: 3,
            iconTitle: "matching_bonus"
          },
          4: {
            iconIndex: 3,
            iconTitle: "deposit_tokenza_ai"
          },
          5: {
            iconIndex: 3,
            iconTitle: "binary_bonus"
          },
          6: {
            iconIndex: 3,
            iconTitle: "matching_bonus"
          },
          7: {
            iconIndex: 4,
            iconTitle: "withrawal_wallet_transfer"
          },
          8: {
            iconIndex: 3,
            iconTitle: "exchange"
          },
        },

      },

    }
  },

  methods: {
    setDecimalPrice(price) {
      return Number(price).toFixed(2)
    },
    
    getCurrentIcon(kindId, currency) {
      if (Object.keys(this.iconsValues[currency]).find(id => Number(id) === kindId) === undefined) return 1;
      return this.iconsValues[currency][kindId] ?? {
        iconIndex: 3,
        iconTitle: "earned"
      }
    },
    
    addWeeksToDate(date, days, hours) {
      var result = new Date(date);
      if (days !== null) result.setDate(result.getDate() + days);
      else result.setHours(result.getHours() + hours);
      return result;
    },

    getDate(date) {
      return `${new Date(+date*1000).getDate()}.${new Date(+date*1000).getMonth()+1}.${new Date(+date*1000).getFullYear()}`
    }
  },
}