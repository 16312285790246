<template>
  <popup-template popupName="wallet-depostin" @closePopup="$emit('closePopup')">
    <div class="popups__heading">
      <span>{{t('popups.deposit.deposit')}}</span>
    </div>
    <div class="popups__tax_block">
      <div class="popups__tax_1">
        <img :src="`img/wallet/${currentAsset.assetName.toLowerCase()}.png`" alt="" />
        <span>{{currentAsset.assetTitle}}</span>
      </div>
      <!-- <div class="popups__tax_2"><span>TRC20</span></div> -->
    </div>
    <div class="popups__qr_code">
      <QrCode :value="qrCodeValue" />
      <!-- <img src="img/wallet/qr.png" alt="" /> -->
    </div>
    <div class="popups__wallet_adress_block">
      <div class="popups__wallet_adress_heading">
        <div class="popups__tax_1">
          <span>{{t('popups.deposit.wallet_address')}}</span>
          <span>{{currentAsset.assetTitle}}</span>
        </div>
      </div>
      <div class="popups__wallet_adress" :class="{copy: addressCopy}"
        @click="copyAdress(depositAdress)">
          <input ref="mylink" type="text" :disabled="addressCopy === true" :value="depositAdress" />
        <img src="img/my-team/copy.png" alt="" />
        <div class="popups__copy_dialog" :class="{copy: addressCopy}">{{t('base.copy')}}</div>
      </div>
    </div>
    <button class="popups__btn_done close" @click="$emit('closePopup')">
      <span>{{t('popups.deposit.done')}}</span>
    </button>
  </popup-template>
</template>

<script>
import { mapState } from 'vuex';
import PopupTemplate from "./PopupTemplate";
import {copyMethods} from "@/mixins/copy"
import QrCode from '@/components/QrCode/QrCode.vue';
import { useI18n } from 'vue-i18n'

export default {
  components: {
    PopupTemplate,
    QrCode
  },

  mixins: [copyMethods],

  computed: {
    ...mapState({
      currentAsset: state => state.asset.asset,
      processingList: (state) => state.finance.processingList,
      depositAdress: state => state.finance.depositAdress
    }),

    processing() {
      return this.getProcessing(this.processingList)
    },

    qrCodeValue() {
      return this.depositAdress
    }
  },

  data() {
    return {
      addressCopy: false,
    }
  },

  methods: {
    getProcessing(processingList) {
      return processingList?.find(processingItem => processingItem.assetsList.find(pAsset => pAsset.assetId === this.currentAsset.assetId) !== undefined);
    },

    copyAdress(address) {
      var Url = this.$refs.mylink;
      Url.value = address;
      Url.select();
      const success = document.execCommand("copy");
      this.addressCopy = true

      if (success) {
        document.activeElement.blur();
        Url.value = address;
        setTimeout(() => this.addressCopy = false, 980)
      }
    },

  },

  created() {
    if (this.processing === undefined) {
      this.$store.dispatch('getWalletProcessingsAll').then((response) => {
        this.$store.dispatch('generateDepositAddress', { processingId: this.getProcessing(response).processingId })
      })
    } else {
      this.$store.dispatch('generateDepositAddress', { processingId: this.processing.processingId })
    }
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  }
};
</script>

<style scoped lang="scss">
.popups {
  &__wallet_adress {
    transition: background .5s ease-in-out;

    img {
      will-change: transform;
      transform: scale(.9);
      transition: transform .13s ease-in-out;
    }

    &.copy {
      background: rgba(234, 187, 78, .4);
      backface-visibility: hidden;

      & img {
        transform: scale(1);
      }
    }
  }

  &__qr_code canvas {
    width: 24.3em !important;
    height: 24.3em !important;
  }


  &__copy_dialog {
      position: absolute;
      top: -3em;
      right: 0;
      // background: #fff;
      background: #292b2b;
      padding: .5em .8em;
      border-radius: .5em;
      color: #ffffff;
      font-weight: 600;
      pointer-events: none;
      user-select: none;
      opacity: 0;
  transform: translate(0, 83%) scale(.5);
      transition: transform .2s ease-in-out, opacity .2s ease-in-out;

      &.copy {
          animation: dialogShow .8s forwards ease-in-out;
      }
  }

  &__wallet_adress {
    input {
      border: 0;
      background: transparent;
      font-size: .95em;
      line-height: 1;
      color: #ffffff;
      font-weight: bold;
      position: relative;
      // top: 0.10526em;
      pointer-events: none;
      outline: none;
      user-select: none;
      padding: 0 !important;
      // background: #000;
      appearance: none;
      -webkit-appearance: none;
      //text-align: center;
        width: 100%;

      &::selection {
        text-decoration: none;
        color: #ffffff;
        background-color: transparent;
      }
    }
  }
}
</style>
