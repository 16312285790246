<template>
  <component :is="currentComponent" @openPopup="data => $emit('openPopup', data)" />
</template>

<script>
import HomePage from './HomePage.vue';
import WelcomePage from './WelcomePage.vue';

import {auth} from '@/mixins/auth';

export default {
  components: {
    HomePage,
    WelcomePage
  },

  mixins: [auth],

  data() {
    return {
      currentComponent: WelcomePage
    }
  },

  methods: {
    getCurrentComponent() {
      return this.getAuthState() ? HomePage : WelcomePage;
    }
  },

  created() {
    this.currentComponent = this.getCurrentComponent();
  },

  mounted() {
    this.$emit('pageUpdate', true)
  },

  unmounted() {
    this.$emit('pageUpdate', false)
  }
};
</script>

<style lang="scss" scoped>
button {
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: 0;
  font-size: 1em
}
</style>
