import { AuthClient } from "../../../../apis/mlmbox/client/auth/v1/auth_grpc_web_pb";
import { SignInRequest, SignUpRequest, PasswordRecoveryRequest } from "../../../../apis/mlmbox/client/auth/v1/auth_pb";
import { Empty } from 'google-protobuf/google/protobuf/empty_pb.js'
import { API_URL } from '@/api';

const client = new AuthClient(API_URL, true, {
  withCredentials: true
});

export default {
  state: {
    authStateChecked: false,
    confirmation: {},
  },

  mutations: {
    setConfirmation(state, confirmation) {
      state.confirmation = confirmation
    },
    setAuthStateChecked(state, authStateChecked) {
      state.authStateChecked = authStateChecked
    },
  },

  actions: {
    setAuthStateChecked({ commit }, value) {
      commit('setAuthStateChecked', value)
    },
    
    signIn({ commit }, { login, password }) {
      return new Promise((resolve, reject) => {
        const request = new SignInRequest();

        request.setId(login)
        request.setPassword(password)
        
        client.signIn(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
          if (err !== null) {
            reject(err)
          } else {
            commit('setConfirmation', response.toObject().confirmation);
            
            resolve(response.toObject())
          }
        })
      })
    },

    signUp({ commit }, data) {
      return new Promise((resolve, reject) => {
        const request = new SignUpRequest();
        
        // request.setReferral(data.referral === '' ? 'root' : data.referral) // required
        request.setReferral(data.referral === '' ? '' : data.referral) // required
        request.setLogin(data.login)
        request.setEmail(data.email)
        request.setPassword(data.password)
        // request.setLocale(data.locale)
        // request.setLocale('en-US')

        client.signUp(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
          if (err !== null) {
            reject(err)
          } else {
            commit('setConfirmation', response.toObject().confirmation);
            resolve(response.toObject())
          }
        })
      })
    },

    passwordRecovery({ commit }, { email, password }) {
      return new Promise((resolve, reject) => {
        const request = new PasswordRecoveryRequest();

        request.setEmail(email)
        request.setPasswordNew(password)

        client.passwordRecovery(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
          if (err !== null) {
            console.log(err)
            reject(err)
          } else {
            if (response.getStatus() === 1) {
              console.log(response.toObject())
              commit('setConfirmation', response.toObject().confirmation);
              resolve(response.toObject())
            }
          }
        })
      })
    },

    tokenValidate({commit}) {
      return new Promise((resolve, reject) => {
        const request = new Empty;

        client.tokenValidate(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
          if (err !== null) {
            reject(err)
          } else {
            commit('stub');
            resolve(response.toObject())
          }
        })
      })
    },

  }
}