// source: mlmbox/client/google_authenticator/v1/google_authenticator.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var mlmbox_type_confirmation_pb = require('../../../../mlmbox/type/confirmation_pb.js');
goog.object.extend(proto, mlmbox_type_confirmation_pb);
var mlmbox_type_google_authenticator_pb = require('../../../../mlmbox/type/google_authenticator_pb.js');
goog.object.extend(proto, mlmbox_type_google_authenticator_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
