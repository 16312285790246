<template>
  <div class="settings__item_active_session tab-content" id="tab_4">
    <div class="settings__item_pass_heading">
      <span>{{t('settings.sessions.title')}}</span>
    </div>
    <div class="settings__item_pass_text">
      <span>{{t('settings.sessions.this_device')}}</span>
    </div>
    <div class="settings__item_active_session_block"> 
      <div class="settings__item_active_session_text_1">
        <div class="settings__item_active_session_text_1-1">
          <span>
            {{session?.current?.description?.agent?.browserName}} 
            {{session?.current?.description?.agent?.platform}}
            {{session?.current?.description?.agent?.osVersion}}
          </span>
        </div>
        <div class="settings__item_active_session_text_1-2">
          <span class="now">{{t('settings.sessions.online')}}</span>
        </div>
      </div>
      <div class="settings__item_active_session_text_2">
        <span>Tokenza
          {{session?.current?.description?.agent?.platform}} 
          {{session?.current?.description?.agent?.osVersion}}
          |
          {{session?.current?.description?.agent?.browserName}} 
          {{session?.current?.description?.agent?.browserVersion}}
        </span>
      </div>
      <div class="settings__item_active_session_btn_block">
        <button class="settings__change_btn session" v-if="session?.othersList?.length > 0" @click.prevent="deactivateOther">
          <span>{{t('settings.sessions.end_other_sessions')}}</span>
        </button>
      </div>
    </div>
    <!-- <div class="settings__item_pass_text_2"><span>Выйти на всех устройствах кроме этого.</span></div> -->
    <div class="settings__item_active_session_block_2">
      <div class="settings__item_active_session_block_left" v-if="session?.othersList?.length > 0"> 
        <div class="settings__item_pass_text_3">
          <span>{{t('settings.sessions.active_sessions')}}</span>
        </div>

        <div class="settings__item_active_session_block_left_block">
          <div class="settings__item_active_session_block_left_block_wrapper">
            <div class="settings__item_active_session_block_left_text" v-for="s in session?.othersList"
              :key="s.id">
              <div class="settings__item_active_session_text_1-1">
                <span>
                  {{s?.description?.agent?.browserName}} 
                  {{s?.description?.agent?.platform}} 
                  {{s?.description?.agent?.osVersion}}  
                </span>
                &nbsp;
                <span class="time">
                  {{ new Date(s?.createdAt * 1000).toISOString().slice(0, 19).replace('T', ' ') }}  
                </span>
              </div>
              <div class="settings__item_active_session_text_2"> 
                <span>Tokenza
                  {{s?.description?.agent?.platform}} 
                  {{s?.description?.agent?.osVersion}} 
                  |
                  {{s?.description?.agent?.browserName}} 
                  {{s?.description?.agent?.browserVersion}}
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="settings__item_active_session_block_left"> 
        <div class="settings__item_pass_text_3">
          <span>{{t('settings.sessions.auto_end_sessions')}}</span>
        </div>
        <div class="settings__item_active_session_block_left_block">
          <div class="settings__item_active_session_block_left_text">
            <div class="settings__item_active_session_text_1-1">
              <span>{{t('settings.sessions.if_inactive')}}</span>
            </div>
          </div>
          <div class="settings__item_active_session_period">
            <button @click.prevent="setExpireDuration(addWeeksToDate(session.current?.createdAt, 7))" :class="{active: optionValue === addWeeksToDate(session.current?.createdAt, 7)}" class="settings__item_active_session_period_item"><span>{{t('settings.sessions.filter.1_week')}}</span></button>
            <button @click.prevent="setExpireDuration(addWeeksToDate(session.current?.createdAt, 90))" :class="{active: optionValue === addWeeksToDate(session.current?.createdAt, 90)}" class="settings__item_active_session_period_item"><span>{{t('settings.sessions.filter.3_months')}}</span></button>
            <button @click.prevent="setExpireDuration(addWeeksToDate(session.current?.createdAt, 180))" :class="{active: optionValue === addWeeksToDate(session.current?.createdAt, 180)}" class="settings__item_active_session_period_item"><span>{{t('settings.sessions.filter.6_months')}}</span></button>
            <button @click.prevent="setExpireDuration(addWeeksToDate(session.current?.createdAt, 365))" :class="{active: optionValue === addWeeksToDate(session.current?.createdAt, 365)}" class="settings__item_active_session_period_item"><span>{{t('settings.sessions.filter.1_year')}}</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import { useI18n } from 'vue-i18n'

export default {
  data() {
    return {
      optionValue: 0
    }
  },

  computed: {
    ...mapState({
      session: state => state?.session?.session,
    })
  },

  methods: {
    deactivateOther() {
      this.$store.dispatch('deactivateDeactivateOther').then(() => {
        this.$store.dispatch('getSessionState')
      })
    },

    addWeeksToDate(date, numberOfDays) {
      const dateObj = new Date(date * 1000);
      dateObj.setDate(dateObj.getDate() + numberOfDays);
      
      return parseInt((dateObj.getTime() / 1000).toFixed(0))-date;
    },

    setExpireDuration(value) {
      this.$store.dispatch('deactivateExpireDurationSet', {durationValue: value, durationInfinity: false}).then((response) => {
        this.optionValue = response.value;
        this.$store.dispatch('getSessionState').then(() => {
          this.optionValue = this.session.expireDuration;
        })
        // this.$router.go()
      })
    }
  },

  created() {
    this.$store.dispatch('getSessionState').then(() => {
      this.optionValue = this.session.expireDuration;
    })
  },
  
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  }
}
</script>

<style lang="scss" scoped>
.mobileScreen {
  @media (max-width: 1024px) {
    .settings__item_active_session_period_item {
      &:hover {
        background: #01ffc2 !important;
        span {
          color: #28185d !important;
        }
      }

      &:active {
        background: #01ffc2 !important;
        span {
          color: #28185d !important;
        }
      }
    }
  }
}

.settings__item_active_session_block_left_block {

  &_wrapper {
    width: 100%;
    height: 30em;
    overflow: hidden;
    overflow-y: auto;

    
    &::-webkit-scrollbar {
      height: em(8);
      width: em(8);
      transform: translateX(em(10));
      margin: 1em;
    }
    &::-webkit-scrollbar-track {
      background: rgba(1, 255, 194, .2);
      border-radius: em(10);
      margin: em(5);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #01ffc2;
      border-radius: em(10);
      border: 0;
    }

    &::-webkit-scrollbar-corner {
      background: rgba(1, 255, 194, .2);
      background: transparent;
    }
  }
}
</style>