<template>
  <div class="wallet">
    <div class="heading_block">
      <div class="heading_block__heading"> <span>{{t('menu.Wallet')}}</span></div>
    </div>

    <TabWallet @openPopup="data => $emit('openPopup', data)"/>
  </div>
</template>

<script>
import TabWallet from '@/components/Wallet/TabWallet'
import {auth} from '@/mixins/auth'
import { mapState } from 'vuex';
import { useI18n } from 'vue-i18n'
import { transactions } from '@/mixins/transactions';

export default {
  mixins: [
    auth,
    transactions
  ],

  components: {
    TabWallet,
  },

  computed: {
    ...mapState({
      userInfo: state => state.account.user.info,

      currentAsset: state => state.asset.asset
    }),
  },

  methods: {
    getProcessing() {
      
    },
  }, 

  mounted() {
    this.$emit('pageUpdate', true)
  },

  unmounted() {
    this.$emit('pageUpdate', false)
  },
  
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  },

};
</script>

<style lang="scss">
</style>