<template>
  <div class="promo-rive__container">
    <canvas ref="canvas" width="1100" height="1100" class="promo-rive"></canvas>
  </div>
</template>

<script>
import {Rive, Layout, Fit, Alignment} from '@rive-app/canvas';

let riveAnimation;

export default {
  name: 'PromoRive',

  props: {
    src: String,
    fit: String,
    alignment: String
  },
  
  mounted() {
    riveAnimation = new Rive({
      canvas: this.$refs.canvas,
      src: "rive/blog_bot.riv",
      // src: "rive/newsletter_bot.riv",
      layout: new Layout({
        fit: Fit.Cover,
        alignment: Alignment.TopCenter,
      }),
      autoplay: true,
      onLoad: () => {
        riveAnimation.resizeDrawingSurfaceToCanvas()
      },
    })
  },

  unmounted() {
    if (riveAnimation !== undefined && riveAnimation !== null) {
      riveAnimation.cleanupInstances(); // Остановить анимацию
      
      riveAnimation = null; // Очистить ссылку на анимацию
    }
  }
}
</script>
<style lang="scss" scoped>
.promo-rive {
  display: block;
  width: 26em;
  height: 26em;
  border-radius: 2em;

  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>