<template>
  <button class="button-refferal" :class="{copy: referralCopy}"
    @click="copyReferralLink(linkBody ?? (getDomain() + '#/?ref='+userInfo?.login))">
    <input ref="mylink" type="text" :disabled="referralCopy === true" :value="`${linkTitle ?? t('base.refferalLink')}`" />
    <span>{{linkTitle ?? t('base.refferalLink')}}</span>
    <div class="button-refferal_dialog" :class="{copy: referralCopy}">{{t('base.copy')}}</div> 
  </button>
</template>

<script>
import { useI18n } from "vue-i18n";
import { mapState } from 'vuex';

export default {
  props: [
    'linkBody',
    'linkTitle',
  ],

  data() {
    return {
      referralCopy: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.account.user.info,
    })
  },

  methods: {
    copyReferralLink(refLink) {
      var Url = this.$refs.mylink;
      Url.value = refLink;
      Url.select();
      const success = document.execCommand("copy");
      this.referralCopy = true;

      if (success) {
        document.activeElement.blur();
        Url.value = refLink;
        setTimeout(() => (this.referralCopy = false), 980);
      }
    },

    getDomain() {
      return window.location.origin;
    }
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

    return { t };
  },
}
</script>

<style scoped lang="scss">
.button-refferal {
  z-index: 5;
  position: relative;
  
  span {
    cursor: pointer;
  }

  input, &_dialog {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }

  &_dialog {
    top: -2.5em;
    right: 0;
    color: #ffffff;
    background: #341e5a;
    padding: .5em .8em;
    border-radius: .2em;
    transition: opacity .2s ease-in-out;
    pointer-events: none
  }

  &.copy &_dialog {
    opacity: 1;
    transition: opacity .2s ease-in-out;
  }

}
.mobileScreen .button-refferal {
  z-index: 9;
}
</style>
