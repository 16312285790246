/**
 * @fileoverview gRPC-Web generated client stub for mlmbox.client.staking.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.15.8
// source: mlmbox/client/staking/v1/staking.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var mlmbox_type_staking_pb = require('../../../../mlmbox/type/staking_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.mlmbox = {};
proto.mlmbox.client = {};
proto.mlmbox.client.staking = {};
proto.mlmbox.client.staking.v1 = require('./staking_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mlmbox.client.staking.v1.StakingClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mlmbox.client.staking.v1.StakingPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.type.Staking.Package.ID,
 *   !proto.mlmbox.type.Staking.Package>}
 */
const methodDescriptor_Staking_PackageGet = new grpc.web.MethodDescriptor(
  '/mlmbox.client.staking.v1.Staking/PackageGet',
  grpc.web.MethodType.UNARY,
  mlmbox_type_staking_pb.Staking.Package.ID,
  mlmbox_type_staking_pb.Staking.Package,
  /**
   * @param {!proto.mlmbox.type.Staking.Package.ID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_staking_pb.Staking.Package.deserializeBinary
);


/**
 * @param {!proto.mlmbox.type.Staking.Package.ID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.type.Staking.Package)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Staking.Package>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.staking.v1.StakingClient.prototype.packageGet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.staking.v1.Staking/PackageGet',
      request,
      metadata || {},
      methodDescriptor_Staking_PackageGet,
      callback);
};


/**
 * @param {!proto.mlmbox.type.Staking.Package.ID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.type.Staking.Package>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.staking.v1.StakingPromiseClient.prototype.packageGet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.staking.v1.Staking/PackageGet',
      request,
      metadata || {},
      methodDescriptor_Staking_PackageGet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.client.staking.v1.PackageBuyRequest,
 *   !proto.mlmbox.type.Staking.Contract>}
 */
const methodDescriptor_Staking_PackageBuy = new grpc.web.MethodDescriptor(
  '/mlmbox.client.staking.v1.Staking/PackageBuy',
  grpc.web.MethodType.UNARY,
  proto.mlmbox.client.staking.v1.PackageBuyRequest,
  mlmbox_type_staking_pb.Staking.Contract,
  /**
   * @param {!proto.mlmbox.client.staking.v1.PackageBuyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_staking_pb.Staking.Contract.deserializeBinary
);


/**
 * @param {!proto.mlmbox.client.staking.v1.PackageBuyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.type.Staking.Contract)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Staking.Contract>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.staking.v1.StakingClient.prototype.packageBuy =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.staking.v1.Staking/PackageBuy',
      request,
      metadata || {},
      methodDescriptor_Staking_PackageBuy,
      callback);
};


/**
 * @param {!proto.mlmbox.client.staking.v1.PackageBuyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.type.Staking.Contract>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.staking.v1.StakingPromiseClient.prototype.packageBuy =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.staking.v1.Staking/PackageBuy',
      request,
      metadata || {},
      methodDescriptor_Staking_PackageBuy);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.mlmbox.type.Staking.Package>}
 */
const methodDescriptor_Staking_PackagesAll = new grpc.web.MethodDescriptor(
  '/mlmbox.client.staking.v1.Staking/PackagesAll',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  mlmbox_type_staking_pb.Staking.Package,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_staking_pb.Staking.Package.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Staking.Package>}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.staking.v1.StakingClient.prototype.packagesAll =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mlmbox.client.staking.v1.Staking/PackagesAll',
      request,
      metadata || {},
      methodDescriptor_Staking_PackagesAll);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Staking.Package>}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.staking.v1.StakingPromiseClient.prototype.packagesAll =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mlmbox.client.staking.v1.Staking/PackagesAll',
      request,
      metadata || {},
      methodDescriptor_Staking_PackagesAll);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.mlmbox.type.Staking.Account>}
 */
const methodDescriptor_Staking_Info = new grpc.web.MethodDescriptor(
  '/mlmbox.client.staking.v1.Staking/Info',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  mlmbox_type_staking_pb.Staking.Account,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_staking_pb.Staking.Account.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.type.Staking.Account)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Staking.Account>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.staking.v1.StakingClient.prototype.info =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.staking.v1.Staking/Info',
      request,
      metadata || {},
      methodDescriptor_Staking_Info,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.type.Staking.Account>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.staking.v1.StakingPromiseClient.prototype.info =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.staking.v1.Staking/Info',
      request,
      metadata || {},
      methodDescriptor_Staking_Info);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.type.Staking.Contract.ID,
 *   !proto.mlmbox.type.Staking.Contract>}
 */
const methodDescriptor_Staking_ContractGet = new grpc.web.MethodDescriptor(
  '/mlmbox.client.staking.v1.Staking/ContractGet',
  grpc.web.MethodType.UNARY,
  mlmbox_type_staking_pb.Staking.Contract.ID,
  mlmbox_type_staking_pb.Staking.Contract,
  /**
   * @param {!proto.mlmbox.type.Staking.Contract.ID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_staking_pb.Staking.Contract.deserializeBinary
);


/**
 * @param {!proto.mlmbox.type.Staking.Contract.ID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.type.Staking.Contract)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Staking.Contract>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.staking.v1.StakingClient.prototype.contractGet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.staking.v1.Staking/ContractGet',
      request,
      metadata || {},
      methodDescriptor_Staking_ContractGet,
      callback);
};


/**
 * @param {!proto.mlmbox.type.Staking.Contract.ID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.type.Staking.Contract>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.staking.v1.StakingPromiseClient.prototype.contractGet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.staking.v1.Staking/ContractGet',
      request,
      metadata || {},
      methodDescriptor_Staking_ContractGet);
};


module.exports = proto.mlmbox.client.staking.v1;

