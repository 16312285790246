<template>
  <div class="settings">
    <div class="heading_block">
      <div class="heading_block__heading">
        <span>{{ t(`menu["Settings"]`) }}</span>
      </div>
    </div>
    <div class="container">
      <div class="user">
        <div class="user__block">
          <div class="user__name">
            <span>{{userInfo?.login}}</span>
          </div>
          <div class="user__status">
            <span>{{currentRankTitle }}</span>
          </div>
        </div>
        <div class="user__btn_block">
          <router-link to="/wallet" class="button-main">
            <span>{{t('header.myWallet')}}</span>
            <div class="button-main__gradient"></div>
          </router-link>

          <ButtonRefferal />
        </div>
      </div>

      <div class="settings__top_bar">
        <div class="settings__signout_btn_block">
          <ButtonEmail />
          <button class="button-main signout-btn" @click="signOut">
            <span>{{ t('settings.sign_out') }}</span>
          </button>
        </div>
        <div class="settings__navigation_block">
          <button class="settings__navigation_item" data-tab="#tab_1" @click="updateTabComponent('TabPassword')" :class="{active: currentComponent === 'TabPassword'}">
            <span>{{ t('settings.change_password') }}</span>
          </button>
          <button class="settings__navigation_item" data-tab="#tab_2" @click="updateTabComponent('TabEmail')" :class="{active: currentComponent === 'TabEmail'}">
            <span>{{ t('settings.change_mail') }}</span>
          </button>
          <button class="settings__navigation_item" data-tab="#tab_3" @click="updateTabComponent('Tab2fa')" :class="{active: currentComponent === 'Tab2fa'}">
            <span>{{ t('settings.google_2FA_setup') }}</span>
          </button>
          <button class="settings__navigation_item" data-tab="#tab_4" @click="updateTabComponent('TabSessions')" :class="{active: currentComponent === 'TabSessions'}">
            <span>{{ t('settings.active_sessions') }}</span>
          </button>

          <button class="settings__navigation_item" data-tab="#tab_5" @click="updateTabComponent('TabBot')" :class="{active: currentComponent === 'TabBot'}">
            <span>Tokenza Bot</span>
          </button>
        </div>
      </div>
      <div class="settings__main_block">

        <component v-bind:is="currentComponent" class="active" @openPopup="data => $emit('openPopup', data)" />

        <SettingsBotRive />
      </div>
    </div>
  </div>
</template>

<script>
import {auth} from '@/mixins/auth'

import TabPassword from '../../components/Settings/TabPassword.vue';
import TabEmail from '../../components/Settings/TabEmail.vue';
import Tab2fa from '../../components/Settings/TabTwoFa.vue';
import TabSessions from '../../components/Settings/TabSessions.vue';
import TabBot from '../../components/Settings/TabBot.vue';

import ButtonRefferal from '@/components/UI/ButtonRefferal.vue'
import SettingsBotRive from '@/components/RiveAnimations/SettingsBotRive.vue'

import { useI18n } from 'vue-i18n'
import { mapState } from 'vuex';
import ButtonEmail from '../../components/UI/ButtonEmail.vue';

export default {
  components: {
    TabPassword,
    TabEmail,
    Tab2fa,
    TabSessions,
    ButtonRefferal,
    SettingsBotRive,
    TabBot,
    ButtonEmail
  },

  mixins: [auth],

  data() {
    return {
      currentComponent: 'TabPassword',
      defaultStatus: "-"
    }
  },

  computed: {
    ...mapState({
      // userInfo
      userInfo: (state) => state.account.user.info,
      // ранг
      careerInfo: (state) => state.career.info,
    }),

    currentRankTitle() {
      return this.careerInfo?.ranksList?.find(rank => this.careerInfo.currentRankId === rank.rankId)?.rankTitle ?? this.defaultStatus
    },
  },

  methods: {
    updateTabComponent(componentName) {
      this.currentComponent = componentName
    },

    signOut() {
      this.$store.dispatch('deactivateDeactivateCurrent').then(() => {
        localStorage.setItem('userAuth', false);
        localStorage.setItem('access_token', null);

        if (this.$route.path !== '/') this.$router.push('/');
        else window.location.reload(true);
      })
    }
  },

  mounted() {
    this.$emit('pageUpdate', true)
  },

  unmounted() {
    this.$emit('pageUpdate', false)
  },

  created() {
    // this.$store.dispatch('getWalletAssetsAll')
    // this.$store.dispatch('getAllAssets')
    this.$store.dispatch('getAccountInfo')
    this.$store.dispatch('get2aInfo')

    if (this.userInfo === undefined)
      this.$store.dispatch("getAccountInfo").then(() => {
    });
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  },
};
</script>

<style scoped>
.settings__main_block {
  position: relative;
}
.tab-content {
  z-index: 1;
  position: relative;
}
.support-button {
}
</style>
