import { AssetPairClient } from "../../../../apis/mlmbox/client/asset_pair/v1/asset_pair_grpc_web_pb";
import { Empty } from 'google-protobuf/google/protobuf/empty_pb.js'
import { API_URL } from '@/api';

const client = new AssetPairClient(API_URL, null, {
    withCredentials: true
});

export default {
    state: {
        assetPairAll: [],
    },
  
    mutations: {
        setAssetPairAll(state, assetPairAll) {
            state.assetPairAll = assetPairAll;
        },
    },

    actions: {
        // getAssetPair({ commit }, pairId) {
        //     return new Promise((resolve, reject) => {
        //         const request = new Career.Rank.ID();

        //         request.setPairId(pairId)
      
        //         client.rankGet(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
        //             if (err !== null) {
        //                 reject(err)
        //             } else {
        //                 commit('setPairId', response.toObject())
        //                 resolve(response.toObject())
        //             }
        //         })
        //     })
        // },

        getAssetPairList({commit}) {
            const result = [];
            commit('setAssetPairAll', [])

            return new Promise((resolve) => {
                const request = new Empty;
                const stream = client.all(request, {"authorization": localStorage.getItem("access_token")})

                stream.on('data', function(response) {
                    resolve(response.toObject())
                    result.push(response.toObject());
                });

                stream.on('status', (status) => {
                    if (status.code === 0) {
                        commit('setAssetPairAll', result)
                    }
                });
            })
        },
    }
}