<template>
  <div class="ranks">
    <div class="heading_block">
      <div class="heading_block__heading">
      <div class="heading_block__heading">
        <span>{{ t(`menu["Career"]`) }}</span>
      </div>
      </div>
    </div>
    <div class="container">
      <div class="user">
        <div class="user__block">
          <div class="user__name">
            <span>{{userInfo?.login}}</span>
          </div>
          <div class="user__status">
            <span>{{currentRankTitle }}</span>
          </div>
        </div>
        <div class="user__btn_block">
          <router-link to="/wallet" class="button-main">
            <span>{{t('header.myWallet')}}</span>
            <div class="button-main__gradient"></div>
          </router-link>

          <ButtonRefferal />
        </div>
      </div>
      <div class="ranks__main_block">

        <div v-for="(rank, index) in careerInfo.ranksList"
          :key="rank"
          class="ranks__line_container"
          >
          <div class="ranks__number">
            <span>{{++index}}</span>
          </div>
          <div class="ranks__info_block first">
            <div class="ranks__info_block_heading">
              <span>{{ rank.rankTitle }}</span>
            </div>
            <div class="ranks__info_text_block">
                <div class="ranks__info_text_item" v-if="rank.giftsList.length !== 0 || (index > 1 && index < 7)">
                  <p v-if="rank.giftsList.length !== 0">
                      <span>{{ t('plans.prize') }}: </span>
                      <span v-for="gift in rank.giftsList" :key="gift">
                        <span v-if="gift.goods !== undefined">
                          {{ gift?.goods?.name }}
                        </span>
                        <span v-if="gift.money !== undefined">
                          {{ Number(gift?.money?.assetBonus) }} $
                        </span>
                      </span>
                    <br>
                  </p>
                  <span v-if="index > 1 && index < 7">+ {{index-1}}L {{ t('base.matching_bonus') }} {{matchingBonuses[index-2]}}</span>
                </div>
              <div class="ranks__info_text_item">
                <span>{{ t('plans.turnover') }}: </span>
                <span>{{ Number(rank.conditionBinaryLineTotalTurnoverLeast) }} $</span>
              </div>
              <div class="ranks__info_text_item">
                <span>{{ t('plans.conditions') }}:</span>
                <p>
                  <span v-html="conditions[index-1]"></span>
                </p>
              </div>
            </div>
          </div>
          <div :class="`ranks__info_progress_line`">
              <div v-if="completedRank(rank.rankId)" class="completedRank">
                  <img src="img/check.svg" alt="">
              </div>
            <div :style="`width: ${rankWidth(rank.rankId)}%`"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { useI18n } from 'vue-i18n'
import { transactions } from '@/mixins/transactions';
import ButtonRefferal from '../../components/UI/ButtonRefferal.vue';

export default {
  components: { ButtonRefferal },
  mixins: [transactions],

  data() {
    return {
      defaultStatus: "-",
      matchingBonuses: [
        '50%',
        '10%',
        '5%',
        '3%',
        '3%',
      ],
    }
  },

  computed: {
    ...mapState({
      // данные пользователя
      userInfo: (state) => state.account.user.info,
      // ранг
      careerInfo: (state) => {
        console.log(state.career.info);
        return state.career.info
      },

      farmingInfo: state => state.farming.info,
      asset: state => state.asset.asset,
      contracts: state => state.farming.info.contractsList,
    }),

    conditions() {
      return [
        '2 '+ this.t('career.condition_1'),
        '2 partners '+ this.t('career.condition_2'),
        '2 Managers + 1 Partner',
        '1 Leader + 2 Managers',
        '2 Directors + 2 Leaders',
        '1 President + 3 Directors',
        '5 Presidents',
      ]
    },

    currentRankTitle() {
      return this.careerInfo?.ranksList?.find(rank => this.careerInfo.currentRankId === rank.rankId)?.rankTitle ?? this.defaultStatus
    },

    passiveYearIncome() {
      return this.setDecimalPrice(+this.currentAssetInfo?.passiveIncomePerDayTotalValue*365);
    },

    currentAssetInfo() {
      return this.farmingInfo?.assetsList?.find(a => a.assetId === this.asset.assetId);
    },

    activeContracts() {
      return this.contracts?.filter(c => c.closed !== true)
    },
  },

  methods: {
    farmingPercentToValute(p, days) {
      const koef = +p.farmingDuration / 86400;
      return +(+p.farmingPercent*koef*days*p.lockupInitBodyValue).toFixed(2);
    },

    completedRank(rankId) {
      return this.careerInfo?.currentRankId >= rankId;
    },

    rankWidth(rankId) {
      return this.completedRank(rankId) ? 100 : 0
    }
  },

  created() {
    this.$store.dispatch("getCareerInfo")

    if (this.userInfo === undefined)
      this.$store.dispatch("getAccountInfo").then(() => {
    });
  },

  mounted() {
    this.$emit('pageUpdate', true)
  },

  unmounted() {
    this.$emit('pageUpdate', false)
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  },

};
</script>

<style scoped>
.ranks__info_text_item span:last-child {
  margin-top: .5em;
  margin-left: 0;
}

.ranks__info_progress_line::after {
  content: none;
}

.ranks__info_progress_line div {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(90deg, #4e3cad 0%, #982bb0 100%);
  border-radius: 1.25em;
}

.completedRank {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 3em;
    }
}
</style>
