<template>
  <div class="my-team">
    <div class="heading_block">
      <div class="heading_block__heading">
        <span>{{ t(`menu["My team"]`) }}</span>
      </div>
    </div>
    <div class="container">

      <div class="user">
        <div class="user__block">
          <div class="user__name">
            <span>{{userInfo?.login}}</span>
          </div>
          <div class="user__status">
            <span>{{ currentRankTitle }}</span>
          </div>
        </div>
        <div class="user__btn_block">
          <router-link to="/wallet" class="button-main">
            <span>{{t('header.myWallet')}}</span>
            <div class="button-main__gradient"></div>
          </router-link>

          <ButtonRefferal />
        </div>
      </div>
      <div class="my-team__main_section">
        <div class="my-team__partners_block">
          <div class="my-team__partners_heading_block">
            <div class="my-team__partners_heading">
              <span>{{ t('home.partners') }}</span>
            </div>
            <div class="my-team__search_block">
              <input class="my-team__search"
                id="myTeamSearch"
                type="text"
                name="myTeamSearch"
                :placeholder="t('base.search')"
                v-model="search"
                @focus="searchFocus = true"
                @focusout="serarchFocusout">
                <img class="search-img" src="img/my-team/search.png" alt="">
            </div>
          </div>
          <div class="my-team__partners_info_container">
            <div class="my-team__partners_info_headings">
              <div class="my-team__partners_info_partner">
                <span>{{t('team.partnersList.partner')}}</span>
              </div>
              <div class="my-team__partners_info_line">
                <span>{{t('team.partnersList.line')}}</span>
              </div>
              <div class="my-team__partners_info_team">
                <span>{{t('team.partnersList.team')}}</span>
              </div>
              <div class="my-team__partners_info_investors">
                <span>{{t('team.partnersList.investors')}}</span>
              </div>
              <div class="my-team__partners_info_turnover">
                <span>{{t('team.partnersList.turnover')}}</span>
              </div>
            </div>
            <template v-if="partnersSearchList.length !== 0">
              <PartnersItem v-for="(partner, index) in partnersSearchList"
                :key="partner?.accountId"
                :index="index"
                :partner="partner" />
            </template>
            <div ref="afterPatnersList" class="afterPatnersList"></div>
          </div>
        </div>
        <div class="my-team__statisticks_block">
          <div class="my-team__statisticks_item">
            <div class="my-team__statisticks_item_heading">
              <span>{{ t(`menu["My team"]`) }}</span>
            </div>
            <div class="my-team__statisticks_team_items">
              <div class="my-team__statisticks_team_item">
                <div class="my-team__statisticks_team_item_heading">
                  <span>{{t("team.regQuantity")}}</span>
                </div>
                <div class="my-team__statisticks_team_item_number">
                  <span>{{userInfo?.teamStatistics?.quantity}}</span>
                </div>
              </div>
              <div class="my-team__statisticks_team_item">
                <div class="my-team__statisticks_team_item_heading">
                  <span>{{t("team.partners")}}</span>
                </div>
                <div class="my-team__statisticks_team_item_number">
                  <span>{{network?.account?.partnersStatistics?.quantity}}</span>
                </div>
              </div>
              <div class="my-team__statisticks_team_item">
                <div class="my-team__statisticks_team_item_heading">
                  <span>{{t("team.investors")}}</span>
                </div>
                <div class="my-team__statisticks_team_item_number">
                  <span>{{userInfo?.teamStatistics?.total?.ownersQuantity}}</span>
                </div>
              </div>
              <div class="my-team__statisticks_team_item">
                <div class="my-team__statisticks_team_item_heading">
                  <span>{{t("team.teamTurnover")}}</span>
                </div>
                <div class="my-team__statisticks_team_item_number last">
                  <span>{{setDecimalPrice(userInfo?.teamStatistics?.total?.turnover)}} $</span>
                </div>
              </div>
            </div>
          </div>
          <div class="my-team__statisticks_item statisticks" v-if="levelsList?.length">
            <div class="my-team__statisticks_item_heading statisticks">
              <span>{{t('team.statistic')}}</span>
            </div>
            <div class="my-team__statisticks_item_slider_block">
              <div class="my-team__statisticks_item_slider" id="sliderStatisticks">
                <div class="swiper-wrapper">

                  <div class="my-team__statisticks_slide">
                    <div class="my-team__statisticks_line_block">
                      <div class="my-team__statisticks_heading">
                        <span>{{t('team.partnersList.line')}}</span>
                      </div>
                      <div class="my-team__statisticks_text" v-for="p in levelsList"
                        :key="p.accountId">
                        <span>{{p?.level}}</span>
                      </div>
                    </div>
                    <div class="my-team__statisticks_line_block">
                      <div class="my-team__statisticks_heading">
                        <span>{{t("team.partners")}}</span>
                      </div>
                      <div class="my-team__statisticks_text" v-for="p in levelsList"
                        :key="p.accountId">
                        <span>{{p?.quantity}}</span>
                      </div>
                    </div>
                    <div class="my-team__statisticks_line_block">
                      <div class="my-team__statisticks_heading">
                        <span>{{t('team.partnersList.turnover')}}</span>
                      </div>
                      <div class="my-team__statisticks_text" v-for="p in levelsList"
                        :key="p.accountId">
                        <span>{{setDecimalPrice(p?.activated?.turnover)}} $</span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="my-team__statisticks_slider_btns" v-if="levelsList?.length > statisticElemsCount">
              <div class="my-team__statisticks_prev" @click.prevent="statisticPage--" v-if="statisticPage > 1"></div>
              <div class="my-team__statisticks_next" @click.prevent="statisticPage++" v-if="(statisticPage < levelsList?.length/statisticElemsCount)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapState } from 'vuex';
import { dataScrollUpdate } from '@/mixins/dataScrollUpdate'
import { transactions } from '@/mixins/transactions';
import PartnersItem from '@/components/MyTeam/PartnersItem'
import ButtonRefferal from '../../components/UI/ButtonRefferal.vue';

export default {
  mixins: [ dataScrollUpdate, transactions ],

  components: {
    PartnersItem,
    ButtonRefferal
  },

  data() {
    return {
      referralCopy: false,
      partnersActive: [],
      search: '',
      statisticPage: 1,
      statisticElemsCount: 6,
      searchFocus: false,

      partnerPage: 0,
      partnersList: [],

      defaultStatus: '-',
      blockUpdateList: false,
    }
  },

  computed: {
    ...mapState({
      userInfo: state => state.account.user.info,
      network: state => state.network.network,
      partnersTeam: state => state.network.partnersTeam,
      asset: state => state.asset.asset,
      // walletAsset: state => state.finance.walletAsset,
      searchPartners: state => state.network.searchPartners,
      // ранг
      careerInfo: (state) => state.career.info,
    }),

    currentRankTitle() {
      return this.careerInfo?.ranksList?.find(rank => this.careerInfo.currentRankId === rank.rankId)?.rankTitle ?? this.defaultStatus
    },

    partnersSearchList() {
      const result = this.search.length > 0 ? this.searchPartners : this.partnersList;

      return result === undefined ? [] : result;
    },

    levelsList() {
      return this.network?.account?.levelsList !== undefined ? this.network?.account?.levelsList : [];
    },
  },

  methods: {
    copyReferralLink(refLink) {
      var Url = this.$refs.mylink;
      Url.value = window.location.origin+refLink;
      Url.select();
      const success = document.execCommand("copy");
      this.referralCopy = true

      if (success) {
        document.activeElement.blur();
        Url.value = refLink;
        setTimeout(() => this.referralCopy = false, 980)
      }
    },

    searchFocusout() {
      if (this.search !== '') this.searchFocus = false
    },


      // getHistoryList() {
      //     this.$store.dispatch('getWalletTransactionsPage', {
      //         assetId: this.currentAsset.assetId,
      //         stepTransactionId: this.walletTransactions[this.walletTransactions.length - 1]?.transactionId ?? 0,
      //     })
      // },
      //
    getPartnerList() {
      // const teamQuantity = this.userInfo?.partnersStatistics?.quantity;
      const step = 15;

      // const stepReferralLine = this.partnerPage*step;

      // if (stepReferralLine >= teamQuantity) return;

      this.$store.dispatch('getTeamPartnerPage', {
          partnerLogin: this.userInfo.login,
          // referralLine: stepReferralLine,
          // step: step
          step: this.partnersList.length + step
        }).then(response => {
          console.log(response);
        this.partnersList = response;
        this.partnerPage += 1;
      })
    },

    getPartnerTeamInfo() {
      this.$store.dispatch('getPartnerTeamInfo', this.userInfo.login)
    },

    partnersListObserver() {
      const callback = (entries) => {
        console.log('observe');
        if (entries[0].isIntersecting) {
          if(this.userInfo === undefined) this.$store.dispatch('getAccountInfo').then(() => this.getPartnerList())
          else this.getPartnerList()
        }
      };

      const observer = new IntersectionObserver(callback, {
        // rootMargin: '0px',
        threshold: .5
      });

      observer.observe(this.$refs.afterPatnersList)
    },
  },

  created() {
    this.$store.dispatch('getAccountInfo').then(() => {
      this.getPartnerTeamInfo()

      console.log(this.userInfo.email)
      // this.getPartnerList()
    })
    // this.$store.dispatch('getWalletAssetsAll')
    // this.$store.dispatch('getAllAssets')

    this.$store.dispatch("getCareerInfo")
  },

  mounted() {
    this.$emit('pageUpdate', true)
    this.partnersListObserver()
  },

  unmounted() {
    this.$emit('pageUpdate', false)

    this.$store.commit('setTeamPartnerList', [])
  },

  watch: {
    search(value) {
      // this.debounce(() => this.$store.dispatch('getPartnerSearch', value), 100)
      this.debounce(() => this.$store.dispatch('getTeamSearch', value), 100)
    }
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  },
}
</script>

<style scoped lang="scss">
.my-team__statisticks_team_item_heading {
  max-width: 11em;
}
.my-team__partners_block {
  padding-bottom: 2em;
  padding-left: .8em;
  padding-right: .8em;
  width: 82.25em;
}

.my-team__statisticks_block {
  width: 26.125em;
}

.my-team__statisticks_team_items {
  display: flex !important;
  flex-direction: column;
  margin-top: 2.125em;
  margin-bottom: 0.75em;
}

.my-team__statisticks_team_item {
  margin-bottom: 1.3em !important;
}

.my-team__partners_info_container {
  width: 100%;
  height: 40em;
  overflow: auto !important;

    &::-webkit-scrollbar {
      height: em(8);
      width: em(8);
        transform: translateX(em(10));
    }
    &::-webkit-scrollbar-track {
        background: rgba(1, 255, 194, .2);
        border-radius: em(10);
        margin: em(5);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #01ffc2;
        border-radius: em(10);
        border: 0;
    }

    &::-webkit-scrollbar-corner {
      background: rgba(1, 255, 194, .2);
      background: transparent;
    }
}

.my-team__statisticks_team {
  &_items {
    display: grid;
    grid-template-columns: repeat(3, 11.25em) 14.375em;
    grid-column-gap: 1.25em;
  }

  &_item {
    width: 100%;
    margin-bottom: 0;
    margin-right: 0;
    height: 100%;
    justify-content: space-between;

    &_number {
      min-width: 100% !important;
    }
  }

}

.mobileScreen {
  @media (max-width: 1024px) and (orientation: portrait) {
    .my-team__statisticks_team {
      &_items {
        grid-template-columns: 100%;
        grid-column-gap: 0;
      }

      &_item {
        width: 100%;
        height: auto;

        &_number {
          margin-bottom: 1.2em;
        }
      }
    }

    .my-team__partners_info {
      &_container {
        width: 25.25em;
      }

      &_item {
        width: 56.25em;
      }
    }
  }
}

.afterPatnersList {
    width: 100%;
    height: 1em;
}
</style>
