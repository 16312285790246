// source: mlmbox/type/account.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var mlmbox_type_bonus_pb = require('../../mlmbox/type/bonus_pb.js');
goog.object.extend(proto, mlmbox_type_bonus_pb);
var mlmbox_type_farming_pb = require('../../mlmbox/type/farming_pb.js');
goog.object.extend(proto, mlmbox_type_farming_pb);
var mlmbox_type_google_authenticator_pb = require('../../mlmbox/type/google_authenticator_pb.js');
goog.object.extend(proto, mlmbox_type_google_authenticator_pb);
var mlmbox_type_relationship_pb = require('../../mlmbox/type/relationship_pb.js');
goog.object.extend(proto, mlmbox_type_relationship_pb);
var mlmbox_type_notification_pb = require('../../mlmbox/type/notification_pb.js');
goog.object.extend(proto, mlmbox_type_notification_pb);
goog.exportSymbol('proto.mlmbox.type.Account', null, global);
goog.exportSymbol('proto.mlmbox.type.Account.FullInfo', null, global);
goog.exportSymbol('proto.mlmbox.type.Account.FullInfo.AccountsStatistics', null, global);
goog.exportSymbol('proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract', null, global);
goog.exportSymbol('proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics', null, global);
goog.exportSymbol('proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract', null, global);
goog.exportSymbol('proto.mlmbox.type.Account.ID', null, global);
goog.exportSymbol('proto.mlmbox.type.Account.ID.UniqueFieldCase', null, global);
goog.exportSymbol('proto.mlmbox.type.Account.ShortInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Account = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.type.Account.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.type.Account, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Account.displayName = 'proto.mlmbox.type.Account';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Account.ID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.mlmbox.type.Account.ID.oneofGroups_);
};
goog.inherits(proto.mlmbox.type.Account.ID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Account.ID.displayName = 'proto.mlmbox.type.Account.ID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Account.ShortInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Account.ShortInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Account.ShortInfo.displayName = 'proto.mlmbox.type.Account.ShortInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Account.FullInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.type.Account.FullInfo.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.type.Account.FullInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Account.FullInfo.displayName = 'proto.mlmbox.type.Account.FullInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Account.FullInfo.AccountsStatistics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Account.FullInfo.AccountsStatistics.displayName = 'proto.mlmbox.type.Account.FullInfo.AccountsStatistics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract.displayName = 'proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.displayName = 'proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract.displayName = 'proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.type.Account.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Account.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Account.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Account} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Account.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.mlmbox.type.Account.FullInfo.toObject(includeInstance, f),
    googleAuthenticator: (f = msg.getGoogleAuthenticator()) && mlmbox_type_google_authenticator_pb.GoogleAuthenticator.toObject(includeInstance, f),
    farming: (f = msg.getFarming()) && mlmbox_type_farming_pb.Farming.Account.toObject(includeInstance, f),
    incomeBonusesList: jspb.Message.toObjectList(msg.getIncomeBonusesList(),
    mlmbox_type_bonus_pb.IncomeBonus.toObject, includeInstance),
    locale: jspb.Message.getFieldWithDefault(msg, 5, ""),
    isAdmin: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    notificationSettings: (f = msg.getNotificationSettings()) && mlmbox_type_notification_pb.Notification.State.Settings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Account}
 */
proto.mlmbox.type.Account.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Account;
  return proto.mlmbox.type.Account.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Account} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Account}
 */
proto.mlmbox.type.Account.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.type.Account.FullInfo;
      reader.readMessage(value,proto.mlmbox.type.Account.FullInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new mlmbox_type_google_authenticator_pb.GoogleAuthenticator;
      reader.readMessage(value,mlmbox_type_google_authenticator_pb.GoogleAuthenticator.deserializeBinaryFromReader);
      msg.setGoogleAuthenticator(value);
      break;
    case 3:
      var value = new mlmbox_type_farming_pb.Farming.Account;
      reader.readMessage(value,mlmbox_type_farming_pb.Farming.Account.deserializeBinaryFromReader);
      msg.setFarming(value);
      break;
    case 4:
      var value = new mlmbox_type_bonus_pb.IncomeBonus;
      reader.readMessage(value,mlmbox_type_bonus_pb.IncomeBonus.deserializeBinaryFromReader);
      msg.addIncomeBonuses(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocale(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAdmin(value);
      break;
    case 7:
      var value = new mlmbox_type_notification_pb.Notification.State.Settings;
      reader.readMessage(value,mlmbox_type_notification_pb.Notification.State.Settings.deserializeBinaryFromReader);
      msg.setNotificationSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Account.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Account.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Account} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Account.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mlmbox.type.Account.FullInfo.serializeBinaryToWriter
    );
  }
  f = message.getGoogleAuthenticator();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      mlmbox_type_google_authenticator_pb.GoogleAuthenticator.serializeBinaryToWriter
    );
  }
  f = message.getFarming();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      mlmbox_type_farming_pb.Farming.Account.serializeBinaryToWriter
    );
  }
  f = message.getIncomeBonusesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      mlmbox_type_bonus_pb.IncomeBonus.serializeBinaryToWriter
    );
  }
  f = message.getLocale();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIsAdmin();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getNotificationSettings();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      mlmbox_type_notification_pb.Notification.State.Settings.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.mlmbox.type.Account.ID.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.mlmbox.type.Account.ID.UniqueFieldCase = {
  UNIQUE_FIELD_NOT_SET: 0,
  ACCOUNT_ID: 1,
  ACCOUNT_LOGIN: 2
};

/**
 * @return {proto.mlmbox.type.Account.ID.UniqueFieldCase}
 */
proto.mlmbox.type.Account.ID.prototype.getUniqueFieldCase = function() {
  return /** @type {proto.mlmbox.type.Account.ID.UniqueFieldCase} */(jspb.Message.computeOneofCase(this, proto.mlmbox.type.Account.ID.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Account.ID.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Account.ID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Account.ID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Account.ID.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountLogin: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Account.ID}
 */
proto.mlmbox.type.Account.ID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Account.ID;
  return proto.mlmbox.type.Account.ID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Account.ID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Account.ID}
 */
proto.mlmbox.type.Account.ID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountLogin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Account.ID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Account.ID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Account.ID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Account.ID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Account.ID.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Account.ID} returns this
 */
proto.mlmbox.type.Account.ID.prototype.setAccountId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.mlmbox.type.Account.ID.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mlmbox.type.Account.ID} returns this
 */
proto.mlmbox.type.Account.ID.prototype.clearAccountId = function() {
  return jspb.Message.setOneofField(this, 1, proto.mlmbox.type.Account.ID.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Account.ID.prototype.hasAccountId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string account_login = 2;
 * @return {string}
 */
proto.mlmbox.type.Account.ID.prototype.getAccountLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Account.ID} returns this
 */
proto.mlmbox.type.Account.ID.prototype.setAccountLogin = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.mlmbox.type.Account.ID.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mlmbox.type.Account.ID} returns this
 */
proto.mlmbox.type.Account.ID.prototype.clearAccountLogin = function() {
  return jspb.Message.setOneofField(this, 2, proto.mlmbox.type.Account.ID.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Account.ID.prototype.hasAccountLogin = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Account.ShortInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Account.ShortInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Account.ShortInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Account.ShortInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    login: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    referralId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    referralLogin: jspb.Message.getFieldWithDefault(msg, 5, ""),
    currentRankId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    currentPackageId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    relationship: (f = msg.getRelationship()) && mlmbox_type_relationship_pb.Relationship.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Account.ShortInfo}
 */
proto.mlmbox.type.Account.ShortInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Account.ShortInfo;
  return proto.mlmbox.type.Account.ShortInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Account.ShortInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Account.ShortInfo}
 */
proto.mlmbox.type.Account.ShortInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReferralId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferralLogin(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCurrentRankId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCurrentPackageId(value);
      break;
    case 8:
      var value = new mlmbox_type_relationship_pb.Relationship;
      reader.readMessage(value,mlmbox_type_relationship_pb.Relationship.deserializeBinaryFromReader);
      msg.setRelationship(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Account.ShortInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Account.ShortInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Account.ShortInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Account.ShortInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReferralId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getReferralLogin();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCurrentRankId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getCurrentPackageId();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getRelationship();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      mlmbox_type_relationship_pb.Relationship.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Account.ShortInfo.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Account.ShortInfo} returns this
 */
proto.mlmbox.type.Account.ShortInfo.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string login = 2;
 * @return {string}
 */
proto.mlmbox.type.Account.ShortInfo.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Account.ShortInfo} returns this
 */
proto.mlmbox.type.Account.ShortInfo.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.mlmbox.type.Account.ShortInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Account.ShortInfo} returns this
 */
proto.mlmbox.type.Account.ShortInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 referral_id = 4;
 * @return {number}
 */
proto.mlmbox.type.Account.ShortInfo.prototype.getReferralId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Account.ShortInfo} returns this
 */
proto.mlmbox.type.Account.ShortInfo.prototype.setReferralId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string referral_login = 5;
 * @return {string}
 */
proto.mlmbox.type.Account.ShortInfo.prototype.getReferralLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Account.ShortInfo} returns this
 */
proto.mlmbox.type.Account.ShortInfo.prototype.setReferralLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 current_rank_id = 6;
 * @return {number}
 */
proto.mlmbox.type.Account.ShortInfo.prototype.getCurrentRankId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Account.ShortInfo} returns this
 */
proto.mlmbox.type.Account.ShortInfo.prototype.setCurrentRankId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 current_package_id = 7;
 * @return {number}
 */
proto.mlmbox.type.Account.ShortInfo.prototype.getCurrentPackageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Account.ShortInfo} returns this
 */
proto.mlmbox.type.Account.ShortInfo.prototype.setCurrentPackageId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional Relationship relationship = 8;
 * @return {?proto.mlmbox.type.Relationship}
 */
proto.mlmbox.type.Account.ShortInfo.prototype.getRelationship = function() {
  return /** @type{?proto.mlmbox.type.Relationship} */ (
    jspb.Message.getWrapperField(this, mlmbox_type_relationship_pb.Relationship, 8));
};


/**
 * @param {?proto.mlmbox.type.Relationship|undefined} value
 * @return {!proto.mlmbox.type.Account.ShortInfo} returns this
*/
proto.mlmbox.type.Account.ShortInfo.prototype.setRelationship = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Account.ShortInfo} returns this
 */
proto.mlmbox.type.Account.ShortInfo.prototype.clearRelationship = function() {
  return this.setRelationship(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Account.ShortInfo.prototype.hasRelationship = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.type.Account.FullInfo.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Account.FullInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Account.FullInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Account.FullInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Account.FullInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    login: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    referralId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    referralLogin: jspb.Message.getFieldWithDefault(msg, 5, ""),
    currentRankId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    currentPackageId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    relationship: (f = msg.getRelationship()) && mlmbox_type_relationship_pb.Relationship.toObject(includeInstance, f),
    selfStatistics: (f = msg.getSelfStatistics()) && proto.mlmbox.type.Account.FullInfo.AccountsStatistics.toObject(includeInstance, f),
    partnersStatistics: (f = msg.getPartnersStatistics()) && proto.mlmbox.type.Account.FullInfo.AccountsStatistics.toObject(includeInstance, f),
    teamStatistics: (f = msg.getTeamStatistics()) && proto.mlmbox.type.Account.FullInfo.AccountsStatistics.toObject(includeInstance, f),
    levelsList: jspb.Message.toObjectList(msg.getLevelsList(),
    proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Account.FullInfo}
 */
proto.mlmbox.type.Account.FullInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Account.FullInfo;
  return proto.mlmbox.type.Account.FullInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Account.FullInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Account.FullInfo}
 */
proto.mlmbox.type.Account.FullInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReferralId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferralLogin(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCurrentRankId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCurrentPackageId(value);
      break;
    case 8:
      var value = new mlmbox_type_relationship_pb.Relationship;
      reader.readMessage(value,mlmbox_type_relationship_pb.Relationship.deserializeBinaryFromReader);
      msg.setRelationship(value);
      break;
    case 9:
      var value = new proto.mlmbox.type.Account.FullInfo.AccountsStatistics;
      reader.readMessage(value,proto.mlmbox.type.Account.FullInfo.AccountsStatistics.deserializeBinaryFromReader);
      msg.setSelfStatistics(value);
      break;
    case 10:
      var value = new proto.mlmbox.type.Account.FullInfo.AccountsStatistics;
      reader.readMessage(value,proto.mlmbox.type.Account.FullInfo.AccountsStatistics.deserializeBinaryFromReader);
      msg.setPartnersStatistics(value);
      break;
    case 11:
      var value = new proto.mlmbox.type.Account.FullInfo.AccountsStatistics;
      reader.readMessage(value,proto.mlmbox.type.Account.FullInfo.AccountsStatistics.deserializeBinaryFromReader);
      msg.setTeamStatistics(value);
      break;
    case 12:
      var value = new proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics;
      reader.readMessage(value,proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.deserializeBinaryFromReader);
      msg.addLevels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Account.FullInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Account.FullInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Account.FullInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Account.FullInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReferralId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getReferralLogin();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCurrentRankId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getCurrentPackageId();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getRelationship();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      mlmbox_type_relationship_pb.Relationship.serializeBinaryToWriter
    );
  }
  f = message.getSelfStatistics();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.mlmbox.type.Account.FullInfo.AccountsStatistics.serializeBinaryToWriter
    );
  }
  f = message.getPartnersStatistics();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.mlmbox.type.Account.FullInfo.AccountsStatistics.serializeBinaryToWriter
    );
  }
  f = message.getTeamStatistics();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.mlmbox.type.Account.FullInfo.AccountsStatistics.serializeBinaryToWriter
    );
  }
  f = message.getLevelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Account.FullInfo.AccountsStatistics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Account.FullInfo.AccountsStatistics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.toObject = function(includeInstance, msg) {
  var f, obj = {
    quantity: jspb.Message.getFieldWithDefault(msg, 1, 0),
    total: (f = msg.getTotal()) && proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract.toObject(includeInstance, f),
    activated: (f = msg.getActivated()) && proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract.toObject(includeInstance, f),
    incomePerDay: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Account.FullInfo.AccountsStatistics}
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Account.FullInfo.AccountsStatistics;
  return proto.mlmbox.type.Account.FullInfo.AccountsStatistics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Account.FullInfo.AccountsStatistics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Account.FullInfo.AccountsStatistics}
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQuantity(value);
      break;
    case 2:
      var value = new proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract;
      reader.readMessage(value,proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract.deserializeBinaryFromReader);
      msg.setTotal(value);
      break;
    case 3:
      var value = new proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract;
      reader.readMessage(value,proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract.deserializeBinaryFromReader);
      msg.setActivated(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIncomePerDay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Account.FullInfo.AccountsStatistics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Account.FullInfo.AccountsStatistics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract.serializeBinaryToWriter
    );
  }
  f = message.getActivated();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract.serializeBinaryToWriter
    );
  }
  f = message.getIncomePerDay();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract.toObject = function(includeInstance, msg) {
  var f, obj = {
    quantity: jspb.Message.getFieldWithDefault(msg, 1, 0),
    turnover: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ownersQuantity: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract}
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract;
  return proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract}
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQuantity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTurnover(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOwnersQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTurnover();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOwnersQuantity();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 quantity = 1;
 * @return {number}
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract} returns this
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string turnover = 2;
 * @return {string}
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract.prototype.getTurnover = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract} returns this
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract.prototype.setTurnover = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 owners_quantity = 3;
 * @return {number}
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract.prototype.getOwnersQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract} returns this
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract.prototype.setOwnersQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 quantity = 1;
 * @return {number}
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Account.FullInfo.AccountsStatistics} returns this
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Contract total = 2;
 * @return {?proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract}
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.prototype.getTotal = function() {
  return /** @type{?proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract, 2));
};


/**
 * @param {?proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract|undefined} value
 * @return {!proto.mlmbox.type.Account.FullInfo.AccountsStatistics} returns this
*/
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.prototype.setTotal = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Account.FullInfo.AccountsStatistics} returns this
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.prototype.clearTotal = function() {
  return this.setTotal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.prototype.hasTotal = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Contract activated = 3;
 * @return {?proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract}
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.prototype.getActivated = function() {
  return /** @type{?proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract, 3));
};


/**
 * @param {?proto.mlmbox.type.Account.FullInfo.AccountsStatistics.Contract|undefined} value
 * @return {!proto.mlmbox.type.Account.FullInfo.AccountsStatistics} returns this
*/
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.prototype.setActivated = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Account.FullInfo.AccountsStatistics} returns this
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.prototype.clearActivated = function() {
  return this.setActivated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.prototype.hasActivated = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string income_per_day = 4;
 * @return {string}
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.prototype.getIncomePerDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Account.FullInfo.AccountsStatistics} returns this
 */
proto.mlmbox.type.Account.FullInfo.AccountsStatistics.prototype.setIncomePerDay = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.toObject = function(includeInstance, msg) {
  var f, obj = {
    level: jspb.Message.getFieldWithDefault(msg, 1, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    total: (f = msg.getTotal()) && proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract.toObject(includeInstance, f),
    activated: (f = msg.getActivated()) && proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract.toObject(includeInstance, f),
    incomePerDay: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics}
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics;
  return proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics}
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLevel(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQuantity(value);
      break;
    case 3:
      var value = new proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract;
      reader.readMessage(value,proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract.deserializeBinaryFromReader);
      msg.setTotal(value);
      break;
    case 4:
      var value = new proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract;
      reader.readMessage(value,proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract.deserializeBinaryFromReader);
      msg.setActivated(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIncomePerDay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLevel();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTotal();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract.serializeBinaryToWriter
    );
  }
  f = message.getActivated();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract.serializeBinaryToWriter
    );
  }
  f = message.getIncomePerDay();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract.toObject = function(includeInstance, msg) {
  var f, obj = {
    quantity: jspb.Message.getFieldWithDefault(msg, 1, 0),
    turnover: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ownersQuantity: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract}
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract;
  return proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract}
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQuantity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTurnover(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOwnersQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTurnover();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOwnersQuantity();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 quantity = 1;
 * @return {number}
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract} returns this
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string turnover = 2;
 * @return {string}
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract.prototype.getTurnover = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract} returns this
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract.prototype.setTurnover = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 owners_quantity = 3;
 * @return {number}
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract.prototype.getOwnersQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract} returns this
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract.prototype.setOwnersQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 level = 1;
 * @return {number}
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.prototype.getLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics} returns this
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.prototype.setLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 quantity = 2;
 * @return {number}
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics} returns this
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Contract total = 3;
 * @return {?proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract}
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.prototype.getTotal = function() {
  return /** @type{?proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract, 3));
};


/**
 * @param {?proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract|undefined} value
 * @return {!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics} returns this
*/
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.prototype.setTotal = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics} returns this
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.prototype.clearTotal = function() {
  return this.setTotal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.prototype.hasTotal = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Contract activated = 4;
 * @return {?proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract}
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.prototype.getActivated = function() {
  return /** @type{?proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract, 4));
};


/**
 * @param {?proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.Contract|undefined} value
 * @return {!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics} returns this
*/
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.prototype.setActivated = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics} returns this
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.prototype.clearActivated = function() {
  return this.setActivated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.prototype.hasActivated = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string income_per_day = 5;
 * @return {string}
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.prototype.getIncomePerDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics} returns this
 */
proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics.prototype.setIncomePerDay = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Account.FullInfo.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Account.FullInfo} returns this
 */
proto.mlmbox.type.Account.FullInfo.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string login = 2;
 * @return {string}
 */
proto.mlmbox.type.Account.FullInfo.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Account.FullInfo} returns this
 */
proto.mlmbox.type.Account.FullInfo.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.mlmbox.type.Account.FullInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Account.FullInfo} returns this
 */
proto.mlmbox.type.Account.FullInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 referral_id = 4;
 * @return {number}
 */
proto.mlmbox.type.Account.FullInfo.prototype.getReferralId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Account.FullInfo} returns this
 */
proto.mlmbox.type.Account.FullInfo.prototype.setReferralId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string referral_login = 5;
 * @return {string}
 */
proto.mlmbox.type.Account.FullInfo.prototype.getReferralLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Account.FullInfo} returns this
 */
proto.mlmbox.type.Account.FullInfo.prototype.setReferralLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 current_rank_id = 6;
 * @return {number}
 */
proto.mlmbox.type.Account.FullInfo.prototype.getCurrentRankId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Account.FullInfo} returns this
 */
proto.mlmbox.type.Account.FullInfo.prototype.setCurrentRankId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 current_package_id = 7;
 * @return {number}
 */
proto.mlmbox.type.Account.FullInfo.prototype.getCurrentPackageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Account.FullInfo} returns this
 */
proto.mlmbox.type.Account.FullInfo.prototype.setCurrentPackageId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional Relationship relationship = 8;
 * @return {?proto.mlmbox.type.Relationship}
 */
proto.mlmbox.type.Account.FullInfo.prototype.getRelationship = function() {
  return /** @type{?proto.mlmbox.type.Relationship} */ (
    jspb.Message.getWrapperField(this, mlmbox_type_relationship_pb.Relationship, 8));
};


/**
 * @param {?proto.mlmbox.type.Relationship|undefined} value
 * @return {!proto.mlmbox.type.Account.FullInfo} returns this
*/
proto.mlmbox.type.Account.FullInfo.prototype.setRelationship = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Account.FullInfo} returns this
 */
proto.mlmbox.type.Account.FullInfo.prototype.clearRelationship = function() {
  return this.setRelationship(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Account.FullInfo.prototype.hasRelationship = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional AccountsStatistics self_statistics = 9;
 * @return {?proto.mlmbox.type.Account.FullInfo.AccountsStatistics}
 */
proto.mlmbox.type.Account.FullInfo.prototype.getSelfStatistics = function() {
  return /** @type{?proto.mlmbox.type.Account.FullInfo.AccountsStatistics} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.type.Account.FullInfo.AccountsStatistics, 9));
};


/**
 * @param {?proto.mlmbox.type.Account.FullInfo.AccountsStatistics|undefined} value
 * @return {!proto.mlmbox.type.Account.FullInfo} returns this
*/
proto.mlmbox.type.Account.FullInfo.prototype.setSelfStatistics = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Account.FullInfo} returns this
 */
proto.mlmbox.type.Account.FullInfo.prototype.clearSelfStatistics = function() {
  return this.setSelfStatistics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Account.FullInfo.prototype.hasSelfStatistics = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional AccountsStatistics partners_statistics = 10;
 * @return {?proto.mlmbox.type.Account.FullInfo.AccountsStatistics}
 */
proto.mlmbox.type.Account.FullInfo.prototype.getPartnersStatistics = function() {
  return /** @type{?proto.mlmbox.type.Account.FullInfo.AccountsStatistics} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.type.Account.FullInfo.AccountsStatistics, 10));
};


/**
 * @param {?proto.mlmbox.type.Account.FullInfo.AccountsStatistics|undefined} value
 * @return {!proto.mlmbox.type.Account.FullInfo} returns this
*/
proto.mlmbox.type.Account.FullInfo.prototype.setPartnersStatistics = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Account.FullInfo} returns this
 */
proto.mlmbox.type.Account.FullInfo.prototype.clearPartnersStatistics = function() {
  return this.setPartnersStatistics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Account.FullInfo.prototype.hasPartnersStatistics = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional AccountsStatistics team_statistics = 11;
 * @return {?proto.mlmbox.type.Account.FullInfo.AccountsStatistics}
 */
proto.mlmbox.type.Account.FullInfo.prototype.getTeamStatistics = function() {
  return /** @type{?proto.mlmbox.type.Account.FullInfo.AccountsStatistics} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.type.Account.FullInfo.AccountsStatistics, 11));
};


/**
 * @param {?proto.mlmbox.type.Account.FullInfo.AccountsStatistics|undefined} value
 * @return {!proto.mlmbox.type.Account.FullInfo} returns this
*/
proto.mlmbox.type.Account.FullInfo.prototype.setTeamStatistics = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Account.FullInfo} returns this
 */
proto.mlmbox.type.Account.FullInfo.prototype.clearTeamStatistics = function() {
  return this.setTeamStatistics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Account.FullInfo.prototype.hasTeamStatistics = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated LevelAccountsStatistics levels = 12;
 * @return {!Array<!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics>}
 */
proto.mlmbox.type.Account.FullInfo.prototype.getLevelsList = function() {
  return /** @type{!Array<!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics, 12));
};


/**
 * @param {!Array<!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics>} value
 * @return {!proto.mlmbox.type.Account.FullInfo} returns this
*/
proto.mlmbox.type.Account.FullInfo.prototype.setLevelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics}
 */
proto.mlmbox.type.Account.FullInfo.prototype.addLevels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.mlmbox.type.Account.FullInfo.LevelAccountsStatistics, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.type.Account.FullInfo} returns this
 */
proto.mlmbox.type.Account.FullInfo.prototype.clearLevelsList = function() {
  return this.setLevelsList([]);
};


/**
 * optional FullInfo info = 1;
 * @return {?proto.mlmbox.type.Account.FullInfo}
 */
proto.mlmbox.type.Account.prototype.getInfo = function() {
  return /** @type{?proto.mlmbox.type.Account.FullInfo} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.type.Account.FullInfo, 1));
};


/**
 * @param {?proto.mlmbox.type.Account.FullInfo|undefined} value
 * @return {!proto.mlmbox.type.Account} returns this
*/
proto.mlmbox.type.Account.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Account} returns this
 */
proto.mlmbox.type.Account.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Account.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GoogleAuthenticator google_authenticator = 2;
 * @return {?proto.mlmbox.type.GoogleAuthenticator}
 */
proto.mlmbox.type.Account.prototype.getGoogleAuthenticator = function() {
  return /** @type{?proto.mlmbox.type.GoogleAuthenticator} */ (
    jspb.Message.getWrapperField(this, mlmbox_type_google_authenticator_pb.GoogleAuthenticator, 2));
};


/**
 * @param {?proto.mlmbox.type.GoogleAuthenticator|undefined} value
 * @return {!proto.mlmbox.type.Account} returns this
*/
proto.mlmbox.type.Account.prototype.setGoogleAuthenticator = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Account} returns this
 */
proto.mlmbox.type.Account.prototype.clearGoogleAuthenticator = function() {
  return this.setGoogleAuthenticator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Account.prototype.hasGoogleAuthenticator = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Farming.Account farming = 3;
 * @return {?proto.mlmbox.type.Farming.Account}
 */
proto.mlmbox.type.Account.prototype.getFarming = function() {
  return /** @type{?proto.mlmbox.type.Farming.Account} */ (
    jspb.Message.getWrapperField(this, mlmbox_type_farming_pb.Farming.Account, 3));
};


/**
 * @param {?proto.mlmbox.type.Farming.Account|undefined} value
 * @return {!proto.mlmbox.type.Account} returns this
*/
proto.mlmbox.type.Account.prototype.setFarming = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Account} returns this
 */
proto.mlmbox.type.Account.prototype.clearFarming = function() {
  return this.setFarming(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Account.prototype.hasFarming = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated IncomeBonus income_bonuses = 4;
 * @return {!Array<!proto.mlmbox.type.IncomeBonus>}
 */
proto.mlmbox.type.Account.prototype.getIncomeBonusesList = function() {
  return /** @type{!Array<!proto.mlmbox.type.IncomeBonus>} */ (
    jspb.Message.getRepeatedWrapperField(this, mlmbox_type_bonus_pb.IncomeBonus, 4));
};


/**
 * @param {!Array<!proto.mlmbox.type.IncomeBonus>} value
 * @return {!proto.mlmbox.type.Account} returns this
*/
proto.mlmbox.type.Account.prototype.setIncomeBonusesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.mlmbox.type.IncomeBonus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.IncomeBonus}
 */
proto.mlmbox.type.Account.prototype.addIncomeBonuses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.mlmbox.type.IncomeBonus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.type.Account} returns this
 */
proto.mlmbox.type.Account.prototype.clearIncomeBonusesList = function() {
  return this.setIncomeBonusesList([]);
};


/**
 * optional string locale = 5;
 * @return {string}
 */
proto.mlmbox.type.Account.prototype.getLocale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Account} returns this
 */
proto.mlmbox.type.Account.prototype.setLocale = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool is_admin = 6;
 * @return {boolean}
 */
proto.mlmbox.type.Account.prototype.getIsAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mlmbox.type.Account} returns this
 */
proto.mlmbox.type.Account.prototype.setIsAdmin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional Notification.State.Settings notification_settings = 7;
 * @return {?proto.mlmbox.type.Notification.State.Settings}
 */
proto.mlmbox.type.Account.prototype.getNotificationSettings = function() {
  return /** @type{?proto.mlmbox.type.Notification.State.Settings} */ (
    jspb.Message.getWrapperField(this, mlmbox_type_notification_pb.Notification.State.Settings, 7));
};


/**
 * @param {?proto.mlmbox.type.Notification.State.Settings|undefined} value
 * @return {!proto.mlmbox.type.Account} returns this
*/
proto.mlmbox.type.Account.prototype.setNotificationSettings = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Account} returns this
 */
proto.mlmbox.type.Account.prototype.clearNotificationSettings = function() {
  return this.setNotificationSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Account.prototype.hasNotificationSettings = function() {
  return jspb.Message.getField(this, 7) != null;
};


goog.object.extend(exports, proto.mlmbox.type);
