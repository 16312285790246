// source: mlmbox/type/career.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.mlmbox.type.Career', null, global);
goog.exportSymbol('proto.mlmbox.type.Career.Rank', null, global);
goog.exportSymbol('proto.mlmbox.type.Career.Rank.ConditionPartnersRank', null, global);
goog.exportSymbol('proto.mlmbox.type.Career.Rank.Gift', null, global);
goog.exportSymbol('proto.mlmbox.type.Career.Rank.Gift.Goods', null, global);
goog.exportSymbol('proto.mlmbox.type.Career.Rank.Gift.Kind', null, global);
goog.exportSymbol('proto.mlmbox.type.Career.Rank.Gift.Money', null, global);
goog.exportSymbol('proto.mlmbox.type.Career.Rank.ID', null, global);
goog.exportSymbol('proto.mlmbox.type.Career.Rank.Process', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Career = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.type.Career.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.type.Career, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Career.displayName = 'proto.mlmbox.type.Career';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Career.Rank = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.type.Career.Rank.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.type.Career.Rank, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Career.Rank.displayName = 'proto.mlmbox.type.Career.Rank';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Career.Rank.ID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Career.Rank.ID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Career.Rank.ID.displayName = 'proto.mlmbox.type.Career.Rank.ID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Career.Rank.ConditionPartnersRank = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Career.Rank.ConditionPartnersRank, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Career.Rank.ConditionPartnersRank.displayName = 'proto.mlmbox.type.Career.Rank.ConditionPartnersRank';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Career.Rank.Gift = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Career.Rank.Gift, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Career.Rank.Gift.displayName = 'proto.mlmbox.type.Career.Rank.Gift';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Career.Rank.Gift.Goods = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Career.Rank.Gift.Goods, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Career.Rank.Gift.Goods.displayName = 'proto.mlmbox.type.Career.Rank.Gift.Goods';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Career.Rank.Gift.Money = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Career.Rank.Gift.Money, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Career.Rank.Gift.Money.displayName = 'proto.mlmbox.type.Career.Rank.Gift.Money';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Career.Rank.Process = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.type.Career.Rank.Process.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.type.Career.Rank.Process, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Career.Rank.Process.displayName = 'proto.mlmbox.type.Career.Rank.Process';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.type.Career.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Career.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Career.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Career} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Career.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentRankId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    nextRankProcess: (f = msg.getNextRankProcess()) && proto.mlmbox.type.Career.Rank.Process.toObject(includeInstance, f),
    ranksList: jspb.Message.toObjectList(msg.getRanksList(),
    proto.mlmbox.type.Career.Rank.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Career}
 */
proto.mlmbox.type.Career.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Career;
  return proto.mlmbox.type.Career.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Career} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Career}
 */
proto.mlmbox.type.Career.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCurrentRankId(value);
      break;
    case 2:
      var value = new proto.mlmbox.type.Career.Rank.Process;
      reader.readMessage(value,proto.mlmbox.type.Career.Rank.Process.deserializeBinaryFromReader);
      msg.setNextRankProcess(value);
      break;
    case 3:
      var value = new proto.mlmbox.type.Career.Rank;
      reader.readMessage(value,proto.mlmbox.type.Career.Rank.deserializeBinaryFromReader);
      msg.addRanks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Career.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Career.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Career} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Career.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentRankId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getNextRankProcess();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mlmbox.type.Career.Rank.Process.serializeBinaryToWriter
    );
  }
  f = message.getRanksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.mlmbox.type.Career.Rank.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.type.Career.Rank.repeatedFields_ = [3,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Career.Rank.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Career.Rank.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Career.Rank} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Career.Rank.toObject = function(includeInstance, msg) {
  var f, obj = {
    rankId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    rankTitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    conditionPartnersRanksList: jspb.Message.toObjectList(msg.getConditionPartnersRanksList(),
    proto.mlmbox.type.Career.Rank.ConditionPartnersRank.toObject, includeInstance),
    conditionPartnersTotalContractsOwnersQuantity: jspb.Message.getFieldWithDefault(msg, 4, 0),
    conditionBinaryLineTotalTurnoverLeast: jspb.Message.getFieldWithDefault(msg, 5, ""),
    bonusBinaryMatchingLevelPercentList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    giftsList: jspb.Message.toObjectList(msg.getGiftsList(),
    proto.mlmbox.type.Career.Rank.Gift.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Career.Rank}
 */
proto.mlmbox.type.Career.Rank.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Career.Rank;
  return proto.mlmbox.type.Career.Rank.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Career.Rank} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Career.Rank}
 */
proto.mlmbox.type.Career.Rank.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRankId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRankTitle(value);
      break;
    case 3:
      var value = new proto.mlmbox.type.Career.Rank.ConditionPartnersRank;
      reader.readMessage(value,proto.mlmbox.type.Career.Rank.ConditionPartnersRank.deserializeBinaryFromReader);
      msg.addConditionPartnersRanks(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setConditionPartnersTotalContractsOwnersQuantity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setConditionBinaryLineTotalTurnoverLeast(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addBonusBinaryMatchingLevelPercent(value);
      break;
    case 7:
      var value = new proto.mlmbox.type.Career.Rank.Gift;
      reader.readMessage(value,proto.mlmbox.type.Career.Rank.Gift.deserializeBinaryFromReader);
      msg.addGifts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Career.Rank.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Career.Rank.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Career.Rank} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Career.Rank.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRankId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getRankTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConditionPartnersRanksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.mlmbox.type.Career.Rank.ConditionPartnersRank.serializeBinaryToWriter
    );
  }
  f = message.getConditionPartnersTotalContractsOwnersQuantity();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getConditionBinaryLineTotalTurnoverLeast();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBonusBinaryMatchingLevelPercentList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getGiftsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.mlmbox.type.Career.Rank.Gift.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Career.Rank.ID.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Career.Rank.ID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Career.Rank.ID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Career.Rank.ID.toObject = function(includeInstance, msg) {
  var f, obj = {
    rankId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Career.Rank.ID}
 */
proto.mlmbox.type.Career.Rank.ID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Career.Rank.ID;
  return proto.mlmbox.type.Career.Rank.ID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Career.Rank.ID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Career.Rank.ID}
 */
proto.mlmbox.type.Career.Rank.ID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRankId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Career.Rank.ID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Career.Rank.ID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Career.Rank.ID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Career.Rank.ID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRankId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 rank_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Career.Rank.ID.prototype.getRankId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Career.Rank.ID} returns this
 */
proto.mlmbox.type.Career.Rank.ID.prototype.setRankId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Career.Rank.ConditionPartnersRank.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Career.Rank.ConditionPartnersRank.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Career.Rank.ConditionPartnersRank} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Career.Rank.ConditionPartnersRank.toObject = function(includeInstance, msg) {
  var f, obj = {
    rankId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    binaryLine1Quantity: jspb.Message.getFieldWithDefault(msg, 3, 0),
    binaryLine2Quantity: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Career.Rank.ConditionPartnersRank}
 */
proto.mlmbox.type.Career.Rank.ConditionPartnersRank.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Career.Rank.ConditionPartnersRank;
  return proto.mlmbox.type.Career.Rank.ConditionPartnersRank.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Career.Rank.ConditionPartnersRank} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Career.Rank.ConditionPartnersRank}
 */
proto.mlmbox.type.Career.Rank.ConditionPartnersRank.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRankId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBinaryLine1Quantity(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBinaryLine2Quantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Career.Rank.ConditionPartnersRank.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Career.Rank.ConditionPartnersRank.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Career.Rank.ConditionPartnersRank} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Career.Rank.ConditionPartnersRank.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRankId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBinaryLine1Quantity();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getBinaryLine2Quantity();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional uint32 rank_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Career.Rank.ConditionPartnersRank.prototype.getRankId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Career.Rank.ConditionPartnersRank} returns this
 */
proto.mlmbox.type.Career.Rank.ConditionPartnersRank.prototype.setRankId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 quantity = 2;
 * @return {number}
 */
proto.mlmbox.type.Career.Rank.ConditionPartnersRank.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Career.Rank.ConditionPartnersRank} returns this
 */
proto.mlmbox.type.Career.Rank.ConditionPartnersRank.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 binary_line1_quantity = 3;
 * @return {number}
 */
proto.mlmbox.type.Career.Rank.ConditionPartnersRank.prototype.getBinaryLine1Quantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Career.Rank.ConditionPartnersRank} returns this
 */
proto.mlmbox.type.Career.Rank.ConditionPartnersRank.prototype.setBinaryLine1Quantity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 binary_line2_quantity = 4;
 * @return {number}
 */
proto.mlmbox.type.Career.Rank.ConditionPartnersRank.prototype.getBinaryLine2Quantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Career.Rank.ConditionPartnersRank} returns this
 */
proto.mlmbox.type.Career.Rank.ConditionPartnersRank.prototype.setBinaryLine2Quantity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Career.Rank.Gift.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Career.Rank.Gift.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Career.Rank.Gift} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Career.Rank.Gift.toObject = function(includeInstance, msg) {
  var f, obj = {
    kind: jspb.Message.getFieldWithDefault(msg, 1, 0),
    goods: (f = msg.getGoods()) && proto.mlmbox.type.Career.Rank.Gift.Goods.toObject(includeInstance, f),
    money: (f = msg.getMoney()) && proto.mlmbox.type.Career.Rank.Gift.Money.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Career.Rank.Gift}
 */
proto.mlmbox.type.Career.Rank.Gift.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Career.Rank.Gift;
  return proto.mlmbox.type.Career.Rank.Gift.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Career.Rank.Gift} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Career.Rank.Gift}
 */
proto.mlmbox.type.Career.Rank.Gift.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.mlmbox.type.Career.Rank.Gift.Kind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 2:
      var value = new proto.mlmbox.type.Career.Rank.Gift.Goods;
      reader.readMessage(value,proto.mlmbox.type.Career.Rank.Gift.Goods.deserializeBinaryFromReader);
      msg.setGoods(value);
      break;
    case 3:
      var value = new proto.mlmbox.type.Career.Rank.Gift.Money;
      reader.readMessage(value,proto.mlmbox.type.Career.Rank.Gift.Money.deserializeBinaryFromReader);
      msg.setMoney(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Career.Rank.Gift.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Career.Rank.Gift.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Career.Rank.Gift} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Career.Rank.Gift.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getGoods();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mlmbox.type.Career.Rank.Gift.Goods.serializeBinaryToWriter
    );
  }
  f = message.getMoney();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mlmbox.type.Career.Rank.Gift.Money.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.mlmbox.type.Career.Rank.Gift.Kind = {
  UNKNOWN: 0,
  GOODS: 1,
  MONEY: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Career.Rank.Gift.Goods.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Career.Rank.Gift.Goods.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Career.Rank.Gift.Goods} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Career.Rank.Gift.Goods.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Career.Rank.Gift.Goods}
 */
proto.mlmbox.type.Career.Rank.Gift.Goods.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Career.Rank.Gift.Goods;
  return proto.mlmbox.type.Career.Rank.Gift.Goods.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Career.Rank.Gift.Goods} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Career.Rank.Gift.Goods}
 */
proto.mlmbox.type.Career.Rank.Gift.Goods.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Career.Rank.Gift.Goods.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Career.Rank.Gift.Goods.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Career.Rank.Gift.Goods} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Career.Rank.Gift.Goods.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mlmbox.type.Career.Rank.Gift.Goods.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Career.Rank.Gift.Goods} returns this
 */
proto.mlmbox.type.Career.Rank.Gift.Goods.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 quantity = 2;
 * @return {number}
 */
proto.mlmbox.type.Career.Rank.Gift.Goods.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Career.Rank.Gift.Goods} returns this
 */
proto.mlmbox.type.Career.Rank.Gift.Goods.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Career.Rank.Gift.Money.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Career.Rank.Gift.Money.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Career.Rank.Gift.Money} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Career.Rank.Gift.Money.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    assetBonus: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Career.Rank.Gift.Money}
 */
proto.mlmbox.type.Career.Rank.Gift.Money.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Career.Rank.Gift.Money;
  return proto.mlmbox.type.Career.Rank.Gift.Money.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Career.Rank.Gift.Money} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Career.Rank.Gift.Money}
 */
proto.mlmbox.type.Career.Rank.Gift.Money.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetBonus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Career.Rank.Gift.Money.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Career.Rank.Gift.Money.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Career.Rank.Gift.Money} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Career.Rank.Gift.Money.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAssetBonus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 asset_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Career.Rank.Gift.Money.prototype.getAssetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Career.Rank.Gift.Money} returns this
 */
proto.mlmbox.type.Career.Rank.Gift.Money.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string asset_bonus = 2;
 * @return {string}
 */
proto.mlmbox.type.Career.Rank.Gift.Money.prototype.getAssetBonus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Career.Rank.Gift.Money} returns this
 */
proto.mlmbox.type.Career.Rank.Gift.Money.prototype.setAssetBonus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Kind kind = 1;
 * @return {!proto.mlmbox.type.Career.Rank.Gift.Kind}
 */
proto.mlmbox.type.Career.Rank.Gift.prototype.getKind = function() {
  return /** @type {!proto.mlmbox.type.Career.Rank.Gift.Kind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.mlmbox.type.Career.Rank.Gift.Kind} value
 * @return {!proto.mlmbox.type.Career.Rank.Gift} returns this
 */
proto.mlmbox.type.Career.Rank.Gift.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Goods goods = 2;
 * @return {?proto.mlmbox.type.Career.Rank.Gift.Goods}
 */
proto.mlmbox.type.Career.Rank.Gift.prototype.getGoods = function() {
  return /** @type{?proto.mlmbox.type.Career.Rank.Gift.Goods} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.type.Career.Rank.Gift.Goods, 2));
};


/**
 * @param {?proto.mlmbox.type.Career.Rank.Gift.Goods|undefined} value
 * @return {!proto.mlmbox.type.Career.Rank.Gift} returns this
*/
proto.mlmbox.type.Career.Rank.Gift.prototype.setGoods = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Career.Rank.Gift} returns this
 */
proto.mlmbox.type.Career.Rank.Gift.prototype.clearGoods = function() {
  return this.setGoods(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Career.Rank.Gift.prototype.hasGoods = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Money money = 3;
 * @return {?proto.mlmbox.type.Career.Rank.Gift.Money}
 */
proto.mlmbox.type.Career.Rank.Gift.prototype.getMoney = function() {
  return /** @type{?proto.mlmbox.type.Career.Rank.Gift.Money} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.type.Career.Rank.Gift.Money, 3));
};


/**
 * @param {?proto.mlmbox.type.Career.Rank.Gift.Money|undefined} value
 * @return {!proto.mlmbox.type.Career.Rank.Gift} returns this
*/
proto.mlmbox.type.Career.Rank.Gift.prototype.setMoney = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Career.Rank.Gift} returns this
 */
proto.mlmbox.type.Career.Rank.Gift.prototype.clearMoney = function() {
  return this.setMoney(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Career.Rank.Gift.prototype.hasMoney = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.type.Career.Rank.Process.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Career.Rank.Process.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Career.Rank.Process.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Career.Rank.Process} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Career.Rank.Process.toObject = function(includeInstance, msg) {
  var f, obj = {
    rankId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    achievementPartnersRanksList: jspb.Message.toObjectList(msg.getAchievementPartnersRanksList(),
    proto.mlmbox.type.Career.Rank.ConditionPartnersRank.toObject, includeInstance),
    achievementPartnersTotalContractsOwnersQuantity: jspb.Message.getFieldWithDefault(msg, 3, 0),
    achievementBinaryLineTotalTurnoverLeast: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Career.Rank.Process}
 */
proto.mlmbox.type.Career.Rank.Process.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Career.Rank.Process;
  return proto.mlmbox.type.Career.Rank.Process.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Career.Rank.Process} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Career.Rank.Process}
 */
proto.mlmbox.type.Career.Rank.Process.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRankId(value);
      break;
    case 2:
      var value = new proto.mlmbox.type.Career.Rank.ConditionPartnersRank;
      reader.readMessage(value,proto.mlmbox.type.Career.Rank.ConditionPartnersRank.deserializeBinaryFromReader);
      msg.addAchievementPartnersRanks(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAchievementPartnersTotalContractsOwnersQuantity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAchievementBinaryLineTotalTurnoverLeast(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Career.Rank.Process.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Career.Rank.Process.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Career.Rank.Process} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Career.Rank.Process.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRankId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAchievementPartnersRanksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.mlmbox.type.Career.Rank.ConditionPartnersRank.serializeBinaryToWriter
    );
  }
  f = message.getAchievementPartnersTotalContractsOwnersQuantity();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getAchievementBinaryLineTotalTurnoverLeast();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional uint32 rank_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Career.Rank.Process.prototype.getRankId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Career.Rank.Process} returns this
 */
proto.mlmbox.type.Career.Rank.Process.prototype.setRankId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated ConditionPartnersRank achievement_partners_ranks = 2;
 * @return {!Array<!proto.mlmbox.type.Career.Rank.ConditionPartnersRank>}
 */
proto.mlmbox.type.Career.Rank.Process.prototype.getAchievementPartnersRanksList = function() {
  return /** @type{!Array<!proto.mlmbox.type.Career.Rank.ConditionPartnersRank>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.type.Career.Rank.ConditionPartnersRank, 2));
};


/**
 * @param {!Array<!proto.mlmbox.type.Career.Rank.ConditionPartnersRank>} value
 * @return {!proto.mlmbox.type.Career.Rank.Process} returns this
*/
proto.mlmbox.type.Career.Rank.Process.prototype.setAchievementPartnersRanksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.mlmbox.type.Career.Rank.ConditionPartnersRank=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.Career.Rank.ConditionPartnersRank}
 */
proto.mlmbox.type.Career.Rank.Process.prototype.addAchievementPartnersRanks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.mlmbox.type.Career.Rank.ConditionPartnersRank, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.type.Career.Rank.Process} returns this
 */
proto.mlmbox.type.Career.Rank.Process.prototype.clearAchievementPartnersRanksList = function() {
  return this.setAchievementPartnersRanksList([]);
};


/**
 * optional uint32 achievement_partners_total_contracts_owners_quantity = 3;
 * @return {number}
 */
proto.mlmbox.type.Career.Rank.Process.prototype.getAchievementPartnersTotalContractsOwnersQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Career.Rank.Process} returns this
 */
proto.mlmbox.type.Career.Rank.Process.prototype.setAchievementPartnersTotalContractsOwnersQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string achievement_binary_line_total_turnover_least = 4;
 * @return {string}
 */
proto.mlmbox.type.Career.Rank.Process.prototype.getAchievementBinaryLineTotalTurnoverLeast = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Career.Rank.Process} returns this
 */
proto.mlmbox.type.Career.Rank.Process.prototype.setAchievementBinaryLineTotalTurnoverLeast = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 rank_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Career.Rank.prototype.getRankId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Career.Rank} returns this
 */
proto.mlmbox.type.Career.Rank.prototype.setRankId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string rank_title = 2;
 * @return {string}
 */
proto.mlmbox.type.Career.Rank.prototype.getRankTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Career.Rank} returns this
 */
proto.mlmbox.type.Career.Rank.prototype.setRankTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ConditionPartnersRank condition_partners_ranks = 3;
 * @return {!Array<!proto.mlmbox.type.Career.Rank.ConditionPartnersRank>}
 */
proto.mlmbox.type.Career.Rank.prototype.getConditionPartnersRanksList = function() {
  return /** @type{!Array<!proto.mlmbox.type.Career.Rank.ConditionPartnersRank>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.type.Career.Rank.ConditionPartnersRank, 3));
};


/**
 * @param {!Array<!proto.mlmbox.type.Career.Rank.ConditionPartnersRank>} value
 * @return {!proto.mlmbox.type.Career.Rank} returns this
*/
proto.mlmbox.type.Career.Rank.prototype.setConditionPartnersRanksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.mlmbox.type.Career.Rank.ConditionPartnersRank=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.Career.Rank.ConditionPartnersRank}
 */
proto.mlmbox.type.Career.Rank.prototype.addConditionPartnersRanks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.mlmbox.type.Career.Rank.ConditionPartnersRank, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.type.Career.Rank} returns this
 */
proto.mlmbox.type.Career.Rank.prototype.clearConditionPartnersRanksList = function() {
  return this.setConditionPartnersRanksList([]);
};


/**
 * optional uint32 condition_partners_total_contracts_owners_quantity = 4;
 * @return {number}
 */
proto.mlmbox.type.Career.Rank.prototype.getConditionPartnersTotalContractsOwnersQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Career.Rank} returns this
 */
proto.mlmbox.type.Career.Rank.prototype.setConditionPartnersTotalContractsOwnersQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string condition_binary_line_total_turnover_least = 5;
 * @return {string}
 */
proto.mlmbox.type.Career.Rank.prototype.getConditionBinaryLineTotalTurnoverLeast = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Career.Rank} returns this
 */
proto.mlmbox.type.Career.Rank.prototype.setConditionBinaryLineTotalTurnoverLeast = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string bonus_binary_matching_level_percent = 6;
 * @return {!Array<string>}
 */
proto.mlmbox.type.Career.Rank.prototype.getBonusBinaryMatchingLevelPercentList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.mlmbox.type.Career.Rank} returns this
 */
proto.mlmbox.type.Career.Rank.prototype.setBonusBinaryMatchingLevelPercentList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.Career.Rank} returns this
 */
proto.mlmbox.type.Career.Rank.prototype.addBonusBinaryMatchingLevelPercent = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.type.Career.Rank} returns this
 */
proto.mlmbox.type.Career.Rank.prototype.clearBonusBinaryMatchingLevelPercentList = function() {
  return this.setBonusBinaryMatchingLevelPercentList([]);
};


/**
 * repeated Gift gifts = 7;
 * @return {!Array<!proto.mlmbox.type.Career.Rank.Gift>}
 */
proto.mlmbox.type.Career.Rank.prototype.getGiftsList = function() {
  return /** @type{!Array<!proto.mlmbox.type.Career.Rank.Gift>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.type.Career.Rank.Gift, 7));
};


/**
 * @param {!Array<!proto.mlmbox.type.Career.Rank.Gift>} value
 * @return {!proto.mlmbox.type.Career.Rank} returns this
*/
proto.mlmbox.type.Career.Rank.prototype.setGiftsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.mlmbox.type.Career.Rank.Gift=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.Career.Rank.Gift}
 */
proto.mlmbox.type.Career.Rank.prototype.addGifts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.mlmbox.type.Career.Rank.Gift, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.type.Career.Rank} returns this
 */
proto.mlmbox.type.Career.Rank.prototype.clearGiftsList = function() {
  return this.setGiftsList([]);
};


/**
 * optional uint32 current_rank_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Career.prototype.getCurrentRankId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Career} returns this
 */
proto.mlmbox.type.Career.prototype.setCurrentRankId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Rank.Process next_rank_process = 2;
 * @return {?proto.mlmbox.type.Career.Rank.Process}
 */
proto.mlmbox.type.Career.prototype.getNextRankProcess = function() {
  return /** @type{?proto.mlmbox.type.Career.Rank.Process} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.type.Career.Rank.Process, 2));
};


/**
 * @param {?proto.mlmbox.type.Career.Rank.Process|undefined} value
 * @return {!proto.mlmbox.type.Career} returns this
*/
proto.mlmbox.type.Career.prototype.setNextRankProcess = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Career} returns this
 */
proto.mlmbox.type.Career.prototype.clearNextRankProcess = function() {
  return this.setNextRankProcess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Career.prototype.hasNextRankProcess = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Rank ranks = 3;
 * @return {!Array<!proto.mlmbox.type.Career.Rank>}
 */
proto.mlmbox.type.Career.prototype.getRanksList = function() {
  return /** @type{!Array<!proto.mlmbox.type.Career.Rank>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.type.Career.Rank, 3));
};


/**
 * @param {!Array<!proto.mlmbox.type.Career.Rank>} value
 * @return {!proto.mlmbox.type.Career} returns this
*/
proto.mlmbox.type.Career.prototype.setRanksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.mlmbox.type.Career.Rank=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.Career.Rank}
 */
proto.mlmbox.type.Career.prototype.addRanks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.mlmbox.type.Career.Rank, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.type.Career} returns this
 */
proto.mlmbox.type.Career.prototype.clearRanksList = function() {
  return this.setRanksList([]);
};


goog.object.extend(exports, proto.mlmbox.type);
