import { GoogleAuthenticatorClient } from "../../../../apis/mlmbox/client/google_authenticator/v1/google_authenticator_grpc_web_pb";
import { GoogleAuthenticator } from "../../../../apis/mlmbox/type/google_authenticator_pb";
import { Empty } from 'google-protobuf/google/protobuf/empty_pb.js'
import { API_URL } from '@/api';

const client = new GoogleAuthenticatorClient(API_URL, null, {
    withCredentials: true
});

export default {
    state: {
        googleAuthenticator: [],
        secretCode: {},
    },
  
    mutations: {
        setGoogleAuthenticator(state, googleAuthenticator) {
            state.googleAuthenticator = googleAuthenticator;
        },
        setSecretCode(state, secretCode) {
            state.secretCode = secretCode;
        },
    },

    actions: {
        get2aInfo({commit}) {
            return new Promise((resolve, reject) => {
                const request = new Empty;
      
                client.info(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('setGoogleAuthenticator', response.toObject())
                        resolve(response.toObject())
                    }
                })
            })
        },
        
        get2aGenerate({commit}) {
            return new Promise((resolve, reject) => {
                const request = new Empty;
      
                client.generate(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('setSecretCode', response.toObject().secretCode)
                        resolve(response.toObject())
                    }
                })
            })
        },
        
        get2aActivate({commit}, verifiedCode) {
            return new Promise((resolve, reject) => {
                const request = new GoogleAuthenticator.VerifiedCode();
                request.setVerifiedCode(verifiedCode)
      
                client.activate(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('stub')
                        resolve(response.toObject())
                    }
                })
            })
        },
        
        get2aDeactivate({commit}) {
            return new Promise((resolve, reject) => {
                const request = new Empty;
      
                client.deactivate(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('stub')
                        resolve(response.toObject())
                    }
                })
            })
        },
        
        get2aUsageSettingsUpdate({commit}, fields) {
            return new Promise((resolve, reject) => {
                const request = new GoogleAuthenticator.UsageSettings.UpdateRequest();
                
                fields.forEach(f => {
                    const field = new GoogleAuthenticator.UsageSettings.UpdateRequest.Field()
                    field.setFieldId(f.fieldId)
                    field.setValue(f.value)
                    request.addFields(field)
                })

                client.usageSettingsUpdate(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('stub')
                        resolve(response.toObject())
                    }
                })
            })
        },

    }
}