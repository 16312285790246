import { createStore } from 'vuex';
import account from './modules/account'
import auth from './modules/auth'
import network from './modules/network'
import locale from './modules/locale'
import session from './modules/session'
import confirmation from './modules/confirmation'
import confirmationGuest from './modules/confirmationGuest'
import google_authenticator from './modules/google_authenticator'
import finance from './modules/finance'
import asset from './modules/asset'
import farming from './modules/farming'
import career from './modules/career'
import binary from './modules/binary'
import asset_pair from './modules/asset_pair'
import staking from './modules/staking'

export default createStore({
  state() {
    return {
      mobileScreen: false
    }
  },

  mutations: {
    setMobileScreen(state, value) {
      state.mobileScreen = value
    }
  },

  actions: {
    updateMobileScreen({commit}, newValue) {
      commit('setMobileScreen', newValue)
    }
  },

  modules: {
    account,
    auth,
    network,
    locale,
    session,
    confirmation,
    confirmationGuest,
    google_authenticator,
    finance,
    asset,
    farming,
    career,
    binary,
    asset_pair,
    staking,
  }
})
