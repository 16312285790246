export const auth = {
    methods: {
        getTokenExist() {
            return localStorage.getItem('access_token') !== null;
        },

        getAuthState() {
            return JSON.parse(localStorage.getItem('userAuth')) === true;
        },
        
        validateLogin(value) {
            const loginRegex = /^[a-zA-Z][_a-zA-Z0-9]{2,149}$/;
            
            return loginRegex.test(value);
        },

        validateEmail(value) {
          const noSpacesRegex = /^[^\s]+(\s+[^\s]+)*$/;
          const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,10}$/;
    
          return noSpacesRegex.test(value) && emailRegex.test(value);
        },

        validatePassword(value) {
            const noSpacesRegex = /^[^\s]+(\s+[^\s]+)*$/;
      
            return noSpacesRegex.test(value) && value.length >= 8 && value.length <= 64;
        },
    }
}