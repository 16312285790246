export const session = {
  methods: {
    getSessionState(cbResponce, cbErr) {
      this.$store.dispatch('getSessionState')
      .then((response) => {
        if (cbResponce !== undefined) cbResponce(response)
      }).catch((err) => {
        if (cbErr !== undefined) cbErr(err)
      })
    },

    deactivateAllSessions(cbResponce, cbErr) {
      this.$store.dispatch('deactivateAllSessions')
      .then((response) => {
        if (cbResponce !== undefined) cbResponce(response)
      }).catch((err) => {
        if (cbErr !== undefined) cbErr(err)
      })
    },


  }
}