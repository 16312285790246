/**
 * @fileoverview gRPC-Web generated client stub for mlmbox.client.google_authenticator.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.15.8
// source: mlmbox/client/google_authenticator/v1/google_authenticator.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var mlmbox_type_confirmation_pb = require('../../../../mlmbox/type/confirmation_pb.js')

var mlmbox_type_google_authenticator_pb = require('../../../../mlmbox/type/google_authenticator_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.mlmbox = {};
proto.mlmbox.client = {};
proto.mlmbox.client.google_authenticator = {};
proto.mlmbox.client.google_authenticator.v1 = require('./google_authenticator_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mlmbox.client.google_authenticator.v1.GoogleAuthenticatorClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mlmbox.client.google_authenticator.v1.GoogleAuthenticatorPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.mlmbox.type.GoogleAuthenticator>}
 */
const methodDescriptor_GoogleAuthenticator_Info = new grpc.web.MethodDescriptor(
  '/mlmbox.client.google_authenticator.v1.GoogleAuthenticator/Info',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  mlmbox_type_google_authenticator_pb.GoogleAuthenticator,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_google_authenticator_pb.GoogleAuthenticator.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.type.GoogleAuthenticator)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.GoogleAuthenticator>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.google_authenticator.v1.GoogleAuthenticatorClient.prototype.info =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.google_authenticator.v1.GoogleAuthenticator/Info',
      request,
      metadata || {},
      methodDescriptor_GoogleAuthenticator_Info,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.type.GoogleAuthenticator>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.google_authenticator.v1.GoogleAuthenticatorPromiseClient.prototype.info =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.google_authenticator.v1.GoogleAuthenticator/Info',
      request,
      metadata || {},
      methodDescriptor_GoogleAuthenticator_Info);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.mlmbox.type.GoogleAuthenticator.SecretCode>}
 */
const methodDescriptor_GoogleAuthenticator_Generate = new grpc.web.MethodDescriptor(
  '/mlmbox.client.google_authenticator.v1.GoogleAuthenticator/Generate',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  mlmbox_type_google_authenticator_pb.GoogleAuthenticator.SecretCode,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_google_authenticator_pb.GoogleAuthenticator.SecretCode.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.type.GoogleAuthenticator.SecretCode)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.GoogleAuthenticator.SecretCode>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.google_authenticator.v1.GoogleAuthenticatorClient.prototype.generate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.google_authenticator.v1.GoogleAuthenticator/Generate',
      request,
      metadata || {},
      methodDescriptor_GoogleAuthenticator_Generate,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.type.GoogleAuthenticator.SecretCode>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.google_authenticator.v1.GoogleAuthenticatorPromiseClient.prototype.generate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.google_authenticator.v1.GoogleAuthenticator/Generate',
      request,
      metadata || {},
      methodDescriptor_GoogleAuthenticator_Generate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.type.GoogleAuthenticator.VerifiedCode,
 *   !proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status>}
 */
const methodDescriptor_GoogleAuthenticator_Activate = new grpc.web.MethodDescriptor(
  '/mlmbox.client.google_authenticator.v1.GoogleAuthenticator/Activate',
  grpc.web.MethodType.UNARY,
  mlmbox_type_google_authenticator_pb.GoogleAuthenticator.VerifiedCode,
  mlmbox_type_google_authenticator_pb.GoogleAuthenticator.VerifiedCode.Status,
  /**
   * @param {!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_google_authenticator_pb.GoogleAuthenticator.VerifiedCode.Status.deserializeBinary
);


/**
 * @param {!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.google_authenticator.v1.GoogleAuthenticatorClient.prototype.activate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.google_authenticator.v1.GoogleAuthenticator/Activate',
      request,
      metadata || {},
      methodDescriptor_GoogleAuthenticator_Activate,
      callback);
};


/**
 * @param {!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.google_authenticator.v1.GoogleAuthenticatorPromiseClient.prototype.activate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.google_authenticator.v1.GoogleAuthenticator/Activate',
      request,
      metadata || {},
      methodDescriptor_GoogleAuthenticator_Activate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.mlmbox.type.Confirmation>}
 */
const methodDescriptor_GoogleAuthenticator_Deactivate = new grpc.web.MethodDescriptor(
  '/mlmbox.client.google_authenticator.v1.GoogleAuthenticator/Deactivate',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  mlmbox_type_confirmation_pb.Confirmation,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_confirmation_pb.Confirmation.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.type.Confirmation)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Confirmation>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.google_authenticator.v1.GoogleAuthenticatorClient.prototype.deactivate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.google_authenticator.v1.GoogleAuthenticator/Deactivate',
      request,
      metadata || {},
      methodDescriptor_GoogleAuthenticator_Deactivate,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.type.Confirmation>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.google_authenticator.v1.GoogleAuthenticatorPromiseClient.prototype.deactivate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.google_authenticator.v1.GoogleAuthenticator/Deactivate',
      request,
      metadata || {},
      methodDescriptor_GoogleAuthenticator_Deactivate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest,
 *   !proto.mlmbox.type.Confirmation>}
 */
const methodDescriptor_GoogleAuthenticator_UsageSettingsUpdate = new grpc.web.MethodDescriptor(
  '/mlmbox.client.google_authenticator.v1.GoogleAuthenticator/UsageSettingsUpdate',
  grpc.web.MethodType.UNARY,
  mlmbox_type_google_authenticator_pb.GoogleAuthenticator.UsageSettings.UpdateRequest,
  mlmbox_type_confirmation_pb.Confirmation,
  /**
   * @param {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_confirmation_pb.Confirmation.deserializeBinary
);


/**
 * @param {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.type.Confirmation)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Confirmation>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.google_authenticator.v1.GoogleAuthenticatorClient.prototype.usageSettingsUpdate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.google_authenticator.v1.GoogleAuthenticator/UsageSettingsUpdate',
      request,
      metadata || {},
      methodDescriptor_GoogleAuthenticator_UsageSettingsUpdate,
      callback);
};


/**
 * @param {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.type.Confirmation>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.google_authenticator.v1.GoogleAuthenticatorPromiseClient.prototype.usageSettingsUpdate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.google_authenticator.v1.GoogleAuthenticator/UsageSettingsUpdate',
      request,
      metadata || {},
      methodDescriptor_GoogleAuthenticator_UsageSettingsUpdate);
};


module.exports = proto.mlmbox.client.google_authenticator.v1;

