import { Empty } from 'google-protobuf/google/protobuf/empty_pb.js'
// import { Session } from "../../../../apis/mlmbox/type/session_pb";
// import { DurationPoint } from "../../../../apis/mlmbox/type/time_point_pb";

import { PackageBuyRequest } from "../../../../apis/mlmbox/client/staking/v1/staking_pb";
import { StakingClient } from "../../../../apis/mlmbox/client/staking/v1/staking_grpc_web_pb";
import { API_URL } from '@/api';

const client = new StakingClient(API_URL, true, {
  withCredentials: true
});

export default {
  state: {
    // info
    info: {},
    // packages
    packages: [],
  },

  mutations: {
    setInfo(state, info) {
      state.info = info;
    },
  },

  actions: {
    getStakingInfo({commit}) {
      return new Promise((resolve, reject) => {
        const request = new Empty;

        client.info(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
          if (err !== null) { reject(err) } 
          else {
            commit('setInfo', response.toObject());

            resolve(response.toObject())
          }
        })
      })
    },

    buyStakingPackage({ commit }, {packageId, assetId, priceValue}) {
      return new Promise((resolve, reject) => {
          const request = new PackageBuyRequest();

          request.setPackageId(packageId)
          request.setAssetId(assetId)
          request.setPriceValue(priceValue)

          client.packageBuy(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
              if (err !== null) {
                  reject(err)
              } else {
                  commit('setContract', response.toObject())
                  resolve(response.toObject())
              }
          })
      })
    },
    // deactivateSession({commit}, sessionId) {
    //   return new Promise((resolve, reject) => {
    //     const request = new Session();
    //     request.setSessionId(sessionId)

    //     client.deactivate(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
    //       if (err !== null) { 
    //         reject(err) 
    //       } 
    //       else { 
    //         commit('stub');
    //         Object.keys(response.toObject()).length === 0 && resolve(true) 
    //       }
    //     })
    //   })
    // },

    // deactivateAllSessions() {
    //   return new Promise((resolve, reject) => {
    //     const request = new Empty;

    //     client.deactivateAll(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
    //       if (err !== null) { reject(err) } 
    //       else { resolve(response.toObject()) }
    //     })
    //   })
    // },
 
    // deactivateDeactivateOther() {
    //   return new Promise((resolve, reject) => {
    //     const request = new Empty;

    //     client.deactivateOther(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
    //       if (err !== null) { reject(err) } 
    //       else { resolve(response.toObject()) }
    //     })
    //   })
    // },

    // deactivateDeactivateCurrent() {
    //   return new Promise((resolve, reject) => {
    //     const request = new Empty;

    //     client.deactivateCurrent(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
    //       if (err !== null) { reject(err) } 
    //       else { resolve(response.toObject()) }
    //     })
    //   })
    // },

    // deactivateExpireDurationSet({commit}, {durationValue, durationInfinity}) {
    //   return new Promise((resolve, reject) => {
    //     const request = new DurationPoint();
    //     request.setValue(durationValue)
    //     request.setInfinity(durationInfinity)

    //     client.expireDurationSet(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
    //       if (err !== null) { reject(err) } 
    //       else { resolve(response.toObject()) }
    //       commit('stub')
    //     })
    //   })
    // },

  }
}