import { LocaleClient } from "../../../../apis/mlmbox/client/locale/v1/locale_grpc_web_pb";
import { Locale } from "../../../../apis/mlmbox/type/locale_pb";
import { Empty } from 'google-protobuf/google/protobuf/empty_pb.js'
import { API_URL } from '@/api';

const client = new LocaleClient(API_URL, true, {
    withCredentials: true
});

export default {
    state: {
        locale: '',
        allLocales: []
    },

    mutations: {
        setLocale(state, locale) {
            state.locale = locale
        },
        setLocaleAll(state, allLocales) {
            state.allLocales = allLocales
        },
    },

    actions: {
        getLocale({commit}, localeId) {
              return new Promise((resolve, reject) => {
                const request = new Locale.ID();
                request.setLocale(localeId)

                client.get(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('setLocale')
                        resolve(response.toObject())
                    }
                })
            })
        },

        getLocaleList({commit}) {
              return new Promise((resolve, reject) => {
                const request = new Empty;

                client.list(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('setLocaleAll')
                        resolve(response.toObject())
                    }
                })
            })
        }
    }
}