import { createWebHashHistory, createRouter } from "vue-router";

import Error_404 from "@/views/Error_404/Error_404";

// import AirdropPage from "@/views/Airdrop/AirdropPage";
import HomeWrapper from "@/views/HomePage/HomeWrapper";
import WalletPage from "@/views/Wallet/WalletPage";
import CareerPage from "@/views/Career/CareerPage";
import MyPlansPage from "@/views/MyPlans/MyPlansPage";
import MyTeamPage from "@/views/MyTeam/MyTeamPage";
import BinaryPage from "@/views/Binary/BinaryPage";
import PromoPage from "@/views/Promo/PromoPage";
import SettingsPage from "@/views/Settings/SettingsPage";
import GraphPage from "@/views/Graph/GraphPage";
import SalePage from "@/views/SalePage/SalePage.vue";

// import AdminPage from "@/views/Admin/AdminPage";
// import AdminPageTransactions from "@/views/Admin/AdminPageTransactions";

import { tokenValidate } from '@/auth'

const routes = [
  {
    path: "/404",
    name: "Error_404",
    component: Error_404,
  },
  {
    path: "/",
    name: "Home",
    component: HomeWrapper,
  },
  // {
  //   path: "/airdrop",
  //   name: "AirdropPage",
  //   component: AirdropPage,
  // },
  {
    path: "/wallet",
    name: "Wallet",
    component: WalletPage,
  },
  {
    path: "/career",
    name: "CareerPage",
    component: CareerPage,
  },
  {
    path: "/my-plans",
    name: "MyPlansPage",
    component: MyPlansPage,
  },
  {
    path: "/my-team",
    name: "MyTeamPage",
    component: MyTeamPage,
  },
  {
    path: "/binary",
    name: "BinaryPage",
    component: BinaryPage,
  },
  {
    path: "/promo-materials",
    name: "PromoPage",
    component: PromoPage,
  },
  {
    path: "/settings",
    name: "SettingsPage",
    component: SettingsPage,
  },
  {
    path: "/tokenza-ai",
    name: "GraphPage",
    component: GraphPage,
  },
  {
    path: "/tkz-sale",
    name: "SalePage",
    component: SalePage,
  },


  // {
  //   path: "/admin",
  //   name: "Admin",
  //   component: AdminPage,
  // },
  // {
  //   path: "/admin/transactions",
  //   name: "AdminTranscations",
  //   component: AdminPageTransactions,
  // },
  { path: '/:catchAll(.*)', redirect: '/404', hidden: true }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next)=> {
  tokenValidate().then(() => {
    next();
  }).catch(() => {
    next();

    if (to.path !== "/") {
      if (to.path !== '/airdrop')
        router.push({name: 'Home'})
    } else if (JSON.parse(localStorage.getItem('userAuth')) === true && to.path === "/") {
      window.location.reload(true);
    }
  })
})

export default router;
