import { AccountClient } from "../../../../apis/mlmbox/client/account/v1/account_grpc_web_pb";
import { AccountPasswordSetRequest, AccountEmailSetRequest } from "../../../../apis/mlmbox/client/account/v1/account_pb";
import { Locale } from "../../../../apis/mlmbox/type/locale_pb";
import { Empty } from 'google-protobuf/google/protobuf/empty_pb.js'
import { API_URL } from '@/api';

const client = new AccountClient(API_URL, null, {
    withCredentials: true
});

export default {
    state: {
        user: {},
    },
  
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
    },

    getters: {
        incomeForecastYear(state) {
            const result = {}
            Object.keys({...state.user.incomeForecast}).forEach(key => result[key] = state.user.incomeForecast[key] * 365)
            return result
        },

        getCurrentRank(state) {
            const data = {
                0: "-",
                1: "Junior",
                2: "Senior",
                3: "Leader",
                4: "Head Leader",
                5: "President"
            }

            return data[state.user?.info?.currentRankId]
        }
    },

    actions: {
        getAccountInfo({ commit }) {
            return new Promise((resolve, reject) => {
                const request = new Empty;
      
                client.info(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('setUser', response.toObject())
                        resolve(response.toObject())
                    }
                })
            })
        },

        setPassword({commit}, password) {
            return new Promise((resolve, reject) => {
                const request = new AccountPasswordSetRequest();

                request.setPasswordNew(password);
      
                client.passwordSet(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('stub')
                        resolve(response.toObject())
                    }
                })
            })
        },

        setEmail({commit}, email) {
            return new Promise((resolve, reject) => {
                const request = new AccountEmailSetRequest();

                request.setEmailNew(email);
      
                client.emailSet(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('stub')
                        resolve(response.toObject())
                    }
                })
            })
        },

        setLocale({commit}, localeName) {
            return new Promise((resolve, reject) => {
                const request = new Locale.ID();

                request.setLocale(localeName);
      
                client.localeSet(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('stub')
                        resolve(response.toObject())
                    }
                })
            })
        },

    }
}