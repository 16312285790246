<template>
  <popup-template
    popupName="lost-password-code"
    @closePopup="$emit('closePopup')"
  >
    <!-- <div class="popups__heading"><span>Подтверждение кода</span></div> -->

    <form class="form form-signin" id="signinForm" action="#">
      <div class="form__wrap" :class="{ valid: confirmationValide === true }">
        <label for="confirmation">{{t('popups.confirmation_guest.confirmation_code')}}</label>
        <input
          class="form__input"
          id="confirmation"
          type="text"
          name="confirmation"
          placeholder="****"
          v-model="confirmation"
          ref="confirmInput"
        />
        <div
          class="error-item"
          :class="{ active: confirmationValide === false }"
        >
          <img src="img/home/err.png" alt="" />
          <span>{{t('popups.confirmation_guest.invalid_code')}}</span>
        </div>
      </div>

      <div class="form__wrap" :class="{ valid: confirmation2faValide === true }" v-if="has2fa === true">
        <label for="confirmation2fa">{{t('popups.confirmation.enter_code_2fa')}}</label>
        <input
          class="form__input"
          id="confirmation2fa"
          type="text"
          name="confirmation2fa"
          placeholder="****"
          v-model="confirmation2fa"
        />
        <div
          class="error-item"
          :class="{ active: confirmation2faValide === false }"
        >
          <img src="img/home/err.png" alt="" />
          <span>{{t('popups.confirmation.invalid_code')}}</span>
        </div>
      </div>

      <div class="popups__wallet_comission">
        <span>{{t('popups.confirmation_guest.enter_code')}}</span>
      </div>
      <div class="form__wrap form-wrap-btn">
        <button
          class="form__submit"
          id="regSubmitBtn"
          type="submit"
          @click.prevent="confirmCode"
        >
          {{t('popups.confirmation_guest.button')}}
        </button>
      </div>
      <div class="error-item" :class="{ active: error === true }">
        <img src="img/home/err.png" alt="" />
        <span>{{ errorMessage }}</span>
      </div>
    </form>
  </popup-template>
</template>

<script>
import {session} from '@/mixins/session'
import PopupTemplate from "./PopupTemplate";
import { useI18n } from 'vue-i18n'
import { mapState } from 'vuex';

export default {
  props: [
    'data'
  ],

  mixins: [
    session
  ],

  components: {
    PopupTemplate,
  },

	data() {
		return {
			confirmation: '',
			confirmationValide: null,

			confirmation2fa: '',
			confirmation2faValide: null,

      error: false,
      errorMessage: 'invalid argument',

      has2fa: false,
		}
	},

  computed: {
    ...mapState({
      confirmationFields: state => state.auth.confirmation.fieldsList
    })
  },

  methods: {
    resetError() {
      this.error = false;
    },

    validateConfirmation(value) {
      const loginRegex = /^(?=.*[A-Za-z0-9_]$)[A-Za-z0-9_][A-Za-z\d.-]{0,19}$/;

      this.confirmationValide = loginRegex.test(value);
    },

    validateConfirmation2fa(value) {
      const regex = /^[^\s]+(\s+[^\s]+)*$/;

      return regex.test(value);
    },

    generateCodeConfirmationFunction() {
      this.$store.dispatch('confirmationGuestGeneratePassword')
        .then(() => {
          if (this.confirmationFields.find(f => f.kindId === 2) !== undefined) this.has2fa = true;
      }).catch((err) => {
        this.error = true;
        this.errorMessage = err;
      })
    },

    generateCodeConfirmation() {
      this.generateCodeConfirmationFunction()
    },

    cancelConfirmation() {
      this.$store.dispatch('confirmationGuestCancel').catch((err) => {
        this.error = true;
        this.errorMessage = err.message;
      })
    },

    confirmCode() {
      if (!this.confirmationValide) return;

      const args = {
        emailValue1: this.confirmation,
        gaValue: this.confirmation2fa,
      }

      this.$store.dispatch('confirmationGuestConfirm', {...args})
        .then((response) => {
          if (response.accessToken !== '' && response.accessToken !== undefined) {
            localStorage.setItem("access_token", response.accessToken);
          }

          if (response.status !== 1) {
            this.error = true;
            this.errorMessage = response.comment;
          } else if (response.status === 1) {
            // в админку
            localStorage.setItem('userAuth', true);
            if (this.data.signUpSuccess === true) {
              this.$emit('openPopup', { name: 'PopupDoneAuth'})
              return;
            }

            if (this.$route.path !== '/') this.$router.push('/');
            else window.location.reload(true)

            // this.$router.push({ name: 'HomePage' })
          }
      }).catch((err) => {
        this.error = true;
        this.errorMessage = err.message;
      })
		},
  },

  watch: {
		confirmation(value) {
      this.validateConfirmation(value);
      this.error && this.resetError()
    },

		confirmation2fa(value) {
      this.confirmation2faValide = this.validateConfirmation2fa(value);
      this.error && this.resetError()
    },
  },

  mounted () {
    this.generateCodeConfirmation()
    this.$refs.confirmInput.focus();
  },

  created() {
  },

  unmounted() {
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  }
};
</script>

<style scoped>
.form__wrap.form-wrap-btn {
	margin-top: 2em
}
</style>
