<template>
    <popup-template popupName="custom-buy" @closePopup="$emit('closePopup')" class="form">
        <div class="popups__heading">
            <span>{{ currentPackage?.packageTitle }}</span>
        </div>
        <div class="popups__line_text">
            <div class="popups__line_text_1 heading fullwidth">
        <span>
          {{ exchangePriceFrom }}
          -
          {{ exchangePriceTo }}
          {{ currentAsset.assetTitle }}</span>
            </div>
        </div>

        <div class="popups__line_text">
            <div class="popups__line_text_1">
                <span>{{ t('base.monthly_income') }}</span>
            </div>
            <div class="popups__line_text_2">
                <span>{{ farmingPercent(currentPackage, 30) }}%</span>
            </div>
        </div>
        <div class="popups__line_text">
            <div class="popups__line_text_1">
                <span>{{ t('base.all_period') }}</span>
            </div>
            <div class="popups__line_text_2">
                <span>{{ farmingPercent(currentPackage, 30 * 24) }}%</span>
            </div>
        </div>
        <div class="popups__line_text">
            <div class="popups__line_text_1">
                <span>{{ t('base.direct_bonus') }}</span>
            </div>
            <div class="popups__line_text_2">
                <span>{{ currentPackage?.bonusDirectPercent * 100 }}%</span>
            </div>
        </div>
        <div class="popups__line_text">
            <div class="popups__line_text_1">
                <span>{{ t('base.binary_bonus') }}</span>
            </div>
            <div class="popups__line_text_2">
                <span>{{ currentPackage?.bonusBinaryPercent * 100 }}%</span>
            </div>
        </div>
        <div class="popups__line_text last">
            <div class="popups__line_text_1">
                <span>{{ t('base.binary_limit') }}</span>
            </div>
            <div class="popups__line_text_2">
                <span>{{ binaryLimit }}$&nbsp;/&nbsp;{{ t('base.per_week') }}</span>
            </div>
        </div>
        <div class="popups__wallet_for_whom_summ">
            <div class="form__wrap" :class="{ valid: sumValide === true }">
                <span>{{ t('popups.buy.enter_amount') }}</span>
                <div class="popups__wallet_input_block summ">
                    <input
                        class="wallet-input"
                        id="buySum"
                        type="text"
                        name="buySum"
                        v-model="sum"
                    />
                    <button class="max-button" @click="setMaxSum">max</button>
                    <select @change="changeCurrency" v-model="currencyId">
                        <option :value="currentAsset.assetId">{{ currentAsset.assetName }}</option>
                        <option v-for="asset in otherAssets" :key="asset" :value="asset.assetId">{{ asset.assetName }}
                        </option>
                    </select>
                </div>
                <div
                    class="error-item"
                    :class="{ active: sumValide === false }"
                >
                    <img src="img/home/err.png" alt=""/>
                    <span>{{ t('popups.buy.Invalid_amount') }}</span>
                </div>
            </div>
            <div class="popups__wallet_comission">
                <img src="img/wallet/warn.png" alt=""/>
                <span>{{ t('popups.buy.balance') }}</span>
                <span>{{ balance }}&nbsp;{{ currentAsset?.assetName }}</span>
            </div>
        </div>
        <button class="popups__btn_withdraw reg-done close" @click="buy">
            <span>{{ t('popups.buy.button') }}</span>
        </button>
        <div
            class="error-item"
            :class="{ active: error === true }"
        >
            <img src="img/home/err.png" alt=""/>
            <span>{{ errorMessage }}</span>
        </div>
    </popup-template>
</template>

<script>
import PopupTemplate from './PopupTemplate'
import {mapState} from 'vuex';
import {useI18n} from 'vue-i18n'

export default {
    components: {
        PopupTemplate
    },

    props: ['data'],

    data() {
        return {
            currencyId: 4,
            targetAssetId: 4,
            sum: '',
            sumValide: null,

            error: false,
            errorMessage: ''
        }
    },

    computed: {
        ...mapState({
            // пакеты
            farmingPackages: state => state.farming.farmingPackages,

            // активы
            currentAsset: state => state.asset.asset,
            // currentAsset: state => state.asset.defaultAsset,
            allAssets: state => state.asset.allAssets,
            walletAssetsList: (state) => state.finance.walletAssetsList,

            // обмен валют
            assetPairAll: state => state.asset_pair.assetPairAll
        }),

        walletAsset() {
            return this.walletAssetsList?.find(a => a.assetId === this.currentAsset.assetId);
        },

        otherAssets() {
            return this.allAssets.filter(a => {
                return a.assetId !== this.currentAsset.assetId
                    && a.assetName !== 'TRX'
                    && this.currentPackage?.paymentPriceRange?.paymentAssetIdsList.find(id => a.assetId === id) !== undefined
            })
        },

        currentPackage() {
            const startPackage = this.data?.packageId === undefined ? this.farmingPackages[0]?.packageId : this.data?.packageId;

            const isSumBig = this.farmingPackages.every(p => {
                const assetPair = this.assetPairAll?.find(pair => pair.assetPair.targetAssetId === this.targetAssetId && pair.assetPair.sourceAssetId === this.currentAsset.assetId);

                if (assetPair) {
                    const rateSell = Number(assetPair.rateSell) === 0 ? 1 : assetPair.rateSell;
                    return Number(this.sum) >= Number(p.paymentPriceRange.priceTo / rateSell);
                }
                // console.log(Number(this.sum) , Number(p.paymentPriceRange.priceTo));
                return Number(this.sum) >= Number(p.paymentPriceRange.priceTo);
            });
            const isSumSmall = this.farmingPackages.every(p => {
                const assetPair = this.assetPairAll?.find(pair => pair.assetPair.targetAssetId === this.targetAssetId && pair.assetPair.sourceAssetId === this.currentAsset.assetId);

                if (assetPair) {
                    const rateSell = Number(assetPair.rateSell) === 0 ? 1 : assetPair.rateSell;
                    return Number(this.sum) <= Number(p.paymentPriceRange.priceTo / rateSell);
                }

                return Number(this.sum) <= Number(p.paymentPriceRange.priceFrom)
            });
            const selectedPackage = this.farmingPackages.find(p => p.packageId === startPackage);
            const findPackageBySum = this.farmingPackages.find(p => {
                const assetPair = this.assetPairAll?.find(pair => pair.assetPair.targetAssetId === this.targetAssetId && pair.assetPair.sourceAssetId === this.currentAsset.assetId);

                if (assetPair) {
                    const rateSell = Number(assetPair.rateSell) === 0 ? 1 : assetPair.rateSell;
                    return Number(this.sum) >= Number(p.paymentPriceRange.priceFrom / rateSell)
                        && Number(this.sum) < Number(p.paymentPriceRange.priceTo / rateSell)
                }

                return Number(this.sum) >= Number(p.paymentPriceRange.priceFrom)
                    && Number(this.sum) < Number(p.paymentPriceRange.priceTo)
            });

            if (this.sum === '') return selectedPackage;
            if (isSumBig) return this.farmingPackages[this.farmingPackages.length - 1]
            if (isSumSmall) return this.farmingPackages[0]

            return findPackageBySum === undefined ? selectedPackage : findPackageBySum;
        },

        exchangePriceFrom() {
            return this.exchangePrice(this.currentPackage?.paymentPriceRange.priceFrom);
        },

        exchangePriceTo() {
            return this.exchangePrice(this.currentPackage?.paymentPriceRange.priceTo);
        },

        balance() {
            if (this.currentAsset?.assetName === 'BTC') {
                return parseFloat(this.walletAsset?.assetBalance).toFixed(8);
            } else {
                return Number(this.walletAsset?.assetBalance).toFixed(2)
            }
        },

        binaryLimit() {
            const assetPair = this.assetPairAll?.find(pair => pair.assetPair.targetAssetId === this.targetAssetId && pair.assetPair.sourceAssetId === this.currentAsset.assetId);

            if (assetPair) {
                const rateSell = Number(assetPair.rateSell) === 0 ? 1 : assetPair.rateSell;
                return parseFloat((this.currentPackage?.bonusBinaryWeekLimit / rateSell).toFixed(8));
            }

            return Number(this.currentPackage?.bonusBinaryWeekLimit)?.toFixed();
        }
    },

    methods: {
        setMaxSum() {
            const assetPair = this.assetPairAll?.find(pair => pair.assetPair.targetAssetId === this.targetAssetId && pair.assetPair.sourceAssetId === this.currentAsset.assetId);
            const maxPrice = this.farmingPackages[this.farmingPackages.length - 1].paymentPriceRange.priceTo;
            const maxsum = maxPrice > +this.balance ? +this.balance : maxPrice;

            if (assetPair) {
                const rateSell = Number(assetPair.rateSell) === 0 ? 1 : assetPair.rateSell;
                this.sum = (maxsum / rateSell).toFixed(8);

                return;
            }

            this.sum = (+maxsum).toFixed(2);
        },

        changeCurrency() {
            this.sum = '';

            this.$store.dispatch('setAsset', this.currencyId).then(() => {
                // this.$store.dispatch('getWalletTransactionList', {assetId: this.currentAsset.assetId, stepTransactionId: 0})
                // this.$store.dispatch('getWalletBalanceHistory', {assetId: this.currentAsset.assetId});
            });
        },

        exchangePrice(amount) {
            const assetPair = this.assetPairAll?.find(pair => pair.assetPair.targetAssetId === this.targetAssetId && pair.assetPair.sourceAssetId === this.currentAsset.assetId);

            if (assetPair) {
                const rateSell = Number(assetPair.rateSell) === 0 ? 1 : assetPair.rateSell;

                return this.changePrice(amount / rateSell)
            }

            return this.changePrice(amount);
        },

        farmingPercent(p, days) {
            const koef = +p?.farmingDuration / 86400;
            return +(+p?.farmingPercent * koef * days * 100).toFixed(2);
        },

        sumValidation(value) {
            const numbersRegex = /^[.,0-9]{1,264}$/;
            const noSpacesRegex = /^[^\s]+(\s+[^\s]+)*$/;
            return noSpacesRegex.test(value) && numbersRegex.test(value);
        },

        setFloor(number) {
            const precision = 8;
            var multiplier = Math.pow(10, precision);
            var result = Math.floor(number * multiplier) / multiplier;

            if (typeof number === 'number' && !Number.isNaN(number)) {
                return parseFloat(result.toFixed(precision));
            } else {
                return +number; // Возвращаем исходное значение, если это не число с плавающей точкой
            }
        },

        numberWithSpaces(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "");
        },

        changePrice(price) {
            return this.numberWithSpaces(this.setFloor(price));
        },

        buy() {
            if (!this.sumValide) return;

            this.$store.dispatch('buyFarmingPackage', {
                packageId: this.currentPackage?.packageId,
                assetId: this.currentAsset.assetId,
                priceValue: this.sum
            }).then(() => {
                // if (response.activated === true) {
                this.$store.dispatch('getFarmingInfo').then(() => {
                    this.$router.push('/my-plans')
                    this.$emit('closePopup')
                })
                // }
            }).catch(err => {
                this.error = true;
                this.errorMessage = err.message;
            })
        }
    },

    created() {
        if (Object.keys(this.farmingPackages).length === 0) this.$store.dispatch('getAllFarmingPackages')
    },

    mounted() {
        this.currencyId = this.targetAssetId;
    },

    unmounted() {
        this.$store.dispatch('setAsset', 4);
    },

    watch: {
        sum(value) {
            this.sumValide = this.sumValidation(value);
        },
    },

    setup() {
        const {t} = useI18n({
            inheritLocale: true,
            useScope: "local"
        });

        return {t};
    }
}

</script>

<style lang="scss" scoped>
.heading.fullwidth {
    width: 100%;
}

.popups__line_text {
    padding: 1.7em 0;
    background-image: radial-gradient(circle at .1em, rgba(255, 255, 255, .5) 1.25px, rgba(255, 255, 255, 0) .1em);
    background-position: bottom left;
    background-size: .7em .4em, .4em .7em;
    background-repeat: repeat-x;

    &.last {
        background: transparent;
    }
}

.popups__wallet_input_block.summ select {
    border: 0;
    background: transparent;
    cursor: pointer;
    position: absolute;
    display: inline-block;
    right: 2.85714em;
    top: 50%;
    transform: translateY(-50%);
    color: #01ffc2;
    font-size: 0.875em;
    text-transform: uppercase;
    margin-top: 0.125em;
    font-weight: bold;
    transition: opacity .2s ease-in-out;
    outline: none;

    &:hover {
        opacity: .7;
    }

    option {
        color: #000;
        font-size: 1.2em;
    }
}

.popups__line_text_1.heading span {
    letter-spacing: 0.04em;
}

.max-button {
    position: absolute;
    top: 50%;
    right: 4.8em !important;
    transform: translate(0, -50%);
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
}

.popups__wallet_for_whom_summ .form__wrap::before {
    left: 0.75em !important;
    right: unset !important;
    background-size: 100% 100% !important;
}

.popups__wallet_input_block.summ select {
    right: .37em;
}
</style>
