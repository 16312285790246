<template>
  <div class="popups__switcher_sign_form_block">
    <form class="form form-signin" id="signinForm" action="#">
      <div class="form__wrap" :class="{ valid: referralValide === true }">
        <label for="regSponserLogin">{{t('popups.sign_out.sponsor')}}</label>
        <input
          class="form__input"
          id="regSponserLogin"
          type="text"
          name="regSponserLogin"
          :class="{disabled: !sponsorEmpty}"
          :placeholder="t('popups.sign_out.sponsor_login')"
          v-model="referral"
        />
        <div class="error-item" :class="{ active: referralValide === false }">
          <img src="img/home/err.png" alt="" />
          <span>{{t('popups.sign_out.sponsor_error')}}</span>
        </div>
      </div>

      <div class="form__wrap" :class="{ valid: loginValide === true }">
        <label for="regLogin">{{t('popups.sign_out.login')}}</label>
        <input
          class="form__input"
          id="regLogin"
          type="text"
          name="regLogin"
          :placeholder="t('popups.sign_out.login_placeholder')"
          v-model="login"
        />
        <div class="error-item" :class="{ active: loginValide === false }">
          <img src="img/home/err.png" alt="" />
          <span>{{t('popups.sign_out.invalid_login')}}</span>
        </div>
      </div>

      <div class="form__wrap" :class="{ valid: emailValide === true }">
        <label for="regEmail">Email:</label>
        <input
          class="form__input"
          id="regEmail"
          type="text"
          name="regEmail"
          :placeholder="t('popups.sign_out.enter_email')"
          v-model="email"
        />
        <div class="error-item" :class="{ active: emailValide === false }">
          <img src="img/home/err.png" alt="" />
          <span>{{t('popups.sign_out.invalid_email')}}</span>
        </div>
      </div>

      <div class="form__wrap" :class="{ valid: passwordValide === true }">
        <label for="regPass">{{t('popups.sign_out.password')}}</label>
        <input
          class="form__input"
          id="regPass"
          type="password"
          name="regPass"
          placeholder="********"
          v-model="password"
          autocomplete="new-password"
        />
        <div class="error-item" :class="{ active: passwordValide === false }">
          <img src="img/home/err.png" alt="" />
          <span>{{t('popups.sign_out.invalid_password')}}</span>
        </div>
      </div>
      <button class="form__submit" id="regSubmitBtn" type="submit" @click.prevent="signUp">
        {{t('popups.sign_out.register')}}
      </button>
      <div class="error-item" :class="{ active: error === true }">
        <img src="img/home/err.png" alt="" /><span>{{errorMessage}}</span>
      </div>
    </form>

    <div class="terms">
      <span>
        {{ t('base.terms_desc') }} <a href="/presentations/Terms_and_Conditions.pdf" target="_blank">«Terms & conditions»</a>
      </span>
    </div>
  </div>
</template>

<script>
import {auth} from '@/mixins/auth';
import { useI18n } from 'vue-i18n';

export default {
  mixins: [auth],

  data() {
    return {
      referral: "",
      login: "",
      email: "",
      password: "",

      referralValide: null,
      loginValide: null,
      emailValide: null,
      passwordValide: null,

      error: false,
      errorMessage: 'invalid arguments'
    };
  },

  computed: {
    sponsorEmpty() {
      return this.$route.query.ref === undefined;
    }
  },

  methods: {
    resetError() {
      this.error = false;
    },

    validateReferral() {
      this.$store.dispatch('getPartner', this.referral).then(() => {
        this.referralValide = true
      }).catch(err => {
        this.referralValide = false
        
        this.error = true;
        this.errorMessage = err.message
      })
    },

    getLang() {
      if (navigator.languages != undefined) 
        return navigator.languages[0]; 
      return navigator.language;
    },

    signUp() {
      // if (!this.validateReferral()) return;
      if (!this.loginValide && !this.emailValide && !this.passwordValide) {
        this.error = true;
        return;
      }

        const args = {
          referral: this.referral,
          login: this.login,
          email: this.email,
          password: this.password,
          locale: this.getLang()
        }

        this.$store.dispatch('signUp', {...args})
          .then((response) => {
            console.log(response);
            if (response.status === 1) {
              // to confirmation
              if (response.accessToken !== '' && response.accessToken !== undefined) {
                localStorage.setItem("access_token", response.accessToken);
              }

              this.$emit("openPopup", {
                name: "PopupConfirmationGuest",
                confirmationId: response.confirmation.confirmationId,
                signUpSuccess: true
              });
            } else if (response.status === 0) {
              // to dashboard
              if (response.accessToken !== '' && response.accessToken !== undefined) {
                localStorage.setItem("access_token", response.accessToken);
              }

              localStorage.setItem("userAuth", true);
              this.$router.go("/");
            }
        }).catch((err) => {
          this.error = true;
          this.errorMessage = err.message
        })
    },

    getLocale() {
      this.$store.dispatch('getLocale')
    },

    updateReferral() {
      let params = this.$route.query.ref;
      if (params !== undefined) localStorage.setItem('referral', params);
      
      this.referral = params !== undefined ? params : 
        localStorage.getItem('referral') !== null ? localStorage.getItem('referral') : '' ;
    }
  },

  created() {
    this.updateReferral();
  },

  watch: {
    login(value) {
      // if (value === "1111") {
      //   this.$emit('openPopup', {name: 'PopupDoneAuth'})
      // }
      this.loginValide = this.validateLogin(value);
      this.error && this.resetError();
    },

    email(value) {
      this.emailValide = this.validateEmail(value);
      this.error && this.resetError();
    },

    password(value) {
      this.passwordValide = this.validatePassword(value);
      this.error && this.resetError();
    },
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  }
};
</script>
<style lang="scss" scoped>
.disabled {
  pointer-events: none;
}
.terms {
  color: #fff;
  margin-top: 1em;
  font-family: 'Visby CF Demi';

  span {
    font-size: .8em;
  }

  a {
    color: #0ede9e;
    border-bottom: .1em solid transparent;
    transition: border-bottom .2s ease-in-out;

    &:hover {
      border-bottom: .1em solid #0ede9e;
    }
  }
}
</style>