import { CareerClient } from "../../../../apis/mlmbox/client/career/v1/career_grpc_web_pb";
import { Career } from "../../../../apis/mlmbox/type/career_pb";
import { Empty } from 'google-protobuf/google/protobuf/empty_pb.js'
import { API_URL } from '@/api';

const client = new CareerClient(API_URL, null, {
    withCredentials: true
});

export default {
    state: {
        info: {},
        rank: {},
        rankAll: [],
    },
  
    mutations: {
        setCareerInfo(state, info) {
            state.info = info;
        },

        setRank(state, rank) {
            state.rank = rank;
        },

        setRankAll(state, rankAll) {
            state.rankAll = rankAll;
        },
    },

    actions: {
        getCareerInfo({commit}) {
            return new Promise((resolve, reject) => {
                const request = new Empty;
      
                client.info(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('setCareerInfo', response.toObject())
                        resolve(response.toObject())
                    }
                })
            })
        },

        getCareerRank({ commit }, rankId) {
            return new Promise((resolve, reject) => {
                const request = new Career.Rank.ID();

                request.setRankId(rankId)
      
                client.rankGet(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('setRank', response.toObject())
                        resolve(response.toObject())
                    }
                })
            })
        },

        getCareerRankAll({commit}) {
            return new Promise((resolve) => {
                const request = new Empty;
                const stream = client.packagesAll(request, {"authorization": localStorage.getItem("access_token")})

                stream.on('data', function(response) {
                    resolve(response.toObject())
                    commit('setRankAll', response.toObject())
                });
            })
        },
    }
}