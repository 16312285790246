export const dataScrollUpdate = {
  data() {
    return {
      dataUpdate: false
    }
  },

  methods: {
    debounce(method, delay) {
      clearTimeout(method._tId);
      method._tId= setTimeout(function() { method(); }, delay);
    },

    onScrollHandle(element) {
      const t = window.innerHeight;
      const rect = element.getBoundingClientRect();
      this.dataUpdate = (t > rect.top+rect.height);
    },
  },

  // created () {
  //   window.addEventListener('scroll', this.onScrollHandle(elementRef));
  // },

  // unmounted() {
  //   window.removeEventListener('scroll');
  // }
}