// source: mlmbox/type/wallet.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var mlmbox_type_bonus_pb = require('../../mlmbox/type/bonus_pb.js');
goog.object.extend(proto, mlmbox_type_bonus_pb);
goog.exportSymbol('proto.mlmbox.type.Wallet', null, global);
goog.exportSymbol('proto.mlmbox.type.Wallet.Asset', null, global);
goog.exportSymbol('proto.mlmbox.type.Wallet.Asset.BalanceHistory', null, global);
goog.exportSymbol('proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction', null, global);
goog.exportSymbol('proto.mlmbox.type.Wallet.Asset.Summary', null, global);
goog.exportSymbol('proto.mlmbox.type.Wallet.Asset.Transaction', null, global);
goog.exportSymbol('proto.mlmbox.type.Wallet.Asset.Transaction.ID', null, global);
goog.exportSymbol('proto.mlmbox.type.Wallet.Asset.Transaction.Kind', null, global);
goog.exportSymbol('proto.mlmbox.type.Wallet.Asset.Transaction.Kind.ID', null, global);
goog.exportSymbol('proto.mlmbox.type.Wallet.Asset.Transaction.Status', null, global);
goog.exportSymbol('proto.mlmbox.type.Wallet.Asset.Transaction.Status.ID', null, global);
goog.exportSymbol('proto.mlmbox.type.Wallet.Processing', null, global);
goog.exportSymbol('proto.mlmbox.type.Wallet.Processing.Asset', null, global);
goog.exportSymbol('proto.mlmbox.type.Wallet.Processing.Asset.ID', null, global);
goog.exportSymbol('proto.mlmbox.type.Wallet.Processing.ID', null, global);
goog.exportSymbol('proto.mlmbox.type.Wallet.WithdrawalTask', null, global);
goog.exportSymbol('proto.mlmbox.type.Wallet.WithdrawalTask.ID', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Wallet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.type.Wallet.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.type.Wallet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Wallet.displayName = 'proto.mlmbox.type.Wallet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Wallet.Asset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.type.Wallet.Asset.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.type.Wallet.Asset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Wallet.Asset.displayName = 'proto.mlmbox.type.Wallet.Asset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Wallet.Asset.Transaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Wallet.Asset.Transaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Wallet.Asset.Transaction.displayName = 'proto.mlmbox.type.Wallet.Asset.Transaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Wallet.Asset.Transaction.ID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Wallet.Asset.Transaction.ID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Wallet.Asset.Transaction.ID.displayName = 'proto.mlmbox.type.Wallet.Asset.Transaction.ID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Wallet.Asset.Transaction.Kind = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Wallet.Asset.Transaction.Kind, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Wallet.Asset.Transaction.Kind.displayName = 'proto.mlmbox.type.Wallet.Asset.Transaction.Kind';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Wallet.Asset.Transaction.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Wallet.Asset.Transaction.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Wallet.Asset.Transaction.Status.displayName = 'proto.mlmbox.type.Wallet.Asset.Transaction.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.type.Wallet.Asset.BalanceHistory.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.type.Wallet.Asset.BalanceHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Wallet.Asset.BalanceHistory.displayName = 'proto.mlmbox.type.Wallet.Asset.BalanceHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction.displayName = 'proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Wallet.Asset.Summary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Wallet.Asset.Summary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Wallet.Asset.Summary.displayName = 'proto.mlmbox.type.Wallet.Asset.Summary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Wallet.Processing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.type.Wallet.Processing.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.type.Wallet.Processing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Wallet.Processing.displayName = 'proto.mlmbox.type.Wallet.Processing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Wallet.Processing.ID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Wallet.Processing.ID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Wallet.Processing.ID.displayName = 'proto.mlmbox.type.Wallet.Processing.ID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Wallet.Processing.Asset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Wallet.Processing.Asset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Wallet.Processing.Asset.displayName = 'proto.mlmbox.type.Wallet.Processing.Asset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Wallet.Processing.Asset.ID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Wallet.Processing.Asset.ID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Wallet.Processing.Asset.ID.displayName = 'proto.mlmbox.type.Wallet.Processing.Asset.ID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Wallet.WithdrawalTask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Wallet.WithdrawalTask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Wallet.WithdrawalTask.displayName = 'proto.mlmbox.type.Wallet.WithdrawalTask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Wallet.WithdrawalTask.ID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Wallet.WithdrawalTask.ID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Wallet.WithdrawalTask.ID.displayName = 'proto.mlmbox.type.Wallet.WithdrawalTask.ID';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.type.Wallet.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Wallet.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Wallet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Wallet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetsList: jspb.Message.toObjectList(msg.getAssetsList(),
    proto.mlmbox.type.Wallet.Asset.toObject, includeInstance),
    processingsList: jspb.Message.toObjectList(msg.getProcessingsList(),
    proto.mlmbox.type.Wallet.Processing.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Wallet}
 */
proto.mlmbox.type.Wallet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Wallet;
  return proto.mlmbox.type.Wallet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Wallet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Wallet}
 */
proto.mlmbox.type.Wallet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.type.Wallet.Asset;
      reader.readMessage(value,proto.mlmbox.type.Wallet.Asset.deserializeBinaryFromReader);
      msg.addAssets(value);
      break;
    case 2:
      var value = new proto.mlmbox.type.Wallet.Processing;
      reader.readMessage(value,proto.mlmbox.type.Wallet.Processing.deserializeBinaryFromReader);
      msg.addProcessings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Wallet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Wallet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Wallet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mlmbox.type.Wallet.Asset.serializeBinaryToWriter
    );
  }
  f = message.getProcessingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.mlmbox.type.Wallet.Processing.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.type.Wallet.Asset.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Wallet.Asset.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Wallet.Asset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Wallet.Asset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.Asset.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    assetBalance: jspb.Message.getFieldWithDefault(msg, 2, ""),
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    proto.mlmbox.type.Wallet.Asset.Transaction.toObject, includeInstance),
    incomeBonus: (f = msg.getIncomeBonus()) && mlmbox_type_bonus_pb.IncomeBonus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Wallet.Asset}
 */
proto.mlmbox.type.Wallet.Asset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Wallet.Asset;
  return proto.mlmbox.type.Wallet.Asset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Wallet.Asset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Wallet.Asset}
 */
proto.mlmbox.type.Wallet.Asset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetBalance(value);
      break;
    case 3:
      var value = new proto.mlmbox.type.Wallet.Asset.Transaction;
      reader.readMessage(value,proto.mlmbox.type.Wallet.Asset.Transaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    case 4:
      var value = new mlmbox_type_bonus_pb.IncomeBonus;
      reader.readMessage(value,mlmbox_type_bonus_pb.IncomeBonus.deserializeBinaryFromReader);
      msg.setIncomeBonus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Wallet.Asset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Wallet.Asset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Wallet.Asset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.Asset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAssetBalance();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.mlmbox.type.Wallet.Asset.Transaction.serializeBinaryToWriter
    );
  }
  f = message.getIncomeBonus();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      mlmbox_type_bonus_pb.IncomeBonus.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Wallet.Asset.Transaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Wallet.Asset.Transaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.Asset.Transaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    kindId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    statusId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fee: jspb.Message.getFieldWithDefault(msg, 5, ""),
    balance: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    description: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Wallet.Asset.Transaction;
  return proto.mlmbox.type.Wallet.Asset.Transaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Wallet.Asset.Transaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTransactionId(value);
      break;
    case 2:
      var value = /** @type {!proto.mlmbox.type.Wallet.Asset.Transaction.Kind.ID} */ (reader.readEnum());
      msg.setKindId(value);
      break;
    case 3:
      var value = /** @type {!proto.mlmbox.type.Wallet.Asset.Transaction.Status.ID} */ (reader.readEnum());
      msg.setStatusId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFee(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBalance(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Wallet.Asset.Transaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Wallet.Asset.Transaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.Asset.Transaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getKindId();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getStatusId();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFee();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBalance();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.ID.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Wallet.Asset.Transaction.ID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Wallet.Asset.Transaction.ID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.Asset.Transaction.ID.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    transactionId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction.ID}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.ID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Wallet.Asset.Transaction.ID;
  return proto.mlmbox.type.Wallet.Asset.Transaction.ID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Wallet.Asset.Transaction.ID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction.ID}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.ID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTransactionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.ID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Wallet.Asset.Transaction.ID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Wallet.Asset.Transaction.ID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.Asset.Transaction.ID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTransactionId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 asset_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.ID.prototype.getAssetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction.ID} returns this
 */
proto.mlmbox.type.Wallet.Asset.Transaction.ID.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 transaction_id = 2;
 * @return {number}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.ID.prototype.getTransactionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction.ID} returns this
 */
proto.mlmbox.type.Wallet.Asset.Transaction.ID.prototype.setTransactionId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.Kind.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Wallet.Asset.Transaction.Kind.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Wallet.Asset.Transaction.Kind} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.Asset.Transaction.Kind.toObject = function(includeInstance, msg) {
  var f, obj = {
    kindId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction.Kind}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.Kind.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Wallet.Asset.Transaction.Kind;
  return proto.mlmbox.type.Wallet.Asset.Transaction.Kind.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Wallet.Asset.Transaction.Kind} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction.Kind}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.Kind.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.mlmbox.type.Wallet.Asset.Transaction.Kind.ID} */ (reader.readEnum());
      msg.setKindId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.Kind.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Wallet.Asset.Transaction.Kind.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Wallet.Asset.Transaction.Kind} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.Asset.Transaction.Kind.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKindId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.Kind.ID = {
  UNKNOWN: 0,
  CANCELED: 1,
  EARNED: 2,
  SPENT: 3,
  DEPOSIT: 4,
  WITHDRAWAL: 5,
  DEPOSIT_WALLET_TRANSFER: 6,
  WITHDRAWAL_WALLET_TRANSFER: 7,
  EXCHANGE: 8,
  WITHDRAWAL_FEE: 9,
  BURNED: 10
};

/**
 * optional ID kind_id = 1;
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction.Kind.ID}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.Kind.prototype.getKindId = function() {
  return /** @type {!proto.mlmbox.type.Wallet.Asset.Transaction.Kind.ID} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.mlmbox.type.Wallet.Asset.Transaction.Kind.ID} value
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction.Kind} returns this
 */
proto.mlmbox.type.Wallet.Asset.Transaction.Kind.prototype.setKindId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Wallet.Asset.Transaction.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Wallet.Asset.Transaction.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.Asset.Transaction.Status.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction.Status}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Wallet.Asset.Transaction.Status;
  return proto.mlmbox.type.Wallet.Asset.Transaction.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Wallet.Asset.Transaction.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction.Status}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.mlmbox.type.Wallet.Asset.Transaction.Status.ID} */ (reader.readEnum());
      msg.setStatusId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Wallet.Asset.Transaction.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Wallet.Asset.Transaction.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.Asset.Transaction.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.Status.ID = {
  PROCESSING: 0,
  SUCCESS: 1,
  REJECTED: 2
};

/**
 * optional ID status_id = 1;
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction.Status.ID}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.Status.prototype.getStatusId = function() {
  return /** @type {!proto.mlmbox.type.Wallet.Asset.Transaction.Status.ID} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.mlmbox.type.Wallet.Asset.Transaction.Status.ID} value
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction.Status} returns this
 */
proto.mlmbox.type.Wallet.Asset.Transaction.Status.prototype.setStatusId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 transaction_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.prototype.getTransactionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction} returns this
 */
proto.mlmbox.type.Wallet.Asset.Transaction.prototype.setTransactionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Kind.ID kind_id = 2;
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction.Kind.ID}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.prototype.getKindId = function() {
  return /** @type {!proto.mlmbox.type.Wallet.Asset.Transaction.Kind.ID} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.mlmbox.type.Wallet.Asset.Transaction.Kind.ID} value
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction} returns this
 */
proto.mlmbox.type.Wallet.Asset.Transaction.prototype.setKindId = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Status.ID status_id = 3;
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction.Status.ID}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.prototype.getStatusId = function() {
  return /** @type {!proto.mlmbox.type.Wallet.Asset.Transaction.Status.ID} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.mlmbox.type.Wallet.Asset.Transaction.Status.ID} value
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction} returns this
 */
proto.mlmbox.type.Wallet.Asset.Transaction.prototype.setStatusId = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string amount = 4;
 * @return {string}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction} returns this
 */
proto.mlmbox.type.Wallet.Asset.Transaction.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string fee = 5;
 * @return {string}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.prototype.getFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction} returns this
 */
proto.mlmbox.type.Wallet.Asset.Transaction.prototype.setFee = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string balance = 6;
 * @return {string}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.prototype.getBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction} returns this
 */
proto.mlmbox.type.Wallet.Asset.Transaction.prototype.setBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint32 created_at = 7;
 * @return {number}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction} returns this
 */
proto.mlmbox.type.Wallet.Asset.Transaction.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string description = 8;
 * @return {string}
 */
proto.mlmbox.type.Wallet.Asset.Transaction.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction} returns this
 */
proto.mlmbox.type.Wallet.Asset.Transaction.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Wallet.Asset.BalanceHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Wallet.Asset.BalanceHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    assetBalance: jspb.Message.getFieldWithDefault(msg, 2, ""),
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Wallet.Asset.BalanceHistory}
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Wallet.Asset.BalanceHistory;
  return proto.mlmbox.type.Wallet.Asset.BalanceHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Wallet.Asset.BalanceHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Wallet.Asset.BalanceHistory}
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetBalance(value);
      break;
    case 3:
      var value = new proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction;
      reader.readMessage(value,proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Wallet.Asset.BalanceHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Wallet.Asset.BalanceHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAssetBalance();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    balance: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction}
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction;
  return proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction}
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTransactionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBalance(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBalance();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 transaction_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction.prototype.getTransactionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction} returns this
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction.prototype.setTransactionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string balance = 2;
 * @return {string}
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction.prototype.getBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction} returns this
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction.prototype.setBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 created_at = 3;
 * @return {number}
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction} returns this
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 asset_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.prototype.getAssetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Wallet.Asset.BalanceHistory} returns this
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string asset_balance = 2;
 * @return {string}
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.prototype.getAssetBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Wallet.Asset.BalanceHistory} returns this
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.prototype.setAssetBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Transaction transactions = 3;
 * @return {!Array<!proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction>}
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction, 3));
};


/**
 * @param {!Array<!proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction>} value
 * @return {!proto.mlmbox.type.Wallet.Asset.BalanceHistory} returns this
*/
proto.mlmbox.type.Wallet.Asset.BalanceHistory.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction}
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.type.Wallet.Asset.BalanceHistory} returns this
 */
proto.mlmbox.type.Wallet.Asset.BalanceHistory.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Wallet.Asset.Summary.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Wallet.Asset.Summary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Wallet.Asset.Summary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.Asset.Summary.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    assetBalance: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Wallet.Asset.Summary}
 */
proto.mlmbox.type.Wallet.Asset.Summary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Wallet.Asset.Summary;
  return proto.mlmbox.type.Wallet.Asset.Summary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Wallet.Asset.Summary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Wallet.Asset.Summary}
 */
proto.mlmbox.type.Wallet.Asset.Summary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Wallet.Asset.Summary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Wallet.Asset.Summary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Wallet.Asset.Summary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.Asset.Summary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAssetBalance();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 asset_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Wallet.Asset.Summary.prototype.getAssetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Wallet.Asset.Summary} returns this
 */
proto.mlmbox.type.Wallet.Asset.Summary.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string asset_balance = 2;
 * @return {string}
 */
proto.mlmbox.type.Wallet.Asset.Summary.prototype.getAssetBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Wallet.Asset.Summary} returns this
 */
proto.mlmbox.type.Wallet.Asset.Summary.prototype.setAssetBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 asset_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Wallet.Asset.prototype.getAssetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Wallet.Asset} returns this
 */
proto.mlmbox.type.Wallet.Asset.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string asset_balance = 2;
 * @return {string}
 */
proto.mlmbox.type.Wallet.Asset.prototype.getAssetBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Wallet.Asset} returns this
 */
proto.mlmbox.type.Wallet.Asset.prototype.setAssetBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Transaction transactions = 3;
 * @return {!Array<!proto.mlmbox.type.Wallet.Asset.Transaction>}
 */
proto.mlmbox.type.Wallet.Asset.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.mlmbox.type.Wallet.Asset.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.type.Wallet.Asset.Transaction, 3));
};


/**
 * @param {!Array<!proto.mlmbox.type.Wallet.Asset.Transaction>} value
 * @return {!proto.mlmbox.type.Wallet.Asset} returns this
*/
proto.mlmbox.type.Wallet.Asset.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.mlmbox.type.Wallet.Asset.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.Wallet.Asset.Transaction}
 */
proto.mlmbox.type.Wallet.Asset.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.mlmbox.type.Wallet.Asset.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.type.Wallet.Asset} returns this
 */
proto.mlmbox.type.Wallet.Asset.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};


/**
 * optional IncomeBonus income_bonus = 4;
 * @return {?proto.mlmbox.type.IncomeBonus}
 */
proto.mlmbox.type.Wallet.Asset.prototype.getIncomeBonus = function() {
  return /** @type{?proto.mlmbox.type.IncomeBonus} */ (
    jspb.Message.getWrapperField(this, mlmbox_type_bonus_pb.IncomeBonus, 4));
};


/**
 * @param {?proto.mlmbox.type.IncomeBonus|undefined} value
 * @return {!proto.mlmbox.type.Wallet.Asset} returns this
*/
proto.mlmbox.type.Wallet.Asset.prototype.setIncomeBonus = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Wallet.Asset} returns this
 */
proto.mlmbox.type.Wallet.Asset.prototype.clearIncomeBonus = function() {
  return this.setIncomeBonus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Wallet.Asset.prototype.hasIncomeBonus = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.type.Wallet.Processing.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Wallet.Processing.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Wallet.Processing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Wallet.Processing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.Processing.toObject = function(includeInstance, msg) {
  var f, obj = {
    processingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    processingName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    assetsList: jspb.Message.toObjectList(msg.getAssetsList(),
    proto.mlmbox.type.Wallet.Processing.Asset.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Wallet.Processing}
 */
proto.mlmbox.type.Wallet.Processing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Wallet.Processing;
  return proto.mlmbox.type.Wallet.Processing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Wallet.Processing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Wallet.Processing}
 */
proto.mlmbox.type.Wallet.Processing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProcessingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessingName(value);
      break;
    case 3:
      var value = new proto.mlmbox.type.Wallet.Processing.Asset;
      reader.readMessage(value,proto.mlmbox.type.Wallet.Processing.Asset.deserializeBinaryFromReader);
      msg.addAssets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Wallet.Processing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Wallet.Processing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Wallet.Processing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.Processing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessingId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getProcessingName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.mlmbox.type.Wallet.Processing.Asset.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Wallet.Processing.ID.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Wallet.Processing.ID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Wallet.Processing.ID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.Processing.ID.toObject = function(includeInstance, msg) {
  var f, obj = {
    processingId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Wallet.Processing.ID}
 */
proto.mlmbox.type.Wallet.Processing.ID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Wallet.Processing.ID;
  return proto.mlmbox.type.Wallet.Processing.ID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Wallet.Processing.ID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Wallet.Processing.ID}
 */
proto.mlmbox.type.Wallet.Processing.ID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProcessingId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Wallet.Processing.ID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Wallet.Processing.ID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Wallet.Processing.ID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.Processing.ID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessingId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 processing_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Wallet.Processing.ID.prototype.getProcessingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Wallet.Processing.ID} returns this
 */
proto.mlmbox.type.Wallet.Processing.ID.prototype.setProcessingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Wallet.Processing.Asset.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Wallet.Processing.Asset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Wallet.Processing.Asset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.Processing.Asset.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    settingsDepositEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    settingsWithdrawalEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    settingsWithdrawalFeeBase: jspb.Message.getFieldWithDefault(msg, 4, ""),
    settingsWithdrawalFeePercent: jspb.Message.getFieldWithDefault(msg, 5, ""),
    settingsWithdrawalOperationValueMin: jspb.Message.getFieldWithDefault(msg, 6, ""),
    settingsWithdrawalOperationValueMax: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Wallet.Processing.Asset}
 */
proto.mlmbox.type.Wallet.Processing.Asset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Wallet.Processing.Asset;
  return proto.mlmbox.type.Wallet.Processing.Asset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Wallet.Processing.Asset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Wallet.Processing.Asset}
 */
proto.mlmbox.type.Wallet.Processing.Asset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSettingsDepositEnabled(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSettingsWithdrawalEnabled(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettingsWithdrawalFeeBase(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettingsWithdrawalFeePercent(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettingsWithdrawalOperationValueMin(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettingsWithdrawalOperationValueMax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Wallet.Processing.Asset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Wallet.Processing.Asset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Wallet.Processing.Asset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.Processing.Asset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getSettingsDepositEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getSettingsWithdrawalEnabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSettingsWithdrawalFeeBase();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSettingsWithdrawalFeePercent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSettingsWithdrawalOperationValueMin();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSettingsWithdrawalOperationValueMax();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Wallet.Processing.Asset.ID.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Wallet.Processing.Asset.ID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Wallet.Processing.Asset.ID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.Processing.Asset.ID.toObject = function(includeInstance, msg) {
  var f, obj = {
    processingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    assetId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Wallet.Processing.Asset.ID}
 */
proto.mlmbox.type.Wallet.Processing.Asset.ID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Wallet.Processing.Asset.ID;
  return proto.mlmbox.type.Wallet.Processing.Asset.ID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Wallet.Processing.Asset.ID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Wallet.Processing.Asset.ID}
 */
proto.mlmbox.type.Wallet.Processing.Asset.ID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProcessingId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Wallet.Processing.Asset.ID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Wallet.Processing.Asset.ID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Wallet.Processing.Asset.ID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.Processing.Asset.ID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessingId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAssetId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 processing_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Wallet.Processing.Asset.ID.prototype.getProcessingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Wallet.Processing.Asset.ID} returns this
 */
proto.mlmbox.type.Wallet.Processing.Asset.ID.prototype.setProcessingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 asset_id = 2;
 * @return {number}
 */
proto.mlmbox.type.Wallet.Processing.Asset.ID.prototype.getAssetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Wallet.Processing.Asset.ID} returns this
 */
proto.mlmbox.type.Wallet.Processing.Asset.ID.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 asset_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Wallet.Processing.Asset.prototype.getAssetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Wallet.Processing.Asset} returns this
 */
proto.mlmbox.type.Wallet.Processing.Asset.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool settings_deposit_enabled = 2;
 * @return {boolean}
 */
proto.mlmbox.type.Wallet.Processing.Asset.prototype.getSettingsDepositEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mlmbox.type.Wallet.Processing.Asset} returns this
 */
proto.mlmbox.type.Wallet.Processing.Asset.prototype.setSettingsDepositEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool settings_withdrawal_enabled = 3;
 * @return {boolean}
 */
proto.mlmbox.type.Wallet.Processing.Asset.prototype.getSettingsWithdrawalEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mlmbox.type.Wallet.Processing.Asset} returns this
 */
proto.mlmbox.type.Wallet.Processing.Asset.prototype.setSettingsWithdrawalEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string settings_withdrawal_fee_base = 4;
 * @return {string}
 */
proto.mlmbox.type.Wallet.Processing.Asset.prototype.getSettingsWithdrawalFeeBase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Wallet.Processing.Asset} returns this
 */
proto.mlmbox.type.Wallet.Processing.Asset.prototype.setSettingsWithdrawalFeeBase = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string settings_withdrawal_fee_percent = 5;
 * @return {string}
 */
proto.mlmbox.type.Wallet.Processing.Asset.prototype.getSettingsWithdrawalFeePercent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Wallet.Processing.Asset} returns this
 */
proto.mlmbox.type.Wallet.Processing.Asset.prototype.setSettingsWithdrawalFeePercent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string settings_withdrawal_operation_value_min = 6;
 * @return {string}
 */
proto.mlmbox.type.Wallet.Processing.Asset.prototype.getSettingsWithdrawalOperationValueMin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Wallet.Processing.Asset} returns this
 */
proto.mlmbox.type.Wallet.Processing.Asset.prototype.setSettingsWithdrawalOperationValueMin = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string settings_withdrawal_operation_value_max = 7;
 * @return {string}
 */
proto.mlmbox.type.Wallet.Processing.Asset.prototype.getSettingsWithdrawalOperationValueMax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Wallet.Processing.Asset} returns this
 */
proto.mlmbox.type.Wallet.Processing.Asset.prototype.setSettingsWithdrawalOperationValueMax = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 processing_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Wallet.Processing.prototype.getProcessingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Wallet.Processing} returns this
 */
proto.mlmbox.type.Wallet.Processing.prototype.setProcessingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string processing_name = 2;
 * @return {string}
 */
proto.mlmbox.type.Wallet.Processing.prototype.getProcessingName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Wallet.Processing} returns this
 */
proto.mlmbox.type.Wallet.Processing.prototype.setProcessingName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Asset assets = 3;
 * @return {!Array<!proto.mlmbox.type.Wallet.Processing.Asset>}
 */
proto.mlmbox.type.Wallet.Processing.prototype.getAssetsList = function() {
  return /** @type{!Array<!proto.mlmbox.type.Wallet.Processing.Asset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.type.Wallet.Processing.Asset, 3));
};


/**
 * @param {!Array<!proto.mlmbox.type.Wallet.Processing.Asset>} value
 * @return {!proto.mlmbox.type.Wallet.Processing} returns this
*/
proto.mlmbox.type.Wallet.Processing.prototype.setAssetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.mlmbox.type.Wallet.Processing.Asset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.Wallet.Processing.Asset}
 */
proto.mlmbox.type.Wallet.Processing.prototype.addAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.mlmbox.type.Wallet.Processing.Asset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.type.Wallet.Processing} returns this
 */
proto.mlmbox.type.Wallet.Processing.prototype.clearAssetsList = function() {
  return this.setAssetsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Wallet.WithdrawalTask.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Wallet.WithdrawalTask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Wallet.WithdrawalTask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.WithdrawalTask.toObject = function(includeInstance, msg) {
  var f, obj = {
    withdrawalId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    processingId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    login: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    assetId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    transaction: (f = msg.getTransaction()) && proto.mlmbox.type.Wallet.Asset.Transaction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Wallet.WithdrawalTask}
 */
proto.mlmbox.type.Wallet.WithdrawalTask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Wallet.WithdrawalTask;
  return proto.mlmbox.type.Wallet.WithdrawalTask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Wallet.WithdrawalTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Wallet.WithdrawalTask}
 */
proto.mlmbox.type.Wallet.WithdrawalTask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWithdrawalId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProcessingId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetId(value);
      break;
    case 7:
      var value = new proto.mlmbox.type.Wallet.Asset.Transaction;
      reader.readMessage(value,proto.mlmbox.type.Wallet.Asset.Transaction.deserializeBinaryFromReader);
      msg.setTransaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Wallet.WithdrawalTask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Wallet.WithdrawalTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Wallet.WithdrawalTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.WithdrawalTask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWithdrawalId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getProcessingId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAssetId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getTransaction();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.mlmbox.type.Wallet.Asset.Transaction.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Wallet.WithdrawalTask.ID.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Wallet.WithdrawalTask.ID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Wallet.WithdrawalTask.ID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.WithdrawalTask.ID.toObject = function(includeInstance, msg) {
  var f, obj = {
    withdrawalId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Wallet.WithdrawalTask.ID}
 */
proto.mlmbox.type.Wallet.WithdrawalTask.ID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Wallet.WithdrawalTask.ID;
  return proto.mlmbox.type.Wallet.WithdrawalTask.ID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Wallet.WithdrawalTask.ID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Wallet.WithdrawalTask.ID}
 */
proto.mlmbox.type.Wallet.WithdrawalTask.ID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWithdrawalId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Wallet.WithdrawalTask.ID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Wallet.WithdrawalTask.ID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Wallet.WithdrawalTask.ID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Wallet.WithdrawalTask.ID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWithdrawalId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 withdrawal_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Wallet.WithdrawalTask.ID.prototype.getWithdrawalId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Wallet.WithdrawalTask.ID} returns this
 */
proto.mlmbox.type.Wallet.WithdrawalTask.ID.prototype.setWithdrawalId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 withdrawal_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Wallet.WithdrawalTask.prototype.getWithdrawalId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Wallet.WithdrawalTask} returns this
 */
proto.mlmbox.type.Wallet.WithdrawalTask.prototype.setWithdrawalId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 processing_id = 2;
 * @return {number}
 */
proto.mlmbox.type.Wallet.WithdrawalTask.prototype.getProcessingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Wallet.WithdrawalTask} returns this
 */
proto.mlmbox.type.Wallet.WithdrawalTask.prototype.setProcessingId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 account_id = 3;
 * @return {number}
 */
proto.mlmbox.type.Wallet.WithdrawalTask.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Wallet.WithdrawalTask} returns this
 */
proto.mlmbox.type.Wallet.WithdrawalTask.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string login = 4;
 * @return {string}
 */
proto.mlmbox.type.Wallet.WithdrawalTask.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Wallet.WithdrawalTask} returns this
 */
proto.mlmbox.type.Wallet.WithdrawalTask.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.mlmbox.type.Wallet.WithdrawalTask.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Wallet.WithdrawalTask} returns this
 */
proto.mlmbox.type.Wallet.WithdrawalTask.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 asset_id = 6;
 * @return {number}
 */
proto.mlmbox.type.Wallet.WithdrawalTask.prototype.getAssetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Wallet.WithdrawalTask} returns this
 */
proto.mlmbox.type.Wallet.WithdrawalTask.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional Asset.Transaction transaction = 7;
 * @return {?proto.mlmbox.type.Wallet.Asset.Transaction}
 */
proto.mlmbox.type.Wallet.WithdrawalTask.prototype.getTransaction = function() {
  return /** @type{?proto.mlmbox.type.Wallet.Asset.Transaction} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.type.Wallet.Asset.Transaction, 7));
};


/**
 * @param {?proto.mlmbox.type.Wallet.Asset.Transaction|undefined} value
 * @return {!proto.mlmbox.type.Wallet.WithdrawalTask} returns this
*/
proto.mlmbox.type.Wallet.WithdrawalTask.prototype.setTransaction = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Wallet.WithdrawalTask} returns this
 */
proto.mlmbox.type.Wallet.WithdrawalTask.prototype.clearTransaction = function() {
  return this.setTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Wallet.WithdrawalTask.prototype.hasTransaction = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated Asset assets = 1;
 * @return {!Array<!proto.mlmbox.type.Wallet.Asset>}
 */
proto.mlmbox.type.Wallet.prototype.getAssetsList = function() {
  return /** @type{!Array<!proto.mlmbox.type.Wallet.Asset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.type.Wallet.Asset, 1));
};


/**
 * @param {!Array<!proto.mlmbox.type.Wallet.Asset>} value
 * @return {!proto.mlmbox.type.Wallet} returns this
*/
proto.mlmbox.type.Wallet.prototype.setAssetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mlmbox.type.Wallet.Asset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.Wallet.Asset}
 */
proto.mlmbox.type.Wallet.prototype.addAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mlmbox.type.Wallet.Asset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.type.Wallet} returns this
 */
proto.mlmbox.type.Wallet.prototype.clearAssetsList = function() {
  return this.setAssetsList([]);
};


/**
 * repeated Processing processings = 2;
 * @return {!Array<!proto.mlmbox.type.Wallet.Processing>}
 */
proto.mlmbox.type.Wallet.prototype.getProcessingsList = function() {
  return /** @type{!Array<!proto.mlmbox.type.Wallet.Processing>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.type.Wallet.Processing, 2));
};


/**
 * @param {!Array<!proto.mlmbox.type.Wallet.Processing>} value
 * @return {!proto.mlmbox.type.Wallet} returns this
*/
proto.mlmbox.type.Wallet.prototype.setProcessingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.mlmbox.type.Wallet.Processing=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.Wallet.Processing}
 */
proto.mlmbox.type.Wallet.prototype.addProcessings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.mlmbox.type.Wallet.Processing, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.type.Wallet} returns this
 */
proto.mlmbox.type.Wallet.prototype.clearProcessingsList = function() {
  return this.setProcessingsList([]);
};


goog.object.extend(exports, proto.mlmbox.type);
