import { ConfirmationClient } from "../../../../apis/mlmbox/client/confirmation/v1/confirmation_grpc_web_pb";
import { ConfirmationGenerateOneTimePasswordRequest, ConfirmationConfirmRequest } from "../../../../apis/mlmbox/client/confirmation/v1/confirmation_pb";
import { Confirmation } from "../../../../apis/mlmbox/type/confirmation_pb";
import { Empty } from 'google-protobuf/google/protobuf/empty_pb.js'
import { API_URL } from '@/api';

const client = new ConfirmationClient(API_URL, true, {
  withCredentials: true
});

export default {
  state: {
    allConfirmations: [],
    confirmation: {},
    fields: {}
  },

  mutations: {
    stub() {},

    setConfirmation(state, confirmation) {
      state.confirmation = confirmation;
    },

    setAllConfirmations(state, allConfirmations) {
      state.allConfirmations = allConfirmations;
    },

    setFields(state, fieldsList) {
      const keys = {
        0: 'UNKNOWN',
        1: 'PASSWORD', // пароль
        2: 'GOOGLE_AUTHENTICATOR_CODE', // 2FA код
        3: 'EMAIL_CONFIRM_CODE', // подтвердить код отправленный на указанный контакт
        4: 'EMAIL_FILL' // заполнить значение контакта (например: sm***el@gmail.com - требуется заполнить поле без маски с звездочками)
      }
      
      fieldsList.forEach(field => {
        state.fields[keys[field.kindId]] = field;
      })
    },
  },

  getters: {
    getConfirmation(state) {
      return state.confirmation
    },

    getField: (state) => fieldName => state.fields[fieldName],
  },

  actions: {
    ConfirmationGet({ commit }, confirmationId) {
      return new Promise((resolve, reject) => {
        const request = new Confirmation();
        request.setConfirmationId(confirmationId)
        
        client.get(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
          if (err !== null) {
            reject(err)
          } else {
            commit('setConfirmation', response.toObject());
            commit('setFields', response.toObject().fieldsList);
            
            resolve(response.toObject())
          }
        })
      })
    },

    ConfirmationGetAll({ commit }) {
      return new Promise((resolve, reject) => {
        const request = new Empty();

        client.all(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
          if (err !== null) {
            reject(err)
          } else {
            resolve(response)
            commit('setAllConfirmations', response.toObject());
          }
        })
      })
    },

    // ConfirmationGeneratePassword({ commit, getters}, {fieldName}) {
    ConfirmationGeneratePassword({ getters}) {
      const confirmationFields = getters.getConfirmation.fieldsList.filter(f => f.kindId === 3);
      const promises = [];
      
      confirmationFields.forEach((field) => {
        promises.push(new Promise((resolve, reject) => {
          const request = new ConfirmationGenerateOneTimePasswordRequest();
          const confirmationWrapper = new Confirmation.ID();
          confirmationWrapper.setConfirmationId(getters.getConfirmation.confirmationId)
  
          request.setConfirmation(confirmationWrapper)
          request.setFieldId(field.fieldId)
  
          client.generateOneTimePassword(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
            if (err !== null) {
              reject(err)
            } else {
              resolve(response.toObject())
            }
          })
        }))
      })

      return Promise.all(promises)
    },

    ConfirmationConfirm({ commit, getters }, {emailValue1, emailValue2, gaValue}) {
      return new Promise((resolve, reject) => {
    // ConfirmationConfirm({ getters }, {emailValue1, emailValue2, gaValue}) {
    //   return new Promise(() => {
        const request = new ConfirmationConfirmRequest();
        const confirmation = getters.getConfirmation;

        const confirmationWrapper = new Confirmation.ID();
        confirmationWrapper.setConfirmationId(confirmation.confirmationId)
        request.setConfirmation(confirmationWrapper)
        request.setOptionId(confirmation.optionsList[0].optionId);
        
        confirmation.fieldsList.forEach((f, index) => {
          const emailValues = {emailValue1, emailValue2}

          const fieldWrapper = new ConfirmationConfirmRequest.Field();
          fieldWrapper.setFieldId(f.fieldId)
          if (f.kindId === 2) fieldWrapper.setValue(gaValue) 
          if (f.kindId === 3) fieldWrapper.setValue(emailValues[`emailValue${index+1}`])
          request.addFields(fieldWrapper)
        })

        client.confirm(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
          if (err !== null) {
            reject(err)
          } else {
            commit('stub');

            if (response.toObject().status === 0) {
              resolve(response.toObject())
              
              return;
            } else if (response.toObject().status === 1) {
              resolve(response.toObject())
            }
          }
        })
      })
    },
    
    ConfirmationCancel({ commit }, id) {
      // вызывать при случайном выходе с сайта
      
      return new Promise((resolve, reject) => {
        const request = new Confirmation.ID;
        
        request.setConfirmation(id)
        
        client.cancel(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
          if (err !== null) {
            reject(err)
          } else {
            commit('stub');
            
            resolve(response.toObject())
          }
        })
      })
    },

  }
}