// source: mlmbox/type/notification.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var mlmbox_type_wallet_pb = require('../../mlmbox/type/wallet_pb.js');
goog.object.extend(proto, mlmbox_type_wallet_pb);
goog.exportSymbol('proto.mlmbox.type.Notification', null, global);
goog.exportSymbol('proto.mlmbox.type.Notification.Event', null, global);
goog.exportSymbol('proto.mlmbox.type.Notification.Event.Career', null, global);
goog.exportSymbol('proto.mlmbox.type.Notification.Event.EventCase', null, global);
goog.exportSymbol('proto.mlmbox.type.Notification.Event.Marketing', null, global);
goog.exportSymbol('proto.mlmbox.type.Notification.Event.Partner', null, global);
goog.exportSymbol('proto.mlmbox.type.Notification.Event.WalletAssetTransaction', null, global);
goog.exportSymbol('proto.mlmbox.type.Notification.ID', null, global);
goog.exportSymbol('proto.mlmbox.type.Notification.ID.UniqueFieldCase', null, global);
goog.exportSymbol('proto.mlmbox.type.Notification.State', null, global);
goog.exportSymbol('proto.mlmbox.type.Notification.State.Settings', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Notification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Notification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Notification.displayName = 'proto.mlmbox.type.Notification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Notification.ID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.mlmbox.type.Notification.ID.oneofGroups_);
};
goog.inherits(proto.mlmbox.type.Notification.ID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Notification.ID.displayName = 'proto.mlmbox.type.Notification.ID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Notification.State = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.type.Notification.State.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.type.Notification.State, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Notification.State.displayName = 'proto.mlmbox.type.Notification.State';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Notification.State.Settings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Notification.State.Settings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Notification.State.Settings.displayName = 'proto.mlmbox.type.Notification.State.Settings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Notification.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.mlmbox.type.Notification.Event.oneofGroups_);
};
goog.inherits(proto.mlmbox.type.Notification.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Notification.Event.displayName = 'proto.mlmbox.type.Notification.Event';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Notification.Event.Partner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Notification.Event.Partner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Notification.Event.Partner.displayName = 'proto.mlmbox.type.Notification.Event.Partner';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Notification.Event.Career = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Notification.Event.Career, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Notification.Event.Career.displayName = 'proto.mlmbox.type.Notification.Event.Career';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Notification.Event.Marketing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Notification.Event.Marketing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Notification.Event.Marketing.displayName = 'proto.mlmbox.type.Notification.Event.Marketing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Notification.Event.WalletAssetTransaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Notification.Event.WalletAssetTransaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Notification.Event.WalletAssetTransaction.displayName = 'proto.mlmbox.type.Notification.Event.WalletAssetTransaction';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Notification.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Notification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Notification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Notification.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Notification}
 */
proto.mlmbox.type.Notification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Notification;
  return proto.mlmbox.type.Notification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Notification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Notification}
 */
proto.mlmbox.type.Notification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Notification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Notification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Notification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Notification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.mlmbox.type.Notification.ID.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.mlmbox.type.Notification.ID.UniqueFieldCase = {
  UNIQUE_FIELD_NOT_SET: 0,
  ACCOUNT_ID: 1,
  TOKEN: 2
};

/**
 * @return {proto.mlmbox.type.Notification.ID.UniqueFieldCase}
 */
proto.mlmbox.type.Notification.ID.prototype.getUniqueFieldCase = function() {
  return /** @type {proto.mlmbox.type.Notification.ID.UniqueFieldCase} */(jspb.Message.computeOneofCase(this, proto.mlmbox.type.Notification.ID.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Notification.ID.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Notification.ID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Notification.ID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Notification.ID.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    token: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Notification.ID}
 */
proto.mlmbox.type.Notification.ID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Notification.ID;
  return proto.mlmbox.type.Notification.ID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Notification.ID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Notification.ID}
 */
proto.mlmbox.type.Notification.ID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Notification.ID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Notification.ID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Notification.ID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Notification.ID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Notification.ID.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Notification.ID} returns this
 */
proto.mlmbox.type.Notification.ID.prototype.setAccountId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.mlmbox.type.Notification.ID.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mlmbox.type.Notification.ID} returns this
 */
proto.mlmbox.type.Notification.ID.prototype.clearAccountId = function() {
  return jspb.Message.setOneofField(this, 1, proto.mlmbox.type.Notification.ID.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Notification.ID.prototype.hasAccountId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string token = 2;
 * @return {string}
 */
proto.mlmbox.type.Notification.ID.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Notification.ID} returns this
 */
proto.mlmbox.type.Notification.ID.prototype.setToken = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.mlmbox.type.Notification.ID.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mlmbox.type.Notification.ID} returns this
 */
proto.mlmbox.type.Notification.ID.prototype.clearToken = function() {
  return jspb.Message.setOneofField(this, 2, proto.mlmbox.type.Notification.ID.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Notification.ID.prototype.hasToken = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.type.Notification.State.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Notification.State.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Notification.State.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Notification.State} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Notification.State.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    login: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    referralId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    referralLogin: jspb.Message.getFieldWithDefault(msg, 5, ""),
    currentRankId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    currentPackageId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    settings: (f = msg.getSettings()) && proto.mlmbox.type.Notification.State.Settings.toObject(includeInstance, f),
    walletList: jspb.Message.toObjectList(msg.getWalletList(),
    mlmbox_type_wallet_pb.Wallet.Asset.Summary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Notification.State}
 */
proto.mlmbox.type.Notification.State.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Notification.State;
  return proto.mlmbox.type.Notification.State.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Notification.State} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Notification.State}
 */
proto.mlmbox.type.Notification.State.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReferralId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferralLogin(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCurrentRankId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCurrentPackageId(value);
      break;
    case 8:
      var value = new proto.mlmbox.type.Notification.State.Settings;
      reader.readMessage(value,proto.mlmbox.type.Notification.State.Settings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    case 9:
      var value = new mlmbox_type_wallet_pb.Wallet.Asset.Summary;
      reader.readMessage(value,mlmbox_type_wallet_pb.Wallet.Asset.Summary.deserializeBinaryFromReader);
      msg.addWallet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Notification.State.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Notification.State.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Notification.State} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Notification.State.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReferralId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getReferralLogin();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCurrentRankId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getCurrentPackageId();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.mlmbox.type.Notification.State.Settings.serializeBinaryToWriter
    );
  }
  f = message.getWalletList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      mlmbox_type_wallet_pb.Wallet.Asset.Summary.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Notification.State.Settings.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Notification.State.Settings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Notification.State.Settings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Notification.State.Settings.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscribed: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Notification.State.Settings}
 */
proto.mlmbox.type.Notification.State.Settings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Notification.State.Settings;
  return proto.mlmbox.type.Notification.State.Settings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Notification.State.Settings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Notification.State.Settings}
 */
proto.mlmbox.type.Notification.State.Settings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubscribed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Notification.State.Settings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Notification.State.Settings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Notification.State.Settings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Notification.State.Settings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscribed();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.mlmbox.type.Notification.State.Settings.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Notification.State.Settings} returns this
 */
proto.mlmbox.type.Notification.State.Settings.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool subscribed = 2;
 * @return {boolean}
 */
proto.mlmbox.type.Notification.State.Settings.prototype.getSubscribed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mlmbox.type.Notification.State.Settings} returns this
 */
proto.mlmbox.type.Notification.State.Settings.prototype.setSubscribed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Notification.State.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Notification.State} returns this
 */
proto.mlmbox.type.Notification.State.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string login = 2;
 * @return {string}
 */
proto.mlmbox.type.Notification.State.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Notification.State} returns this
 */
proto.mlmbox.type.Notification.State.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.mlmbox.type.Notification.State.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Notification.State} returns this
 */
proto.mlmbox.type.Notification.State.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 referral_id = 4;
 * @return {number}
 */
proto.mlmbox.type.Notification.State.prototype.getReferralId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Notification.State} returns this
 */
proto.mlmbox.type.Notification.State.prototype.setReferralId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string referral_login = 5;
 * @return {string}
 */
proto.mlmbox.type.Notification.State.prototype.getReferralLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Notification.State} returns this
 */
proto.mlmbox.type.Notification.State.prototype.setReferralLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 current_rank_id = 6;
 * @return {number}
 */
proto.mlmbox.type.Notification.State.prototype.getCurrentRankId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Notification.State} returns this
 */
proto.mlmbox.type.Notification.State.prototype.setCurrentRankId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 current_package_id = 7;
 * @return {number}
 */
proto.mlmbox.type.Notification.State.prototype.getCurrentPackageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Notification.State} returns this
 */
proto.mlmbox.type.Notification.State.prototype.setCurrentPackageId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional Settings settings = 8;
 * @return {?proto.mlmbox.type.Notification.State.Settings}
 */
proto.mlmbox.type.Notification.State.prototype.getSettings = function() {
  return /** @type{?proto.mlmbox.type.Notification.State.Settings} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.type.Notification.State.Settings, 8));
};


/**
 * @param {?proto.mlmbox.type.Notification.State.Settings|undefined} value
 * @return {!proto.mlmbox.type.Notification.State} returns this
*/
proto.mlmbox.type.Notification.State.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Notification.State} returns this
 */
proto.mlmbox.type.Notification.State.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Notification.State.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated Wallet.Asset.Summary wallet = 9;
 * @return {!Array<!proto.mlmbox.type.Wallet.Asset.Summary>}
 */
proto.mlmbox.type.Notification.State.prototype.getWalletList = function() {
  return /** @type{!Array<!proto.mlmbox.type.Wallet.Asset.Summary>} */ (
    jspb.Message.getRepeatedWrapperField(this, mlmbox_type_wallet_pb.Wallet.Asset.Summary, 9));
};


/**
 * @param {!Array<!proto.mlmbox.type.Wallet.Asset.Summary>} value
 * @return {!proto.mlmbox.type.Notification.State} returns this
*/
proto.mlmbox.type.Notification.State.prototype.setWalletList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.mlmbox.type.Wallet.Asset.Summary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.Wallet.Asset.Summary}
 */
proto.mlmbox.type.Notification.State.prototype.addWallet = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.mlmbox.type.Wallet.Asset.Summary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.type.Notification.State} returns this
 */
proto.mlmbox.type.Notification.State.prototype.clearWalletList = function() {
  return this.setWalletList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.mlmbox.type.Notification.Event.oneofGroups_ = [[2,3,4,5]];

/**
 * @enum {number}
 */
proto.mlmbox.type.Notification.Event.EventCase = {
  EVENT_NOT_SET: 0,
  PARTNER: 2,
  CAREER: 3,
  MARKETING: 4,
  WALLET_ASSET_TRANSACTION: 5
};

/**
 * @return {proto.mlmbox.type.Notification.Event.EventCase}
 */
proto.mlmbox.type.Notification.Event.prototype.getEventCase = function() {
  return /** @type {proto.mlmbox.type.Notification.Event.EventCase} */(jspb.Message.computeOneofCase(this, proto.mlmbox.type.Notification.Event.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Notification.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Notification.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Notification.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Notification.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    partner: (f = msg.getPartner()) && proto.mlmbox.type.Notification.Event.Partner.toObject(includeInstance, f),
    career: (f = msg.getCareer()) && proto.mlmbox.type.Notification.Event.Career.toObject(includeInstance, f),
    marketing: (f = msg.getMarketing()) && proto.mlmbox.type.Notification.Event.Marketing.toObject(includeInstance, f),
    walletAssetTransaction: (f = msg.getWalletAssetTransaction()) && proto.mlmbox.type.Notification.Event.WalletAssetTransaction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Notification.Event}
 */
proto.mlmbox.type.Notification.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Notification.Event;
  return proto.mlmbox.type.Notification.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Notification.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Notification.Event}
 */
proto.mlmbox.type.Notification.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 2:
      var value = new proto.mlmbox.type.Notification.Event.Partner;
      reader.readMessage(value,proto.mlmbox.type.Notification.Event.Partner.deserializeBinaryFromReader);
      msg.setPartner(value);
      break;
    case 3:
      var value = new proto.mlmbox.type.Notification.Event.Career;
      reader.readMessage(value,proto.mlmbox.type.Notification.Event.Career.deserializeBinaryFromReader);
      msg.setCareer(value);
      break;
    case 4:
      var value = new proto.mlmbox.type.Notification.Event.Marketing;
      reader.readMessage(value,proto.mlmbox.type.Notification.Event.Marketing.deserializeBinaryFromReader);
      msg.setMarketing(value);
      break;
    case 5:
      var value = new proto.mlmbox.type.Notification.Event.WalletAssetTransaction;
      reader.readMessage(value,proto.mlmbox.type.Notification.Event.WalletAssetTransaction.deserializeBinaryFromReader);
      msg.setWalletAssetTransaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Notification.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Notification.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Notification.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Notification.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPartner();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mlmbox.type.Notification.Event.Partner.serializeBinaryToWriter
    );
  }
  f = message.getCareer();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mlmbox.type.Notification.Event.Career.serializeBinaryToWriter
    );
  }
  f = message.getMarketing();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.mlmbox.type.Notification.Event.Marketing.serializeBinaryToWriter
    );
  }
  f = message.getWalletAssetTransaction();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.mlmbox.type.Notification.Event.WalletAssetTransaction.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Notification.Event.Partner.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Notification.Event.Partner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Notification.Event.Partner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Notification.Event.Partner.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    login: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Notification.Event.Partner}
 */
proto.mlmbox.type.Notification.Event.Partner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Notification.Event.Partner;
  return proto.mlmbox.type.Notification.Event.Partner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Notification.Event.Partner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Notification.Event.Partner}
 */
proto.mlmbox.type.Notification.Event.Partner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Notification.Event.Partner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Notification.Event.Partner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Notification.Event.Partner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Notification.Event.Partner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Notification.Event.Partner.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Notification.Event.Partner} returns this
 */
proto.mlmbox.type.Notification.Event.Partner.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string login = 2;
 * @return {string}
 */
proto.mlmbox.type.Notification.Event.Partner.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Notification.Event.Partner} returns this
 */
proto.mlmbox.type.Notification.Event.Partner.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.mlmbox.type.Notification.Event.Partner.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Notification.Event.Partner} returns this
 */
proto.mlmbox.type.Notification.Event.Partner.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Notification.Event.Career.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Notification.Event.Career.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Notification.Event.Career} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Notification.Event.Career.toObject = function(includeInstance, msg) {
  var f, obj = {
    rankId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Notification.Event.Career}
 */
proto.mlmbox.type.Notification.Event.Career.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Notification.Event.Career;
  return proto.mlmbox.type.Notification.Event.Career.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Notification.Event.Career} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Notification.Event.Career}
 */
proto.mlmbox.type.Notification.Event.Career.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRankId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Notification.Event.Career.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Notification.Event.Career.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Notification.Event.Career} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Notification.Event.Career.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRankId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 rank_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Notification.Event.Career.prototype.getRankId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Notification.Event.Career} returns this
 */
proto.mlmbox.type.Notification.Event.Career.prototype.setRankId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Notification.Event.Marketing.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Notification.Event.Marketing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Notification.Event.Marketing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Notification.Event.Marketing.toObject = function(includeInstance, msg) {
  var f, obj = {
    farmingPackageId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Notification.Event.Marketing}
 */
proto.mlmbox.type.Notification.Event.Marketing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Notification.Event.Marketing;
  return proto.mlmbox.type.Notification.Event.Marketing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Notification.Event.Marketing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Notification.Event.Marketing}
 */
proto.mlmbox.type.Notification.Event.Marketing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFarmingPackageId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Notification.Event.Marketing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Notification.Event.Marketing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Notification.Event.Marketing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Notification.Event.Marketing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFarmingPackageId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 farming_package_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Notification.Event.Marketing.prototype.getFarmingPackageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Notification.Event.Marketing} returns this
 */
proto.mlmbox.type.Notification.Event.Marketing.prototype.setFarmingPackageId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Notification.Event.WalletAssetTransaction.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Notification.Event.WalletAssetTransaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Notification.Event.WalletAssetTransaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Notification.Event.WalletAssetTransaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    transaction: (f = msg.getTransaction()) && mlmbox_type_wallet_pb.Wallet.Asset.Transaction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Notification.Event.WalletAssetTransaction}
 */
proto.mlmbox.type.Notification.Event.WalletAssetTransaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Notification.Event.WalletAssetTransaction;
  return proto.mlmbox.type.Notification.Event.WalletAssetTransaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Notification.Event.WalletAssetTransaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Notification.Event.WalletAssetTransaction}
 */
proto.mlmbox.type.Notification.Event.WalletAssetTransaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetId(value);
      break;
    case 2:
      var value = new mlmbox_type_wallet_pb.Wallet.Asset.Transaction;
      reader.readMessage(value,mlmbox_type_wallet_pb.Wallet.Asset.Transaction.deserializeBinaryFromReader);
      msg.setTransaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Notification.Event.WalletAssetTransaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Notification.Event.WalletAssetTransaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Notification.Event.WalletAssetTransaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Notification.Event.WalletAssetTransaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTransaction();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      mlmbox_type_wallet_pb.Wallet.Asset.Transaction.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 asset_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Notification.Event.WalletAssetTransaction.prototype.getAssetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Notification.Event.WalletAssetTransaction} returns this
 */
proto.mlmbox.type.Notification.Event.WalletAssetTransaction.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Wallet.Asset.Transaction transaction = 2;
 * @return {?proto.mlmbox.type.Wallet.Asset.Transaction}
 */
proto.mlmbox.type.Notification.Event.WalletAssetTransaction.prototype.getTransaction = function() {
  return /** @type{?proto.mlmbox.type.Wallet.Asset.Transaction} */ (
    jspb.Message.getWrapperField(this, mlmbox_type_wallet_pb.Wallet.Asset.Transaction, 2));
};


/**
 * @param {?proto.mlmbox.type.Wallet.Asset.Transaction|undefined} value
 * @return {!proto.mlmbox.type.Notification.Event.WalletAssetTransaction} returns this
*/
proto.mlmbox.type.Notification.Event.WalletAssetTransaction.prototype.setTransaction = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Notification.Event.WalletAssetTransaction} returns this
 */
proto.mlmbox.type.Notification.Event.WalletAssetTransaction.prototype.clearTransaction = function() {
  return this.setTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Notification.Event.WalletAssetTransaction.prototype.hasTransaction = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Notification.Event.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Notification.Event} returns this
 */
proto.mlmbox.type.Notification.Event.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Partner partner = 2;
 * @return {?proto.mlmbox.type.Notification.Event.Partner}
 */
proto.mlmbox.type.Notification.Event.prototype.getPartner = function() {
  return /** @type{?proto.mlmbox.type.Notification.Event.Partner} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.type.Notification.Event.Partner, 2));
};


/**
 * @param {?proto.mlmbox.type.Notification.Event.Partner|undefined} value
 * @return {!proto.mlmbox.type.Notification.Event} returns this
*/
proto.mlmbox.type.Notification.Event.prototype.setPartner = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.mlmbox.type.Notification.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Notification.Event} returns this
 */
proto.mlmbox.type.Notification.Event.prototype.clearPartner = function() {
  return this.setPartner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Notification.Event.prototype.hasPartner = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Career career = 3;
 * @return {?proto.mlmbox.type.Notification.Event.Career}
 */
proto.mlmbox.type.Notification.Event.prototype.getCareer = function() {
  return /** @type{?proto.mlmbox.type.Notification.Event.Career} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.type.Notification.Event.Career, 3));
};


/**
 * @param {?proto.mlmbox.type.Notification.Event.Career|undefined} value
 * @return {!proto.mlmbox.type.Notification.Event} returns this
*/
proto.mlmbox.type.Notification.Event.prototype.setCareer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.mlmbox.type.Notification.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Notification.Event} returns this
 */
proto.mlmbox.type.Notification.Event.prototype.clearCareer = function() {
  return this.setCareer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Notification.Event.prototype.hasCareer = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Marketing marketing = 4;
 * @return {?proto.mlmbox.type.Notification.Event.Marketing}
 */
proto.mlmbox.type.Notification.Event.prototype.getMarketing = function() {
  return /** @type{?proto.mlmbox.type.Notification.Event.Marketing} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.type.Notification.Event.Marketing, 4));
};


/**
 * @param {?proto.mlmbox.type.Notification.Event.Marketing|undefined} value
 * @return {!proto.mlmbox.type.Notification.Event} returns this
*/
proto.mlmbox.type.Notification.Event.prototype.setMarketing = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.mlmbox.type.Notification.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Notification.Event} returns this
 */
proto.mlmbox.type.Notification.Event.prototype.clearMarketing = function() {
  return this.setMarketing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Notification.Event.prototype.hasMarketing = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional WalletAssetTransaction wallet_asset_transaction = 5;
 * @return {?proto.mlmbox.type.Notification.Event.WalletAssetTransaction}
 */
proto.mlmbox.type.Notification.Event.prototype.getWalletAssetTransaction = function() {
  return /** @type{?proto.mlmbox.type.Notification.Event.WalletAssetTransaction} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.type.Notification.Event.WalletAssetTransaction, 5));
};


/**
 * @param {?proto.mlmbox.type.Notification.Event.WalletAssetTransaction|undefined} value
 * @return {!proto.mlmbox.type.Notification.Event} returns this
*/
proto.mlmbox.type.Notification.Event.prototype.setWalletAssetTransaction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.mlmbox.type.Notification.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Notification.Event} returns this
 */
proto.mlmbox.type.Notification.Event.prototype.clearWalletAssetTransaction = function() {
  return this.setWalletAssetTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Notification.Event.prototype.hasWalletAssetTransaction = function() {
  return jspb.Message.getField(this, 5) != null;
};


goog.object.extend(exports, proto.mlmbox.type);
