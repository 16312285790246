import { AssetClient } from "../../../../apis/mlmbox/client/asset/v1/asset_grpc_web_pb";
import { Asset } from "../../../../apis/mlmbox/type/asset_pb";
import { Empty } from 'google-protobuf/google/protobuf/empty_pb.js'
import { API_URL } from '@/api';

const client = new AssetClient(API_URL, null, {
    withCredentials: true
});

export default {
    state: {
        defaultAsset: {
            "assetId": 4, 
            "assetName": "USDT", 
            "assetTitle": "USDT (TRC20)", 
            "decimal": 6
        },
        asset: {
            "assetId": 4, 
            "assetName": "USDT", 
            "assetTitle": "USDT (TRC20)", 
            "decimal": 6
        },
        allAssets: [],
    },
  
    mutations: {
        updateAsset(state, asset) {
            state.asset = asset;
        },

        setAllAssets(state, allAssets) {
            state.allAssets = allAssets;
        },
    },

    getters: {
        getAllAssets(state) {
            return state.allAssets
        },
    },

    actions: {
        setAsset({state, commit}, assetId) {
            commit('updateAsset', state.allAssets.find(a => a.assetId === assetId))
        },

        getAsset({ commit }, assetId) {
            return new Promise((resolve, reject) => {
                const request = new Asset.ID();

                request.setAssetId(assetId)
      
                client.get(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('addAsset', response.toObject())
                        resolve(response.toObject())
                    }
                })
            })
        },
        
        getAllAssets({commit}) {
            const result = [];
            commit('setAllAssets', []);

            return new Promise((resolve, reject) => {
                const request = new Empty;
                const stream = client.all(request, {"authorization": localStorage.getItem("access_token")})

                stream.on('data', function(response) {
                    result.push(response.toObject());
                    resolve(response.toObject());
                });
                
                stream.on('status', (status) => {
                    if (status.code !== 0) reject(status)
                    if (status.code === 0) {
                        commit('setAllAssets', result)
                    }
                });
            })
        },

    }
}