<template>
  <div class="my-team__partners_info_item" :class="{show: partnersOpen}" v-if="partner !== undefined">
    
    <div class="my-team__partners_info_item_visible" @click="showPartners">
      <div class="my-team__partners_info_item_partner">
        <div class="my-team__partners_info_item_partner_1" :class="{active: partnersOpen}">
          <span>{{ partner.login }}</span>
        </div>
        <div class="my-team__partners_info_item_partner_2" :class="{active: partnersOpen}" v-if="partnersHeight !== '0px'">
          <span>></span>
        </div>
      </div>
      <div class="my-team__partners_info_item_line">
        <span>{{ partner.relationship?.level }}</span>
      </div>
      <div
        class="my-team__partners_info_item_team"
        v-if="partner.selfStatistics"
      >
        <span>{{ setDecimalPrice(depositValue) }}&nbsp;USDT</span>
      </div>
      <div
        class="my-team__partners_info_item_investors"
        v-if="partner.teamStatistics"
      >
        <span>{{ partner?.teamStatistics?.total?.ownersQuantity }}</span>
      </div>
      <div
        class="my-team__partners_info_item_turnover"
        v-if="partner.teamStatistics"
      >
        <!-- <span>{{setDecimalPrice(partner.teamStatistics.total?.turnover)}}&nbsp;USDT</span> -->
        <span>{{setDecimalPrice(partner?.teamStatistics.total?.turnover)}}&nbsp;USDT</span>
      </div>
    </div>
    
    <div class="my-team__partners_info_item_hidden_wrapper"
      :class="{active: partnersOpen}"
      :style="`height: ${getPartnersHeight}; margin-left: ${2.125+level}em`">
      <div :ref="`hiddenPatner-${partner.accountId}`">
        <!-- <div class="my-team__partners_info_email">
          <span>{{ partner.email }}</span>
        </div> -->

        <template v-if="partners?.length !== 0">
          <PartnersItem v-for="(p, i) in partners"
            :key="p?.accountId"
            :index="i"
            @childOpen="childOpen"
            @childClose="childClose"
            :levelProp="level"
            :partner="p" />
        </template>

        <SpinnerComponent v-if="partner?.teamStatistics?.quantity !== 0 && partners?.length === 0" />
          
      </div>
    </div>
  </div>
</template>

<script>
import SpinnerComponent from "@/components/UI/SpinnerComponent" 
import {transactions} from "@/mixins/transactions" 
import { mapState } from 'vuex'

export default {
  name: 'PartnersItem',

  props: [
    'index',
    'partner',
    'partnersActive',
    'levelProp'
  ],

  components: {
    SpinnerComponent
  },

  mixins: [
    transactions
  ],

  data() {
    return {
      partnersOpen: false,
      partners: [],
      partnersHeight: '',
      level: 1
    }
  },

  computed: {
    ...mapState({
      package: state => state.farming.package,
      defaultAsset: state => state.asset.defaultAsset,
    }),
    
    getPartnersHeight() {
      return this.partnersOpen === true ? this.partnersHeight+'px' : '0px'
    },

    depositValue() {
      return this.partner?.selfStatistics?.total?.turnover ?? 0;
    },
  },

  methods: {
    showPartners() {
      if (this.partnersOpen === false) {
        this.getPartners();
      }
      
      this.partnersOpen = !this.partnersOpen;
    },

    childOpen(childHeight) {
      this.partnersHeight += childHeight
    },

    childClose(childHeight) {
      this.partnersHeight -= childHeight
    },

    updatePartnersHeight() {
      const currentElem = this.$refs[`hiddenPatner-${this.partner.accountId}`];
      this.partnersHeight = currentElem.offsetHeight;
    },

    getPartners() {
      if (this.partner.partnersStatistics.quantity > 0) {
        this.$store.dispatch('getTeamPartnerList', { partnerLogin: this.partner.login, referralLine: 10}).then((response) => {
          this.partners = response;
        })
      }
    }
  },

  created() {
    if (this.levelProp !== undefined) {
      this.level = this.levelProp+1;
    }
  },

  mounted() {
    if (this.partner?.currentPackageId !== 0) {
      this.$store.dispatch('getFarmingPackage', this.partner?.currentPackageId)
    }
  },

  watch: {
    partnersOpen(value) {
      if (value === true) {
        this.updatePartnersHeight()
        this.$emit('childOpen', this.partnersHeight)
      } else {
        this.$emit('childClose', this.partnersHeight)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.my-team__partners_info_item_hidden_wrapper {
  &.active {
    height: auto !important;
  }
}
.my-team__partners_info_item {
  &.show {
    overflow: unset !important;
  }
}

.my-team__partners_info_item_partner_2 {
  &.active {
    span {
      transition: transform .2s ease-in-out;
      display: block;
      transform: rotate(-180deg);
    }
  }
}

.my-team__partners_info_item_partner_1.active span {
  color: #01ffc2;
  transition: color .2s ease-in-out;
}

.my-team__partners_info_item_visible {
  justify-content: space-between;
}
</style>