import { Empty } from 'google-protobuf/google/protobuf/empty_pb.js'
import { Session } from "../../../../apis/mlmbox/type/session_pb";
import { DurationPoint } from "../../../../apis/mlmbox/type/time_point_pb";
import { SessionClient } from "../../../../apis/mlmbox/client/session/v1/session_grpc_web_pb";
import { API_URL } from '@/api';

const client = new SessionClient(API_URL, true, {
  withCredentials: true
});

export default {
  state: {
    session: {}
  },

  mutations: {
    setSession(state, session) {
      state.session = session;
    },
  },

  actions: {
    getSessionState({commit}) {
      return new Promise((resolve, reject) => {
        const request = new Empty;

        client.stateGet(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
          if (err !== null) { reject(err) } 
          else {
            commit('setSession', response.toObject());

            resolve(response.toObject())
          }
        })
      })
    },

    deactivateSession({commit}, sessionId) {
      return new Promise((resolve, reject) => {
        const request = new Session();
        request.setSessionId(sessionId)

        client.deactivate(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
          if (err !== null) { 
            reject(err) 
          } 
          else { 
            commit('stub');
            Object.keys(response.toObject()).length === 0 && resolve(true) 
          }
        })
      })
    },

    deactivateAllSessions() {
      return new Promise((resolve, reject) => {
        const request = new Empty;

        client.deactivateAll(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
          if (err !== null) { reject(err) } 
          else { resolve(response.toObject()) }
        })
      })
    },
 
    deactivateDeactivateOther() {
      return new Promise((resolve, reject) => {
        const request = new Empty;

        client.deactivateOther(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
          if (err !== null) { reject(err) } 
          else { resolve(response.toObject()) }
        })
      })
    },

    deactivateDeactivateCurrent() {
      return new Promise((resolve, reject) => {
        const request = new Empty;

        client.deactivateCurrent(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
          if (err !== null) { reject(err) } 
          else { resolve(response.toObject()) }
        })
      })
    },

    deactivateExpireDurationSet({commit}, {durationValue, durationInfinity}) {
      return new Promise((resolve, reject) => {
        const request = new DurationPoint();
        request.setValue(durationValue)
        request.setInfinity(durationInfinity)

        client.expireDurationSet(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
          if (err !== null) { reject(err) } 
          else { resolve(response.toObject()) }
          commit('stub')
        })
      })
    },

  }
}