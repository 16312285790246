<template>
  <div v-if="show" class="home-2__main_plan_slider_block">
      <!-- :initialSlide="2" -->
    <swiper class="home-2__main_plan_slider sliderPlans"
      v-if="farmingPackages?.length !== 0"
      :navigation="{ nextEl: '.home-2__main_plan_slide_next', prevEl: '.home-2__main_plan_slide_prev'}"
      :paginatopn="{ nextEl: '.home-2__main_plan_slide_next', prevEl: '.home-2__main_plan_slide_prev'}"
      :loop="true"
      :initialSlide="2"
      :slidesPerView="1"
      :grabCursor="true"
      effect="coverflow"
      :allowTouchMove="true"
      :spaceBetween="50"
      :speed="800"
      :maxBackfaceHiddenSlides="0"
      :slideToClickedSlide="true"
      :modules="modules"
      :breakpoints="brakePoints"
      >
      <swiper-slide class="home-2__main_plan_slide"
        v-for="(farmingPackage, index) in farmingPackages"
        :key="farmingPackage?.packageId">
        <div class="home-2__main_plan_slide_container">
            <div class="home-2__main_plan_slide_heading" :class="`color-${index}`">
              <span>{{ farmingPackage?.packageTitle }}</span>
            </div>
            <div class="home-2__main_plan_slide_price">
              <span v-if="index < farmingPackages?.length-1">{{ changePrice(farmingPackage?.paymentPriceRange.priceFrom) }}-{{ changePrice(+(farmingPackage?.paymentPriceRange.priceTo)-1) }}
                <br>
                {{ asset.assetName }}</span>
              <span v-else>{{ changePrice(farmingPackage?.paymentPriceRange.priceFrom) }}-{{ changePrice(+(farmingPackage?.paymentPriceRange.priceTo)) }}
                <br>
                {{ asset.assetName }}</span>
            </div>
            <div class="home-2__main_plan_slide_text_block">
                <div class="home-2__main_plan_slide_text_1">
                  <span>{{t('base.monthly_income')}}</span>
                </div>
                <div class="home-2__main_plan_slide_text_2">
                  <span>{{ farmingPercent(farmingPackage, 30) }}%</span>
                </div>
            </div>
            <div class="home-2__main_plan_slide_text_block">
                <div class="home-2__main_plan_slide_text_1">
                  <span>{{t('base.all_period')}}</span>
                </div>
                <div class="home-2__main_plan_slide_text_2">
                  <span>{{ farmingPercent(farmingPackage, 30*24) }}%</span>
                </div>
            </div>
            <div class="home-2__main_plan_slide_text_block">
                <div class="home-2__main_plan_slide_text_1">
                  <span>{{t('base.direct_bonus')}}</span>
                </div>
                <div class="home-2__main_plan_slide_text_2">
                  <span>{{ farmingPackage?.bonusDirectPercent*100 }}%</span>
                </div>
            </div>
            <div class="home-2__main_plan_slide_text_block">
                <div class="home-2__main_plan_slide_text_1">
                  <span>{{t('base.binary_bonus')}}</span>
                </div>
                <div class="home-2__main_plan_slide_text_2">
                  <span>{{ farmingPackage?.bonusBinaryPercent*100 }}%</span>
                </div>
            </div>
            <div class="home-2__main_plan_slide_text_block">
                <div class="home-2__main_plan_slide_text_1">
                  <span>{{t('base.binary_limit')}}</span>
                </div>
                <div class="home-2__main_plan_slide_text_2">
                  <span>{{ Number(farmingPackage?.bonusBinaryWeekLimit)?.toFixed() }}$&nbsp;/&nbsp;{{t('base.per_week')}}</span>
                </div>
            </div>
            <button class="home-2__main_plan_slide_btn open_popup" data-popup="custom-buy" @click="openPopup(farmingPackage?.packageId)">
              {{t('home.slider.button')}}
            </button>
        </div>
      </swiper-slide>
    </swiper>
    <div class="home-2__main_plan_pagination"></div>
  </div>
</template>

<script>
// Import Swiper Vue.js components

import { Swiper } from "swiper/vue/swiper-vue";
import { SwiperSlide } from "swiper/vue/swiper-slide";

// import { Swiper, SwiperSlide } from 'swiper/swiper-vue.mjs';
import 'swiper/swiper-bundle.css';

import { Navigation, Pagination } from "swiper";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { useI18n } from 'vue-i18n'
import { mapState } from 'vuex';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  computed: {
    ...mapState({
      farmingPackages: state => state.farming.farmingPackages,
      asset: state => state.asset.defaultAsset,

      isMobileScreen: state => state.mobileScreen
    }),

    brakePoints() {
      const b = this.isMobileScreen === true ? 1025 : 10;
      const res = {};

      res[b] = {
        loop: true,
        slidesPerView: 1,
        grabCursor:false,
        effect: 'slide',
        // speed: 400,
        // allowTouchMove: false,
        spaceBetween: 30,
      }

      return res
    }
  },

  methods: {
    openPopup(packageId) {
      this.$emit('openPopup', {name: 'PopupCustomBuy', packageId})
    },

    farmingPercent(p, days) {
      const koef = +p.farmingDuration / 86400;
      return +(+p.farmingPercent*koef*days*100).toFixed(2);
    },

    setFloor(number) {
      return +number;
    },

    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "`");
    },

    changePrice(price) {
      return this.numberWithSpaces(this.setFloor(price));
    }
  },

  created() {
    if (Object.keys(this.farmingPackages).length === 0) this.$store.dispatch('getAllFarmingPackages')
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return {
      t,
      modules: [Navigation, Pagination],
    };
  },

  data() {
    return {
      show: true
    }
  },

  watch: {
    isMobileScreen() {
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
};
</script>

<style lang='scss' scoped>
.home-2__main_plan_slider_block {
  margin: 0 auto;
  width: 33.75em !important;
}
.home-2__main_plan_slide_container {
  width: 33.75em;
}

@media (max-width: 1024px) and (orientation: portrait) {
  .mobileScreen {
    .home-2__main_plan_slider_block {
      margin-top: 1.5em;
      width: 100% !important;
    }
    .home-2__main_plan_slide_container {
      width: 100%;
    }
  }
}
</style>
