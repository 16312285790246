/**
 * @fileoverview gRPC-Web generated client stub for mlmbox.client.network.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.15.8
// source: mlmbox/client/network/v1/network.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var mlmbox_type_account_pb = require('../../../../mlmbox/type/account_pb.js')

var mlmbox_type_limit_pb = require('../../../../mlmbox/type/limit_pb.js')

var mlmbox_type_sort_pb = require('../../../../mlmbox/type/sort_pb.js')
const proto = {};
proto.mlmbox = {};
proto.mlmbox.client = {};
proto.mlmbox.client.network = {};
proto.mlmbox.client.network.v1 = require('./network_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mlmbox.client.network.v1.NetworkClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mlmbox.client.network.v1.NetworkPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.type.Account.ID,
 *   !proto.mlmbox.type.Account.ShortInfo>}
 */
const methodDescriptor_Network_PartnerGet = new grpc.web.MethodDescriptor(
  '/mlmbox.client.network.v1.Network/PartnerGet',
  grpc.web.MethodType.UNARY,
  mlmbox_type_account_pb.Account.ID,
  mlmbox_type_account_pb.Account.ShortInfo,
  /**
   * @param {!proto.mlmbox.type.Account.ID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_account_pb.Account.ShortInfo.deserializeBinary
);


/**
 * @param {!proto.mlmbox.type.Account.ID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.type.Account.ShortInfo)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Account.ShortInfo>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.network.v1.NetworkClient.prototype.partnerGet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.network.v1.Network/PartnerGet',
      request,
      metadata || {},
      methodDescriptor_Network_PartnerGet,
      callback);
};


/**
 * @param {!proto.mlmbox.type.Account.ID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.type.Account.ShortInfo>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.network.v1.NetworkPromiseClient.prototype.partnerGet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.network.v1.Network/PartnerGet',
      request,
      metadata || {},
      methodDescriptor_Network_PartnerGet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.client.network.v1.AccountsSearchRequest,
 *   !proto.mlmbox.type.Account.ShortInfo>}
 */
const methodDescriptor_Network_PartnerSearch = new grpc.web.MethodDescriptor(
  '/mlmbox.client.network.v1.Network/PartnerSearch',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.mlmbox.client.network.v1.AccountsSearchRequest,
  mlmbox_type_account_pb.Account.ShortInfo,
  /**
   * @param {!proto.mlmbox.client.network.v1.AccountsSearchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_account_pb.Account.ShortInfo.deserializeBinary
);


/**
 * @param {!proto.mlmbox.client.network.v1.AccountsSearchRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Account.ShortInfo>}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.network.v1.NetworkClient.prototype.partnerSearch =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mlmbox.client.network.v1.Network/PartnerSearch',
      request,
      metadata || {},
      methodDescriptor_Network_PartnerSearch);
};


/**
 * @param {!proto.mlmbox.client.network.v1.AccountsSearchRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Account.ShortInfo>}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.network.v1.NetworkPromiseClient.prototype.partnerSearch =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mlmbox.client.network.v1.Network/PartnerSearch',
      request,
      metadata || {},
      methodDescriptor_Network_PartnerSearch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.client.network.v1.TeamRequest,
 *   !proto.mlmbox.client.network.v1.TeamResponse>}
 */
const methodDescriptor_Network_TeamInfo = new grpc.web.MethodDescriptor(
  '/mlmbox.client.network.v1.Network/TeamInfo',
  grpc.web.MethodType.UNARY,
  proto.mlmbox.client.network.v1.TeamRequest,
  proto.mlmbox.client.network.v1.TeamResponse,
  /**
   * @param {!proto.mlmbox.client.network.v1.TeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mlmbox.client.network.v1.TeamResponse.deserializeBinary
);


/**
 * @param {!proto.mlmbox.client.network.v1.TeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.client.network.v1.TeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.client.network.v1.TeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.network.v1.NetworkClient.prototype.teamInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.network.v1.Network/TeamInfo',
      request,
      metadata || {},
      methodDescriptor_Network_TeamInfo,
      callback);
};


/**
 * @param {!proto.mlmbox.client.network.v1.TeamRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.client.network.v1.TeamResponse>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.network.v1.NetworkPromiseClient.prototype.teamInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.network.v1.Network/TeamInfo',
      request,
      metadata || {},
      methodDescriptor_Network_TeamInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.client.network.v1.AccountsSearchRequest,
 *   !proto.mlmbox.type.Account.ShortInfo>}
 */
const methodDescriptor_Network_TeamSearch = new grpc.web.MethodDescriptor(
  '/mlmbox.client.network.v1.Network/TeamSearch',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.mlmbox.client.network.v1.AccountsSearchRequest,
  mlmbox_type_account_pb.Account.ShortInfo,
  /**
   * @param {!proto.mlmbox.client.network.v1.AccountsSearchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_account_pb.Account.ShortInfo.deserializeBinary
);


/**
 * @param {!proto.mlmbox.client.network.v1.AccountsSearchRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Account.ShortInfo>}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.network.v1.NetworkClient.prototype.teamSearch =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mlmbox.client.network.v1.Network/TeamSearch',
      request,
      metadata || {},
      methodDescriptor_Network_TeamSearch);
};


/**
 * @param {!proto.mlmbox.client.network.v1.AccountsSearchRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Account.ShortInfo>}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.network.v1.NetworkPromiseClient.prototype.teamSearch =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mlmbox.client.network.v1.Network/TeamSearch',
      request,
      metadata || {},
      methodDescriptor_Network_TeamSearch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.client.network.v1.TeamPartnerListRequest,
 *   !proto.mlmbox.type.Account.FullInfo>}
 */
const methodDescriptor_Network_TeamPartnerList = new grpc.web.MethodDescriptor(
  '/mlmbox.client.network.v1.Network/TeamPartnerList',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.mlmbox.client.network.v1.TeamPartnerListRequest,
  mlmbox_type_account_pb.Account.FullInfo,
  /**
   * @param {!proto.mlmbox.client.network.v1.TeamPartnerListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_account_pb.Account.FullInfo.deserializeBinary
);


/**
 * @param {!proto.mlmbox.client.network.v1.TeamPartnerListRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Account.FullInfo>}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.network.v1.NetworkClient.prototype.teamPartnerList =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mlmbox.client.network.v1.Network/TeamPartnerList',
      request,
      metadata || {},
      methodDescriptor_Network_TeamPartnerList);
};


/**
 * @param {!proto.mlmbox.client.network.v1.TeamPartnerListRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Account.FullInfo>}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.network.v1.NetworkPromiseClient.prototype.teamPartnerList =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mlmbox.client.network.v1.Network/TeamPartnerList',
      request,
      metadata || {},
      methodDescriptor_Network_TeamPartnerList);
};


module.exports = proto.mlmbox.client.network.v1;

