<template>
  <popup-template
    popupName="lost-password-code"
    @closePopup="$emit('closePopup')"
  >
    <form class="form form-signin" id="signinForm" action="#">
      <template v-if="step === 1">
        <div class="form__wrap" :class="{ valid: confirmationValide === true }">
          <label for="confirmation">{{t('popups.confirmation_email.enter_code')}}</label>
          <input
            class="form__input"
            id="confirmation"
            type="text"
            name="confirmation"
            placeholder="****"
            v-model="confirmation"
          />
          <div
            class="error-item"
            :class="{ active: confirmationValide === false }"
          >
            <img src="img/home/err.png" alt="" />
            <span>{{t('popups.confirmation_email.invalid_code')}}</span>
          </div>
        </div>
        <!-- <div class="popups__wallet_comission">
          <span>Код пришел на ваш старый email</span>
        </div> -->
      </template>

      <template v-if="step === 2">
        <div class="form__wrap" :class="{ valid: emailCode2Valide === true }">
          <label for="emailCode2">{{t('popups.confirmation_email.confirm_new_email:')}}</label>
          <input
            class="form__input"
            id="emailCode2"
            type="text"
            name="emailCode2"
            placeholder="****"
            v-model="emailCode2"
          />
          <div
            class="error-item"
            :class="{ active: emailCode2Valide === false }"
          >
            <img src="img/home/err.png" alt="" />
            <span>{{t('popups.confirmation_email.invalid_code')}}</span>
          </div>
        </div>
        <div class="popups__wallet_comission">
          <span>{{t('popups.confirmation_email.enter_code_2')}}</span>
        </div>
      </template>

      <template v-if="step === 3">
        <div class="form__wrap" :class="{ valid: confirmation2faValide === true }">
          <label for="confirmation2fa">{{t('popups.confirmation_email.enter_code_2fa')}}</label>
          <input
            class="form__input"
            id="confirmation2fa"
            type="text"
            name="confirmation2fa"
            placeholder="****"
            v-model="confirmation2fa"
          />
          <div
            class="error-item"
            :class="{ active: confirmation2faValide === false }"
          >
            <img src="img/home/err.png" alt="" />
            <span>{{t('popups.confirmation_email.invalid_code')}}</span>
          </div>
        </div>
      </template>
      
      <div class="form__wrap form-wrap-btn">
        <button
          class="form__submit"
          id="regSubmitBtn"
          type="submit"
          @click.prevent="confirmCode"
        >
          {{t('popups.confirmation_email.button')}}
        </button>
      </div>
      <div class="error-item" :class="{ active: error === true }">
        <img src="img/home/err.png" alt="" />
        <span>{{ errorMessage }}</span>
      </div>
    </form>
  </popup-template>
</template>

<script>
import {session} from '@/mixins/session'
import PopupTemplate from "./PopupTemplate";
import { useI18n } from 'vue-i18n'

export default {
  props: [
    'data'
  ],

  mixins: [
    session
  ],

  components: {
    PopupTemplate,
  },

	data() {
		return {
      step: 1,

			confirmation: '',
			confirmationValide: null,

			emailCode2: '',
			emailCode2Valide: null,

      has2fa: false,
			confirmation2fa: '',
			confirmation2faValide: null,

      error: false,
      errorMessage: 'invalid argument',
		}
	},

  methods: {
    resetError() {
      this.error = false;
    },

    validateConfirmation(value) {
      const regex = /^(?=.*[A-Za-z0-9_]$)[A-Za-z0-9_][A-Za-z\d.-]{0,19}$/;

      return regex.test(value);
    },

    validateEmailCode2(value) {
      const regex = /^(?=.*[A-Za-z0-9_]$)[A-Za-z0-9_][A-Za-z\d.-]{0,19}$/;

      return regex.test(value);
    },

    validateConfirmation2fa(value) {
      const regex = /^[^\s]+(\s+[^\s]+)*$/;

      return regex.test(value);
    },

    generateCodeConfirmationFunction() {
      this.$store.dispatch('ConfirmationGeneratePassword').catch((err) => {
        this.error = true;
        this.errorMessage = err.message;
      })
    },

    getConfirmation(cb, args) {
      this.$store.dispatch('ConfirmationGet', this.data.confirmationId).then(() => {
        if (cb !== undefined) cb(args)
      }).catch((err) => {
        this.error = true;
        this.errorMessage = err.message;
      })
    },

    generateCodeConfirmation() {
      // this.generateCodeConfirmationFunction()
      this.getConfirmation(this.generateCodeConfirmationFunction)
    },

    cancelConfirmation() {
      this.$store.dispatch('ConfirmationCancel').catch((err) => {
        this.error = true;
        this.errorMessage = err.message;
      })
    },

    confirmCode() {
      if (!this.confirmationValide) return;

      if (this.step === 1) {
        this.step++
        return;
      }

      if (this.step === 2 && this.has2fa === true) {
        this.step++
        return;
      }

      const args = {
        emailValue1: this.confirmation,
        emailValue2: this.emailCode2,
      }

      if (this.has2fa === true ) args.gaValue = this.confirmation2fa;

      this.$store.dispatch('ConfirmationConfirm', {...args})
        .then((response) => {
          if (response.status !== 1) {
            this.error = true;
            this.errorMessage = response.comment !== '' ? 'rejected' : response.comment;
          } else if (response.status === 1) {
            // в админку
            localStorage.setItem('userAuth', true);
            if (this.data.signUpSuccess === true) {
              this.$emit('openPopup', { name: 'PopupDoneAuth'})
              return;
            }
            this.$router.go('/');
          }
      }).catch((err) => {
        this.error = true;
        this.errorMessage = err.message;
      })
		},
  },

  watch: {
		confirmation(value) {
      this.confirmationValide = this.validateConfirmation(value);
      this.error && this.resetError()
    },

		emailCode2(value) {
      this.emailCode2Valide = this.validateEmailCode2(value);
      this.error && this.resetError()
    },

		confirmation2fa(value) {
      this.confirmation2faValide = this.validateConfirmation2fa(value);
      this.error && this.resetError()
    },
  },

  mounted () {  
    this.generateCodeConfirmation()
    this.$store.dispatch('get2aInfo').then((response) => {
      if (response.status === 1) this.has2fa = true
    })
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  }
};
</script>

<style scoped>
.form__wrap.form-wrap-btn {
	margin-top: 2em
}
</style>