import { NetworkClient } from "../../../../apis/mlmbox/client/network/v1/network_grpc_web_pb";
import { AccountsSearchRequest, TeamRequest, TeamPartnerListRequest } from "../../../../apis/mlmbox/client/network/v1/network_pb";
import { Account } from "../../../../apis/mlmbox/type/account_pb";
import { Sort } from "../../../../apis/mlmbox/type/sort_pb";
import { Limit } from "../../../../apis/mlmbox/type/limit_pb";
import { API_URL } from '@/api';

const client = new NetworkClient(API_URL, true, {
    withCredentials: true
});

export default {
  state: {
    network: {},
    partnersTeam: {},
    searchPartners: [],
    teamList: [],

    teamPartnerList: []
  },

  mutations: {
    setNetwork(state, network) {
      state.network = network
    },

    setPartnersTeam(state, {name, value}) {
      state.partnersTeam[name] = value;
    },
    setSearchPartners(state, searchPartners) {
      state.searchPartners = searchPartners
      // if (state.searchPartners.find(s => s.accountId === searchPartners.accountId) === undefined) {
        //   state.searchPartners.push(searchPartners)
        // }
    },
    setTeamList(state, teamList) {
      state.teamList = teamList;
    },
    setTeamPartnerList(state, teamPartnerList) {
      state.teamPartnerList = teamPartnerList;
    },
  },

  actions: {
    getPartner({commit}, accountLogin) {
      return new Promise((resolve, reject) => {
        const request = new Account.ID();

        request.setAccountLogin(accountLogin)

        client.partnerGet(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
          if (err !== null) {
            reject(err)
          } else {
            commit('stub');
            resolve(response.toObject())
          }
        })
      })
    },

    getPartnersInfo({dispatch, commit}, patners) {
      const promiseList = [];

      if (patners.length !== 0) {
        patners.forEach(p => {
          promiseList.push(dispatch('getMyPartnersTeamInfo', p.login))
        });
      }

      Promise.all([...promiseList]).then((values) => {
        commit('setSearchPartners', values.map(v => v.account))
      });
    },

    getPartnerSearch({dispatch, commit}, loginPrefix) {
      return new Promise((resolve, reject) => {
        const request = new AccountsSearchRequest();

        if (loginPrefix === '') {
          commit('setSearchPartners', [])
          return;
        }

        request.setLoginPrefix(loginPrefix)
        const stream = client.partnerSearch(request, {"authorization": localStorage.getItem("access_token")})

        const results = [];

        stream.on('data', function(response) {
          results.push(response.toObject())
        });

        stream.on('status', (status) => {
          dispatch('getPartnersInfo', results)
          if (status.code !== 0) {
            reject(status)
          } else {
            resolve()
          }
        });

      })
    },

    getTeamSearch({dispatch, commit}, loginPrefix) {
      return new Promise((resolve, reject) => {
        const request = new AccountsSearchRequest();

        if (loginPrefix === '') {
          commit('setSearchPartners', [])
          return;
        }

        request.setLoginPrefix(loginPrefix)
        const stream = client.teamSearch(request, {"authorization": localStorage.getItem("access_token")})

        const results = [];

        stream.on('data', function(response) {
          results.push(response.toObject())
        });

        stream.on('status', (status) => {
          if (status.code !== 0) {
            reject(status)
          } else {
            resolve()
          }
        });

        stream.on('end', function() {
          dispatch('getPartnersInfo', results)
          // stream end signal
        });

      })
    },

    getPartnerTeamInfo({commit}, partnerLogin) {
      return new Promise((resolve, reject) => {
        const request = new TeamRequest();
        const partnerWrapper = new Account.ID();
        partnerWrapper.setAccountLogin(partnerLogin)

        request.setViewPartner(partnerWrapper)

        client.teamInfo(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
          if (err !== null) {
            reject(err)
          } else {
            commit('setNetwork', response.toObject());
            // commit('stub')
            resolve(response.toObject())
          }
        })
      })
    },

    getMyPartnersTeamInfo({commit}, partnerLogin) {
      return new Promise((resolve, reject) => {
        const request = new TeamRequest();

        const partnerWrapper = new Account.ID();
        partnerWrapper.setAccountLogin(partnerLogin)

        const sortWrapper = new Sort();
        sortWrapper.setSide('DESC');

        const limitWrapper = new Limit();
        limitWrapper.setUnlimited = false;
        limitWrapper.setValue = 25;

        sortWrapper.setLimit(limitWrapper);

        partnerWrapper.setAccountLogin(partnerLogin)
        // partnerWrapper.setSort(sortWrapper)

        // console.log(partnerWrapper)

        // partners_sort

        request.setViewPartner(partnerWrapper)

        client.teamInfo(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
          // commit('setPartnersTeam', {name: partnerLogin, value: response.toObject().partnersList})
          commit('stub')
          if (err !== null) reject(err)
          else resolve(response.toObject())
        })
      })
    },

    getPartnerTeamSearch({commit}, loginPrefix) {
      return new Promise((resolve, reject) => {
        const request = new AccountsSearchRequest();

        request.setLoginPrefix(loginPrefix)

        client.teamSearch(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
          if (err !== null) {
            reject(err)
          } else {
            commit('stub');
            resolve(response.toObject())
          }
        })
      })
    },

    getTeamPartnerList({commit}, { partnerLogin, referralLine }) {
    // getTeamPartnerList({state, commit}, { partnerLogin, referralLine }) {
      // const result = state.teamPartnerList.length > 0 ? [...state.teamPartnerList] : [];
      const result = [];
      commit('setTeamPartnerList', result);

      return new Promise((resolve, reject) => {
          const request = new TeamPartnerListRequest();
          const accountWrapper = new Account.ID();
          accountWrapper.setAccountLogin(partnerLogin)

          request.setStepReferralLine(referralLine)
          request.setViewPartner(accountWrapper);

          const stream = client.teamPartnerList(request, {"authorization": localStorage.getItem("access_token")})

          stream.on('data', function(response) {
              result.push(response.toObject());
          });

          stream.on('status', (status) => {
              if (status.code !== 0) reject(status)
              if (status.code === 0) {
                resolve(result);
                commit('setTeamPartnerList', result)
              }
          });
      })
    },

    getTeamPartnerPage({commit}, { partnerLogin, step }) {
      // const result = state.teamPartnerList.length > 0 ? [...state.teamPartnerList] : [];
      const result = [];
      commit('setTeamPartnerList', result);

      console.log('result', result)

      return new Promise((resolve, reject) => {
          const request = new TeamPartnerListRequest();

          const accountWrapper = new Account.ID();
          accountWrapper.setAccountLogin(partnerLogin);
          request.setViewPartner(accountWrapper);

          const sortWrapper = new Sort();
          const limitWrapper = new Limit();
          limitWrapper.setValue(step);
          sortWrapper.setLimit(limitWrapper);

          request.setPartnersSort(sortWrapper)

          console.log(request, sortWrapper);

          const stream = client.teamPartnerList(request, {"authorization": localStorage.getItem("access_token")})

          stream.on('data', function(response) {
              result.push(response.toObject());
          });

          stream.on('status', (status) => {
              if (status.code !== 0) reject(status)
              if (status.code === 0) {
                resolve(result);
                commit('setTeamPartnerList', result)
              }
          });
      })
    },

    getPartnerTeamPartnerList({state, commit}, {partnerLogin, referralLine}) {
      return new Promise((resolve, reject) => {
        const request = new TeamPartnerListRequest();
        const accountWrapper = new Account.ID();
        accountWrapper.setAccountLogin(partnerLogin)

        request.setStepReferralLine(referralLine)
        request.setViewPartner(accountWrapper)

        const stream = client.teamPartnerList(request, {"authorization": localStorage.getItem("access_token")})
        const results = state.teamList.length > 0 ? [...state.teamList] : [];

        stream.on('data', function(response) {
          if (state.teamList.length > 0) {
            const existPartner = state.teamList.find(p => p.accountId === response.toObject().accountId);
            existPartner ?? results.push(response.toObject());
            return;
          }

          results.push(response.toObject())
        });

        stream.on('status', (status) => {
          if (status.code !== 0) {
            reject(status)
          } else {
            resolve(results)
          }
        });

        commit('setTeamList', results);

        // client.teamPartnerList(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
        //   if (err !== null) {
        //     reject(err)
        //   } else {
        //     commit('stub');
        //     resolve(response.toObject())
        //   }
        // })
      })
    },

  }
}
