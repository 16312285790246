<template>
  <div class="bot-rive__container">
    <canvas ref="canvas" width="400" height="400" class="bot-rive"></canvas>
  </div>
</template>

<script>
import {Rive, Layout, Fit, Alignment} from '@rive-app/canvas';

let riveAnimation;

export default {
  name: 'BotRive',

  props: {
    src: String,
    fit: String,
    alignment: String
  },
  
  mounted() {
    riveAnimation = new Rive({
      canvas: this.$refs.canvas,
      src: "rive/digital_engine_robots.riv",
      layout: new Layout({
        fit: Fit.Contain,
        alignment: Alignment.TopCenter,
      }),
      autoplay: true,
      // onLoad: () => {
      //   r.resizeDrawingSurfaceToCanvas();
      // },
    })

    riveAnimation.resizeDrawingSurfaceToCanvas()
  },

  unmounted() {
    if (riveAnimation !== undefined && riveAnimation !== null) {
      riveAnimation.cleanupInstances(); // Остановить анимацию
      
      riveAnimation = null; // Очистить ссылку на анимацию
    }
  }
}
</script>
<style lang="scss" scoped>
.bot-rive {
  width: 18em;
  height: 14em;
  display: block;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>