/**
 * @fileoverview gRPC-Web generated client stub for mlmbox.client.career.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.15.8
// source: mlmbox/client/career/v1/career.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var mlmbox_type_career_pb = require('../../../../mlmbox/type/career_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.mlmbox = {};
proto.mlmbox.client = {};
proto.mlmbox.client.career = {};
proto.mlmbox.client.career.v1 = require('./career_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mlmbox.client.career.v1.CareerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mlmbox.client.career.v1.CareerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.mlmbox.type.Career>}
 */
const methodDescriptor_Career_Info = new grpc.web.MethodDescriptor(
  '/mlmbox.client.career.v1.Career/Info',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  mlmbox_type_career_pb.Career,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_career_pb.Career.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.type.Career)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Career>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.career.v1.CareerClient.prototype.info =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.career.v1.Career/Info',
      request,
      metadata || {},
      methodDescriptor_Career_Info,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.type.Career>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.career.v1.CareerPromiseClient.prototype.info =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.career.v1.Career/Info',
      request,
      metadata || {},
      methodDescriptor_Career_Info);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.type.Career.Rank.ID,
 *   !proto.mlmbox.type.Career.Rank>}
 */
const methodDescriptor_Career_RankGet = new grpc.web.MethodDescriptor(
  '/mlmbox.client.career.v1.Career/RankGet',
  grpc.web.MethodType.UNARY,
  mlmbox_type_career_pb.Career.Rank.ID,
  mlmbox_type_career_pb.Career.Rank,
  /**
   * @param {!proto.mlmbox.type.Career.Rank.ID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_career_pb.Career.Rank.deserializeBinary
);


/**
 * @param {!proto.mlmbox.type.Career.Rank.ID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.type.Career.Rank)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Career.Rank>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.career.v1.CareerClient.prototype.rankGet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.career.v1.Career/RankGet',
      request,
      metadata || {},
      methodDescriptor_Career_RankGet,
      callback);
};


/**
 * @param {!proto.mlmbox.type.Career.Rank.ID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.type.Career.Rank>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.career.v1.CareerPromiseClient.prototype.rankGet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.career.v1.Career/RankGet',
      request,
      metadata || {},
      methodDescriptor_Career_RankGet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.mlmbox.type.Career.Rank>}
 */
const methodDescriptor_Career_RankAll = new grpc.web.MethodDescriptor(
  '/mlmbox.client.career.v1.Career/RankAll',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  mlmbox_type_career_pb.Career.Rank,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_career_pb.Career.Rank.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Career.Rank>}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.career.v1.CareerClient.prototype.rankAll =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mlmbox.client.career.v1.Career/RankAll',
      request,
      metadata || {},
      methodDescriptor_Career_RankAll);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Career.Rank>}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.career.v1.CareerPromiseClient.prototype.rankAll =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mlmbox.client.career.v1.Career/RankAll',
      request,
      metadata || {},
      methodDescriptor_Career_RankAll);
};


module.exports = proto.mlmbox.client.career.v1;

