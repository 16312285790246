import { ConfirmationGuestClient } from "../../../../apis/mlmbox/client/confirmation/v1/confirmation_guest_grpc_web_pb";
import { ConfirmationGuestGenerateOneTimePasswordRequest, ConfirmationGuestConfirmRequest } from "../../../../apis/mlmbox/client/confirmation/v1/confirmation_guest_pb";
import { Confirmation } from "../../../../apis/mlmbox/type/confirmation_pb";
import { Empty } from 'google-protobuf/google/protobuf/empty_pb.js'
import { API_URL } from '@/api';

const client = new ConfirmationGuestClient(API_URL, true, {
  withCredentials: true
});

export default {
  state: {
    confirmation: {},
    fields: {}
  },

  mutations: {
    stub() {},

    setConfirmation(state, confirmation) {
      state.confirmation = confirmation;
    },

    setFields(state, fieldsList) {
      const keys = {
        0: 'UNKNOWN',
        1: 'PASSWORD', // пароль
        2: 'GOOGLE_AUTHENTICATOR_CODE', // 2FA код
        3: 'EMAIL_CONFIRM_CODE', // подтвердить код отправленный на указанный контакт
        4: 'EMAIL_FILL' // заполнить значение контакта (например: sm***el@gmail.com - требуется заполнить поле без маски с звездочками)
      }
      
      fieldsList.forEach(field => {
        state.fields[keys[field.kindId]] = field;
      })
    },
  },

  getters: {
    getGuestConfirmation(state) {
      return state.confirmation
    },

    getGuestField: (state) => fieldName => state.fields[fieldName],
  },

  actions: {
    confirmationGuestGet({ commit }) {
      return new Promise((resolve, reject) => {
        const request = new Empty();
        
        client.get(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
          if (err !== null) {
            reject(err)
          } else {
            commit('setConfirmation', response.toObject());
            commit('setFields', response.toObject().fieldsList);
            
            resolve(response.toObject())
          }
        })
      })
    },

    confirmationGuestGeneratePassword({ commit}) {

      return new Promise((resolve, reject) => {
        const request = new ConfirmationGuestGenerateOneTimePasswordRequest();
        const confirmationField = this.state.auth.confirmation.fieldsList.find(f => f.kindId === 3);
        // const field = getters.getGuestField('EMAIL_CONFIRM_CODE');

        request.setFieldId(confirmationField.fieldId)

        client.generateOneTimePassword(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
          if (err !== null) {
            reject(err)
          } else {
            commit('stub')
            resolve(response.toObject())
          }
        })
      })
    },

    // confirmationGuestConfirm({ state, commit, getters }, {emailValue1, gaValue}) {
    confirmationGuestConfirm({ commit }, {emailValue1, gaValue}) {
      return new Promise((resolve, reject) => {

        const request = new ConfirmationGuestConfirmRequest();
        // const confirmation = getters.getGuestConfirmation;
        const confirmation = this.state.auth.confirmation;

        // const field = getters.getGuestField('EMAIL_CONFIRM_CODE');
        // const fieldWrapper = new ConfirmationGuestConfirmRequest.Field();
        // const option = confirmation.optionsList.find(option => {
        //   return option.fieldIdsList.find(f => f === field.fieldId) !== undefined
        // })
        // request.addFields(fieldWrapper)
        
        // fieldWrapper.setFieldId(field.fieldId)
        // fieldWrapper.setValue(value)
        request.setOptionId(confirmation.optionsList[0].optionId);

        confirmation.fieldsList.forEach((f, index) => {
          const emailValues = {emailValue1}

          const fieldWrapper = new ConfirmationGuestConfirmRequest.Field();
          fieldWrapper.setFieldId(f.fieldId)

          if (f.kindId === 2) fieldWrapper.setValue(gaValue) 
          if (f.kindId === 3) fieldWrapper.setValue(emailValues[`emailValue${index+1}`])
          request.addFields(fieldWrapper)
        })

        
        client.confirm(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
          if (err !== null) {
            reject(err)
          } else {
            if (response.accessToken !== '' && response.accessToken !== undefined) {
              localStorage.setItem("access_token", response.accessToken);
            }
            commit('stub');
            
            if (response.toObject().status === 0) {
              const responseField = response.toObject().fieldsList.find(f => f.statusId !== 1)
              resolve(responseField)
              
              return;
            } else if (response.toObject().status === 1) {
              resolve(response.toObject())
            }
          }
        })
      })
    },

    confirmationGuestCancel({ commit }, id) {
      // вызывать при случайном выходе с сайта
      
      return new Promise((resolve, reject) => {
        const request = new Confirmation.ID;
        
        request.setConfirmation(id)
        
        client.cancel(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
          if (err !== null) {
            reject(err)
          } else {
            commit('stub');
            
            resolve(response.toObject())
          }
        })
      })
    },
  }
}