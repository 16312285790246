<template>
  <div class="spinner">
    <svg class="spinner__svg" :width="width" :height="width" :viewBox="viewBox">
      <g :transform="transform">
        <circle
          class="spinner__circle"
          :class="uniqClass"
          :stroke-width="strokeWidth"
          :x="pos"
          :y="pos"
          :r="radius"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "spinnerComponent",

  props: {
    size: {
        type: String,
        default: "md",
        validator: (v) => ["sm", "md", "lg"].find((it) => it === v),
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sizes: { sm: 20, md: 40, lg: 80 },
    };
  },
  computed: {
    viewBox() {
      const ratio = 0.825 * this.sizes[this.size];
      return [0, 0, ratio, ratio].join(" ");
    },
    width() {
      return this.sizes[this.size] + "px";
    },
    transform() {
      const ratio = (0.825 * this.sizes[this.size]) / 2;
      return `translate(${ratio}, ${ratio})`;
    },
    pos() {
      return -0.125 * this.sizes[this.size];
    },
    radius() {
      return 0.25 * this.sizes[this.size];
    },
    strokeWidth() {
      const ratio = 0.05 * this.sizes[this.size];
      return ratio < 1 ? 1 : ratio;
    },
    uniqClass() {
      return "--" + this.size;
    },
  },
};
</script>

<style lang="scss" scoped>
$spinner-stroke-dasharrays:( sm: 31, md: 62, lg: 125 );
$spinner-color: purple !default;

.example {
  background: #fff;
  margin: 30px auto 0;
  padding: 10px 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  max-width: 200px;
  border-radius: 4px;
  box-shadow:
    0 2px 4px rgba(0,0,0,0.15),
    0 2px 4px rgba(0,0,0,0.20);

  & > .spinner {
    margin: 10px 0;
    flex: 0 100%;
    text-align: center;
  }
}

.spinner {
  &__svg { animation: 1.4s circle-rotate linear infinite; }
  &__circle {
    stroke: $spinner-color;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    fill: none;
    @each $class, $size in $spinner-stroke-dasharrays {
      &.--#{$class} {
        stroke-dasharray: $size;
        animation:
          5s circle-#{$class}-dash ease-in-out infinite,
          5s circle-spin ease-in-out infinite;
      }
    }
  }
}

@keyframes circle-rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes circle-spin {
  12.5% { transform: rotate(0deg); }
  25% { transform: rotate(240deg); }
  37.5% { transform: rotate(240deg); }
  50% { transform: rotate(540deg); }
  62.5% { transform: rotate(540deg); }
  75% { transform: rotate(780deg); }
  87.5% { transform: rotate(780deg); }
  100% { transform: rotate(1080deg); }
}

@each $class, $size in $spinner-stroke-dasharrays {
  @keyframes circle-#{$class}-dash {
    0% { stroke-dashoffset: $size; }
    12.5% { stroke-dashoffset: calc($size/4); }
    25% { stroke-dashoffset: $size; }
    37.5% { stroke-dashoffset: calc($size/4); }
    50% { stroke-dashoffset: $size; }
    62.5% { stroke-dashoffset: calc($size/4); }
    75% { stroke-dashoffset: $size; }
    87.5% { stroke-dashoffset: calc($size/4); }
    100% { stroke-dashoffset: $size; }
  }
}
</style>
