<template>
  <popup-template popupName="registration" @closePopup="$emit('closePopup')">
    <div class="popups__heading">
      <span>{{t('popups.deactivate_contract.title')}}</span>
    </div>
    <div class="popups__wallet_description">
      <span>{{t('popups.deactivate_contract.description')}}</span>
    </div>
    <div class="popups__wallet_description_info">
      <span>{{t('popups.deactivate_contract.info')}}</span>
    </div>
    <button class="popups__btn_withdraw" @click.prevent="withdraw">
      <span>{{t('popups.deactivate_contract.button')}}</span>
    </button>
  </popup-template>
</template>

<script>
import PopupTemplate from "./PopupTemplate";
import { useI18n } from 'vue-i18n'

export default {
  props: ['data'],

  components: {
    PopupTemplate,
  },

  methods: {
    withdraw() {
      // data.contractId
      this.$store.dispatch('farmingContractDeactivate', this.data.contractId).then(response => {
        if (response.closed === true) {
          this.$store.dispatch('getFarmingInfo')
          this.$emit('closePopup')
        }
      })
    }
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  }
};
</script>
