<template>
  <div class="settings__checkboxes">
    <br>
    <div class="settings__item_pass_text">
      <span>{{ t("settings.g2fa.changes.title") }}</span>
    </div>
    <div class="settings__item_pass_text" v-for="checkbox in checkboxes"
      :key="checkbox.name">
      <span>{{ t(`settings.g2fa.changes.${checkbox.name}`) }}</span>
      <label class="settings__checkbox_button">
        <input type="checkbox" v-model="checkbox.value">
        <img src="img/settings/check.svg" alt="" />
      </label>
    </div>
    <br>
    <button class="settings__change_btn disabled" @click.prevent="save">
      Change
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { useI18n } from 'vue-i18n'

export default {
  computed: {
    ...mapState({
      fieldsList: state => state?.google_authenticator?.googleAuthenticator?.usageSetting?.fieldsList
    })
  },

  data() {
    return {
      checkboxes: [
        { 
          name: "authentication",
          fieldId: 1,
          value: false
        },
        { 
          name: "password_recovery",
          fieldId: 2,
          value: false
        },
        { 
          name: "password_change",
          fieldId: 3,
          value: false
        },
        { 
          name: "email_change",
          fieldId: 4,
          value: false
        },
        { 
          name: "withdraw",
          fieldId: 5,
          value: false
        },
      ]
    }
  },

  methods: {
    setCurrentValues() {
      this.fieldsList.forEach(f => {
        this.checkboxes.find(c => c.fieldId === f.fieldId).value = f.currentValue
      })
    },

    save() {
      this.$store.dispatch('get2aUsageSettingsUpdate', this.checkboxes).then(response => {
        switch(response.statusId) {
          case 1: 
            this.$router.go("/");
            break;
          case 0: 
            this.$emit('openPopup', {name: 'PopupConfirmation', confirmationId: response.confirmationId})
            break;
          case 3:
            this.error = true;
            this.errorMessage = 'Rejected' 
            break;
        }
        // this.$store.dispatch('get2aInfo')
      })
    }
  },

  created() {
    this.setCurrentValues()
  },
  
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  }
};
</script>

<style></style>
