<template>
  <div class="2fa__steps">

    <template v-if="step === 1">
      <div class="settings__item_pass_text">
        <span>
          {{t('settings.g2fa.install_title.you_must')}}
          <a target="_blank" href="https://apps.apple.com/app/google-authenticator/id388497605">
            {{t('settings.g2fa.install_title.link')}}
          </a>
          {{t('settings.g2fa.install_title.to_phone')}}
        </span>
      </div>
    </template>

    <template v-if="step === 2">
      <br>
      <button class="button-main signout-btn" @click.prevent="prevStep">
        <span>{{t('settings.g2fa.back')}}</span>
      </button>
      <div class="settings__item_pass_text">
        <span>
          {{t('settings.g2fa.add_private_key')}}
        </span>
      </div>

      <QrCode :value="qrCodeValue" />

      <div class="button-refferal" style="text-align: center; margin-top: 1em" :class="{copy: this.copy === true}" @click="copySecretCode">
        <span>
          {{secretCode}}
        </span>
      </div>
    </template>

    <template v-if="step === 3">
      <br>
      <button class="button-main signout-btn" @click.prevent="prevStep">
        <span>{{t('settings.g2fa.back')}}</span>
      </button>
      <div class="settings__item_pass_text">
        <span>
          {{t('settings.g2fa.confirmation')}}
        </span>
      </div>

      <div class="settings__form_item">
        <input
          class="settings-input"
          id="googleVerificationCode"
          type="text"
          name="googleVerificationCode"
          v-model="googleVerificationCode"
          autocomplete="on"
          required
          @keyup.enter="nextStep"
        />
        <div class="error-item" :class="{ active: error === true }">
          <img src="img/home/err.png" alt="" />
          <span>{{ errorMessage }}</span>
        </div>
      </div>
    </template>

    <div class="settings__switcher_btn_block">
      <br>
      <button class="button-main signout-btn" @click.prevent="nextStep">
        <span>{{t('settings.g2fa.next')}}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import QrCode from '@/components/QrCode/QrCode.vue';
import { useI18n } from 'vue-i18n'

export default {
  components: { QrCode },

  data() {
    return {
      step: 1,
      // secretCode: '',
      copy: false,

      googleVerificationCode: '',
      validateCode: false,
      error: false,
      errorMessage: '',
      errors: {
        invalid: '',
        unknown: '',
        wrong: '',
        reject: ''
      }
    }
  },

  computed: {
    ...mapState({
      userName: state => state?.account?.user?.info?.login,
      secretCode: state => state.google_authenticator.secretCode
    }),

    qrCodeValue() {
      return `otpauth://totp/tokenza.ui:${this.userName}?secret=${this.secretCode}&issuer=tokenza.ui`
    },
  },

  methods: {
    prevStep() {
      if (this.step <= 1) return;
      this.step--;
    },

    googleVerificationCodeValide() {
      const noSpacesRegex = /^[^\s]+(\s+[^\s]+)*$/;
      const regex = this.googleVerificationCode.length > 2 && noSpacesRegex.test(this.googleVerificationCode);

      if (!regex) {
        this.error = true;
        this.errorMessage = this.errors.invalid
      }

      return regex
    },

    nextStep() {
      if (this.step < 3) {
        this.step++;
        return;
      }

      if (this.step === 3) {
        if (!this.validateCode) {
          this.error = true;
          this.errorMessage = this.errors.invalid
          return;
        }

        this.$store.dispatch('get2aActivate', this.googleVerificationCode).then(response => {
          // если всё ок - обновить данные о 2fa
          switch (response.statusId){
            case 0: 
              this.error = true;
              this.errorMessage = this.errors.unknown
              break;
            case 1: 
              this.$store.dispatch('get2aInfo')
              break;
            case 2: 
              this.error = true;
              this.errorMessage = this.errors.reject
              break;
            case 3: 
              this.error = true;
              this.errorMessage = this.errors.wrong
              break;
          } 
        })
      }
    },

    copySecretCode() {
      navigator.clipboard.writeText(this.secretCode);
      this.copy = true

      setTimeout(() => this.copy = false, 500)
    },

    setErrorsLocale() {
      this.errors = {
        invalid: this.t('settings.g2fa.errors.invalid'),
        unknown: this.t('settings.g2fa.errors.unknown'),
        wrong: this.t('settings.g2fa.errors.wrong'),
        reject: this.t('settings.g2fa.errors.reject')
      }
    }
  },

  created() {
    this.$store.dispatch('get2aGenerate')
    this.setErrorsLocale()
  },

  watch: {
    googleVerificationCode() {
      if (this.error === true) this.error = false;
      this.validateCode = this.googleVerificationCodeValide();
    }
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  }
}
</script>