<template>
  <popup-template popupName="registration" @closePopup="$emit('closePopup')">
    <div class="popups__switcher">
      <div class="popups__switcher_heading_block">
        <div class="popups__switcher_heading reg" @click="activeTab = 'signUp'" :class="{active: activeTab === 'signUp'}">
          <span>{{t('popups.auth.sign_up')}}</span>
        </div>
        <div class="popups__switcher_heading signin" @click="activeTab = 'signIn'" :class="{active: activeTab === 'signIn'}">
          <span>{{t('popups.auth.sign_in')}}</span>
        </div>
      </div>
      
      <SignIn v-if="activeTab === 'signIn'" :class="{active: activeTab === 'signIn'}" 
        @rerenderHeader="$emit('rerenderHeader')" 
        @openPopup="data => $emit('openPopup', data)" />

      <SignUp v-if="activeTab === 'signUp'" :class="{active: activeTab === 'signUp'}" 
        @openPopup="data => $emit('openPopup', data)" />

    </div>
  </popup-template>
</template>

<script>
import PopupTemplate from "./PopupTemplate";
import SignIn from "./Auth/SignIn";
import SignUp from "./Auth/SignUp";
import { useI18n } from 'vue-i18n'

export default {
  props: ['data'],

  components: {
    PopupTemplate,
    SignIn,
    SignUp,
  },

  data() {
    return {
      activeTab: 'signUp',
    }
  },

  watch: {
    data(dataVal) {
      if (dataVal.register === true) this.activeTab = 'signUp'
    }
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  }
};
</script>
<style lang="scss" scoped>
.popups__switcher {
  &_heading {
    padding: .75em;
    width: 47%;
    text-align: center;
    margin-right: 4%;
    margin-left: 0 !important;
    border: .15em solid transparent;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      background: #01ffc2;
      border: .15em solid #01ffc2;
      border-radius: 0.42105em;

      span {
        color: #1a0d44;
      }
    }

    span {
      text-transform: none;
      font-weight: 600;
      transition: color .2s ease-in-out;
    }
  }
}
</style>