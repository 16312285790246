<template>
  <div class="header__languages">
    <div class="header__languages_wrapper">
      <button
        href="#"
        class="header__languages_switcher"
        @click="toggleVisibility"
        @keydown.space.exact.prevent="toggleVisibility"
        @keydown.esc.exact="hideDropdown"
        @keydown.shift.tab="hideDropdown"
        @keydown.up.exact.prevent="startArrowKeys"
        @keydown.down.exact.prevent="startArrowKeys"
      >
        {{$i18n.locale}}
      </button>
      <transition name="dropdown-fade">
        <ul
          v-if="isVisible"
          ref="dropdown"
          v-on-clickaway="hideDropdown"
          class="header__languages_dropdown"
        >
          <li v-for="(lang, idx) in langs" :key="idx" class="header__languages_item">
            <button
              @click.prevent="setLocale(lang.shortName)"
              @keydown.up.exact.prevent
              @keydown.tab.exact="focusNext(false)"
              @keydown.down.exact.prevent="focusNext(true)"
              @keydown.esc.exact="hideDropdown"
            >
              {{ lang.shortName }}
            </button>
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "LanguageSwitcher",
  data() {
    return {
      isVisible: false,
      focusedIndex: 0,
      langs: [
        {
          shortName: "en",
        },
        {
          shortName: "es",
        },
        {
          shortName: "pt",
        },
        {
          shortName: "ru",
        },
        {
          shortName: "tr",
        },
        {
          shortName: "vn",
        },
        {
          shortName: "ko",
        },
        {
          shortName: "zh",
        },
        {
          shortName: "ja",
        },
      ]
    };
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    },
    hideDropdown() {
      this.isVisible = false;
      this.focusedIndex = 0;
    },
    startArrowKeys() {
      if (this.isVisible) {
        this.$refs.dropdown.children[0].children[0].focus();
      }
    },
    focusPrevious(isArrowKey) {
      this.focusedIndex = this.focusedIndex - 1;
      if (isArrowKey) {
        this.focusItem();
      }
    },
    focusNext(isArrowKey) {
      this.focusedIndex = this.focusedIndex + 1;
      if (isArrowKey) {
        this.focusItem();
      }
    },
    focusItem() {
      this.$refs.dropdown.children[this.focusedIndex].children[0].focus();
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("lang", locale);
      this.hideDropdown();
    }
  }
};
</script>

<style lang="scss" scoped>
.header__languages {
  position: relative;
  z-index: 10;
  width: 10em !important;
  height: 3.125em;
  align-items: flex-start !important;
  justify-content: flex-end !important;

  &_wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background: #341e5a;
    border-radius: 0.43243em;
    overflow: hidden;
  }

  &_dropdown {
    width: 100%;
    display: block;
  }

  &_item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 1;
    background: #341e5a;
    transition: .2s ease-in-out;
  }

  button {
    background: transparent;
    border: 0;
    color: #01ffc2;
    font-size: 1.15625em;
    text-transform: uppercase;
    cursor: pointer;
    transition: background .2s ease-in-out, color .2s ease-in-out;
    padding: 0.9375em 1.5em;
    width: 100%;
    height: 2.48649em;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: #01ffc2;
      color: #341e5a;
    }
  }

  // @media (max-width: 1024px) and (orientation: portrait) {
  // }
}

.mobileScreen {
  @media (max-width: 1024px) and (orientation: portrait) {
    & .header {
      &__languages {
        justify-content: center !important;
        position: absolute;
        top: 2.8em;
        left: 1.7em;
        width: 4em !important;
  
        // &_dropdown {
        //   font-size: .2em;
        // }
      }
    }
  }
}

.nav li.header__languages_item {
  font-size: 1em !important;
}
</style>