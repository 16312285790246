<template>
  <div class="my-team">
    <div class="heading_block">
      <div class="heading_block__heading">
        <span>Contract Portfolio</span>
      </div>
    </div>
    <div class="container">
      <div class="wallet__main_block">
        <div class="my-team__main_section w-50">
          <div class="my-team__statisticks_block">
            <div class="my-team__statisticks_item">
              <div class="my-team__statisticks_item_heading">
                <span>PERFORMANCE OF TOKENZA AI #1</span>
                <!-- <span>Performance of MEV Types</span> -->
              </div>
              <div class="my-team__statisticks_team_items">
                <div class="my-team__statisticks_team_item">

                  <GraphOne :propsData="diagramsTransactionsTypes" class="inner" v-if="diagramsTransactionsTypes.length > 0" />

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="my-team__main_section w-50">
          <div class="my-team__statisticks_block">
            <div class="my-team__statisticks_item">
              <div class="my-team__statisticks_item_heading">
                <span>Top 10 Favourite Tokens</span>
              </div>
              <div class="my-team__statisticks_team_items">
                <div class="my-team__statisticks_team_item">

                  <GraphTwo :propsData="diagramsTransactionsTokens" class="inner" v-if="diagramsTransactionsTokens.length > 0" />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="my-team__main_section full">
        <div class="my-team__statisticks_block">
          <div class="my-team__statisticks_item">
            <div class="my-team__statisticks_item_heading">
              <span>LATEST TRADES</span>
              <!-- <span>Latest MEV</span> -->
            </div>
            <div class="my-team__statisticks_team_items">
              <div class="my-team__statisticks_team_item list__wrap">

                <ul class="list">
                  <li class="list__head">
                    <div class="list__head_item">Tx</div>
                    <div class="list__head_item">Token</div>
                    <div class="list__head_item green">Revenue</div>
                    <div class="list__head_item">Type</div>
                  </li>

                  <GraphListItem v-for="item in transactions.txs"
                    :key="item.hash"
                    :item="item"
                    />

                </ul>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import GraphOne from '../../components/Graph/GraphOne.vue'
import GraphListItem from '../../components/Graph/GraphListItem.vue'
import GraphTwo from "@/components/Graph/GraphTwo.vue";

export default {
  components: {
      GraphTwo,
      GraphOne,
    GraphListItem
  },

  data() {
    return {
      graph1: [
        {
          createdAt: 21232323232,
          value: 1
        },
        {
          createdAt: 21232323232,
          value: -5
        },
        {
          createdAt: 21232323232,
          value: 5
        },
        {
          createdAt: 21232323232,
          value: -3
        },
        {
          createdAt: 21232323232,
          value: 1
        },
        {
          createdAt: 21232323232,
          value: 1
        },
      ],
      graph2: [
        {
          createdAt: 21232323232,
          value: 1
        },
        {
          createdAt: 21232323232,
          value: 5
        },
        {
          createdAt: 21232323232,
          value: 1
        },
        {
          createdAt: 21232323232,
          value: 3
        },
        {
          createdAt: 21232323232,
          value: 1
        },
        {
          createdAt: 21232323232,
          value: 1
        },
      ],

      list: [
        {
          hash: 'sdfsdfsdfsdfsdf0',
          tokens: ['weth', 'eth'],
          revenue: '$70',
          type: 'Arbitrage',
        },
        {
          hash: 'sdfsdfsdfsdfsdf1',
          tokens: ['weth', 'eth'],
          revenue: '$70',
          type: 'Arbitrage',
        },
        {
          hash: 'sdfsdfsdfsdfsdf2',
          tokens: ['weth', 'eth'],
          revenue: '$70',
          type: 'Sandwich',
        },
        {
          hash: 'sdfsdfsdfsdfsdf3',
          tokens: ['weth', 'eth'],
          revenue: '$70',
          type: 'Sandwich',
        },
      ],

      // diagramsTransactionsMoney: [{"sum":1102.0500676970619,"date":"2023-11-12T00:00:00Z"},{"sum":2180.6330765179573,"date":"2023-11-13T00:00:00Z"},{"sum":50.52966299930829,"date":"2023-11-28T00:00:00Z"},{"sum":562.5237723741693,"date":"2023-11-29T00:00:00Z"}],
      // diagramsTokens: [{"symbol":"WETH","tx_count":48},{"symbol":"TRUCK","tx_count":9},{"symbol":"ROCK","tx_count":9},{"symbol":"Gravitas","tx_count":6},{"symbol":"GROK","tx_count":6},{"symbol":"USDC","tx_count":4},{"symbol":"Q","tx_count":4},{"symbol":"ALD","tx_count":2},{"symbol":"AXL","tx_count":2},{"symbol":"BASE","tx_count":2},{"symbol":"FAFO","tx_count":2},{"symbol":"GALA","tx_count":2},{"symbol":"GNO","tx_count":2},{"symbol":"LTO","tx_count":2},{"symbol":"SPRM","tx_count":2},{"symbol":"THOR","tx_count":2},{"symbol":"XRUNE","tx_count":2},{"symbol":"SPX","tx_count":1},{"symbol":"DEXTF","tx_count":1},{"symbol":"HAY","tx_count":1},{"symbol":"LDO","tx_count":1},{"symbol":"HEX","tx_count":1},{"symbol":"DOGE2.0","tx_count":1}],

      // diagramsTransactionsTypes: [{"tx_type":"Arbitrage","tx_count":4,"date":"2023-11-12T00:00:00Z"},{"tx_type":"PartialSandwich","tx_count":6,"date":"2023-11-12T00:00:00Z"},{"tx_type":"Arbitrage","tx_count":13,"date":"2023-11-13T00:00:00Z"},{"tx_type":"PartialSandwich","tx_count":16,"date":"2023-11-13T00:00:00Z"},{"tx_type":"Arbitrage","tx_count":1,"date":"2023-11-28T00:00:00Z"},{"tx_type":"Arbitrage","tx_count":4,"date":"2023-11-29T00:00:00Z"},{"tx_type":"PartialSandwich","tx_count":4,"date":"2023-11-29T00:00:00Z"}],
      // diagramsTransactionsTokens: [{"symbol":"FAFO","tx_count":2,"date":"2023-11-12T00:00:00Z"},{"symbol":"GROK","tx_count":4,"date":"2023-11-12T00:00:00Z"},{"symbol":"HEX","tx_count":1,"date":"2023-11-12T00:00:00Z"},{"symbol":"SPX","tx_count":1,"date":"2023-11-12T00:00:00Z"},{"symbol":"THOR","tx_count":2,"date":"2023-11-12T00:00:00Z"},{"symbol":"WETH","tx_count":10,"date":"2023-11-12T00:00:00Z"},{"symbol":"XRUNE","tx_count":2,"date":"2023-11-12T00:00:00Z"},{"symbol":"ALD","tx_count":2,"date":"2023-11-13T00:00:00Z"},{"symbol":"BASE","tx_count":2,"date":"2023-11-13T00:00:00Z"},{"symbol":"DEXTF","tx_count":1,"date":"2023-11-13T00:00:00Z"},{"symbol":"DOGE2.0","tx_count":1,"date":"2023-11-13T00:00:00Z"},{"symbol":"GALA","tx_count":2,"date":"2023-11-13T00:00:00Z"},{"symbol":"GNO","tx_count":2,"date":"2023-11-13T00:00:00Z"},{"symbol":"Gravitas","tx_count":6,"date":"2023-11-13T00:00:00Z"},{"symbol":"GROK","tx_count":2,"date":"2023-11-13T00:00:00Z"},{"symbol":"HAY","tx_count":1,"date":"2023-11-13T00:00:00Z"},{"symbol":"LDO","tx_count":1,"date":"2023-11-13T00:00:00Z"},{"symbol":"ROCK","tx_count":8,"date":"2023-11-13T00:00:00Z"},{"symbol":"SPRM","tx_count":2,"date":"2023-11-13T00:00:00Z"},{"symbol":"TRUCK","tx_count":8,"date":"2023-11-13T00:00:00Z"},{"symbol":"USDC","tx_count":2,"date":"2023-11-13T00:00:00Z"},{"symbol":"WETH","tx_count":29,"date":"2023-11-13T00:00:00Z"},{"symbol":"ROCK","tx_count":1,"date":"2023-11-28T00:00:00Z"},{"symbol":"TRUCK","tx_count":1,"date":"2023-11-28T00:00:00Z"},{"symbol":"WETH","tx_count":1,"date":"2023-11-28T00:00:00Z"},{"symbol":"AXL","tx_count":2,"date":"2023-11-29T00:00:00Z"},{"symbol":"LTO","tx_count":2,"date":"2023-11-29T00:00:00Z"},{"symbol":"Q","tx_count":4,"date":"2023-11-29T00:00:00Z"},{"symbol":"USDC","tx_count":2,"date":"2023-11-29T00:00:00Z"},{"symbol":"WETH","tx_count":8,"date":"2023-11-29T00:00:00Z"}],
      // transactions: {"count":48,"txs":[{"result_id":"0xfcdf6d9c98e100f032aa95d8a13be9cf7c4d4d37330c005b12ce0838319b604d","hash":"0x7c272e07d59f3c746fe30b1b80368bcd01f61905035d2646b42e397d1e01e013","block_height":18677813,"tokens":"USD Coin, Axelar, Wrapped Ether, USD Coin","from":"0x1264f83b093abbf840ea80a361988d19c7f5a686","to":"0xb0bababe78a9be0810fadf99dd2ed31ed12568be","tx_fee":0.010304722319872757,"gas_price":37762004353,"tx_type":"PartialSandwich","profit":166.31293304898534,"cost":21.198843558792365,"revenue":187.51177660777773,"created_at":"2023-11-29T16:17:11Z"},{"result_id":"0xfcdf6d9c98e100f032aa95d8a13be9cf7c4d4d37330c005b12ce0838319b604d","hash":"0x7c272e07d59f3c746fe30b1b80368bcd01f61905035d2646b42e397d1e01e013","block_height":18677813,"tokens":"USD Coin, Axelar, Wrapped Ether, USD Coin","from":"0x1264f83b093abbf840ea80a361988d19c7f5a686","to":"0xb0bababe78a9be0810fadf99dd2ed31ed12568be","tx_fee":0.010304722319872757,"gas_price":37762004353,"tx_type":"PartialSandwich","profit":166.31293304898534,"cost":21.198843558792365,"revenue":187.51177660777773,"created_at":"2023-11-29T16:17:11Z"},]},
      diagramsTransactionsTypes: [],
      diagramsTransactionsTokens: [],
      transactions: [],
    }
  },

  methods: {
    sendRequest(endpoint) {
      const url = process.env.VUE_APP_API_URL;

      return new Promise((resolve, reject) => {
        fetch(url+endpoint, {
          method: "get",
        })
        .then(response => response.json())
        .then(data => {
          resolve(data)
        })
        .catch(error => reject(error));
      })
    },

    getData() {
      const current = new Date();

      const previous = new Date(current);
      previous.setMonth(current.getMonth() - 1);

      // Extract year, month, and day from the new date
      const previousYear = previous.getFullYear();
      const previousMonth = String(previous.getMonth() + 1).padStart(2, '0');
      const previousDay = String(previous.getDate()).padStart(2, '0');

      // Create the formatted date string
      const formattedPreviousMonth = `${previousYear}-${previousMonth}-${previousDay}`;

      // Extract year, month, and day
      const year = current.getFullYear();
      const month = String(current.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(current.getDate()).padStart(2, '0');
      const currentDate = `${year}-${month}-${day}`;

      this.sendRequest(`diagrams/transactions/types?from=${formattedPreviousMonth}&to=${currentDate}&limit=200`).then(result => {
        this.diagramsTransactionsTypes = result
      });

      this.sendRequest(`diagrams/transactions/tokens?from=${formattedPreviousMonth}&to=${currentDate}&limit=200`).then(result => {
        this.diagramsTransactionsTokens = result
      });

      this.sendRequest(`transactions?limit=30`).then(result => {
        this.transactions = result;
      });
    }
  },

  mounted() {
    this.$emit('pageUpdate', true);

    this.getData();
  },

  unmounted() {
    this.$emit('pageUpdate', false)
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  },
}
</script>

<style scoped lang="scss">
.list {
  width: 100%;
  // min-width: 36em;
  min-width: 78em;
  color: #ffffff;
  max-height: 42em;
  overflow: auto;
  padding-right: 1em;

  &::-webkit-scrollbar {
    width: em(4);
    transform: translateX(em(10));
  }
  &::-webkit-scrollbar-track {
    background: rgba(1, 255, 194, 0.2);
    border-radius: em(10);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #01ffc2;
    border-radius: em(10);
    border: 0;
  }

  &__wrap {
    width: 100%;
  }

  &__head {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    margin-bottom: 1em;
    padding: 0 .8em;

    &_item {
      &:last-child {
        text-align: right;
      }
    }
  }
}

.my-team__statisticks_team_items {
  margin-bottom: 0;
}

.my-team__statisticks_team_item {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.mobileScreen {
  @media (max-width: 1024px) {
    .my-team__statisticks_team_item {
      overflow: auto;
      width: 100%;
      align-items: flex-start;
    }

    .w-50 {
      width: 100%;
    }
  }
}

.button-main {
  margin-bottom: .5em;
}

.my-team__statisticks_block {
  width: 97%;
}

.my-team__main_section.full .my-team__statisticks_block {
  width: 100%;
}

.green {
  color: #01ffc2;
}
</style>
