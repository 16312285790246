<template>
  <div class="settings__item_ffa tab-content" id="tab_3">
    <div class="settings__item_pass_heading">
      <span>{{t('settings.g2fa.setting_title')}}</span>
    </div>

    <template  v-if="gaStatus === true">
      <div class="settings__item_pass_text">
        <span v-html="t('settings.g2fa.turn_the_switch')"></span>
      </div>
      <div class="settings__switcher_block" @change="change2faStatus">
        <span>{{t('settings.g2fa.on')}}</span>
        <label class="switch">
          <input type="checkbox" :checked="!gaStatus"><span class="slider round"></span>
        </label>
        <span>{{t('settings.g2fa.off')}}</span>
      </div>
      
      <CheckboxesForm @openPopup="data => $emit('openPopup', data)" />
    </template>

    <TwoFASteps v-if="gaStatus === false" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TwoFASteps from "./g2fa/TwoFASteps.vue"
import { useI18n } from 'vue-i18n'
import CheckboxesForm from './g2fa/CheckboxesForm.vue';

export default {
  components: {
    TwoFASteps,
    CheckboxesForm
  },

  data() {
    return {
      gaActivation: false,

      gaCode: '',
      gaCodeValidate: null
    }
  },

  computed: {
    ...mapState({
      gaStatus: state => state?.google_authenticator?.googleAuthenticator?.status === 1
    })
  },

  methods: {
    change2faStatus() {
      if (this.gaStatus === true) {
        this.$store.dispatch('get2aDeactivate').then(response => {
          console.log(response);
          this.$emit('openPopup', {name: 'popupConfirmation', confirmationId: response.confirmationId})
        })
      }

      this.$store.dispatch('get2aInfo')
    }
  },
  
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  }
}
</script>