<template>
  <popup-template popupName="new-password" @closePopup="$emit('closePopup')">
    <div class="popups__heading">
      <span>{{t('popups.new_password.title')}}</span>
    </div>
    <form class="form" id="signinForm" action="#">
      <div class="popups__wallet_adress_block">
        <div class="form__wrap popups__wallet_for_whom_summ" 
          :class="{ valid: password1Valide === true }">
          <span>{{t('popups.new_password.new_password')}}</span>
          <div class="popups__wallet_input_block code">
            <input
              class="wallet-input"
              id="newPassword"
              type="password"
              name="newPassword"
              placeholder="********"
              autocomplete="new-password"
              v-model="password1"
            />
            <div class="error-item" :class="{ active: password1Valide === false }">
              <img src="img/home/err.png" alt="" />
              <span>{{t('popups.new_password.invalid_password')}}</span>
            </div>
          </div>
        </div>
        <div class="form__wrap popups__wallet_for_whom_summ" :class="{ valid: password2Valide === true }">
          <span>{{t('popups.new_password.repeat_password')}}</span>
          <div class="popups__wallet_input_block code">
            <input
              class="wallet-input"
              id="newPasswordRepeat"
              type="password"
              name="newPasswordRepeat"
              placeholder="********"
              v-model="password2"
            />
            <div class="error-item" :class="{ active: password2Valide === false }">
              <img src="img/home/err.png" alt="" />
              <span>{{t('popups.new_password.invalid_password')}}</span>
            </div>
          </div>
        </div>
      </div>
      <button class="popups__btn_withdraw lost-email-btn-code close" @click.prevent="setNewPassword">
        <span>{{t('popups.new_password.send')}}</span>
      </button>
      <div class="error-item" :class="{ active: error === true }">
        <img src="img/home/err.png" alt="" /><span>{{ errorMessage }}</span>
      </div>
    </form>
  </popup-template>
</template>

<script>
import PopupTemplate from "./PopupTemplate";
import { useI18n } from 'vue-i18n'

export default {
  components: {
    PopupTemplate,
  },
  data() {
    return {
      password1: "",
      password2: "",

      password1Valide: null,
      password2Valide: null,

      error: false,
      errorMessage: 'invalid argument',
    };
  },

  methods: {
    resetError() {
      this.error = false;
    },

    validatePassword(value) {
      const noSpacesRegex = /^[^\s]+(\s+[^\s]+)*$/;

      return noSpacesRegex.test(value) && value.length >= 8 && value.length <= 64;
    },

    setNewPassword() {
      const passwordsValide = this.password1Valide && this.password2Valide;
      const passwordsMatch = this.password1 === this.password2;

      if (!(passwordsValide && passwordsMatch)) {
        this.error = true;
        return; 
      }

      const args = {
        email: localStorage.getItem('forgetFormEMAIL'),
        password: this.password1
      }

      this.$store.dispatch('passwordRecovery', {...args})
        .then((response) => {
          if (response.accessToken !== '' && response.accessToken !== undefined) {
            localStorage.setItem("access_token", response.accessToken);
          }

          switch(response.status) {
            case 1:
              // to confirmation
              this.$emit('openPopup', {name: 'PopupConfirmationGuest', confirmationId: response.confirmation.confirmationId});
              break;
            case 0:
              // to dashboard
              localStorage.setItem('userAuth', true);
              this.$router.go('/');
              break;
          }
      }).catch((err) => {
          console.log(err);
        this.error = true;
        this.errorMessage = err.message;
      })
    },
  },

  watch: {
    password1(value) {
      this.password1Valide = this.validatePassword(value);
      this.error && this.resetError();
    },

    password2(value) {
      this.password2Valide = this.validatePassword(value) && value === this.password1;
      this.error && this.resetError();
    },
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  }
};
</script>