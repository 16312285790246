// source: mlmbox/type/google_authenticator.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.mlmbox.type.GoogleAuthenticator', null, global);
goog.exportSymbol('proto.mlmbox.type.GoogleAuthenticator.SecretCode', null, global);
goog.exportSymbol('proto.mlmbox.type.GoogleAuthenticator.Status', null, global);
goog.exportSymbol('proto.mlmbox.type.GoogleAuthenticator.Status.ID', null, global);
goog.exportSymbol('proto.mlmbox.type.GoogleAuthenticator.UsageSettings', null, global);
goog.exportSymbol('proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field', null, global);
goog.exportSymbol('proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest', null, global);
goog.exportSymbol('proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field', null, global);
goog.exportSymbol('proto.mlmbox.type.GoogleAuthenticator.VerifiedCode', null, global);
goog.exportSymbol('proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status', null, global);
goog.exportSymbol('proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status.ID', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.GoogleAuthenticator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.GoogleAuthenticator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.GoogleAuthenticator.displayName = 'proto.mlmbox.type.GoogleAuthenticator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.GoogleAuthenticator.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.GoogleAuthenticator.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.GoogleAuthenticator.Status.displayName = 'proto.mlmbox.type.GoogleAuthenticator.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.GoogleAuthenticator.SecretCode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.GoogleAuthenticator.SecretCode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.GoogleAuthenticator.SecretCode.displayName = 'proto.mlmbox.type.GoogleAuthenticator.SecretCode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.GoogleAuthenticator.VerifiedCode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.displayName = 'proto.mlmbox.type.GoogleAuthenticator.VerifiedCode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status.displayName = 'proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.type.GoogleAuthenticator.UsageSettings.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.type.GoogleAuthenticator.UsageSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.GoogleAuthenticator.UsageSettings.displayName = 'proto.mlmbox.type.GoogleAuthenticator.UsageSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.displayName = 'proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field.displayName = 'proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field.displayName = 'proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.GoogleAuthenticator.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.GoogleAuthenticator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.GoogleAuthenticator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.GoogleAuthenticator.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    modifiedAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    usageSetting: (f = msg.getUsageSetting()) && proto.mlmbox.type.GoogleAuthenticator.UsageSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.GoogleAuthenticator}
 */
proto.mlmbox.type.GoogleAuthenticator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.GoogleAuthenticator;
  return proto.mlmbox.type.GoogleAuthenticator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.GoogleAuthenticator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.GoogleAuthenticator}
 */
proto.mlmbox.type.GoogleAuthenticator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.mlmbox.type.GoogleAuthenticator.Status.ID} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModifiedAt(value);
      break;
    case 3:
      var value = new proto.mlmbox.type.GoogleAuthenticator.UsageSettings;
      reader.readMessage(value,proto.mlmbox.type.GoogleAuthenticator.UsageSettings.deserializeBinaryFromReader);
      msg.setUsageSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.GoogleAuthenticator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.GoogleAuthenticator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.GoogleAuthenticator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.GoogleAuthenticator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getModifiedAt();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getUsageSetting();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mlmbox.type.GoogleAuthenticator.UsageSettings.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.GoogleAuthenticator.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.GoogleAuthenticator.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.GoogleAuthenticator.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.GoogleAuthenticator.Status.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.GoogleAuthenticator.Status}
 */
proto.mlmbox.type.GoogleAuthenticator.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.GoogleAuthenticator.Status;
  return proto.mlmbox.type.GoogleAuthenticator.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.GoogleAuthenticator.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.GoogleAuthenticator.Status}
 */
proto.mlmbox.type.GoogleAuthenticator.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.mlmbox.type.GoogleAuthenticator.Status.ID} */ (reader.readEnum());
      msg.setStatusId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.GoogleAuthenticator.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.GoogleAuthenticator.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.GoogleAuthenticator.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.GoogleAuthenticator.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.mlmbox.type.GoogleAuthenticator.Status.ID = {
  DEACTIVATED: 0,
  ACTIVATED: 1,
  REQUESTED: 2
};

/**
 * optional ID status_id = 1;
 * @return {!proto.mlmbox.type.GoogleAuthenticator.Status.ID}
 */
proto.mlmbox.type.GoogleAuthenticator.Status.prototype.getStatusId = function() {
  return /** @type {!proto.mlmbox.type.GoogleAuthenticator.Status.ID} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.mlmbox.type.GoogleAuthenticator.Status.ID} value
 * @return {!proto.mlmbox.type.GoogleAuthenticator.Status} returns this
 */
proto.mlmbox.type.GoogleAuthenticator.Status.prototype.setStatusId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.GoogleAuthenticator.SecretCode.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.GoogleAuthenticator.SecretCode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.GoogleAuthenticator.SecretCode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.GoogleAuthenticator.SecretCode.toObject = function(includeInstance, msg) {
  var f, obj = {
    secretCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.GoogleAuthenticator.SecretCode}
 */
proto.mlmbox.type.GoogleAuthenticator.SecretCode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.GoogleAuthenticator.SecretCode;
  return proto.mlmbox.type.GoogleAuthenticator.SecretCode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.GoogleAuthenticator.SecretCode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.GoogleAuthenticator.SecretCode}
 */
proto.mlmbox.type.GoogleAuthenticator.SecretCode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecretCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.GoogleAuthenticator.SecretCode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.GoogleAuthenticator.SecretCode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.GoogleAuthenticator.SecretCode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.GoogleAuthenticator.SecretCode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSecretCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string secret_code = 1;
 * @return {string}
 */
proto.mlmbox.type.GoogleAuthenticator.SecretCode.prototype.getSecretCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.GoogleAuthenticator.SecretCode} returns this
 */
proto.mlmbox.type.GoogleAuthenticator.SecretCode.prototype.setSecretCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.toObject = function(includeInstance, msg) {
  var f, obj = {
    verifiedCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    usageSetting: (f = msg.getUsageSetting()) && proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode}
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.GoogleAuthenticator.VerifiedCode;
  return proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode}
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVerifiedCode(value);
      break;
    case 2:
      var value = new proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest;
      reader.readMessage(value,proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.deserializeBinaryFromReader);
      msg.setUsageSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVerifiedCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsageSetting();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    result: (f = msg.getResult()) && proto.mlmbox.type.GoogleAuthenticator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status}
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status;
  return proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status}
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status.ID} */ (reader.readEnum());
      msg.setStatusId(value);
      break;
    case 2:
      var value = new proto.mlmbox.type.GoogleAuthenticator;
      reader.readMessage(value,proto.mlmbox.type.GoogleAuthenticator.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mlmbox.type.GoogleAuthenticator.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status.ID = {
  UNKNOWN: 0,
  APPROVED: 1,
  REJECTED: 2,
  WRONG_CODE: 3
};

/**
 * optional ID status_id = 1;
 * @return {!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status.ID}
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status.prototype.getStatusId = function() {
  return /** @type {!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status.ID} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status.ID} value
 * @return {!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status} returns this
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status.prototype.setStatusId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional GoogleAuthenticator result = 2;
 * @return {?proto.mlmbox.type.GoogleAuthenticator}
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status.prototype.getResult = function() {
  return /** @type{?proto.mlmbox.type.GoogleAuthenticator} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.type.GoogleAuthenticator, 2));
};


/**
 * @param {?proto.mlmbox.type.GoogleAuthenticator|undefined} value
 * @return {!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status} returns this
*/
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status} returns this
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.Status.prototype.hasResult = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string verified_code = 1;
 * @return {string}
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.prototype.getVerifiedCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode} returns this
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.prototype.setVerifiedCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UsageSettings.UpdateRequest usage_setting = 2;
 * @return {?proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest}
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.prototype.getUsageSetting = function() {
  return /** @type{?proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest, 2));
};


/**
 * @param {?proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest|undefined} value
 * @return {!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode} returns this
*/
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.prototype.setUsageSetting = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.GoogleAuthenticator.VerifiedCode} returns this
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.prototype.clearUsageSetting = function() {
  return this.setUsageSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.GoogleAuthenticator.VerifiedCode.prototype.hasUsageSetting = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.GoogleAuthenticator.UsageSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.GoogleAuthenticator.UsageSettings;
  return proto.mlmbox.type.GoogleAuthenticator.UsageSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field;
      reader.readMessage(value,proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.GoogleAuthenticator.UsageSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest;
  return proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field;
      reader.readMessage(value,proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field;
  return proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFieldId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getValue();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional uint32 field_id = 1;
 * @return {number}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field.prototype.getFieldId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field} returns this
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool value = 2;
 * @return {boolean}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field.prototype.getValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field} returns this
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field.prototype.setValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated Field fields = 1;
 * @return {!Array<!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field>}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field, 1));
};


/**
 * @param {!Array<!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field>} value
 * @return {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest} returns this
*/
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.Field, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest} returns this
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.UpdateRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fieldName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    defaultValue: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    currentValue: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    editable: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field;
  return proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFieldId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefaultValue(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCurrentValue(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEditable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDefaultValue();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCurrentValue();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getEditable();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional uint32 field_id = 1;
 * @return {number}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field.prototype.getFieldId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field} returns this
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string field_name = 2;
 * @return {string}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field} returns this
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool default_value = 3;
 * @return {boolean}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field.prototype.getDefaultValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field} returns this
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field.prototype.setDefaultValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool current_value = 4;
 * @return {boolean}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field.prototype.getCurrentValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field} returns this
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field.prototype.setCurrentValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool editable = 5;
 * @return {boolean}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field.prototype.getEditable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field} returns this
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field.prototype.setEditable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated Field fields = 1;
 * @return {!Array<!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field>}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field, 1));
};


/**
 * @param {!Array<!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field>} value
 * @return {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings} returns this
*/
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field}
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mlmbox.type.GoogleAuthenticator.UsageSettings.Field, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.type.GoogleAuthenticator.UsageSettings} returns this
 */
proto.mlmbox.type.GoogleAuthenticator.UsageSettings.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional Status.ID status = 1;
 * @return {!proto.mlmbox.type.GoogleAuthenticator.Status.ID}
 */
proto.mlmbox.type.GoogleAuthenticator.prototype.getStatus = function() {
  return /** @type {!proto.mlmbox.type.GoogleAuthenticator.Status.ID} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.mlmbox.type.GoogleAuthenticator.Status.ID} value
 * @return {!proto.mlmbox.type.GoogleAuthenticator} returns this
 */
proto.mlmbox.type.GoogleAuthenticator.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 modified_at = 2;
 * @return {number}
 */
proto.mlmbox.type.GoogleAuthenticator.prototype.getModifiedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.GoogleAuthenticator} returns this
 */
proto.mlmbox.type.GoogleAuthenticator.prototype.setModifiedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional UsageSettings usage_setting = 3;
 * @return {?proto.mlmbox.type.GoogleAuthenticator.UsageSettings}
 */
proto.mlmbox.type.GoogleAuthenticator.prototype.getUsageSetting = function() {
  return /** @type{?proto.mlmbox.type.GoogleAuthenticator.UsageSettings} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.type.GoogleAuthenticator.UsageSettings, 3));
};


/**
 * @param {?proto.mlmbox.type.GoogleAuthenticator.UsageSettings|undefined} value
 * @return {!proto.mlmbox.type.GoogleAuthenticator} returns this
*/
proto.mlmbox.type.GoogleAuthenticator.prototype.setUsageSetting = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.GoogleAuthenticator} returns this
 */
proto.mlmbox.type.GoogleAuthenticator.prototype.clearUsageSetting = function() {
  return this.setUsageSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.GoogleAuthenticator.prototype.hasUsageSetting = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.mlmbox.type);
