<template>
  <div>
    <canvas ref="canvas" width="400" height="400" class="graph-rive"></canvas>
  </div>
</template>

<script>
import { Rive, Layout } from '@rive-app/canvas';
// import {Rive} from "@rive-app/canvas";


let riveAnimation;

export default {
  name: 'GraphRive',

  props: {
    src: String,
    fit: String,
    alignment: String
  },

  mounted() {
    riveAnimation = new Rive({
      canvas: this.$refs.canvas,
      src: "rive/wait.riv",
      layout: new Layout({
          fit: this.$props.fit,
          alignment: this.$props.alignment,
      }),
      autoplay: true,
      onLoad: () => {
        riveAnimation?.resizeDrawingSurfaceToCanvas()
      },
    })
  },

  unmounted() {
    if (riveAnimation !== undefined && riveAnimation !== null) {
      // console.log(riveAnimation);
      riveAnimation?.cleanupInstances(); // Остановить анимацию

      riveAnimation = null; // Очистить ссылку на анимацию
    }
  }
}
</script>
<style scoped>
.graph-rive {
  display: block;
  margin: 1em auto;
  width: 6em;
}
</style>
