<template>
    <component :is="popupName ? popupName : 'PopupDoneAuth'" :popupOpen="popupOpen" 
        :data="data !== undefined ? data : null"
        @closePopup="$emit('closePopup')" 
        @openPopup="data => $emit('openPopup', data)" 
        @rerenderHeader="$emit('rerenderHeader')" 
    />
</template>

<script>
import PopupCustomBuy from "./PopupCustomBuy.vue";
import PopupDoneAuth from "./PopupDoneAuth.vue";
import PopupLostPassword from "./PopupLostPassword.vue";
import PopupConfirmation from "./PopupConfirmation.vue";
import PopupConfirmationEmail from "./PopupConfirmationEmail.vue";
import PopupConfirmationGuest from "./PopupConfirmationGuest.vue";
import PopupNewPassword from "./PopupNewPassword.vue";
import PopupAuth from "./PopupAuth.vue";
import PopupWalletDeposit from "./PopupWalletDeposit.vue";
import PopupWalletWithdraw from "./PopupWalletWithdraw.vue";
import PopupVideo from "./PopupVideo.vue";
import PopupDeactivateContract from "./PopupDeactivateContract.vue";
import PopupSuccess from "./PopupSuccess.vue";
import PopupWalletTkzBuy from "./PopupWalletTkzBuy.vue";

export default {
    props: ['popupOpen', 'popupName', 'data'],

    components: {
        PopupCustomBuy,
        PopupDoneAuth,
        PopupLostPassword,
        PopupConfirmation,
        PopupConfirmationEmail,
        PopupConfirmationGuest,
        PopupNewPassword,
        PopupAuth,
        PopupWalletDeposit,
        PopupWalletWithdraw,
        PopupVideo,
        PopupDeactivateContract,
        PopupSuccess,
        PopupWalletTkzBuy,
    },
}
</script>