// source: mlmbox/type/binary.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var mlmbox_type_account_pb = require('../../mlmbox/type/account_pb.js');
goog.object.extend(proto, mlmbox_type_account_pb);
goog.exportSymbol('proto.mlmbox.type.Binary', null, global);
goog.exportSymbol('proto.mlmbox.type.Binary.Position', null, global);
goog.exportSymbol('proto.mlmbox.type.Binary.Position.ID', null, global);
goog.exportSymbol('proto.mlmbox.type.Binary.Position.Line', null, global);
goog.exportSymbol('proto.mlmbox.type.Binary.Position.Settings', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Binary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.type.Binary.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.type.Binary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Binary.displayName = 'proto.mlmbox.type.Binary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Binary.Position = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.type.Binary.Position.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.type.Binary.Position, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Binary.Position.displayName = 'proto.mlmbox.type.Binary.Position';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Binary.Position.ID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Binary.Position.ID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Binary.Position.ID.displayName = 'proto.mlmbox.type.Binary.Position.ID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Binary.Position.Line = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Binary.Position.Line, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Binary.Position.Line.displayName = 'proto.mlmbox.type.Binary.Position.Line';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Binary.Position.Settings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Binary.Position.Settings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Binary.Position.Settings.displayName = 'proto.mlmbox.type.Binary.Position.Settings';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.type.Binary.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Binary.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Binary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Binary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Binary.toObject = function(includeInstance, msg) {
  var f, obj = {
    selfPositionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    viewPositionId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    positionsList: jspb.Message.toObjectList(msg.getPositionsList(),
    proto.mlmbox.type.Binary.Position.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Binary}
 */
proto.mlmbox.type.Binary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Binary;
  return proto.mlmbox.type.Binary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Binary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Binary}
 */
proto.mlmbox.type.Binary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelfPositionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setViewPositionId(value);
      break;
    case 3:
      var value = new proto.mlmbox.type.Binary.Position;
      reader.readMessage(value,proto.mlmbox.type.Binary.Position.deserializeBinaryFromReader);
      msg.addPositions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Binary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Binary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Binary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Binary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelfPositionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getViewPositionId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPositionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.mlmbox.type.Binary.Position.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.type.Binary.Position.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Binary.Position.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Binary.Position.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Binary.Position} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Binary.Position.toObject = function(includeInstance, msg) {
  var f, obj = {
    positionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    positionRefId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    positionRefLine: jspb.Message.getFieldWithDefault(msg, 3, 0),
    positionWidth: jspb.Message.getFieldWithDefault(msg, 4, 0),
    positionLevel: jspb.Message.getFieldWithDefault(msg, 5, 0),
    settings: (f = msg.getSettings()) && proto.mlmbox.type.Binary.Position.Settings.toObject(includeInstance, f),
    account: (f = msg.getAccount()) && mlmbox_type_account_pb.Account.ShortInfo.toObject(includeInstance, f),
    linesList: jspb.Message.toObjectList(msg.getLinesList(),
    proto.mlmbox.type.Binary.Position.Line.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Binary.Position}
 */
proto.mlmbox.type.Binary.Position.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Binary.Position;
  return proto.mlmbox.type.Binary.Position.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Binary.Position} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Binary.Position}
 */
proto.mlmbox.type.Binary.Position.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositionRefId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositionRefLine(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositionWidth(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositionLevel(value);
      break;
    case 6:
      var value = new proto.mlmbox.type.Binary.Position.Settings;
      reader.readMessage(value,proto.mlmbox.type.Binary.Position.Settings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    case 7:
      var value = new mlmbox_type_account_pb.Account.ShortInfo;
      reader.readMessage(value,mlmbox_type_account_pb.Account.ShortInfo.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 8:
      var value = new proto.mlmbox.type.Binary.Position.Line;
      reader.readMessage(value,proto.mlmbox.type.Binary.Position.Line.deserializeBinaryFromReader);
      msg.addLines(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Binary.Position.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Binary.Position.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Binary.Position} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Binary.Position.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPositionRefId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPositionRefLine();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getPositionWidth();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getPositionLevel();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.mlmbox.type.Binary.Position.Settings.serializeBinaryToWriter
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      mlmbox_type_account_pb.Account.ShortInfo.serializeBinaryToWriter
    );
  }
  f = message.getLinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.mlmbox.type.Binary.Position.Line.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Binary.Position.ID.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Binary.Position.ID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Binary.Position.ID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Binary.Position.ID.toObject = function(includeInstance, msg) {
  var f, obj = {
    viewPositionId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Binary.Position.ID}
 */
proto.mlmbox.type.Binary.Position.ID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Binary.Position.ID;
  return proto.mlmbox.type.Binary.Position.ID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Binary.Position.ID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Binary.Position.ID}
 */
proto.mlmbox.type.Binary.Position.ID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setViewPositionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Binary.Position.ID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Binary.Position.ID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Binary.Position.ID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Binary.Position.ID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getViewPositionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 view_position_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Binary.Position.ID.prototype.getViewPositionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Binary.Position.ID} returns this
 */
proto.mlmbox.type.Binary.Position.ID.prototype.setViewPositionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Binary.Position.Line.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Binary.Position.Line.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Binary.Position.Line} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Binary.Position.Line.toObject = function(includeInstance, msg) {
  var f, obj = {
    positionLine: jspb.Message.getFieldWithDefault(msg, 1, 0),
    balanceValue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    turnoverValue: jspb.Message.getFieldWithDefault(msg, 3, ""),
    positionsQuantity: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Binary.Position.Line}
 */
proto.mlmbox.type.Binary.Position.Line.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Binary.Position.Line;
  return proto.mlmbox.type.Binary.Position.Line.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Binary.Position.Line} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Binary.Position.Line}
 */
proto.mlmbox.type.Binary.Position.Line.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositionLine(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBalanceValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTurnoverValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositionsQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Binary.Position.Line.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Binary.Position.Line.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Binary.Position.Line} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Binary.Position.Line.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositionLine();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBalanceValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTurnoverValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPositionsQuantity();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional uint32 position_line = 1;
 * @return {number}
 */
proto.mlmbox.type.Binary.Position.Line.prototype.getPositionLine = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Binary.Position.Line} returns this
 */
proto.mlmbox.type.Binary.Position.Line.prototype.setPositionLine = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string balance_value = 2;
 * @return {string}
 */
proto.mlmbox.type.Binary.Position.Line.prototype.getBalanceValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Binary.Position.Line} returns this
 */
proto.mlmbox.type.Binary.Position.Line.prototype.setBalanceValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string turnover_value = 3;
 * @return {string}
 */
proto.mlmbox.type.Binary.Position.Line.prototype.getTurnoverValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Binary.Position.Line} returns this
 */
proto.mlmbox.type.Binary.Position.Line.prototype.setTurnoverValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 positions_quantity = 4;
 * @return {number}
 */
proto.mlmbox.type.Binary.Position.Line.prototype.getPositionsQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Binary.Position.Line} returns this
 */
proto.mlmbox.type.Binary.Position.Line.prototype.setPositionsQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Binary.Position.Settings.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Binary.Position.Settings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Binary.Position.Settings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Binary.Position.Settings.toObject = function(includeInstance, msg) {
  var f, obj = {
    userSide: jspb.Message.getFieldWithDefault(msg, 1, 0),
    systemSide: jspb.Message.getFieldWithDefault(msg, 2, 0),
    done: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Binary.Position.Settings}
 */
proto.mlmbox.type.Binary.Position.Settings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Binary.Position.Settings;
  return proto.mlmbox.type.Binary.Position.Settings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Binary.Position.Settings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Binary.Position.Settings}
 */
proto.mlmbox.type.Binary.Position.Settings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserSide(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSystemSide(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Binary.Position.Settings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Binary.Position.Settings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Binary.Position.Settings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Binary.Position.Settings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserSide();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getSystemSide();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDone();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional uint32 user_side = 1;
 * @return {number}
 */
proto.mlmbox.type.Binary.Position.Settings.prototype.getUserSide = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Binary.Position.Settings} returns this
 */
proto.mlmbox.type.Binary.Position.Settings.prototype.setUserSide = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 system_side = 2;
 * @return {number}
 */
proto.mlmbox.type.Binary.Position.Settings.prototype.getSystemSide = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Binary.Position.Settings} returns this
 */
proto.mlmbox.type.Binary.Position.Settings.prototype.setSystemSide = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool done = 3;
 * @return {boolean}
 */
proto.mlmbox.type.Binary.Position.Settings.prototype.getDone = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mlmbox.type.Binary.Position.Settings} returns this
 */
proto.mlmbox.type.Binary.Position.Settings.prototype.setDone = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional uint32 position_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Binary.Position.prototype.getPositionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Binary.Position} returns this
 */
proto.mlmbox.type.Binary.Position.prototype.setPositionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 position_ref_id = 2;
 * @return {number}
 */
proto.mlmbox.type.Binary.Position.prototype.getPositionRefId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Binary.Position} returns this
 */
proto.mlmbox.type.Binary.Position.prototype.setPositionRefId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 position_ref_line = 3;
 * @return {number}
 */
proto.mlmbox.type.Binary.Position.prototype.getPositionRefLine = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Binary.Position} returns this
 */
proto.mlmbox.type.Binary.Position.prototype.setPositionRefLine = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 position_width = 4;
 * @return {number}
 */
proto.mlmbox.type.Binary.Position.prototype.getPositionWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Binary.Position} returns this
 */
proto.mlmbox.type.Binary.Position.prototype.setPositionWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 position_level = 5;
 * @return {number}
 */
proto.mlmbox.type.Binary.Position.prototype.getPositionLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Binary.Position} returns this
 */
proto.mlmbox.type.Binary.Position.prototype.setPositionLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional Settings settings = 6;
 * @return {?proto.mlmbox.type.Binary.Position.Settings}
 */
proto.mlmbox.type.Binary.Position.prototype.getSettings = function() {
  return /** @type{?proto.mlmbox.type.Binary.Position.Settings} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.type.Binary.Position.Settings, 6));
};


/**
 * @param {?proto.mlmbox.type.Binary.Position.Settings|undefined} value
 * @return {!proto.mlmbox.type.Binary.Position} returns this
*/
proto.mlmbox.type.Binary.Position.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Binary.Position} returns this
 */
proto.mlmbox.type.Binary.Position.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Binary.Position.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Account.ShortInfo account = 7;
 * @return {?proto.mlmbox.type.Account.ShortInfo}
 */
proto.mlmbox.type.Binary.Position.prototype.getAccount = function() {
  return /** @type{?proto.mlmbox.type.Account.ShortInfo} */ (
    jspb.Message.getWrapperField(this, mlmbox_type_account_pb.Account.ShortInfo, 7));
};


/**
 * @param {?proto.mlmbox.type.Account.ShortInfo|undefined} value
 * @return {!proto.mlmbox.type.Binary.Position} returns this
*/
proto.mlmbox.type.Binary.Position.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Binary.Position} returns this
 */
proto.mlmbox.type.Binary.Position.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Binary.Position.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated Line lines = 8;
 * @return {!Array<!proto.mlmbox.type.Binary.Position.Line>}
 */
proto.mlmbox.type.Binary.Position.prototype.getLinesList = function() {
  return /** @type{!Array<!proto.mlmbox.type.Binary.Position.Line>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.type.Binary.Position.Line, 8));
};


/**
 * @param {!Array<!proto.mlmbox.type.Binary.Position.Line>} value
 * @return {!proto.mlmbox.type.Binary.Position} returns this
*/
proto.mlmbox.type.Binary.Position.prototype.setLinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.mlmbox.type.Binary.Position.Line=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.Binary.Position.Line}
 */
proto.mlmbox.type.Binary.Position.prototype.addLines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.mlmbox.type.Binary.Position.Line, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.type.Binary.Position} returns this
 */
proto.mlmbox.type.Binary.Position.prototype.clearLinesList = function() {
  return this.setLinesList([]);
};


/**
 * optional uint32 self_position_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Binary.prototype.getSelfPositionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Binary} returns this
 */
proto.mlmbox.type.Binary.prototype.setSelfPositionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 view_position_id = 2;
 * @return {number}
 */
proto.mlmbox.type.Binary.prototype.getViewPositionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Binary} returns this
 */
proto.mlmbox.type.Binary.prototype.setViewPositionId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated Position positions = 3;
 * @return {!Array<!proto.mlmbox.type.Binary.Position>}
 */
proto.mlmbox.type.Binary.prototype.getPositionsList = function() {
  return /** @type{!Array<!proto.mlmbox.type.Binary.Position>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.type.Binary.Position, 3));
};


/**
 * @param {!Array<!proto.mlmbox.type.Binary.Position>} value
 * @return {!proto.mlmbox.type.Binary} returns this
*/
proto.mlmbox.type.Binary.prototype.setPositionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.mlmbox.type.Binary.Position=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.Binary.Position}
 */
proto.mlmbox.type.Binary.prototype.addPositions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.mlmbox.type.Binary.Position, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.type.Binary} returns this
 */
proto.mlmbox.type.Binary.prototype.clearPositionsList = function() {
  return this.setPositionsList([]);
};


goog.object.extend(exports, proto.mlmbox.type);
