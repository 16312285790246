<template>
  <div class="home-2">
    <div class="heading_block">
      <div class="heading_block__heading">
        <span>{{ t("menu.Dashboard") }}</span>
      </div>
    </div>
    <div class="container">
      <div class="user">
        <div class="user__block">
          <div class="user__name">
            <span>{{userInfo?.login}}</span>
          </div>
          <div class="user__status">
            <span>{{ currentRankTitle }}</span>
          </div>
        </div>
        <div class="user__btn_block">
          <a href="https://t.me/tokenza_crypto_bot" class="button-main" target="_blank">
            <span>Telegram bot</span>
            <div class="button-main__gradient"></div>
          </a>

            <router-link to="/wallet" class="button-main">
            <span>{{t('header.myWallet')}}</span>
            <div class="button-main__gradient"></div>
          </router-link>

          <ButtonRefferal />
        </div>
      </div>
      <div class="home-2__main_block">
        <div class="home-2__main_block_1">
          <div class="home-2__main_team_block">
            <div class="home-2__main_team_item first">
              <div class="home-2__main_team_item_heading">
                <span>{{t('team.pageName')}}</span>
              </div>
              <div class="home-2__main_team_item_img">
                <BotRive />
                <!-- <img src="img/home/home-team.png" alt="" /> -->
              </div>
            </div>
            <div class="home-2__main_team_item second">
              <div class="home-2__main_team_item_heading_2">
                <span>{{t('home.direct_partners')}}</span>
              </div>
              <div class="home-2__main_team_item_text">
                <span>{{network?.account?.partnersStatistics?.quantity}}</span>
              </div>
            </div>
            <div class="home-2__main_team_item second">
              <div class="home-2__main_team_item_heading_2">
                <span>{{t('home.partners')}}</span>
              </div>
              <div class="home-2__main_team_item_text">
                <span>{{network?.account?.teamStatistics?.quantity}}</span>
              </div>
            </div>
            <div class="home-2__main_team_item third">
              <div class="home-2__main_team_item_heading_2">
                <span>{{t('base.turnover')}}</span>
              </div>
              <div class="home-2__main_team_item_text">
                <span>{{Number(network?.account?.teamStatistics?.total.turnover).toFixed(2)}} $</span>
              </div>
            </div>
          </div>
          <div class="home-2__main_plan_block">
            <div class="home-2__main_plan_heading">
              <span>{{t('home.buy_plans')}}</span>
            </div>

            <HomeSlider @openPopup="data => $emit('openPopup', data)" />

            <div class="home-2__main_plan_slide_prev">
              <img src="img/home/arrow.svg" alt="">
            </div>
            <div class="home-2__main_plan_slide_next">
              <img src="img/home/arrow.svg" alt="">
            </div>
          </div>
        </div>
        <div class="home-2__main_block_2">
          <div class="home-2__main_passive_block">
            <div class="home-2__main_passive_heading_block">
              <div class="home-2__main_passive_heading">
                <span>{{t('base.passive_income')}}</span>
              </div>
              <div class="home-2__main_passive__image">
                <div class="home-2__graph" v-if="bonusesIncomeToPercent?.month >= 0">
                  <CircleGraph :graphData="bonusesIncomeToPercent" />
                </div>
              </div>
            </div>
            <div class="home-2__main_passive_line">
              <div class="home-2__main_passive_line_text_1">
                <span>{{ t('base.per_month') }}</span>
              </div>
              <div class="home-2__main_passive_line_text_2">
                <span>{{ numberWithSpaces(setDecimalPrice(monthIncome))}} {{defaultAsset?.assetName}}</span>
              </div>
              <div class="home-2__main_passive_line_text_3">
                <div
                  class="home-2__main_passive_line_text_3_circle green"
                ></div>
                <!-- <span class="green"></span> -->
                <span>{{ t('base.per_month') }}</span>
              </div>
            </div>
            <div class="home-2__main_passive_line">
              <div class="home-2__main_passive_line_text_1">
                <span>{{ t('base.all_period') }}</span>
              </div>
              <div class="home-2__main_passive_line_text_2">
                <span>{{ numberWithSpaces(setDecimalPrice(monthIncome*24)) }} {{defaultAsset?.assetName}}</span>
              </div>
              <div class="home-2__main_passive_line_text_3">
                <div class="home-2__main_passive_line_text_3_circle pink"></div>
                <!-- <span class="green"></span> -->
                <span>{{ t('base.all_period') }}</span>
              </div>
            </div>
          </div>
          <div class="home-2__main_rank_block" @click="$router.push('/career')">
            <div class="home-2__main_rank_heading"><span>{{ t('home.rank') }}</span></div>
            <div class="home-2__main_rank_items_block">
              <div class="home-2__main_rank_item">
                <div class="home-2__main_rank_item_text_1 active">
                  <span>{{ currentRankTitle }}</span>
                </div>
                <div class="home-2__main_rank_item_text_2">
                  <span>{{ t('home.my_rank') }}</span>
                </div>
              </div>
              <div class="home-2__main_rank_item_arr">
                <img src="img/home/arr.png" alt="" />
              </div>
<!--              <div class="home-2__main_rank_item" v-if="!isMaxRank">-->
              <div class="home-2__main_rank_item" v-if="nextRankTitle">
                <div class="home-2__main_rank_item_text_1">
                  <span>{{ nextRankTitle }}</span>
                </div>
                <div class="home-2__main_rank_item_text_2">
                  <span>{{ t('home.next_rank') }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="home-2__main_earned_block">
            <div class="home-2__main_earned_heading_block">
              <div class="home-2__main_earned_heading">
                <span>{{t('base.total_earned')}}</span>
              </div>
              <div class="home-2__main_earned_heading_tax">
                <!-- <span>{{totalPassiveReferralEarned}} {{defaultAsset?.assetName}}</span> -->
                <span>{{totalEarnedValue}} {{defaultAsset?.assetName}}</span>
              </div>
            </div>
            <div class="home-2__main_earned_text">
              <span>{{ t('base.sources') }}</span>
            </div>
            <div :class="`home-2__main_earned_line persents-${Number(passiveIncome*100/totalPassiveReferralEarned).toFixed(2)}`"></div>
            <div class="home-2__main_earned_text_block">
              <div class="home-2__main_earned_text_2">
                <span>{{ t('base.passive') }}</span>
                <span>{{passiveIncome}} $</span>
              </div>
              <div class="home-2__main_earned_text_2">
                <span>{{ t('base.referral') }}</span>
                <span>{{referralIncome}} $</span>
              </div>
            </div>
          </div>

        </div>
      </div>
          <BottomRive />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { useI18n } from "vue-i18n";
import { transactions } from "@/mixins/transactions";
import ButtonRefferal from '@/components/UI/ButtonRefferal.vue'
import HomeSlider from '../../components/Home/HomeSlider.vue';
import CircleGraph from '../../components/Graph/CircleGraph.vue'

// import SpinnerRive from '@/components/RiveAnimations/SpinnerRive.vue'
import BotRive from '@/components/RiveAnimations/BotRive.vue'
import BottomRive from '@/components/RiveAnimations/BottomRive.vue'

export default {
  name: "HomePage",

  mixins: [transactions],

  components: {
    ButtonRefferal,
    HomeSlider,
    CircleGraph,

    BotRive,
    BottomRive
    // SpinnerRive,
    // AirdropRive,
  },

  data() {
    return {
      defaultStatus: '-'
    }
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

    return { t };
  },

  computed: {
    ...mapState({
      // данные пользователя
      userInfo: (state) => state.account.user.info,

      // активы
      defaultAsset: (state) => state.asset.defaultAsset,
      walletAssetsList: (state) => state.finance.walletAssetsList,

      // ранг
      careerInfo: (state) => state.career.info,

      farmingInfo: (state) => state.farming.info,
      network: (state) => state.network.network,
    }),

    dataLoaded() {
      return this.userInfo !== undefined && this.careerInfo !== undefined && this.farmingInfo !== undefined;
    },

    walletAsset() {
      return this.walletAssetsList?.find(a => a.assetId === this.defaultAsset.assetId);
    },

    bonusesList() {
      return this.walletAsset?.incomeBonus?.bonusesList;
    },

    totalEarnedValue() {
      return this.numberWithSpaces(Number(this.walletAsset?.incomeBonus?.totalEarnedValue).toFixed(2));
    },

    currentAssetInfo() {
      return this.farmingInfo?.assetsList?.find(a => a.assetId === this.defaultAsset.assetId);
    },

    activeContracts() {
      return this.currentAssetInfo?.contractsList?.filter(c => c.closed !== true)
    },

    monthIncome() {
      if (!this.activeContracts?.length) return 0;

      return this.setDecimalPrice(this.activeContracts?.reduce((sum, contract) => sum + Number(contract.incomePerDay)*30, 0))
    },

    currentRankTitle() {
      return this.careerInfo?.ranksList?.find(rank => this.careerInfo.currentRankId === rank.rankId)?.rankTitle ?? this.defaultStatus
    },

    nextRankTitle() {
      return this.careerInfo?.ranksList?.find(rank => this.careerInfo?.nextRankProcess?.rankId === rank.rankId)?.rankTitle
    },

    isMaxRank() {
      return this.careerInfo?.ranksList?.find(rank => this.careerInfo?.currentRankId === rank?.rankId) !== undefined;
    },

    passiveIncome() {
      // bonusFarming
      return this.setDecimalPrice(this.bonusesList?.find((b) => b.bonusId === 4).earnedValue)
    },

    referralIncome() {
      if (!this.bonusesList?.length) return 0;
      // было 4
      const bonus1 = this.bonusesList?.find((b) => b.bonusId === 0)?.earnedValue ?? 0;
      const bonus2 = this.bonusesList?.find((b) => b.bonusId === 1).earnedValue;
      const bonusRank = this.bonusesList?.find((b) => b.bonusId === 2).earnedValue;
      const bonusDirekt = this.bonusesList?.find((b) => b.bonusId === 3).earnedValue;
      const bonusBinary = this.bonusesList?.find((b) => b.bonusId === 5).earnedValue;
      const bonusBinaryMatching = this.bonusesList?.find((b) => b.bonusId === 6).earnedValue;

      return this.setDecimalPrice(Number(bonus1) + Number(bonus2) + Number(bonusRank) + Number(bonusDirekt) + Number(bonusBinary) + Number(bonusBinaryMatching))
    },

    rankIncome() {
      return this.setDecimalPrice(this.bonusesList?.find((b) => b.bonusId === 2).earnedValue)
    },

    totalPassiveReferralEarned() {
      return Number(this.passiveIncome) + Number(this.referralIncome);
    },

    homeFarmingInfo() {
      return {
        month: Number(this.monthIncome).toFixed(),
        allPeriod: Number(this.monthIncome*365*2).toFixed(),
      };
    },

    visibleGraph() {
      return this.bonusesList !== undefined;
    },

    bonusesIncomeToPercent() {
      const inputObj = { ...this.homeFarmingInfo };
      const objPercent = {};
      // const koef = 0
      const koef = 2

      objPercent.month = (Number(inputObj.month) * 100 / Number(inputObj.allPeriod))+koef;
      objPercent.allPeriod = 100 - objPercent.month;

      return this.visibleGraph === true ? objPercent : { passive: 0, referral: 0 };
    },
  },

  methods: {
    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    scrollToCases() {
      const element = this.$refs.cases;
      const y = element.getBoundingClientRect().top + window.scrollY;

      window.scroll({
        top: y,
        behavior: "smooth",
      });
    },

    isGraphDataEmpty(data) {
      return Object.values(data).every((v) => +v === 0);
    },

    checkBuyRoute() {
      if (this.$route.query.buy === null) {
        this.$emit('openPopup', { name: 'PopupCustomBuy' })
      }
    }
  },

  created() {
    this.checkBuyRoute();

    if (this.walletAssetsList.length === 0) {
      this.$store.dispatch("getWalletAssetsList");
    }

    if (this.userInfo === undefined)
      this.$store.dispatch("getAccountInfo").then((response) => {
        if (Object.keys(this.network).length === 0)
          this.$store.dispatch("getPartnerTeamInfo", response.login);
      });
    else if (Object.keys(this.network).length === 0)
      this.$store.dispatch("getPartnerTeamInfo", this.userInfo.login);

    if (Object.keys(this.farmingInfo).length === 0)
      this.$store.dispatch("getFarmingInfo");

    this.$store.dispatch("getCareerInfo");
    this.$store.dispatch('getAllAssets');
    this.$store.dispatch("getAssetPairList");
  },
};
</script>

<style lang="scss" scoped>
// .home-2__main_rank_item_text_1 {
//   cursor: auto !important;
// }

.home-2__main_earned_heading_tax span {
  color: #01ffc2
}

.mobileScreen {
  @media (max-width: 1024px) {
    .home-2__main_passive_line {
      &_text_3 {
        text-align: left;
        justify-content: flex-start;
        padding-left: 3.6em;
      }
    }
  }
}

.home-2__main_rank_block {
  cursor: pointer;
}


.home-2__main_plan_slide {
  &_prev {
    position: absolute;
    top: 50%;
    left: 4em;
    transform: translate(0, -50%);
    cursor: pointer;
    width: 6em;
    transition: opacity .2s ease-in-out;

    img {
      width: 100%;
    }

    &:hover {
      opacity: .7;
    }
  }

  &_next {
    position: absolute;
    top: 50%;
    right: 4em;
    transform: translate(0, -50%);
    cursor: pointer;
    width: 6em;
    transition: opacity .2s ease-in-out;

    img {
      width: 100%;
      transform: rotate(180deg) ;
    }

    &:hover {
      opacity: .7;
    }
  }
}

.user__btn_block {
    width: 60em;
}

@media (max-width: 1024px) and (orientation: portrait) {
  .mobileScreen {
    .home-2__main_plan_slide {
      &_prev {
        left: -0.5em;
        width: 4em;
      }

      &_next {
        right: -0.5em;
        transform: translate(0, -50%);
        cursor: pointer;
        width: 4em;
      }
    }
  }


    .user__btn_block {
        width: 60em;

        a {
            margin-bottom: 0.625em;
        }

        button {
            margin-top: 0 !important;
        }
    }
}

.home-2__main_team_item_text {
  width: 100%;

  span {
    font-size: 2.5em;
  }
}


</style>
