import { BinaryClient } from "../../../../apis/mlmbox/client/binary/v1/binary_grpc_web_pb";
import { InfoRequest, SearchRequest, SettingsSideSetRequest } from "../../../../apis/mlmbox/client/binary/v1/binary_pb";
import { Limit } from "../../../../apis/mlmbox/type/limit_pb";
// import { Empty } from 'google-protobuf/google/protobuf/empty_pb.js'
import { API_URL } from '@/api';

const client = new BinaryClient(API_URL, null, {
    withCredentials: true
});

export default {
    state: {
        info: {},
        binarySearchResults: [],
        settingsSide: [],
    },
  
    mutations: {
        setBinaryInfo(state, info) {
            state.info = info;
        },

        setBinarySearchResults(state, binarySearchResults) {
            state.binarySearchResults = binarySearchResults;
        },

        setSettingsSide(state, settingsSide) {
            state.settingsSide = settingsSide;
        },
    },
 
    actions: {
        getBinaryInfo({commit}, { viewLevel, viewPositionId }) {
            return new Promise((resolve, reject) => {
                const request = new InfoRequest();

                request.setViewLevel(viewLevel);
                if (viewPositionId) {
                    request.setViewPositionId(viewPositionId);
                }
      
                client.info(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('setBinaryInfo', response.toObject())
                        resolve(response.toObject())
                    }
                })
            })
        },

        searchBinary({commit}, { loginPrefix }) {
            const result = [];
            commit('setBinarySearchResults', []);

            return new Promise((resolve, reject) => {
                const request = new SearchRequest();

                request.setLoginPrefix(loginPrefix);

                const limit = new Limit();
                limit.setUnlimited(true);
                limit.setValue(15);
                request.setLimit(limit);

                const stream = client.search(request, {"authorization": localStorage.getItem("access_token")})

                stream.on('data', function(response) {
                    result.push(response.toObject());
                    resolve(response.toObject());
                });
                
                stream.on('status', (status) => {
                    if (status.code !== 0) reject(status)
                    if (status.code === 0) {
                        commit('setBinarySearchResults', result)
                    }
                });
            })

        },

        setSettingsSide({commit}, { userSide }) {
            return new Promise((resolve, reject) => {
                const request = new SettingsSideSetRequest();

                request.setUserSide(userSide);
      
                client.settingsSideSet(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        console.log(response.toObject());
                        commit('setSettingsSide', response.toObject())
                        resolve(response.toObject())
                    }
                })
            })
        },

        // getCareerRank({ commit }, rankId) {
        //     return new Promise((resolve, reject) => {
        //         const request = new Career.Rank.ID();

        //         request.setRankId(rankId)
      
        //         client.rankGet(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
        //             if (err !== null) {
        //                 reject(err)
        //             } else {
        //                 commit('setRank', response.toObject())
        //                 resolve(response.toObject())
        //             }
        //         })
        //     })
        // },

        // getCareerRankAll({commit}) {
        //     return new Promise((resolve) => {
        //         const request = new Empty;
        //         const stream = client.packagesAll(request, {"authorization": localStorage.getItem("access_token")})

        //         stream.on('data', function(response) {
        //             resolve(response.toObject())
        //             commit('setRankAll', response.toObject())
        //         });
        //     })
        // },
    }
}