<template>
  <div class="settings__item_pass tab-content" id="tab_1"> 
    <div class="settings__item_pass_heading">
      <span>{{t('settings.password.title')}}</span>
    </div>
    <div class="settings__item_pass_text">
      <span v-html="t('settings.password.fill_fields')"></span>
    </div>
    <form class="settings__form" action=""> 
      <div class="settings__form_wrapper">
        <!-- <div class="settings__form_item">
          <label for="oldPass">Старый пароль:</label>
          <div class="settings__form_item_input valid">
            <input class="settings-input" id="oldPass" type="password" name="oldPass" required>
          </div>
        </div> -->
        <div class="settings__form_item">
          <label for="newPass">{{t('settings.password.new_password')}}</label>
          <!-- <input class="settings-input" id="newPass" type="password" name="newPass" required> -->
          <input
              class="settings-input"
              id="newPass"
              type="password"
              name="newPass"
              v-model="newPassword"
              autocomplete="on"
              required
            />
        </div>
        <div class="settings__form_item">
          <label for="repeatNewPass">{{t('settings.password.repeat_password')}}</label>
            <input
              class="settings-input"
              id="repeatPass"
              type="password"
              name="repeatPass"
              v-model="newPasswordRepeat"
              autocomplete="on"
              required
            />
        </div>
      </div>
      <button class="settings__change_btn" type="submit" 
        @click.prevent="changePassword" 
        :class="{disabled: !newPasswordValide || !newPasswordRepeatValide}" 
        :disabled="!newPasswordValide || !newPasswordRepeatValide">
        {{t('settings.password.button')}}
      </button>
      <div class="error-item" :class="{active: error === true}">
        <img src="img/home/err.png" alt="">
        <span>{{errorMessage}}</span>
      </div>
    </form>
  </div>
</template>

<script>
import {auth} from '@/mixins/auth'
import { useI18n } from 'vue-i18n'

export default {
  mixins: [auth],

  data() {
    return {
      // oldPassword: '00000000',
      newPassword: '',
      newPasswordRepeat: '',

      newPasswordValide: null,
      newPasswordRepeatValide: null,

      error: false,
      errorMessage: ''
    }
  },

  methods: {
    setPassword() {
      this.$store.dispatch('setPassword', this.newPassword).then(response => {
        console.log(response);
        switch(response.statusId) {
          case 1: 
            // this.$router.go("/");
            // this.$emit('openPopup', {name: 'PopupConfirmation', confirmationId: response.confirmationId})
            break;
          case 0: 
            this.$emit('openPopup', {name: 'PopupConfirmation', confirmationId: response.confirmationId})
            break;
          case 3:
            this.error = true;
            this.errorMessage = 'Rejected' 
            break;
        }
      }).catch(err => {
        this.error = true;
        this.errorMessage = err.message;
      })

      this.newPassword = ''
      this.newPasswordRepeat = ''
    },

    changePassword() {
      if (this.newPasswordValide && this.newPasswordRepeatValide) {
        this.setPassword()
      }
    }
  },

  watch: {
    newPassword(value) {
      this.newPasswordValide = this.validatePassword(value)
      if (value === '') this.newPasswordValide = null
      if (this.error !== false) this.error = false;
    },

    newPasswordRepeat(value) {
      this.newPasswordRepeatValide = value === this.newPassword;
      if (value === '') this.newPasswordRepeatValide = null
      if (this.error !== false) this.error = false;
    },
  },
  
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  }
}
</script>

<style lang="scss">
.settings-input.disabled {
  pointer-events: none;
  user-select: none;
}

.settings__change_btn {
  &[disabled] {
    background: #9e9e9e;
    cursor: not-allowed;
    
    &:hover {
      background: #9e9e9e;
    }
  }
}
</style>