<template>
  <footer class="footer">
    <div class="footer__container">
      <div class="footer__date main-page-date">
        <div class="mobile__switcher">
          <button class="button-main" @click.prevent="() => updateScreenStyles(false)">
            <span v-if="isMobileScreen">{{t('footer.to_desktop_button')}}</span>
            <span v-else>{{t('footer.to_mobile_button')}}</span>
            <div class="button-main__gradient"></div></button>
        </div>
        &nbsp;
        <span>© 2023</span>
      </div>
      <div class="footer__copyights main-page-copyrights">
          <span>
            {{t('footer.copyright')}}
          </span>
      </div>
    </div>
  </footer>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      isMobileScreen: state => state.mobileScreen
    })
  },
 
  methods: {
    updateScreenStyles() {
      this.$store.dispatch('updateMobileScreen', !this.isMobileScreen)
      localStorage.setItem('mobileScreen', this.isMobileScreen)
    }
  },

  created() {
    // localStorage.setItem('mobileScreen', true)
    // this.$store.dispatch('updateMobileScreen', true)
    const value = localStorage.getItem('mobileScreen') !== null ? JSON.parse(localStorage.getItem('mobileScreen')) : true;
    this.$store.dispatch('updateMobileScreen', value)
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  },
};
</script>

<style lang="scss" scoped>
.mobile__switcher {
  display: none;

  & button {
    width: 100%;
    margin: 1em auto;
  }

  @media (max-width:1024px) and (orientation:portrait) {
    display: flex;
    flex-direction: column;
    margin-right: 1em;
    transform: translateY(-0.3em);

    .mobileScreen & {
      display: block;
      width: 100%;
      transform: translateY(0);
    }
  }
}
</style>