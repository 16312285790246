<template>
  <div class="settings__item_email tab-content" id="tab_2">
    <div class="settings__item_pass_heading">
      <span>{{t('settings.email.title')}}</span>
    </div>
    <div class="settings__item_pass_text">
      <span v-html="t('settings.email.fill_fields')"></span>
    </div>
    <form class="settings__form" action=""> 
      <div class="settings__form_wrapper">
        <div class="settings__form_item">
          <label for="oldEmail">{{t('settings.email.old_email')}}</label>
          <div class="settings__form_item_input valid">
            <input class="settings-input" type="text" name="oldEmail" :value="oldEmail" required>
          </div>
        </div>
        <div class="settings__form_item">
          <label for="newEmail">{{t('settings.email.new_email')}}</label>
          <input
            class="settings-input"
            id="newEmail"
            type="email"
            name="newEmail"
            v-model="newEmail"
            autocomplete="on"
            required
          />
        </div>
        <div class="settings__form_item">
          <label for="repeatEmail">{{t('settings.email.repeat_email')}}</label>
          <input
            class="settings-input"
            id="repeatEmail"
            type="email"
            name="repeatEmail"
            v-model="newEmailRepeat"
            autocomplete="on"
            required
          />
        </div>
      </div>
      <button class="settings__change_btn" type="submit" 
        @click.prevent="changeEmail" 
        :class="{disabled: !newEmailValide || !newEmailRepeatValide}" 
        :disabled="!newEmailValide || !newEmailRepeatValide">
        {{t('settings.email.button')}}
      </button>
      <div class="error-item" :class="{active: error === true}">
        <img src="img/home/err.png" alt="">
        <span>{{errorMessage}}</span>
      </div>
    </form>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {auth} from '@/mixins/auth'
import { useI18n } from 'vue-i18n'

export default {
  mixins: [auth],

  data() {
    return {
      // oldEmail: '',
      newEmail: '',
      newEmailRepeat: '',

      newEmailValide: null,
      newEmailRepeatValide: null,

      error: false,
      errorMessage: ''
    }
  },

  computed: {
    ...mapState({
      oldEmail: state => state?.account?.user?.info?.email,
    })
  },

  methods: {
    // async getOldEmail() {
    //   const oldEmail = await this.$store.dispatch('getEmail')
      
    //   if (oldEmail !== '') {
    //     this.oldEmail = oldEmail
    //   }
    // },

    setEmail() {
      this.$store.dispatch('setEmail', this.newEmail).then(response => {
        console.log(response);
        switch(response.statusId) {
          // case 1: 
          //   this.$router.go("/");
          //   break;
          case 0: 
            this.$emit('openPopup', {name: 'PopupConfirmationEmail', confirmationId: response.confirmationId})
            break;
          case 3:
            this.error = true;
            this.errorMessage = 'Rejected' 
            break;
        }
      }).catch(err => {
        this.error = true;
        this.errorMessage = err.message;
      })

      this.newEmail = ''
      this.newEmailRepeat = ''
    },

    changeEmail() {
      if (this.newEmailValide && this.newEmailRepeatValide) {
        this.setEmail()
      }
    }
  },

  watch: {
    newEmail(value) {
      this.newEmailValide = this.validateEmail(value)
      if (value === '') this.newEmailValide = null
      if (this.error !== false) this.error = false;
    },

    newEmailRepeat(value) {
      this.newEmailRepeatValide = value === this.newEmail;

      if (value === '') this.newEmailRepeatValide = null
      if (this.error !== false) this.error = false;
    },
  },
  
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  }
}
</script>

<style lang="scss">
.settings-input.disabled {
  pointer-events: none;
  user-select: none;
}

.settings__change_btn {
  &[disabled] {
    background: #9e9e9e;
    cursor: not-allowed;
    
    &:hover {
      background: #9e9e9e;
    }
  }
}
</style>