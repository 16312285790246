<template>
  <canvas ref="chart"></canvas>
</template>

<script>
import Chart from 'chart.js/auto';
import { transactions } from '@/mixins/transactions';

export default {
  name: 'ChartJs',

  props: ['graphData'],

  mixins: [transactions],

  data () {
    return {
      bodyFontSize: 1,
      barName: 'Bar Chart',
      type: 'doughnut',
      barData: {
        labels: [...Object.keys(this.graphData)],
        datasets: [
          {
            label: 'Dataset 1',
            data: Object.values(this.graphData).every(v => +v === 0) ? [] : [...Object.values(this.graphData)],
            backgroundColor: [
              'rgb(38, 161, 123)',
              'rgb(255, 1, 246)',
              'rgb(198, 187, 167)'
            ],
            borderColor: 'rgba(0, 0, 0, 0)',
            rotation: -20,
            weight: .2,
            borderWidth: 1
          },
        ]
      },
      // this.options.plugins.emptyDoughnut.width
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: 'bottom',
          },
          title: {
            display: false,
          },
          emptyDoughnut: {
            color: 'rgba(205, 205, 205, 0.1)',
            width: 30*this.bodyFontSize,
            radiusDecrease: 20*this.bodyFontSize
          }
        },

        onResize() {
          const isMobile = (window.innerWidth <= 1024) && (window.innerWidth < window.innerHeight)
          const koef = !isMobile ? .1 : .3;

          this.bodyFontSize = +Number(window.getComputedStyle(document.body).fontSize.replace('px', '')).toFixed()*koef;
          this.options.plugins.emptyDoughnut.width = (this.bodyFontSize*30).toFixed();
          this.options.plugins.emptyDoughnut.radiusDecrease = (20*this.bodyFontSize).toFixed();
        }
      },

      plugin: {
        id: 'emptyDoughnut',
        afterDraw(chart, args, options) {
          const {datasets} = chart.data;
          const {color, width, radiusDecrease} = options;
          let hasData = false;

          for (let i = 0; i < datasets.length; i += 1) {
            const dataset = datasets[i];
            hasData |= dataset.data.length > 0;
          }

          if (!hasData) {
            const {chartArea: {left, top, right, bottom}, ctx} = chart;
            const centerX = (left + right) / 2;
            const centerY = (top + bottom) / 2;
            const r = Math.min(right - left, bottom - top) / 2;

            ctx.beginPath();
            ctx.lineWidth = width || 2;
            ctx.strokeStyle = color || 'rgba(255, 128, 0, 0.5)';
            ctx.arc(centerX, centerY, (r - radiusDecrease || 0), 0, 2 * Math.PI);
            ctx.stroke();
          }
        }
      },
    }
  },

  methods: {
    createGraph() {
      const chrt = this.$refs.chart;
      const chart = new Chart(chrt, {
        type: this.type,
        data: this.barData,
        options: this.options,
        plugins: [this.plugin]
      });
      chart;
    },

    // circleResize() {
    //   this.options.scales.x.ticks.font.size = this.bodyFontSize;
    // },

    // updateBodyFontSize() {
    //   this.bodyFontSize = +Number(window.getComputedStyle(document.body).fontSize.replace('px', '')).toFixed();
    // },
  },


  mounted() {
    this.createGraph()
  },
}
</script>

<style scoped>
/* .container {
  height: 40rem;
  width: 40rem;
} */
</style>
