<template>
    <div class="container">
        <div class="user">
            <div class="user__block">
                <div class="user__name">
                    <span>{{ userInfo?.login }}</span>
                </div>
                <div class="user__status">
                    <span>{{ currentRankTitle }}</span>
                </div>
            </div>
            <div class="user__apps_block"><a class="user__app" href="#"><img src="img/app-1.png" alt=""></a><a
                class="user__app" href="#"><img src="img/app-2.png" alt=""></a></div>
            <div class="user__btn_block">
                <router-link to="/wallet" class="button-main">
                    <span>{{ t('header.myWallet') }}</span>
                    <div class="button-main__gradient"></div>
                </router-link>

                <ButtonRefferal/>
            </div>
        </div>
        <div class="wallet__main_block">

            <div class="wallet__weekly_block">
                <div class="wallet__weekly_heading"><span>{{ t('wallet.weekly_income') }}:</span></div>
                <div class="wallet__weekly_asset" v-for="a in allAssets" :key="a">
                    <div class="wallet__weekly_price" v-if="a.assetName !== 'TRX'"
                         @click.prevent="() => changeActiveAsset(a)">
                        <div class="wallet__weekly_price_container"
                             :class="{active: a.assetId === currentAsset.assetId}">
                            <div class="wallet__weekly_price_img">
                                <img :src="`img/wallet/${a.assetName.toLowerCase()}.png`" alt="">
                            </div>
                            <div class="wallet__weekly_price_name"><span>{{ a.assetName }}</span></div>
                        </div>
                        <div class="wallet__weekly_price_count">
                            <span>{{ numberWithSpaces(getAssetBalance(a.assetId, a.decimal)) }} {{ a.assetName }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="wallet__balance_block">
                <div class="wallet__balance_heading">
                    <span>{{ t('header.balance') }}</span>
                </div>
                <div class="wallet__balance_count">
                    <span>{{ balance }}</span>
                    &nbsp;
                    <span>{{ currentAsset?.assetName }}</span>
                </div>
                <div class="wallet__balance_btn_block">
                    <!-- v-if="currentAsset?.assetName !== 'TKZ'" -->
                    <button class="button-main wallet-withdraw"
                            @click.prevent="$emit('openPopup', {name: 'PopupWalletWithdraw'})">
                        <span>{{ t('wallet.withdraw') }}</span>
                        <div class="button-main__gradient"></div>
                    </button>

                    <button class="button-main wallet-deposit"
                            @click.prevent="$emit('openPopup', {name: 'PopupWalletDeposit'})">
                        <span>{{ t('wallet.deposit') }}</span>
                        <div class="button-main__gradient green"></div>
                    </button>
                </div>
            </div>
            <div class="wallet__transactions_block">
                <div class="wallet__transactions_heading_block">
                    <div class="wallet__transactions_heading">
                        <span>{{ t('wallet.transactions') }}:</span>
                    </div>
                    <div class="wallet__transactions_dropdown">
                        <select class="wallet__select" name="wallet-transactions"
                                @change="transactionsFilterValue = $event.target.value">
                            <option :value="0">{{ t('wallet.filter.all') }}</option>
                            <option :value="addWeeksToDate(new Date().valueOf(), -30).valueOf()">
                                {{ t('wallet.filter.last_month') }}
                            </option>
                            <option :value="addWeeksToDate(new Date().valueOf(), -7).valueOf()">
                                {{ t('wallet.filter.last_week') }}
                            </option>
                            <option :value="addWeeksToDate(new Date().valueOf(), -1).valueOf()">
                                {{ t('wallet.filter.last_day') }}
                            </option>
                            <option :value="addWeeksToDate(new Date().valueOf(), null, -1).valueOf()">
                                {{ t('wallet.filter.last_hour') }}
                            </option>
                        </select>
                    </div>
                </div>

<!--                <Transition :duration="550" name="nested">-->
                    <div v-if="show" class="wallet__transactions_details outer">
                        <div class="wallet__transactions_headings_info">
                            <div class="wallet__transactions_headings_type">
                                <span>{{ t('wallet.tableHeadings.type') }}</span>
                            </div>
                            <div class="wallet__transactions_headings_date">
                                <span>{{ t('wallet.tableHeadings.date') }}</span>
                            </div>
                            <div class="wallet__transactions_headings_summ">
                              <span>
                                {{ t('wallet.tableHeadings.amount') }}
                              </span>
                            </div>
                            <div class="wallet__transactions_headings_date">
                              <span>
                                Hash
                              </span>
                            </div>
                            <div class="wallet__transactions_headings_date">
                              <span>
                                Address
                              </span>
                            </div>
                        </div>
<!--                        <template v-if="sortTransactions.length !== 0">-->
                            <div class="wallet__transactions_text_info inner" v-for="transaction in sortTransactions"
                                 :key="transaction.transactionId">
                                <div class="wallet__transactions_text_type">
                                    <img
                                        :src="`img/wallet/info-item-${ getTransactionDescription(currentAsset?.assetName, 'kindId', transaction.kindId)?.icon }.png`"
                                        alt=""/>
                                    <span v-if="JSON.parse(transaction.description).staking_contract_id !== undefined">
                                      <p class="bonus">
                                        TKZ private round
                                      </p>
                                    </span>
                                    <span v-else
                                          :set="bonusTitle = getTransactionDescription(currentAsset?.assetName, 'bonus_id', JSON.parse(transaction.description).bonus_id)?.title ">
                                        {{
                                            t(`wallet.transactionsIcons['${getTransactionDescription(currentAsset?.assetName, 'kindId', transaction.kindId)?.title}']`)
                                        }}
                                      <p v-if="bonusTitle" class="bonus">
                                        ({{ t(`wallet.transactionsIcons['${bonusTitle}']`) }})
                                      </p>
                                    </span>
                                </div>
                                <div class="wallet__transactions_text_date">
                                    <span>
                                      {{ getDate(transaction.createdAt) }}
                                    </span>
                                </div>
                                <div class="wallet__transactions_text_summ">
                                    <span>{{ numberWithSpaces(setDecimalPrice(transaction.amount)) }} </span>
                                    <span>{{ currentAsset?.assetName }}</span>
                                </div>
                                <div class="wallet__transactions_text_date">
                                    <span>
                                      <template v-if="JSON.parse(transaction.description).transaction_hash">
                                        <CopyString :body="JSON.parse(transaction.description).transaction_hash"/>
                                      </template>
                                    </span>
                                </div>
                                <div class="wallet__transactions_text_date">
                                    <span>
                                      <template v-if="JSON.parse(transaction.description).address">
                                        <CopyString :body="JSON.parse(transaction.description).address"/>
                                      </template>
                                    </span>
                                </div>
                            </div>
<!--                        </template>-->

<!--                        <GraphRive class="inner" v-else/>-->

                        <div ref="afterWalletList" class="afterWalletList"></div>
                    </div>

<!--                {{sortTransactions.length}}-->
<!--                </Transition>-->

            </div>
        </div>

        <div class="wallet__graph_block">
            <div class="wallet__graph_heading">
                <span>{{ t('wallet.balance_history') }}</span>
            </div>
            <Transition :duration="550" name="nested">
                <div class="wallet__graph outer" v-if="show">
                    <WalletGraph v-if="walletBalanceHistory.length !== 0" class="inner"/>
                    <GraphRive :currentAsset="currentAsset" v-else/>
                </div>
            </Transition>
        </div>

    </div>
</template>

<script>
import {Transition} from 'vue'
import {mapState} from 'vuex';
import {transactions} from '@/mixins/transactions';
import {useI18n} from 'vue-i18n'
import ButtonRefferal from '../UI/ButtonRefferal.vue';
import WalletGraph from '@/components/Graph/WalletGraph'
import GraphRive from '@/components/RiveAnimations/GraphRive'
import CopyString from '../CopyString/CopyString.vue';

export default {
    name: "TabWallet",

    mixins: [transactions],

    data() {
        return {
            show: true,
            defaultStatus: '-',

            transactionsTypes: {
                BTC: [
                    {
                        title: "Пополнение",
                        icon: 3,
                        key: 'kindId',
                        values: [4, 6]
                    },
                    {
                        title: "Вывод",
                        icon: 4,
                        key: 'kindId',
                        values: [5, 7]
                    },
                    {
                        title: "Покупка плана",
                        icon: 4,
                        key: 'kindId',
                        values: [3]
                    }
                ],

                TKZ: [
                    {
                        title: "Заработано",
                        icon: 2,
                        key: 'kindId',
                        values: [1, 2, 3, 7, 8, 9]
                    },
                    {
                        title: "Пополнение",
                        icon: 3,
                        key: 'kindId',
                        values: [4, 6]
                    },
                    {
                        title: "Вывод",
                        icon: 4,
                        key: 'kindId',
                        values: [5, 7]
                    },
                ],

                USDT: [
                    {
                        title: "Пополнение",
                        icon: 3,
                        key: 'kindId',
                        values: [4, 6]
                    },
                    {
                        title: "Вывод",
                        icon: 4,
                        key: 'kindId',
                        values: [5, 7]
                    },
                    {
                        title: "Покупка плана",
                        icon: 4,
                        key: 'kindId',
                        values: [3]
                    },
                    {
                        title: "Binary Bonus",
                        icon: 2,
                        key: 'bonus_id',
                        values: [5]
                    },
                    {
                        title: "Matching Bonus",
                        icon: 2,
                        key: 'bonus_id',
                        values: [6]
                    },
                    {
                        title: "Direct Bonus",
                        icon: 2,
                        key: 'bonus_id',
                        values: [3]
                    },
                    {
                        title: "Доход от Tokenza AI",
                        icon: 2,
                        key: 'bonus_id',
                        values: [4]
                    },
                    {
                        title: "Заработано",
                        icon: 2,
                        key: 'kindId',
                        values: [2, 7, 8, 9]
                    },
                ],

            },

            page: 0,
            // walletTransactions: []
        }
    },

    components: {
        Transition,
        ButtonRefferal,
        WalletGraph,
        GraphRive,
        CopyString
    },

    computed: {
        ...mapState({
            // данные пользователя
            userInfo: (state) => state.account.user.info,

            //активы
            currentAsset: state => state.asset.asset,
            allAssets: state => state.asset.allAssets,

            //кошелёк активов
            walletAssetsList: state => state.finance.walletAssetsList,

            // баналс и транзакции
            walletBalanceHistory: state => state.finance.walletBalanceHistory,
            walletTransactions: state => state.finance.walletTransactions,

            // ранг
            careerInfo: (state) => state.career.info,
        }),

        balance() {
            if (this.currentAsset?.assetName === 'BTC') {
                return this.numberWithSpaces(Number(this.walletAssetsList.find(a => a.assetId === this.currentAsset?.assetId)?.assetBalance).toFixed(8))
            } else {
                return this.numberWithSpaces(Number(this.walletAssetsList.find(a => a.assetId === this.currentAsset?.assetId)?.assetBalance).toFixed(2))
            }
        },

        currentRankTitle() {
            return this.careerInfo?.ranksList?.find(rank => this.careerInfo.currentRankId === rank.rankId)?.rankTitle ?? this.defaultStatus
        },

        sortTransactions() {
            const transactions = this.walletTransactions;
            const res = transactions?.filter(t => +t.createdAt * 1000 >= +this.transactionsFilterValue);

            console.log(+this.transactionsFilterValue === 0 ? transactions : res, 'SORTED')

            // return +this.transactionsFilterValue === 0 ? transactions : res;
            return transactions;
        },
    },

    methods: {
        getTransactionDescription(assetName, filterBy, id) {
            return this.transactionsTypes[assetName].find(t => t.key === filterBy && t.values.find(v => v === id) !== undefined)
        },

        numberWithSpaces(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },

        getAssetBalance(assetId) {
            if (assetId === 1) {
                const assetInfo = this.walletAssetsList.find(a => a.assetId === assetId);
                if (assetInfo === undefined) return 0;

                return Number(assetInfo.assetBalance).toFixed(8);
            }

            const assetInfo = this.walletAssetsList.find(a => a.assetId === assetId);
            if (assetInfo === undefined) return 0;

            return Number(assetInfo.assetBalance).toFixed(2);
        },

        changeActiveAsset(asset) {
            this.$store.dispatch('setAsset', asset.assetId).then(() => {
                this.$store.dispatch('getWalletTransactionList', {
                    assetId: this.currentAsset.assetId,
                    stepTransactionId: 0
                })
                this.$store.dispatch('getWalletBalanceHistory', {assetId: this.currentAsset.assetId});
            });
            this.show = false;
            setTimeout(() => this.show = true, 200)
        },

        getProcessing(processingList) {
            return processingList?.find(processingItem => processingItem.assetsList.find(pAsset => pAsset.assetId === this.currentAsset.assetId) !== undefined);
        },

        getHistoryList() {
            this.$store.dispatch('getWalletTransactionsPage', {
                assetId: this.currentAsset.assetId,
                stepTransactionId: this.walletTransactions[this.walletTransactions.length - 1]?.transactionId ?? 0,
            })
        },

        historyListObserver() {
            console.log('observe 1');
            const callback = (entries) => {
                console.log('observe');

                const $this = this;

                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        console.log('TEST')
                        // Отправляем запрос на бэкэнд
                        $this.getHistoryList();
                        // После отправки запроса можно отключить дальнейшее отслеживание, если это нужно
                        // observer.unobserve(entry.target);
                    }
                });
            };

            const observer = new IntersectionObserver(callback, {
                root: document.querySelector('.wallet__transactions_details'),
                rootMargin: '0px',
                // threshold: .25
                threshold: .5
            });

            observer.observe(this.$refs.afterWalletList)
        },
    },

    created() {
        if (this.userInfo === undefined) this.$store.dispatch('getAccountInfo');
        this.$store.dispatch('getWalletAssetsList');

        this.$store.dispatch('getAllAssets').then(() => {
            this.$store.dispatch('getWalletTransactionList', {assetId: this.currentAsset.assetId, stepTransactionId: 0})
            this.$store.dispatch('getWalletBalanceHistory', {assetId: this.currentAsset.assetId});
            this.historyListObserver();
        });

        this.$store.dispatch('getWalletProcessingsAll').then(response => {
            this.$store.dispatch('generateDepositAddress', {processingId: this.getProcessing(response).processingId})
        })
        this.$store.dispatch("getCareerInfo")
    },

    setup() {
        const {t} = useI18n({
            inheritLocale: true,
            useScope: "local"
        });

        return {t};
    },
};
</script>

<style lang="scss" scoped>

.wallet {
    &__transactions_details {
        max-height: 30em;
        margin-top: 1em !important;
        overflow: hidden;
        overflow-y: auto;
        width: 100%;

        &::-webkit-scrollbar {
            width: em(4);
            transform: translateX(em(10));
        }

        &::-webkit-scrollbar-track {
            background: rgba(1, 255, 194, .2);
            border-radius: em(10);
        }

        &::-webkit-scrollbar-thumb {
            background-color: #01ffc2;
            border-radius: em(10);
            border: 0;
        }
    }

    &__weekly {
        &_asset {
            width: 100%;
        }

        &_asset:last-child &_price {
            margin-bottom: 0 !important;
        }

        &_price {
            margin-bottom: 0.9375em !important;

            &_count {
                text-align: right;
                line-height: 150%;
                max-width: 9em;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}

/* rules that target nested elements */
.nested-enter-active .inner,
.nested-leave-active .inner {
    transition: all 0.3s ease-in-out;
}

.nested-enter-from .inner,
.nested-leave-to .inner {
    transform: translateX(30px);
    opacity: 0;
}

/* ... other necessary CSS omitted */

/* delay enter of nested element for staggered effect */
.nested-enter-active .inner {
    transition-delay: 0.25s;
}

.wallet__transactions_text_type .bonus {
    display: block;
    font-size: .8em;
    margin-top: .2em;
}

.wallet__balance_count span {
    font-size: 2.6em;
}

.wallet__balance_block {
    width: 26em;
}

.wallet__transactions_block {
    width: 57em;
}

.wallet__transactions_text_info {
    min-width: 46em;
}

.wallet__transactions_details {
    overflow: auto;

    &::-webkit-scrollbar {
        height: .2em;
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
    }
}

.afterWalletList {
    height: 1em;
}

.wallet__transactions_headings_info {
    min-width: 46em;
}
</style>
