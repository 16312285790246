<template>
  <qrcode-vue class="qr-code" :value="value" :size="size" level="H" />
</template>
<script>
  import QrcodeVue from 'qrcode.vue'

  export default {
    props: ['value'],

    data() {
      return {
        size: 300,
      }
    },

    components: {
      QrcodeVue,
    },
  }
</script>

<style scoped>
.qr-code {
  width: 20em !important;
  height: 20em !important;
  margin-top: 1em;
}
</style>