import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store'
import i18n from './i18n'
// import "./assets/iconfont/iconfont";
// import '../dist/style.css'
import IconPlugin from 'iconfont-vue3';
import './assets/scss/main.scss';
import './registerServiceWorker'

createApp(App)
    .use(router)
    .use(store)
    .use(i18n)
    .use(IconPlugin)
    .mount('#app')