<template>
      <canvas ref="chart"></canvas>
</template>

<script>
import Chart from 'chart.js/auto';
import {mapState} from 'vuex';
import {transactions} from '@/mixins/transactions';


export default {
    name: 'ChartJs',

    mixins: [transactions],

    data() {
        return {
            bodyFontSize: 1,
            chart: {},
            barName: 'Bar Chart',
            type: 'line',
            barData: {
                datasets: [{
                    borderColor: 'rgb(1, 255, 194)',
                    tension: 0.4
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        ticks: {
                            font: {
                                size: this.bodyFontSize,
                            }
                        }
                    },
                    y: {
                        ticks: {
                            font: {
                                size: this.bodyFontSize,
                            }
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                animations: {
                    tension: {
                        duration: 2400,
                        easing: 'linear',
                        from: .5,
                        to: .2,
                        loop: true
                    }
                },
                transactions: {
                    show: {
                        animations: {
                            visible: false
                        }
                    }
                }
            }
        }
    },

    computed: {
        ...mapState({
            asset: state => state.asset.asset,
            walletBalanceHistory: state => state.finance.walletBalanceHistory
        })
    },

    methods: {
        createGraph(list) {
            const labels = list?.map(t => this.getDate(t.createdAt)).reverse();
            const values = list?.map(t => t.balance).reverse();
            this.barData.labels = [...labels]
            this.barData.datasets[0].data = [...values]

            const chrt = this.$refs.chart;
            this.chart = new Chart(chrt, {
                type: this.type,
                data: this.barData,
                options: this.options
            });

            window.addEventListener('resize', () => {
                this.updateBodyFontSize()
                this.fontResize()
            })
        },

        fontResize() {
            this.options.scales.x.ticks.font.size = this.bodyFontSize;
            this.options.scales.y.ticks.font.size = this.bodyFontSize;
        },

        updateBodyFontSize() {
            this.bodyFontSize = +Number(window.getComputedStyle(document.body).fontSize.replace('px', '')).toFixed();
        }
    },

    mounted() {
        this.updateBodyFontSize()
        this.fontResize()

        this.createGraph(this.walletBalanceHistory)
    },
}
</script>

<style scoped>
canvas {
    height: 30rem;
}
</style>
