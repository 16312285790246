<template>
  <popup-template :isVideoPopup="true" @closePopup="$emit('closePopup')">
    <iframe :src="t('welcome.video.url')" 
    :title="t('welcome.video.title')" 
    frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
    allowfullscreen
    ref="video"
    ></iframe>
  </popup-template>
</template>

<script>
import PopupTemplate from "./PopupTemplate";
import { useI18n } from 'vue-i18n'

export default {
  components: {
    PopupTemplate,
  },
  
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  },
};
</script>
<style scoped>
iframe {
  width: 100%;
  height: 40em;
}

@media (max-width: 768px) {
  iframe {
    height: 20em;
  }
}
</style>