<template>
  <div class="popups__switcher_sign_form_block">
    <form class="form form-signin" id="signinForm" action="#">
      <div class="form__wrap" :class="{ valid: loginValide === true }">
        <label for="signInLogin">{{t('popups.sign_in.login')}}</label>
        <input
          class="form__input"
          id="signInLogin"
          type="text"
          name="signInLogin"
          :placeholder="t('popups.sign_in.enter_login')"
          v-model="login"
        />
        <div class="error-item" :class="{ active: loginValide === false }">
          <img src="img/home/err.png" alt="" />
          <span>{{t('popups.sign_in.invalid_login')}}</span>
        </div>
      </div>
      <div class="form__wrap" :class="{ valid: passwordValide === true }">
        <label for="signInPass">{{t('popups.sign_in.password')}}</label>
        <input
          class="form__input"
          id="signInPass"
          type="password"
          name="signInPass"
          placeholder="********"
          v-model="password"
        />
        <div class="error-item" :class="{ active: passwordValide === false }">
          <img src="img/home/err.png" alt="" />
          <span>{{t('popups.sign_in.invalid_password')}}</span>
        </div>
      </div>
      <div class="form__wrap form-wrap-btn">
        <button
          class="form__submit"
          id="regSubmitBtn"
          type="submit"
          @click.prevent="signIn"
        >
          {{t('popups.auth.sign_in')}}
        </button>
        <button
          class="lost-pass"
          @click.prevent="$emit('openPopup', { name: 'PopupLostPassword' })"
        >
          {{t('popups.auth.forgot_password')}}
        </button>
      </div>
      <div class="error-item" :class="{ active: error === true }">
        <img src="img/home/err.png" alt="" />
        <span>{{ errorMessage }}</span>
      </div>
    </form>
  </div>
</template>

<script>
import { auth } from "@/mixins/auth";
import { useI18n } from 'vue-i18n'

export default {
  mixins: [auth],

  data() {
    return {
      login: "",
      password: "",

      loginValide: null,
      passwordValide: null,

      error: false,
      errorMessage: "invalid argument",
    };
  },

  methods: {
    resetError() {
      this.error = false;
    },

    signIn() {
      if (!this.loginValide || !this.passwordValide) return;

      const args = {
        login: this.login,
        password: this.password,
      };

      this.$store
        .dispatch("signIn", { ...args })
        .then((response) => {
          console.log(response);
          // if (response.accessToken !== '' && response.accessToken !== undefined) {
            if (response.status === 1) {
              // to confirmation
            if (response.accessToken !== '' && response.accessToken !== undefined) {
              localStorage.setItem("access_token", response.accessToken);
            }

            // this.error = true;
            // this.errorMessage = response

            this.$emit("openPopup", {
              name: "PopupConfirmationGuest",
              confirmationId: response.confirmation.confirmationId,
            });
          } else if (response.status === 0) {
            // to dashboard
            if (response.accessToken !== '' && response.accessToken !== undefined) {
              localStorage.setItem("access_token", response.accessToken);
            }

            localStorage.setItem("userAuth", true);
            if (this.$route.path !== '/') this.$router.push('/');
            else window.location.reload(true)
          }
        })
        .catch((err) => {
          this.error = true;
          this.errorMessage = err.message;
        });
    },
  },

  watch: {
    login(value) {
      this.loginValide = this.validateLogin(value) || this.validateEmail(value);
      this.error && this.resetError();
    },

    password(value) {
      this.passwordValide = this.validatePassword(value);
      this.error && this.resetError();
    },
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  }
};
</script>
