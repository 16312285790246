/**
 * @fileoverview gRPC-Web generated client stub for mlmbox.client.auth.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.15.8
// source: mlmbox/client/auth/v1/auth.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var mlmbox_type_confirmation_pb = require('../../../../mlmbox/type/confirmation_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.mlmbox = {};
proto.mlmbox.client = {};
proto.mlmbox.client.auth = {};
proto.mlmbox.client.auth.v1 = require('./auth_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mlmbox.client.auth.v1.AuthClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mlmbox.client.auth.v1.AuthPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.client.auth.v1.SignInRequest,
 *   !proto.mlmbox.client.auth.v1.AuthResponse>}
 */
const methodDescriptor_Auth_SignIn = new grpc.web.MethodDescriptor(
  '/mlmbox.client.auth.v1.Auth/SignIn',
  grpc.web.MethodType.UNARY,
  proto.mlmbox.client.auth.v1.SignInRequest,
  proto.mlmbox.client.auth.v1.AuthResponse,
  /**
   * @param {!proto.mlmbox.client.auth.v1.SignInRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mlmbox.client.auth.v1.AuthResponse.deserializeBinary
);


/**
 * @param {!proto.mlmbox.client.auth.v1.SignInRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.client.auth.v1.AuthResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.client.auth.v1.AuthResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.auth.v1.AuthClient.prototype.signIn =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.auth.v1.Auth/SignIn',
      request,
      metadata || {},
      methodDescriptor_Auth_SignIn,
      callback);
};


/**
 * @param {!proto.mlmbox.client.auth.v1.SignInRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.client.auth.v1.AuthResponse>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.auth.v1.AuthPromiseClient.prototype.signIn =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.auth.v1.Auth/SignIn',
      request,
      metadata || {},
      methodDescriptor_Auth_SignIn);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.client.auth.v1.SignUpRequest,
 *   !proto.mlmbox.client.auth.v1.AuthResponse>}
 */
const methodDescriptor_Auth_SignUp = new grpc.web.MethodDescriptor(
  '/mlmbox.client.auth.v1.Auth/SignUp',
  grpc.web.MethodType.UNARY,
  proto.mlmbox.client.auth.v1.SignUpRequest,
  proto.mlmbox.client.auth.v1.AuthResponse,
  /**
   * @param {!proto.mlmbox.client.auth.v1.SignUpRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mlmbox.client.auth.v1.AuthResponse.deserializeBinary
);


/**
 * @param {!proto.mlmbox.client.auth.v1.SignUpRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.client.auth.v1.AuthResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.client.auth.v1.AuthResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.auth.v1.AuthClient.prototype.signUp =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.auth.v1.Auth/SignUp',
      request,
      metadata || {},
      methodDescriptor_Auth_SignUp,
      callback);
};


/**
 * @param {!proto.mlmbox.client.auth.v1.SignUpRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.client.auth.v1.AuthResponse>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.auth.v1.AuthPromiseClient.prototype.signUp =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.auth.v1.Auth/SignUp',
      request,
      metadata || {},
      methodDescriptor_Auth_SignUp);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.client.auth.v1.PasswordRecoveryRequest,
 *   !proto.mlmbox.client.auth.v1.AuthResponse>}
 */
const methodDescriptor_Auth_PasswordRecovery = new grpc.web.MethodDescriptor(
  '/mlmbox.client.auth.v1.Auth/PasswordRecovery',
  grpc.web.MethodType.UNARY,
  proto.mlmbox.client.auth.v1.PasswordRecoveryRequest,
  proto.mlmbox.client.auth.v1.AuthResponse,
  /**
   * @param {!proto.mlmbox.client.auth.v1.PasswordRecoveryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mlmbox.client.auth.v1.AuthResponse.deserializeBinary
);


/**
 * @param {!proto.mlmbox.client.auth.v1.PasswordRecoveryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.client.auth.v1.AuthResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.client.auth.v1.AuthResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.auth.v1.AuthClient.prototype.passwordRecovery =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.auth.v1.Auth/PasswordRecovery',
      request,
      metadata || {},
      methodDescriptor_Auth_PasswordRecovery,
      callback);
};


/**
 * @param {!proto.mlmbox.client.auth.v1.PasswordRecoveryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.client.auth.v1.AuthResponse>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.auth.v1.AuthPromiseClient.prototype.passwordRecovery =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.auth.v1.Auth/PasswordRecovery',
      request,
      metadata || {},
      methodDescriptor_Auth_PasswordRecovery);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.mlmbox.client.auth.v1.TokenValidateResponse>}
 */
const methodDescriptor_Auth_TokenValidate = new grpc.web.MethodDescriptor(
  '/mlmbox.client.auth.v1.Auth/TokenValidate',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.mlmbox.client.auth.v1.TokenValidateResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mlmbox.client.auth.v1.TokenValidateResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.client.auth.v1.TokenValidateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.client.auth.v1.TokenValidateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.auth.v1.AuthClient.prototype.tokenValidate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.auth.v1.Auth/TokenValidate',
      request,
      metadata || {},
      methodDescriptor_Auth_TokenValidate,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.client.auth.v1.TokenValidateResponse>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.auth.v1.AuthPromiseClient.prototype.tokenValidate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.auth.v1.Auth/TokenValidate',
      request,
      metadata || {},
      methodDescriptor_Auth_TokenValidate);
};


module.exports = proto.mlmbox.client.auth.v1;

