import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import ru from './locales/ru.json'
import es from './locales/es.json'
import pt from './locales/pt.json'
import tr from './locales/tr.json'
// import fr from './locales/fr.json'
// import hi from './locales/hi.json'
import vn from './locales/vn.json'
import ko from './locales/ko.json'
import ja from './locales/ja.json'
import zh from './locales/zh.json'

// const lng = window.navigator.userLanguage || window.navigator.language;

function getLocale() {
    if (localStorage.getItem("lang") !== null) 
        return localStorage.getItem("lang")

    // || window.navigator.userLanguage || window.navigator.language

    return process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en";
}

export default createI18n({
    legacy: false,
    globalInjection: true,
    silentFallbackWarn: true,
    silentTranslationWarn: true,
    locale: getLocale(),
    fallbackLocale: getLocale(),
    messages: {
        en,
        ru,
        es,
        vn,
        pt,
        tr,
        ja,
        zh,
        ko,
    }
})