<template>
  <popup-template popupName="wallet-withdraw" @closePopup="$emit('closePopup')" class="form">
    <div class="popups__heading">
      <span>{{ t('sale.popup.buy') }} TKZ</span>
    </div>
    <div class="popups__wallet_adress_block">
      <div class="popups__wallet_for_whom_summ">
        <div class="form__wrap" :class="{ valid: sumValide === true }">
          <div class="popups__tax_block">
            <div class="popups__tax_1">
              <span>{{ t('sale.popup.enter_sum') }}</span>
            </div>
            <div class="popups__tax_1">
              <span>{{ t('sale.popup.your_limit') }} {{limitValue}} TKZ</span>
            </div>
          </div>
          <div class="popups__wallet_input_block summ">
            <input
              class="wallet-input"
              id="forwhomSumm"
              type="text"
              name="forwhomsumm"
              v-model="sum"
              @focus="sumFocus = true"
              @blur="sumFocus = false"
              @input="sumInput"
            />
            <span class="max" @click="sum = limitValue/10">max</span>
            <span>{{currentAsset.assetName}}</span>
          </div>
          <div
            class="error-item"
            :class="{ active: sumValide === false }"
            >
            <img src="img/home/err.png" alt="" />
            <span>{{sumError}}</span>
          </div>
        </div>
      </div>
      <div class="popups__wallet_comission">
        <img src="img/wallet/warn.png" alt="" />
        <span>
          <span>{{ t('sale.popup.your_balance') }} {{Number(balance)}} {{currentAsset?.assetName}}</span>
        </span>
      </div>
      <div class="you-get">
        <svg xmlns="http://www.w3.org/2000/svg" width="49" height="48" viewBox="0 0 49 48" fill="none">
          <ellipse cx="24.5" cy="24" rx="24.5" ry="24" fill="#211441"/>
          <path d="M38 17.7C38 18.135 37.8256 18.5702 37.4768 18.9017L23.1911 32.5017C22.8437 32.8353 22.3862 33 21.9286 33C21.471 33 21.0145 32.834 20.6663 32.502L13.5234 25.702C13.1747 25.3713 13 24.9356 13 24.5C13 23.5289 13.8343 22.8 14.7857 22.8C15.2426 22.8 15.6998 22.966 16.048 23.298L21.9286 28.8987L34.9531 16.4994C35.2991 16.1663 35.7567 16 36.2143 16C37.1685 16 38 16.7278 38 17.7Z" fill="#01FFC1"/>
        </svg>
        <span>{{ t('sale.popup.your_get') }} <span>{{ sum*10 }} TKZ</span></span>
      </div>
    </div>
    <button class="popups__btn_withdraw" @click.prevent="buy">
      <span>{{ t('sale.popup.buy') }}</span>
    </button>
    <div class="error-item"
      :class="{ active: error === true }"
      >
      <img src="img/home/err.png" alt="" />
      <span>{{errorMessage}}</span>
    </div>
  </popup-template>
</template>

<script>
import { mapState } from 'vuex';
import PopupTemplate from "./PopupTemplate";
import { useI18n } from 'vue-i18n'

export default {
  components: {
    PopupTemplate,
  },

  computed: {
    ...mapState({
      currentAsset: state => state.asset.asset,
      walletAssetsList: (state) => state.finance.walletAssetsList,
      processingList: (state) => state.finance.processingList,

      // staking info
      stakingInfo: state => state.staking.info
    }),

    limitValue() {
      return (this.stakingInfo.assetsList[0].state.turnoverUsdtLimit - this.stakingInfo.assetsList[0].state.lockupTotalValue)*10;
    },

    processing() {
      return this.processingList.find(processingItem => processingItem.assetsList.find(pAsset => pAsset.assetId === this.currentAsset.assetId) !== undefined);
    },

    processingAsset() {
      return this.processing?.assetsList?.find(p => p.assetId === this.currentAsset.assetId);
    },

    walletAsset() {
      return this.walletAssetsList?.find(a => a.assetId === this.currentAsset.assetId);
    },

    balance() {
      return this.walletAsset?.assetBalance === undefined ? 0 : Number(this.walletAsset?.assetBalance).toFixed(2);
    },

    commission() {
      const percent = this.processing?.assetsList?.find(a => a.assetId === this.currentAsset.assetId)?.settingsWithdrawalFeePercent;
      const percentValue = this.sumValide ? percent*1*Number(this.sum) : percent*1;
      return this.processing?.assetsList !== undefined ? percentValue.toFixed(2) : 1;
    }
  },

  data() {
    return {
      address: '',
      sumWithComission: '',
      sum: '',
      sumFocus: false,
      addressValide: null,
      sumValide: null,

      sumErrors: {
        min: "min 10 USDT",
      },

      sumError: '',

      error: false,
      errorMessage: ''
    }
  },

  methods: {
    updateSumСomission() {
      const baseComission = Number(this.processingAsset.settingsWithdrawalFeeBase);
      const percentComission = this.processingAsset.settingsWithdrawalFeePercent*100;

      if (+this.sum === 0) {
        this.sumWithComission = ''
        return;
      }


      if (this.currentAsset.assetName === 'TKZ') {
        const result = Number(this.sum) + Number(2);

        this.sumWithComission = this.sumValide ? result.toFixed(2) : '';

        return;
      }

      const result = Number(this.sum)*(100+percentComission)/100 + baseComission;

      this.sumWithComission = this.sumValide ? result.toFixed(2) : '';
    },

    updateSum() {
      const baseComission = Number(this.processingAsset.settingsWithdrawalFeeBase);
      const percentComission = this.processingAsset.settingsWithdrawalFeePercent*100;
      
      if (+this.sumWithComission === 0) {
        this.sum = ''
        return;
      }

      if (this.currentAsset.assetName === 'TKZ') {
        const result = this.sumWithComission - 2;

        this.sum = result > 0 ? result.toString() : '';

        return;
      }

      const result = Number(this.sumWithComission)*(100-percentComission)/100 - baseComission;

      this.sum = result > 0 ? result.toString() : '';
    },


    buy() {
      if (!this.sumValide) return;

      this.$store.dispatch('buyStakingPackage', { 
        packageId: 1, 
        assetId: this.currentAsset.assetId, 
        priceValue: this.sum 
      }).then(() => {
        // if (response.activated === true) {
        this.$store.dispatch('getStakingInfo').then(() => {
          this.$router.push('/tkz-sale')
          this.$emit('closePopup')
        })
        // }
      }).catch(err => {
        this.error = true;
        this.errorMessage = err.message;
      })
    },

    sumInput(e) {
      this.sum = e.target.value;
    },

    addressValidation(value) {
      const noSpacesRegex = /^[^\s]+(\s+[^\s]+)*$/;
      return noSpacesRegex.test(value);
    },

    sumValidation(value) {
      /* eslint-disable-next-line */
      const onlyNumbersRegex = /^(\d+(?:[\.\,]\d{1,32})?)$/;
      const noSpacesRegex = /^[^\s]+(\s+[^\s]+)*$/;

      if (!(noSpacesRegex.test(value) && onlyNumbersRegex.test(value))) {
        this.sumError = this.sumErrors.invalid;

        return false;
      }

      if (value === '') {
        this.sumError = this.sumErrors.min; 
        return false;
      }
      
      if (+value < 50) {
        this.sumError = 'min 50 USDT';
        return false;
      }

      if (+value > this.limitValue/10) {
        this.sumError = `max ${this.limitValue/10} USDT`;
        return false;
      }

      // return value >= 1 && noSpacesRegex.test(value) && onlyNumbersRegex.test(value);
      return noSpacesRegex.test(value) && onlyNumbersRegex.test(value);
    }
  },

  created() {
    if (Object.keys(this.processingList).length === 0) this.$store.dispatch('getWalletProcessingsAll')
    this.$store.dispatch('getStakingInfo');
  },

  mounted() {
    this.sumErrors.invalid = this.t('popups.withdraw.invalid_sum');
  },

  watch: {
    address(value) {
      this.addressValide = this.addressValidation(value);
    },

    sum(value) {
      this.sumValide = this.sumValidation(value);

      if (this.sumFocus) {
        this.updateSumСomission()
      }
    },
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  }
};
</script>

<style lang="scss" scoped>
.popups__wallet_for_whom_summ .form__wrap::before {
  display: none;
}

.popups__wallet_comission input {
    background: rgba(26, 13, 68, 0.3);
    border: 0;
    color: #fff;
    margin-left: 0.4em;
    border-radius: 0.5em;
    padding: 0.4em 1.2em;
    outline: none;
}

.you-get {
  color: #ffffff;
  border-radius: 0.5em;
  background-color: rgba(26, 13, 68, 0.3);

  display: flex;
  align-items: center;
  padding: .5em .6em;
  margin-top: .85em;

  span {
    line-height: 73%;

    span {
      background-color: rgba(26, 13, 68, 0.3);
      color: #01ffc2;
      padding: .3em .8em;
      border-radius: 1em;
      margin-left: .5em;
      margin-right: .5em;
    }
  }

  svg {
    width: 1.8em;
    height: 1.8em;
    margin-right: .9em;
  }
}


.popups__wallet_input_block.summ span {
  right: 0.7em;
}

.max {
  right: 3.8em !important;
  color: rgba(255, 255, 255, .5) !important;
  cursor: pointer;
  transition: color .2s ease-in-out;

  &:hover {
    color: rgba(255, 255, 255, .8) !important;
  }
}

.form__wrap {
  margin-bottom: 1.3125em;
}

.popups__wallet_for_whom_summ {
  margin-top: 0;
}

.popups__tax_1 span {
  margin-left: 0 !important;
}
</style>