<template>
  <div class="cases">
    <div class="heading_block">
      <div class="heading_block__heading">
        <span>TKZ SALE</span>
      </div>
    </div>
    <div class="container">
      <div class="cases__main_block">

        <div class="roadmap">
          <div class="roadmap__title">
            <span v-html="t('sale.roadmap.title')"></span>
          </div>
          <div class="roadmap__items">
            <div class="roadmap__item">
              <div class="roadmap__item_number">
                <span>2022</span>
              </div>
              <div class="roadmap__item_info">
                <span v-html="t('sale.roadmap.item_1')"></span>
              </div>
            </div>
            <div class="roadmap__item">
              <div class="roadmap__item_number">
                <span>2023</span>
              </div>
              <div class="roadmap__item_info">
                <span v-html="t('sale.roadmap.item_2')"></span>
              </div>
            </div>
            <div class="roadmap__item">
              <div class="roadmap__item_number">
                <span>2024</span>
              </div>
              <div class="roadmap__item_info">
                <span v-html="t('sale.roadmap.item_3')"></span>
              </div>
            </div>
            <div class="roadmap__item">
              <div class="roadmap__item_number">
                <span>2024</span>
              </div>
              <div class="roadmap__item_info">
                <span v-html="t('sale.roadmap.item_4')"></span>
              </div>
            </div>
          </div>
        </div>

        <div class="buy">
          <div class="buy__title">
            <span>{{ t('sale.popup.buy') }} TKZ</span>
          </div>
          <div class="buy__items">
            <div class="buy__item">
              <span>
                {{ t('sale.buy.item_1.title') }}
              </span>
              <p>
                {{ t('sale.buy.item_1.desc') }}
              </p>
            </div>

            <div class="buy__item">
              <span>
                {{ t('sale.buy.item_2.title') }}
              </span>
              <p>
                0.10 USDT
              </p>
            </div>

            <div class="buy__item">
              <span>
                {{ t('sale.buy.item_3.title') }}
              </span>
              <p>
                50 000 TKZ
              </p>
            </div>

            <div class="buy__item">
              <span>
                {{ t('sale.buy.item_4.title') }}
              </span>
              <p>
                {{ t('sale.buy.item_4.desc') }}
              </p>
            </div>
          </div>

          <button class="buy__button" @click="$emit('openPopup', {name: 'PopupWalletTkzBuy'})">
            <span>
              {{ t('sale.popup.buy') }} TKZ
            </span>
          </button>
        </div>
      </div>

      <div class="cases__main_dohod_table_block" v-if="activeContracts?.length">
        <div class="cases__main_dohod_table_container">
          <div class="cases__main_dohod_headings">
            <div class="cases__main_dohod_headind_case">
              <span>{{t('cases.case')}}</span>
            </div>
            <div class="cases__main_dohod_headind_month">
              <span>{{ t('sale.list.price') }}</span>
            </div>
            <div class="cases__main_dohod_headind_year">
              <span>{{t('cases.earned')}}</span>
            </div>
            <div class="cases__main_dohod_headind_earned">
              <span>{{ t('sale.list.to_get') }}</span>
            </div>
            <div class="cases__main_dohod_headind_date">
              <span>{{t('cases.purchase_date')}}</span>
            </div>
          </div>

          <div class="cases__main_dohod_text" v-for="contract in activeContracts"
            :key="contract.contractId">
            <div class="cases__main_dohod_text_case">
              <span>{{contract.packageTitle}}</span>
              <span>( {{setDecimalPrice(contract.lockupValue)}} TKZ )</span>
            </div>
            <div class="cases__main_dohod_text_month">
              <span>{{setDecimalPrice(contract.turnoverUsdtValue)}} {{defaultAsset.assetName}}</span>
            </div>
            <div class="cases__main_dohod_text_year">
              <span>{{setDecimalPrice(getEarnedValue(contract.lockupDurationMonthReady, contract.lockupDurationMonthTarget, contract.lockupValue))}}</span>
              <span>TKZ</span>
            </div>
            <div class="cases__main_dohod_text_earned">
              <span>{{setDecimalPrice( +contract.lockupValue - +getEarnedValue(contract.lockupDurationMonthReady, contract.lockupDurationMonthTarget, contract.lockupValue))}}</span>
              <span>TKZ</span>
            </div>
            <div class="cases__main_dohod_text_date">
              <span>{{getDate(contract.createdAt)}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { useI18n } from 'vue-i18n'
import { transactions } from '@/mixins/transactions';
// import PlansBotRive from '../../components/RiveAnimations/PlansBotRive.vue';
// import PlansBotRiveRight from '../../components/RiveAnimations/PlansBotRiveRight.vue';

export default {
  components: {
    // PlansBotRive,
    // PlansBotRiveRight
  },
  mixins: [transactions],

  computed: {
    ...mapState({
      // farmingInfo: state => state.farming.info,
      defaultAsset: state => state.asset.defaultAsset,
      // staking info
      stakingInfo: state => state.staking.info
    }),

    contracts() {
      return this.stakingInfo?.assetsList?.length ? this.stakingInfo?.assetsList[0]?.contractsList : [];
    },

    activeContracts() {
      return this.contracts
      // return this.contracts?.filter(c => c.closed !== true)
    },
  },

  methods: {
    getEarnedValue(lockupDurationMonthReady, lockupDurationMonthTarget, lockupValue) {
      // 0 18 510
      return lockupValue/lockupDurationMonthTarget*lockupDurationMonthReady;
    }
  },

  created() {
    // this.$store.dispatch('getWalletAssetsAll')
    this.$store.dispatch('getAllAssets')
    // this.$store.dispatch('getFarmingInfo')

    this.$store.dispatch('getStakingInfo');
  },

  mounted() {
    this.$emit('pageUpdate', true)
  },

  unmounted() {
    this.$emit('pageUpdate', false)
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  },

};
</script>

<style lang="scss" scoped>
.cases__main_block {
  flex-basis: 1px;

  &>div {
    height: 100%;
  }
}

.cases__main_passive_block {
  padding-bottom: 1em;
}

.cases__main_stat {
  &_items {
    width: 100%;
    // display: grid;
    // grid-template-columns: 50% 50%;
    height: 100%;
  }

  &_item {
    height: 100%;
    width: 16.6875em;

    &:nth-child(2n+1) {
      justify-self: flex-start;
    }

    &:nth-child(2n) {
      justify-self: flex-end;
    }

    &_text_2 {
      height: 100%;
    }
  }
}
.mobileScreen {
  @media (max-width: 1024px) {
    .cases__main_stat {
      &_items {
        // grid-template-columns: 100%;
        display: flex;
      }

      &_item {
        &_text_2 {
          height: auto;
        }
      }
    }
  }
}

// --------------
.cases__main_block {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.roadmap {
  width: 49%;
  border-radius: .8em;
  background-image: linear-gradient( 0deg, rgba(91, 73, 203, .3) 0%, rgba(189, 51, 207, .3) 100%);
  padding: 5em 6em;
  position: relative;

  &__title {
    color: #fff;
    margin-bottom: 3em;

    span {
      font-size: 1.4em;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  &::before {
    content: '';
    width: 10em;
    height: 30.8em;
    background-image: url('~@/../public/img/borders.png');
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    left: 6.2em;
    bottom: 11.2em;
    pointer-events: none;
  }

  &__item {
    position: relative;
    margin-top: em(5);
    display: flex;
    align-items: center;
    margin-bottom: 3em;
    margin-left: 3em;

    &:nth-child(2) &_info {
      box-shadow: 0 0 4em -0.1em #01FFC1;
    }

    &_number {
        background: #01FFC1;
        width: em(67);
        height: em(67);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #1C1C1C;
        border-radius: 50%;
        box-shadow: 0 0 0em 0.5em #180E41;
        z-index: 1;
        position: relative;

        span {
            font-size: em(20);
            font-weight: 800;
        }
    }

    &_info {
      padding: em(10) em(44);
      border-radius: em(19);
      border: em(1) solid #01FFC1;
      background: linear-gradient(91deg, rgba(93, 73, 204, 0.40) -2.31%, rgba(187, 52, 207, 0.40) 98.94%);
      backdrop-filter: blur(.6em);
      margin-left: -1em;
      width: em(481);
      height: 7em;
      display: flex;
      align-items: center;
      position: relative;

      &::after {
        content: '';
        width: 3em;
        height: 3em;
        background: #211441;
        background-image: url('~@/../public/img/rotate.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 1.2em;
        position: absolute;
        right: -1.5em;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }

      span {
          color: #fff;
          font-size: 1.35em;
          line-height: 130%;

          b {
              font-weight: 700;
          }
      }
    }

    &:first-child &_info::after {
      background-image: url('~@/../public/img/check.svg');
    }

    &:nth-child(2) &_info::after {
      background-image: url('~@/../public/img/check.svg');
    }
  }
}

.buy {
  width: 49%;
  border-radius: .8em;
  background-image: linear-gradient( 0deg, rgba(91, 73, 203, .3) 0%, rgba(189, 51, 207, .3) 100%);
  padding: 5em 6em;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background-image: url('~@/../public/img/coins.png');
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 0;
  }

  &__title {
    color: #fff;
    margin-bottom: 3em;

    span {
      font-size: 1.4em;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  &__items {
    width: 50%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
  }

  &__item {
    width: calc(50% - .4em);
    display: flex;
    flex-direction: column;
    border-radius: .6em;
    background-image: linear-gradient(90deg, rgba(47, 25, 111, .8) 10%, rgba(86, 18, 113, .8) 100%);
    padding: 1em .2em 1em 1.5em;
    margin-bottom: .8em;

    span {
      font-size: 1.1em;
      color: #b3a8cf;
      font-weight: normal;
      display: block;
      margin-bottom: 1em;
    }

    p {
      font-size: 1.1em;
      color: #fff;
    }
  }

  &__button {
    background-color: rgb(1, 255, 194);
    width: 19em;
    height: 2.8em;
    border-radius: 2em;
    color: #1a0d44;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3em;
    transition: background .2s ease-in-out;
    z-index: 1;
    position: relative;

    &:hover {
      background-color: rgba(1, 255, 194, .85);
    }

    span {
      font-weight: 600;
      font-size: 1.1em;
    }
  }
}

.mobileScreen {
  @media (orientation: portrait) {
    .cases__main_block {
      flex-basis: unset;
    }

    .roadmap, .buy {
      width: 100%;
      margin-bottom: 1em;
      padding: 3em;

      &__items {
        font-size: .6em;
      }
    }

    .roadmap {
      &::before {
        height: 18.3em;
        left: 3.2em;
        bottom: 6.75em;
      }
    }
  }
}
</style>
