<template>
  <popup-template popupName="wallet-withdraw" @closePopup="$emit('closePopup')" class="form">
    <div class="popups__heading">
      <span>{{t('popups.withdraw.title')}}</span>
    </div>
    <div class="popups__tax_block">
      <div class="popups__tax_1">
        <img :src="`img/wallet/${currentAsset.assetName.toLowerCase()}.png`" alt="" />
        <span>{{currentAsset.assetTitle}}</span>
      </div>
      <!-- <div class="popups__tax_2"><span>TRC20</span></div> -->
    </div>
    <div class="popups__wallet_adress_block">
      <div class="popups__wallet_for_whom_adress">
        <div class="form__wrap" :class="{ valid: addressValide === true }">
          <span v-if="currentAsset.assetName === 'TKZ'">Address TRC20:</span>
          <span v-else>{{t('popups.withdraw.to')}}</span>
          <div class="popups__wallet_input_block">
            <input class="wallet-input" id="forwhom" type="text" name="forwhom" v-model="address" />
          </div>
          <div
            class="error-item"
            :class="{ active: addressValide === false }"
            >
            <img src="img/home/err.png" alt="" />
            <span>{{t('popups.withdraw.invalid_address')}}</span>
          </div>
        </div>
      </div>
      <div class="popups__wallet_for_whom_summ">
        <div class="form__wrap" :class="{ valid: sumValide === true }">
          <div class="popups__tax_block">
            <div class="popups__tax_1">
              <span>{{t('popups.withdraw.enter_amount')}}</span>
            </div>
            <div class="popups__tax_2">
              <span>max: {{Number(balance)}} {{currentAsset?.assetName}}</span>
            </div>
          </div>
          
          <div class="popups__wallet_input_block summ">
            <input
              class="wallet-input"
              id="forwhomSumm"
              type="text"
              name="forwhomsumm"
              v-model="sum"
              @focus="sumFocus = true"
              @blur="sumFocus = false"
              @input="sumInput"
            />
            <span>{{currentAsset.assetName}}</span>
          </div>
          <div
            class="error-item"
            :class="{ active: sumValide === false }"
          >
            <img src="img/home/err.png" alt="" />
            <span>{{sumError}}</span>
          </div>
        </div>
      </div>
      <div class="popups__wallet_comission" v-if="currentAsset.assetName !== 'TKZ'">
        <img src="img/wallet/warn.png" alt="" />
        <span> {{t('popups.withdraw.including_commission')}} </span>
        <input type="text" 
          v-model="sumWithComission"
          @input="updateSum"
          >
      </div>
      <div class="popups__wallet_comission">
        <img src="img/wallet/warn.png" alt="" />
        <span v-if="currentAsset.assetName === 'TKZ'">
          {{t('popups.withdraw.commission')}}: 2 USDT
        </span>
        <span v-else>{{t('popups.withdraw.commission')}} {{Number(processingAsset.settingsWithdrawalFeeBase)}} {{currentAsset.assetName}} + {{processingAsset.settingsWithdrawalFeePercent*100}}% </span>
      </div>
    </div>
    <button class="popups__btn_withdraw" @click.prevent="withdraw">
      <span>{{t('popups.withdraw.withdraw')}}</span>
    </button>
    <button class="popups__btn_close" @click.prevent="$emit('closePopup')">
      <span>{{t('popups.withdraw.close')}}</span>
    </button>
    <div class="error-item"
      :class="{ active: error === true }"
      >
      <img src="img/home/err.png" alt="" />
      <span>{{errorMessage}}</span>
    </div>
  </popup-template>
</template>

<script>
import { mapState } from 'vuex';
import PopupTemplate from "./PopupTemplate";
import { useI18n } from 'vue-i18n'

export default {
  components: {
    PopupTemplate,
  },

  computed: {
    ...mapState({
      currentAsset: state => state.asset.asset,
      walletAssetsList: (state) => state.finance.walletAssetsList,
      processingList: (state) => state.finance.processingList,
    }),

    processing() {
      return this.processingList.find(processingItem => processingItem.assetsList.find(pAsset => pAsset.assetId === this.currentAsset.assetId) !== undefined);
    },

    processingAsset() {
      return this.processing?.assetsList?.find(p => p.assetId === this.currentAsset.assetId);
    },

    walletAsset() {
      return this.walletAssetsList?.find(a => a.assetId === this.currentAsset.assetId);
    },

    balance() {
      return this.walletAsset?.assetBalance === undefined ? 0 : Number(this.walletAsset?.assetBalance).toFixed(2);
    },

    commission() {
      const percent = this.processing?.assetsList?.find(a => a.assetId === this.currentAsset.assetId)?.settingsWithdrawalFeePercent;
      const percentValue = this.sumValide ? percent*1*Number(this.sum) : percent*1;
      return this.processing?.assetsList !== undefined ? percentValue.toFixed(2) : 1;
    }
  },

  data() {
    return {
      address: '',
      sumWithComission: '',
      sum: '',
      sumFocus: false,
      addressValide: null,
      sumValide: null,

      sumErrors: {
        min: "min 10$",
      },

      sumError: '',

      error: false,
      errorMessage: ''
    }
  },

  methods: {
    updateSumСomission() {
      const baseComission = Number(this.processingAsset.settingsWithdrawalFeeBase);
      const percentComission = this.processingAsset.settingsWithdrawalFeePercent*100;

      if (+this.sum === 0) {
        this.sumWithComission = ''
        return;
      }


      if (this.currentAsset.assetName === 'TKZ') {
        const result = Number(this.sum) + Number(2);

        this.sumWithComission = this.sumValide ? result.toFixed(2) : '';

        return;
      }

      const result = Number(this.sum)*(100+percentComission)/100 + baseComission;

      this.sumWithComission = this.sumValide ? result.toFixed(2) : '';
    },

    updateSum() {
      const baseComission = Number(this.processingAsset.settingsWithdrawalFeeBase);
      const percentComission = this.processingAsset.settingsWithdrawalFeePercent*100;
      
      if (+this.sumWithComission === 0) {
        this.sum = ''
        return;
      }

      if (this.currentAsset.assetName === 'TKZ') {
        const result = this.sumWithComission - 2;

        this.sum = result > 0 ? result.toString() : '';

        return;
      }

      const result = Number(this.sumWithComission)*(100-percentComission)/100 - baseComission;

      this.sum = result > 0 ? result.toString() : '';
    },

    withdraw() {
      if (!this.addressValide && !this.sumValide) return;
      
      this.validateAdress().then(addressValidated => {
        console.log(addressValidated, this.sum);
        if (addressValidated !== true) return this.addressValide = false;

        // this.sum = this.sum.includes(',') ? this.sum.replace(',', '.') : this.sum;

        this.$store.dispatch('withdrawalCreate', {
          processingId: this.processing.processingId, 
          assetId: this.currentAsset.assetId, 
          operationAmount: this.sum, 
          address: this.address
        }).then(response => {
          console.log(response);
          switch(response.statusId) {
            case 1: 
              this.$emit('openPopup', {name: 'PopupSuccess', data: {sum: this.sum+' '+this.currentAsset?.assetName}})
              // this.$router.to("/wallet")
              break;
            case 0: 
              if (response.fieldsList.length === 0) {
                this.$emit('openPopup', {name: 'PopupSuccess', data: {sum: this.sum+' '+this.currentAsset?.assetName}})
                // this.$router.go("/wallet")
                break;
              }
              this.$emit('openPopup', {name: 'PopupConfirmation', confirmationId: response.confirmationId, showSuccessPopup: true, sum: this.sum+' '+this.currentAsset?.assetName})
              break;
            case 3:
              this.error = true;
              this.errorMessage = 'Rejected' 
              break;
          }
        }).catch(err => {
          this.error = true;
          this.errorMessage = err.message
        })
      });
    },

    sumInput(e) {
      // const isDot = e.target.value.indexOf('.') !== -1 ? e.target.value.indexOf('.') : -1;
      // const isComma = e.target.value.indexOf(',') !== -1 ? e.target.value.indexOf(',') : -1;

      // const dotIndex = isDot || isComma;
      // this.sum = dotIndex !== -1 ? e.target.value.slice(0, dotIndex+3): e.target.value;
      this.sum = e.target.value;
    },

    validateAdress() {
      return new Promise((resolve) => {
        this.$store.dispatch('getWalletValidateAddress', {processingId: this.processing.processingId, address: this.address}).then((response) => {
          resolve(response.validated)
        })
      })
    },

    addressValidation(value) {
      const noSpacesRegex = /^[^\s]+(\s+[^\s]+)*$/;
      return noSpacesRegex.test(value);
    },

    sumValidation(value) {
      // const onlyNumbersRegex = /^(?<=^| )\d+(\.\d+)?(?=$| )|(?<=^| )\.\d+(?=$| )*$/;
      
      // const onlyNumbersRegex = /^\d*\,?\d*$/;;
      // const onlyNumbersRegex = /^(\d+(?:[\.\,]\d{1})?)$/;
      /* eslint-disable-next-line */
      const onlyNumbersRegex = /^(\d+(?:[\.\,]\d{1,32})?)$/;
      const noSpacesRegex = /^[^\s]+(\s+[^\s]+)*$/;

      if (!(noSpacesRegex.test(value) && onlyNumbersRegex.test(value))) {
        this.sumError = this.sumErrors.invalid;

        return false;
      }

      // if (value === '' || Number(value) < 10) {
      if (value === '') {
        this.sumError = this.sumErrors.min; 
        return false;
      }

      // return value >= 1 && noSpacesRegex.test(value) && onlyNumbersRegex.test(value);
      return noSpacesRegex.test(value) && onlyNumbersRegex.test(value);
    }
  },

  created() {
    if (Object.keys(this.processingList).length === 0) this.$store.dispatch('getWalletProcessingsAll')
  },

  mounted() {
    this.sumErrors.invalid = this.t('popups.withdraw.invalid_sum');
  },

  watch: {
    address(value) {
      this.addressValide = this.addressValidation(value);
    },

    sum(value) {
      this.sumValide = this.sumValidation(value);

      if (this.sumFocus) {
        this.updateSumСomission()
      }
    },
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  }
};
</script>

<style scoped>
.popups__wallet_for_whom_summ .form__wrap::before {
  display: none;
}

.popups__wallet_comission input {
    background: rgba(26, 13, 68, 0.3);
    border: 0;
    color: #fff;
    margin-left: 0.4em;
    border-radius: 0.5em;
    padding: 0.4em 1.2em;
    outline: none;
}
</style>