<template>
  <canvas ref="chart"></canvas>
</template>

<script>
import Chart from 'chart.js';
import { mapState } from 'vuex';
import { transactions } from '@/mixins/transactions';

export default {
  name: 'Graph1',

  mixins: [transactions],
  props: [
    'propsData'
  ],

  data () {
    return {
      bodyFontSize: 1,
      chart: {},
      barName: 'Bar Chart',
      type: 'bar',
      barData: {
        datasets: [
          // {
          //   label: 'Arbitrage',
          //   data: [],
          //   backgroundColor: '#009cfa',
          //   fill: false
          // },
          // {
          //   label: 'PartialSandwich',
          //   data: [],
          //   backgroundColor: '#01ffc2',
          //   fill: false
          // },
          // {
          //   type: 'line',
          //   label: 'Count',
          //   data: [],
          //   backgroundColor: '#a370ff',
          //   borderColor: '#a370ff',
          //   fill: false,
          // },
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
            ticks: {
              color: "#ffffff",
              font: {
                size: this.bodyFontSize,
              }
            },
            grid: {
              display: false,
            }
          },
          y: {
            stacked: true,
            ticks: {
              color: "#ffffff",
              font: {
                size: this.bodyFontSize,
              }
            },
            grid: {
              display: false,
            }
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              color: "#ffffff",
              padding: 20,
              usePointStyle: true,
              boxWidth: 6,
              font: {
                size: this.bodyFontSize,
              }
            },
          }
        },
        animations: {
          tension: {
            duration: 2400,
            easing: 'linear',
            from: .5,
            to: .2,
            loop: true
          }
        },
        transactions: {
          show: {
            animations: {
              visible: false
            }
          }
        },
      }
    }
  },

  computed: {
    ...mapState({
      asset: state => state.asset.asset,
      walletBalanceHistory: state => state.finance.walletBalanceHistory
    }),
  },

  methods: {
    // getGraphData() {
    //   const result = {
    //     'Arbitrage': [],
    //     'PartialSandwich': [],
    //     // 'Count': [],
    //   }

    //   result["Arbitrage"] = this.propsData.filter(f => f.tx_type === "Arbitrage")
    //   result["PartialSandwich"] = this.propsData.filter(f => f.tx_type === "PartialSandwich")
    //   // result["Count"] = this.propsData.filter(f => f.tx_type === "Count")

    //   return result;
    // },

    getGraphData() {
      const result = {}

      // format keys
      const keys = this.propsData.reduce((acc, item) => {

        return (acc.find(f => f.tx_type === item.tx_type) === undefined ) && item.tx_type !== '' ? [...acc, item.tx_type] : acc;
      }, []);


      keys.forEach(k => {
        result[k] = this.propsData.filter(f => f.tx_type === k)
      })

      return result;
    },

    createGraph() {
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];

      const graphList = this.getGraphData();
      const colors = ['#009cfa', '#01ffc2'];

      Object.keys(graphList).forEach((item, index) => {
        const data = graphList[item].map((a, i) => {
          const date = new Date(graphList[item][i]?.date?.split('T')[0]);
          return {x: (date.getDate() +". "+ monthNames[date.getMonth()]), y: graphList[item][i].tx_count}
        })

        this.barData.datasets.push({
          label: item,
          data: data,
          backgroundColor: colors[index] ?? '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0'),
          fill: false
        })
      })

      const chrt = this.$refs.chart;
      this.chart = new Chart(chrt, {
        type: this.type,
        data: this.barData,
        options: this.options
      });

      window.addEventListener('resize', () => {
        this.updateBodyFontSize()
        this.fontResize()
      })
    },

    fontResize() {
      // const scaleCoef = window.innerWidth < 500 ? 3.3 : 1;
      const isMobile = window.matchMedia("(orientation: portrait)").matches
                    && window.innerWidth < 1024
                    && document.querySelector('.body-wrap').classList.contains('mobileScreen');

      const scaleCoef = isMobile ? 3.3 : 1;
      // const scaleCoef = 1;

      this.options.scales.x.ticks.font.size = this.bodyFontSize*scaleCoef;
      this.options.scales.y.ticks.font.size = this.bodyFontSize*scaleCoef;

      this.options.plugins.legend.labels.font.size = this.bodyFontSize*scaleCoef;
      this.options.plugins.legend.labels.boxWidth = this.bodyFontSize*5;
      this.options.plugins.legend.labels.boxHeight = this.bodyFontSize*.8;
    },

    updateBodyFontSize() {
      this.bodyFontSize = +Number(window.getComputedStyle(document.body).fontSize.replace('px', '')).toFixed();
      // console.log(this.bodyFontSize);
    }
  },

  mounted() {
    this.updateBodyFontSize()
    this.fontResize()

    this.createGraph(this.propsData)
  },
}
</script>

<style lang="scss" scoped>
canvas {
  height: 37rem !important;
}

.mobileScreen {
  @media (max-width: 500px) {
    canvas {
      height: 60rem !important;
    }
  }
}
</style>

<script setup>
</script>
