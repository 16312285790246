<template>
  <popup-template
    popupName="lost-password-code"
    @closePopup="$emit('closePopup')"
     v-if="hasEmailField || has2fa"
  >
    <!-- <div class="popups__heading"><span>Подтверждение кода</span></div> -->

    <form class="form form-signin" id="signinForm" action="#">
      <div class="form__wrap" :class="{ valid: confirmationValide === true }" v-if="hasEmailField">
        <label for="confirmation">{{t('popups.confirmation.code_email')}}</label>
        <input
          class="form__input"
          id="confirmation"
          type="text"
          name="confirmation"
          placeholder="****"
          v-model="confirmation"
          ref="confirmInput"
        />
        <div
          class="error-item"
          :class="{ active: confirmationValide === false }"
        >
          <img src="img/home/err.png" alt="" />
           <span>{{t('popups.confirmation.invalid_code')}}</span>
        </div>
      </div>

      <div class="form__wrap" :class="{ valid: confirmation2faValide === true }" v-if="has2fa === true">
        <label for="confirmation2fa">{{t('popups.confirmation.enter_code_2fa')}}</label>
        <input
          class="form__input"
          id="confirmation2fa"
          type="text"
          name="confirmation2fa"
          placeholder="****"
          v-model="confirmation2fa"
        />
        <div
          class="error-item"
          :class="{ active: confirmation2faValide === false }"
        >
          <img src="img/home/err.png" alt="" />
          <span>{{t('popups.confirmation.invalid_code')}}</span>
        </div>
      </div>

      <!-- <div class="popups__wallet_comission">
        <span>Введите код, который вам пришел на почту</span>
      </div> -->
      <div class="form__wrap form-wrap-btn">
        <button
          class="form__submit"
          id="regSubmitBtn"
          type="submit"
          @click.prevent="confirmCode"
        >
          {{t('popups.confirmation.button')}}
        </button>
      </div>
      <div class="error-item" :class="{ active: error === true }">
        <img src="img/home/err.png" alt="" /><span>{{ errorMessage }}</span>
      </div>
    </form>
  </popup-template>
</template>

<script>
import {session} from '@/mixins/session'
import PopupTemplate from "./PopupTemplate";
import { useI18n } from 'vue-i18n'
import { mapState } from 'vuex';

export default {
  props: [
    'data'
  ],

  mixins: [
    session
  ],

  components: {
    PopupTemplate,
  },

  computed: {
    ...mapState({
      confirmationFields: state => state.confirmation.confirmation.fieldsList
    }),

    hasEmailField() {
      // return true;
      return this.confirmationFields?.find(f => f.kindId === 3) !== undefined;
    }
  },

	data() {
		return {
			confirmation: '',
			confirmationValide: null,

			confirmation2fa: '',
			confirmation2faValide: null,

      error: false,
      errorMessage: 'invalid argument',

      has2fa: false
		}
	},

  methods: {
    resetError() {
      this.error = false;
    },

    validateConfirmation(value) {
      const regex = /^(?=.*[A-Za-z0-9_]$)[A-Za-z0-9_][A-Za-z\d.-]{0,19}$/;

      return regex.test(value);
    },

    validateConfirmation2fa(value) {
      const regex = /^[^\s]+(\s+[^\s]+)*$/;

      return regex.test(value);
    },

    generateCodeConfirmationFunction() {
      this.$store.dispatch('ConfirmationGeneratePassword')
        .then(() => {
          if (this.confirmationFields.find(f => f.kindId === 2) !== undefined) this.has2fa = true;
      }).catch((err) => {
        
        this.error = true;
        this.errorMessage = err.message;
      })
    },

    getConfirmation(cb, args) {
      this.$store.dispatch('ConfirmationGet', this.data.confirmationId).then(() => {
        if (cb !== undefined) cb(args)
      }).catch((err) => {
        
        this.error = true;
        this.errorMessage = err.message;
      })
    },

    generateCodeConfirmation() {
      // generateEmailConfirmation
      // this.generateCodeConfirmationFunction()
      this.getConfirmation(this.generateCodeConfirmationFunction)
    },

    cancelConfirmation() {
      this.$store.dispatch('ConfirmationCancel').catch((err) => {
        this.error = true;
        this.errorMessage = err.message;
      })
    },

    confirmCode() {
      if (!this.confirmationValide && this.hasEmailField) return;

      const args = {};

      if (this.confirmation !== '') {
        args.emailValue1 = this.confirmation;
      }

      if (this.confirmation2fa !== '') {
        args.gaValue = this.confirmation2fa;
      }
      

      this.$store.dispatch('ConfirmationConfirm', {...args})
        .then((response) => {
          if (response.status !== 1) {
            this.error = true;
            this.errorMessage = response.fieldsList.find(f => f.status !== 0).comment;
          } else if (response.status === 1) {
            if (this.data.showSuccessPopup) {
              // this.$emit('closePopup');
              this.$emit('openPopup', {name: 'PopupSuccess', data: {sum: this.data.sum}})

              return;
            }

            // в админку
            localStorage.setItem('userAuth', true);

            if (this.data.signUpSuccess === true) {
              this.$emit('openPopup', { name: 'PopupDoneAuth'})
              return;
            }

            this.$emit('closePopup')
            window.location.reload(true)
          }
      }).catch((err) => {
        this.error = true;
        console.log(err);
        this.errorMessage = err.message;
      })
		},
  },

  watch: {
		confirmation(value) {
      this.confirmationValide = this.validateConfirmation(value);
      this.error && this.resetError()
    },

		confirmation2fa(value) {
      this.confirmation2faValide = this.validateConfirmation2fa(value);
      this.error && this.resetError()
    },
  },

  mounted () {  
    this.generateCodeConfirmation();

    if (this.$refs.confirmInput) {
      this.$refs.confirmInput.focus();
    }
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  }
};
</script>

<style scoped>
.form__wrap.form-wrap-btn {
	margin-top: 2em
}
</style>