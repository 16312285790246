<template>
  <button :class="`binary__pyramid_item_1 item`">
    <div :class="`binary__pyramid_item_1_img`" :style="{background: packageColors[currentPackageTitle]}">
      <img src="img/binary/user-icon.png" alt="">
    </div>
    <div class="binary__pyramid_item_text">
      <span :class="{hide: hideElems}">{{position?.account?.login ?? "hide"}}</span>
      <span class="rank" :class="{hide: hideElems}">{{ currentPackageTitle ?? "hide" }}</span>
    </div>
  </button>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: [
    'position',
    'itemIndex',
    'lineIndex',
    'nohover',
  ],

  computed: {
    ...mapState({
      // ранг
      ranksList: (state) => state.career.info?.ranksList,
      farmingPackages: (state) => state.farming.farmingPackages,
    }),

    currentRankTitle() {
      return this.ranksList?.find(r => r.rankId === this.position?.account?.currentRankId ?? -1)?.rankTitle ?? '-';
    },

    currentPackageTitle() {
      return this.farmingPackages?.find(p => p.packageId === this.position?.account?.currentPackageId)?.packageTitle ?? '-';
    },

    hideElems() {
      return this.position?.account?.login === undefined;
    }
  },
  
  data() {
    return {
      packageColors: {
        "Bronze": "#dfcb98",
        "Silver": "#8a8484",
        "Gold": "#dcc92d",
        "Platinum": "#c4c4c4",
        "Diamond": "#68d5f6",
      }
    }
  },
}
</script>

<style lang="scss" scoped>


.binary__pyramid_item  {
  &_1 {
    margin: 1em 0;

    &.item {
      transition: opacity .2s ease-in-out;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row !important;
      width: 100%;

      &:hover {
        opacity: .7;
      }

      &.nohover {
        opacity: 1 !important;
      }
    }
    &_img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: em(40);
      min-width: em(40);
      height: em(40);

      & img {
        width: 30%;
        border-radius: 0;
        object-fit: unset;
      }
    }
  }
  &_text {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 0;
    margin-left: 1em;
  }
}
</style>