// source: mlmbox/type/confirmation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
var mlmbox_type_time_point_pb = require('../../mlmbox/type/time_point_pb.js');
goog.object.extend(proto, mlmbox_type_time_point_pb);
goog.exportSymbol('proto.mlmbox.type.Confirmation', null, global);
goog.exportSymbol('proto.mlmbox.type.Confirmation.Field', null, global);
goog.exportSymbol('proto.mlmbox.type.Confirmation.Field.Kind', null, global);
goog.exportSymbol('proto.mlmbox.type.Confirmation.Field.Notification', null, global);
goog.exportSymbol('proto.mlmbox.type.Confirmation.ID', null, global);
goog.exportSymbol('proto.mlmbox.type.Confirmation.ID.UniqueFieldCase', null, global);
goog.exportSymbol('proto.mlmbox.type.Confirmation.Option', null, global);
goog.exportSymbol('proto.mlmbox.type.Confirmation.Status', null, global);
goog.exportSymbol('proto.mlmbox.type.ConfirmationConfirmResponse', null, global);
goog.exportSymbol('proto.mlmbox.type.ConfirmationConfirmResponse.Field', null, global);
goog.exportSymbol('proto.mlmbox.type.ConfirmationConfirmResponse.Field.Status', null, global);
goog.exportSymbol('proto.mlmbox.type.ConfirmationConfirmResponse.Status', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Confirmation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.type.Confirmation.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.type.Confirmation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Confirmation.displayName = 'proto.mlmbox.type.Confirmation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Confirmation.ID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.mlmbox.type.Confirmation.ID.oneofGroups_);
};
goog.inherits(proto.mlmbox.type.Confirmation.ID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Confirmation.ID.displayName = 'proto.mlmbox.type.Confirmation.ID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Confirmation.Field = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Confirmation.Field, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Confirmation.Field.displayName = 'proto.mlmbox.type.Confirmation.Field';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Confirmation.Field.Notification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Confirmation.Field.Notification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Confirmation.Field.Notification.displayName = 'proto.mlmbox.type.Confirmation.Field.Notification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Confirmation.Option = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.type.Confirmation.Option.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.type.Confirmation.Option, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Confirmation.Option.displayName = 'proto.mlmbox.type.Confirmation.Option';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.ConfirmationConfirmResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.type.ConfirmationConfirmResponse.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.type.ConfirmationConfirmResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.ConfirmationConfirmResponse.displayName = 'proto.mlmbox.type.ConfirmationConfirmResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.ConfirmationConfirmResponse.Field = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.ConfirmationConfirmResponse.Field, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.ConfirmationConfirmResponse.Field.displayName = 'proto.mlmbox.type.ConfirmationConfirmResponse.Field';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.type.Confirmation.repeatedFields_ = [9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Confirmation.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Confirmation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Confirmation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Confirmation.toObject = function(includeInstance, msg) {
  var f, obj = {
    confirmationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    methodName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    statusId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
    expiresAt: (f = msg.getExpiresAt()) && mlmbox_type_time_point_pb.TimePoint.toObject(includeInstance, f),
    modifiedAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    counterLimit: jspb.Message.getFieldWithDefault(msg, 7, 0),
    counterValue: jspb.Message.getFieldWithDefault(msg, 8, 0),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.mlmbox.type.Confirmation.Field.toObject, includeInstance),
    optionsList: jspb.Message.toObjectList(msg.getOptionsList(),
    proto.mlmbox.type.Confirmation.Option.toObject, includeInstance),
    description: (f = msg.getDescription()) && google_protobuf_any_pb.Any.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Confirmation}
 */
proto.mlmbox.type.Confirmation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Confirmation;
  return proto.mlmbox.type.Confirmation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Confirmation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Confirmation}
 */
proto.mlmbox.type.Confirmation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setConfirmationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethodName(value);
      break;
    case 3:
      var value = /** @type {!proto.mlmbox.type.Confirmation.Status} */ (reader.readEnum());
      msg.setStatusId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = new mlmbox_type_time_point_pb.TimePoint;
      reader.readMessage(value,mlmbox_type_time_point_pb.TimePoint.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModifiedAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCounterLimit(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCounterValue(value);
      break;
    case 9:
      var value = new proto.mlmbox.type.Confirmation.Field;
      reader.readMessage(value,proto.mlmbox.type.Confirmation.Field.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 10:
      var value = new proto.mlmbox.type.Confirmation.Option;
      reader.readMessage(value,proto.mlmbox.type.Confirmation.Option.deserializeBinaryFromReader);
      msg.addOptions(value);
      break;
    case 11:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Confirmation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Confirmation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Confirmation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Confirmation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfirmationId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMethodName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatusId();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      mlmbox_type_time_point_pb.TimePoint.serializeBinaryToWriter
    );
  }
  f = message.getModifiedAt();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getCounterLimit();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getCounterValue();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.mlmbox.type.Confirmation.Field.serializeBinaryToWriter
    );
  }
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.mlmbox.type.Confirmation.Option.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.mlmbox.type.Confirmation.Status = {
  ACTIVATED: 0,
  APPROVED: 1,
  REJECT: 2,
  CANCELED: 3,
  EXPIRED: 4
};


/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.mlmbox.type.Confirmation.ID.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.mlmbox.type.Confirmation.ID.UniqueFieldCase = {
  UNIQUE_FIELD_NOT_SET: 0,
  CONFIRMATION_ID: 1,
  METHOD_NAME: 2
};

/**
 * @return {proto.mlmbox.type.Confirmation.ID.UniqueFieldCase}
 */
proto.mlmbox.type.Confirmation.ID.prototype.getUniqueFieldCase = function() {
  return /** @type {proto.mlmbox.type.Confirmation.ID.UniqueFieldCase} */(jspb.Message.computeOneofCase(this, proto.mlmbox.type.Confirmation.ID.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Confirmation.ID.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Confirmation.ID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Confirmation.ID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Confirmation.ID.toObject = function(includeInstance, msg) {
  var f, obj = {
    confirmationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    methodName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Confirmation.ID}
 */
proto.mlmbox.type.Confirmation.ID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Confirmation.ID;
  return proto.mlmbox.type.Confirmation.ID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Confirmation.ID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Confirmation.ID}
 */
proto.mlmbox.type.Confirmation.ID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setConfirmationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethodName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Confirmation.ID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Confirmation.ID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Confirmation.ID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Confirmation.ID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 confirmation_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Confirmation.ID.prototype.getConfirmationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Confirmation.ID} returns this
 */
proto.mlmbox.type.Confirmation.ID.prototype.setConfirmationId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.mlmbox.type.Confirmation.ID.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mlmbox.type.Confirmation.ID} returns this
 */
proto.mlmbox.type.Confirmation.ID.prototype.clearConfirmationId = function() {
  return jspb.Message.setOneofField(this, 1, proto.mlmbox.type.Confirmation.ID.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Confirmation.ID.prototype.hasConfirmationId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string method_name = 2;
 * @return {string}
 */
proto.mlmbox.type.Confirmation.ID.prototype.getMethodName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Confirmation.ID} returns this
 */
proto.mlmbox.type.Confirmation.ID.prototype.setMethodName = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.mlmbox.type.Confirmation.ID.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mlmbox.type.Confirmation.ID} returns this
 */
proto.mlmbox.type.Confirmation.ID.prototype.clearMethodName = function() {
  return jspb.Message.setOneofField(this, 2, proto.mlmbox.type.Confirmation.ID.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Confirmation.ID.prototype.hasMethodName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Confirmation.Field.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Confirmation.Field.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Confirmation.Field} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Confirmation.Field.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    kindId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    notification: (f = msg.getNotification()) && proto.mlmbox.type.Confirmation.Field.Notification.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Confirmation.Field}
 */
proto.mlmbox.type.Confirmation.Field.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Confirmation.Field;
  return proto.mlmbox.type.Confirmation.Field.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Confirmation.Field} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Confirmation.Field}
 */
proto.mlmbox.type.Confirmation.Field.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFieldId(value);
      break;
    case 2:
      var value = /** @type {!proto.mlmbox.type.Confirmation.Field.Kind} */ (reader.readEnum());
      msg.setKindId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = new proto.mlmbox.type.Confirmation.Field.Notification;
      reader.readMessage(value,proto.mlmbox.type.Confirmation.Field.Notification.deserializeBinaryFromReader);
      msg.setNotification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Confirmation.Field.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Confirmation.Field.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Confirmation.Field} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Confirmation.Field.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getKindId();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNotification();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.mlmbox.type.Confirmation.Field.Notification.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.mlmbox.type.Confirmation.Field.Kind = {
  UNKNOWN: 0,
  PASSWORD: 1,
  GOOGLE_AUTHENTICATOR_CODE: 2,
  EMAIL_CONFIRM_CODE: 3,
  EMAIL_FILL: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Confirmation.Field.Notification.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Confirmation.Field.Notification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Confirmation.Field.Notification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Confirmation.Field.Notification.toObject = function(includeInstance, msg) {
  var f, obj = {
    nextAt: jspb.Message.getFieldWithDefault(msg, 1, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    expiresAt: (f = msg.getExpiresAt()) && mlmbox_type_time_point_pb.TimePoint.toObject(includeInstance, f),
    counterLimit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    counterValue: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Confirmation.Field.Notification}
 */
proto.mlmbox.type.Confirmation.Field.Notification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Confirmation.Field.Notification;
  return proto.mlmbox.type.Confirmation.Field.Notification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Confirmation.Field.Notification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Confirmation.Field.Notification}
 */
proto.mlmbox.type.Confirmation.Field.Notification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNextAt(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = new mlmbox_type_time_point_pb.TimePoint;
      reader.readMessage(value,mlmbox_type_time_point_pb.TimePoint.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCounterLimit(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCounterValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Confirmation.Field.Notification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Confirmation.Field.Notification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Confirmation.Field.Notification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Confirmation.Field.Notification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNextAt();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      mlmbox_type_time_point_pb.TimePoint.serializeBinaryToWriter
    );
  }
  f = message.getCounterLimit();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getCounterValue();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional uint32 next_at = 1;
 * @return {number}
 */
proto.mlmbox.type.Confirmation.Field.Notification.prototype.getNextAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Confirmation.Field.Notification} returns this
 */
proto.mlmbox.type.Confirmation.Field.Notification.prototype.setNextAt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 created_at = 2;
 * @return {number}
 */
proto.mlmbox.type.Confirmation.Field.Notification.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Confirmation.Field.Notification} returns this
 */
proto.mlmbox.type.Confirmation.Field.Notification.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional TimePoint expires_at = 3;
 * @return {?proto.mlmbox.type.TimePoint}
 */
proto.mlmbox.type.Confirmation.Field.Notification.prototype.getExpiresAt = function() {
  return /** @type{?proto.mlmbox.type.TimePoint} */ (
    jspb.Message.getWrapperField(this, mlmbox_type_time_point_pb.TimePoint, 3));
};


/**
 * @param {?proto.mlmbox.type.TimePoint|undefined} value
 * @return {!proto.mlmbox.type.Confirmation.Field.Notification} returns this
*/
proto.mlmbox.type.Confirmation.Field.Notification.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Confirmation.Field.Notification} returns this
 */
proto.mlmbox.type.Confirmation.Field.Notification.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Confirmation.Field.Notification.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint32 counter_limit = 4;
 * @return {number}
 */
proto.mlmbox.type.Confirmation.Field.Notification.prototype.getCounterLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Confirmation.Field.Notification} returns this
 */
proto.mlmbox.type.Confirmation.Field.Notification.prototype.setCounterLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 counter_value = 5;
 * @return {number}
 */
proto.mlmbox.type.Confirmation.Field.Notification.prototype.getCounterValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Confirmation.Field.Notification} returns this
 */
proto.mlmbox.type.Confirmation.Field.Notification.prototype.setCounterValue = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 field_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Confirmation.Field.prototype.getFieldId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Confirmation.Field} returns this
 */
proto.mlmbox.type.Confirmation.Field.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Kind kind_id = 2;
 * @return {!proto.mlmbox.type.Confirmation.Field.Kind}
 */
proto.mlmbox.type.Confirmation.Field.prototype.getKindId = function() {
  return /** @type {!proto.mlmbox.type.Confirmation.Field.Kind} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.mlmbox.type.Confirmation.Field.Kind} value
 * @return {!proto.mlmbox.type.Confirmation.Field} returns this
 */
proto.mlmbox.type.Confirmation.Field.prototype.setKindId = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.mlmbox.type.Confirmation.Field.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Confirmation.Field} returns this
 */
proto.mlmbox.type.Confirmation.Field.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Notification notification = 4;
 * @return {?proto.mlmbox.type.Confirmation.Field.Notification}
 */
proto.mlmbox.type.Confirmation.Field.prototype.getNotification = function() {
  return /** @type{?proto.mlmbox.type.Confirmation.Field.Notification} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.type.Confirmation.Field.Notification, 4));
};


/**
 * @param {?proto.mlmbox.type.Confirmation.Field.Notification|undefined} value
 * @return {!proto.mlmbox.type.Confirmation.Field} returns this
*/
proto.mlmbox.type.Confirmation.Field.prototype.setNotification = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Confirmation.Field} returns this
 */
proto.mlmbox.type.Confirmation.Field.prototype.clearNotification = function() {
  return this.setNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Confirmation.Field.prototype.hasNotification = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.type.Confirmation.Option.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Confirmation.Option.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Confirmation.Option.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Confirmation.Option} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Confirmation.Option.toObject = function(includeInstance, msg) {
  var f, obj = {
    optionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fieldIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Confirmation.Option}
 */
proto.mlmbox.type.Confirmation.Option.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Confirmation.Option;
  return proto.mlmbox.type.Confirmation.Option.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Confirmation.Option} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Confirmation.Option}
 */
proto.mlmbox.type.Confirmation.Option.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOptionId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFieldIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Confirmation.Option.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Confirmation.Option.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Confirmation.Option} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Confirmation.Option.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOptionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getFieldIdsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 option_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Confirmation.Option.prototype.getOptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Confirmation.Option} returns this
 */
proto.mlmbox.type.Confirmation.Option.prototype.setOptionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated uint32 field_ids = 2;
 * @return {!Array<number>}
 */
proto.mlmbox.type.Confirmation.Option.prototype.getFieldIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.mlmbox.type.Confirmation.Option} returns this
 */
proto.mlmbox.type.Confirmation.Option.prototype.setFieldIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.Confirmation.Option} returns this
 */
proto.mlmbox.type.Confirmation.Option.prototype.addFieldIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.type.Confirmation.Option} returns this
 */
proto.mlmbox.type.Confirmation.Option.prototype.clearFieldIdsList = function() {
  return this.setFieldIdsList([]);
};


/**
 * optional uint32 confirmation_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Confirmation.prototype.getConfirmationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Confirmation} returns this
 */
proto.mlmbox.type.Confirmation.prototype.setConfirmationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string method_name = 2;
 * @return {string}
 */
proto.mlmbox.type.Confirmation.prototype.getMethodName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Confirmation} returns this
 */
proto.mlmbox.type.Confirmation.prototype.setMethodName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Status status_id = 3;
 * @return {!proto.mlmbox.type.Confirmation.Status}
 */
proto.mlmbox.type.Confirmation.prototype.getStatusId = function() {
  return /** @type {!proto.mlmbox.type.Confirmation.Status} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.mlmbox.type.Confirmation.Status} value
 * @return {!proto.mlmbox.type.Confirmation} returns this
 */
proto.mlmbox.type.Confirmation.prototype.setStatusId = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint32 created_at = 4;
 * @return {number}
 */
proto.mlmbox.type.Confirmation.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Confirmation} returns this
 */
proto.mlmbox.type.Confirmation.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional TimePoint expires_at = 5;
 * @return {?proto.mlmbox.type.TimePoint}
 */
proto.mlmbox.type.Confirmation.prototype.getExpiresAt = function() {
  return /** @type{?proto.mlmbox.type.TimePoint} */ (
    jspb.Message.getWrapperField(this, mlmbox_type_time_point_pb.TimePoint, 5));
};


/**
 * @param {?proto.mlmbox.type.TimePoint|undefined} value
 * @return {!proto.mlmbox.type.Confirmation} returns this
*/
proto.mlmbox.type.Confirmation.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Confirmation} returns this
 */
proto.mlmbox.type.Confirmation.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Confirmation.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint32 modified_at = 6;
 * @return {number}
 */
proto.mlmbox.type.Confirmation.prototype.getModifiedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Confirmation} returns this
 */
proto.mlmbox.type.Confirmation.prototype.setModifiedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 counter_limit = 7;
 * @return {number}
 */
proto.mlmbox.type.Confirmation.prototype.getCounterLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Confirmation} returns this
 */
proto.mlmbox.type.Confirmation.prototype.setCounterLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 counter_value = 8;
 * @return {number}
 */
proto.mlmbox.type.Confirmation.prototype.getCounterValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Confirmation} returns this
 */
proto.mlmbox.type.Confirmation.prototype.setCounterValue = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated Field fields = 9;
 * @return {!Array<!proto.mlmbox.type.Confirmation.Field>}
 */
proto.mlmbox.type.Confirmation.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.mlmbox.type.Confirmation.Field>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.type.Confirmation.Field, 9));
};


/**
 * @param {!Array<!proto.mlmbox.type.Confirmation.Field>} value
 * @return {!proto.mlmbox.type.Confirmation} returns this
*/
proto.mlmbox.type.Confirmation.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.mlmbox.type.Confirmation.Field=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.Confirmation.Field}
 */
proto.mlmbox.type.Confirmation.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.mlmbox.type.Confirmation.Field, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.type.Confirmation} returns this
 */
proto.mlmbox.type.Confirmation.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * repeated Option options = 10;
 * @return {!Array<!proto.mlmbox.type.Confirmation.Option>}
 */
proto.mlmbox.type.Confirmation.prototype.getOptionsList = function() {
  return /** @type{!Array<!proto.mlmbox.type.Confirmation.Option>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.type.Confirmation.Option, 10));
};


/**
 * @param {!Array<!proto.mlmbox.type.Confirmation.Option>} value
 * @return {!proto.mlmbox.type.Confirmation} returns this
*/
proto.mlmbox.type.Confirmation.prototype.setOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.mlmbox.type.Confirmation.Option=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.Confirmation.Option}
 */
proto.mlmbox.type.Confirmation.prototype.addOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.mlmbox.type.Confirmation.Option, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.type.Confirmation} returns this
 */
proto.mlmbox.type.Confirmation.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};


/**
 * optional google.protobuf.Any description = 11;
 * @return {?proto.google.protobuf.Any}
 */
proto.mlmbox.type.Confirmation.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.Any} */ (
    jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 11));
};


/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.mlmbox.type.Confirmation} returns this
*/
proto.mlmbox.type.Confirmation.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Confirmation} returns this
 */
proto.mlmbox.type.Confirmation.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Confirmation.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.type.ConfirmationConfirmResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.ConfirmationConfirmResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.ConfirmationConfirmResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.ConfirmationConfirmResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.ConfirmationConfirmResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.mlmbox.type.ConfirmationConfirmResponse.Field.toObject, includeInstance),
    data: (f = msg.getData()) && google_protobuf_any_pb.Any.toObject(includeInstance, f),
    accessToken: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.ConfirmationConfirmResponse}
 */
proto.mlmbox.type.ConfirmationConfirmResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.ConfirmationConfirmResponse;
  return proto.mlmbox.type.ConfirmationConfirmResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.ConfirmationConfirmResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.ConfirmationConfirmResponse}
 */
proto.mlmbox.type.ConfirmationConfirmResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.mlmbox.type.ConfirmationConfirmResponse.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = new proto.mlmbox.type.ConfirmationConfirmResponse.Field;
      reader.readMessage(value,proto.mlmbox.type.ConfirmationConfirmResponse.Field.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 3:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.ConfirmationConfirmResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.ConfirmationConfirmResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.ConfirmationConfirmResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.ConfirmationConfirmResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.mlmbox.type.ConfirmationConfirmResponse.Field.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.mlmbox.type.ConfirmationConfirmResponse.Status = {
  REJECT: 0,
  APPROVED: 1
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.ConfirmationConfirmResponse.Field.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.ConfirmationConfirmResponse.Field.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.ConfirmationConfirmResponse.Field} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.ConfirmationConfirmResponse.Field.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.ConfirmationConfirmResponse.Field}
 */
proto.mlmbox.type.ConfirmationConfirmResponse.Field.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.ConfirmationConfirmResponse.Field;
  return proto.mlmbox.type.ConfirmationConfirmResponse.Field.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.ConfirmationConfirmResponse.Field} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.ConfirmationConfirmResponse.Field}
 */
proto.mlmbox.type.ConfirmationConfirmResponse.Field.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFieldId(value);
      break;
    case 2:
      var value = /** @type {!proto.mlmbox.type.ConfirmationConfirmResponse.Field.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.ConfirmationConfirmResponse.Field.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.ConfirmationConfirmResponse.Field.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.ConfirmationConfirmResponse.Field} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.ConfirmationConfirmResponse.Field.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.mlmbox.type.ConfirmationConfirmResponse.Field.Status = {
  OK: 0,
  EMPTY: 1,
  WRONG: 2,
  EXPIRED: 3
};

/**
 * optional uint32 field_id = 1;
 * @return {number}
 */
proto.mlmbox.type.ConfirmationConfirmResponse.Field.prototype.getFieldId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.ConfirmationConfirmResponse.Field} returns this
 */
proto.mlmbox.type.ConfirmationConfirmResponse.Field.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Status status = 2;
 * @return {!proto.mlmbox.type.ConfirmationConfirmResponse.Field.Status}
 */
proto.mlmbox.type.ConfirmationConfirmResponse.Field.prototype.getStatus = function() {
  return /** @type {!proto.mlmbox.type.ConfirmationConfirmResponse.Field.Status} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.mlmbox.type.ConfirmationConfirmResponse.Field.Status} value
 * @return {!proto.mlmbox.type.ConfirmationConfirmResponse.Field} returns this
 */
proto.mlmbox.type.ConfirmationConfirmResponse.Field.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.mlmbox.type.ConfirmationConfirmResponse.Field.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.ConfirmationConfirmResponse.Field} returns this
 */
proto.mlmbox.type.ConfirmationConfirmResponse.Field.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Status status = 1;
 * @return {!proto.mlmbox.type.ConfirmationConfirmResponse.Status}
 */
proto.mlmbox.type.ConfirmationConfirmResponse.prototype.getStatus = function() {
  return /** @type {!proto.mlmbox.type.ConfirmationConfirmResponse.Status} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.mlmbox.type.ConfirmationConfirmResponse.Status} value
 * @return {!proto.mlmbox.type.ConfirmationConfirmResponse} returns this
 */
proto.mlmbox.type.ConfirmationConfirmResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated Field fields = 2;
 * @return {!Array<!proto.mlmbox.type.ConfirmationConfirmResponse.Field>}
 */
proto.mlmbox.type.ConfirmationConfirmResponse.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.mlmbox.type.ConfirmationConfirmResponse.Field>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.type.ConfirmationConfirmResponse.Field, 2));
};


/**
 * @param {!Array<!proto.mlmbox.type.ConfirmationConfirmResponse.Field>} value
 * @return {!proto.mlmbox.type.ConfirmationConfirmResponse} returns this
*/
proto.mlmbox.type.ConfirmationConfirmResponse.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.mlmbox.type.ConfirmationConfirmResponse.Field=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.ConfirmationConfirmResponse.Field}
 */
proto.mlmbox.type.ConfirmationConfirmResponse.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.mlmbox.type.ConfirmationConfirmResponse.Field, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.type.ConfirmationConfirmResponse} returns this
 */
proto.mlmbox.type.ConfirmationConfirmResponse.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional google.protobuf.Any data = 3;
 * @return {?proto.google.protobuf.Any}
 */
proto.mlmbox.type.ConfirmationConfirmResponse.prototype.getData = function() {
  return /** @type{?proto.google.protobuf.Any} */ (
    jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 3));
};


/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.mlmbox.type.ConfirmationConfirmResponse} returns this
*/
proto.mlmbox.type.ConfirmationConfirmResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.ConfirmationConfirmResponse} returns this
 */
proto.mlmbox.type.ConfirmationConfirmResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.ConfirmationConfirmResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string access_token = 4;
 * @return {string}
 */
proto.mlmbox.type.ConfirmationConfirmResponse.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.ConfirmationConfirmResponse} returns this
 */
proto.mlmbox.type.ConfirmationConfirmResponse.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


goog.object.extend(exports, proto.mlmbox.type);
