<template>
  <div class="error-404">
    <div class="heading">
      <span><b>404</b> - page not found</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error_404",
}
</script>

<style scoped lang="scss">
.error-404 {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 10em;
}

.heading {
  color: #fff;
  font-size: 2em;

  b {
    font-size: 1.3em;
  }
}
</style>