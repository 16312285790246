<template>
  <popup-template popupName="done-registration" @closePopup="$emit('closePopup')">
    <div class="popups__heading">
      <img src="img/home/done.png" alt="" />
      <span>{{t('popups.done.title')}}</span>
    </div>
    <div class="popups__done_reg_block">
      <img src="img/home/hands.png" alt="" />
      <span v-html="t('popups.done.description')"></span>
    </div>
    <button class="popups__btn_withdraw reg-done close" 
      @click="toHomePage">
      <span>{{t('popups.done.button')}}</span>
    </button>
  </popup-template>
</template>

<script>
import PopupTemplate from "./PopupTemplate";
import { useI18n } from 'vue-i18n'

export default {
  components: {
    PopupTemplate,
  },

  methods: {
    toHomePage() {
      if (this.$route.path !== '/') this.$router.push('/');
      else window.location.reload(true)
    }
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  }
};
</script>
