<template>
  <popup-template popupName="lost-password" @closePopup="$emit('closePopup')">
    <div class="popups__heading">
      <span>{{t('popups.lost_password.title')}}</span>
    </div>
    <form class="form form-signin" id="signinForm" action="#">
      <div class="popups__wallet_adress_block">
        <div class="form__wrap popups__wallet_for_whom_summ" 
          :class="{ valid: emailValide === true }">
          <label for="regEmail">Email:</label>
          <input
            class="form__input"
            id="regEmail"
            type="text"
            name="regEmail"
            placeholder="Введите ваш email"
            v-model="email"
          />
          <div class="error-item" :class="{ active: emailValide === false }">
            <img src="img/home/err.png" alt="" />
            <span>{{t('popups.lost_password.invalid_email')}}</span>
          </div>
        </div>
        <div class="popups__wallet_comission">
          <span>{{t('popups.lost_password.enter_email')}}</span>
        </div>
      </div>
      <button class="popups__btn_withdraw lost-email-btn close" @click="goToNewPasswordPopup">
        <span>{{t('popups.lost_password.send')}}</span>
      </button>
      <div class="error-item" :class="{ active: error === true }">
        <img src="img/home/err.png" alt="" />
        <span>{{errorMessage}}</span>
      </div>
    </form>
  </popup-template>
</template>

<script>
import PopupTemplate from "./PopupTemplate";
import { useI18n } from 'vue-i18n'

export default {
  components: {
    PopupTemplate,
  },

  data() {
    return {
      email: "",
      emailValide: null,

      error: false,
      errorMessage: "invalid argument"
    }
  },

  methods: {
    resetError() {
      this.error = false;
    },

    validateEmail(value) {
      const noSpacesRegex = /^[^\s]+(\s+[^\s]+)*$/;
      const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,10}$/;

      return noSpacesRegex.test(value) && emailRegex.test(value);
    },

    goToNewPasswordPopup(e) { 
      e.preventDefault()

      if (this.emailValide === true) {
        localStorage.setItem('forgetFormEMAIL', this.email)
        this.$emit('openPopup', {name: 'PopupNewPassword'});
        
        return;
      }

      this.error = true;
    }
  },
  
  watch: {
    email(value) {
      this.emailValide = this.validateEmail(value);
      this.error && this.resetError();
    },
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    });

    return { t };
  }
};
</script>
