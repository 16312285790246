import { WalletClient } from "../../../../apis/mlmbox/client/finance/v1/wallet_grpc_web_pb";
import { WalletAssetGetRequest, WalletValidateAddressRequest,
    WalletAssetTransactionListRequest,
    WalletWithdrawalCreateRequest } from "../../../../apis/mlmbox/client/finance/v1/wallet_pb";
import { Sort } from "../../../../apis/mlmbox/type/sort_pb";
import { Wallet } from "../../../../apis/mlmbox/type/wallet_pb";
import { Empty } from 'google-protobuf/google/protobuf/empty_pb.js'
import { API_URL } from '@/api';

const client = new WalletClient(API_URL, null, {
    withCredentials: true
});

export default {
    state: {
        walletAssetsList: [],

        walletTransactions: [],
        walletBalanceHistory: [],

        processingList: [],

        //

        poolAsset: {},
        pool: {},

        processing: {},
        depositAdress: '',

        allPackages: [],
        package: {},
        contract: {},
    },

    mutations: {
        setWalletAssetsList(state, walletAssetList) {
            state.walletAssetsList = walletAssetList;
        },
        setWalletTransactions(state, walletTransaction) {
            state.walletTransactions = walletTransaction;
        },
        setWalletBalanceHistory(state, walletBalanceHistory) {
            state.walletBalanceHistory = walletBalanceHistory.transactionsList;
        },

        setProcessingList(state, processingList) {
            state.processingList = processingList;
        },



        setWallet(state, wallet) {
            state.wallet = wallet;
        },
        setWalletAsset(state, walletAsset) {
            state.walletAsset = walletAsset;
        },


        setPoolAsset(state, poolAsset) {
            state.poolAsset = poolAsset;
        },
        setPool(state, pool) {
            state.pool = pool;
        },

        setProcessing(state, processing) {
            state.processing = processing;
        },
        setDepositAdress(state, depositAdress) {
            state.depositAdress = depositAdress;
        },
    },

    actions: {
        getWalletInfo({commit}) {
            return new Promise((resolve, reject) => {
                const request = new Empty;

                client.info(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('setWallet', response.toObject())
                        resolve(response.toObject())
                    }
                })
            })
        },

        getWalletAsset({commit}, {assetId, side}) {
            return new Promise((resolve, reject) => {
                const request = new WalletAssetGetRequest();
                const sortWrapper = new Sort()

                sortWrapper.setSide(side) // ASC || DESC
                request.setAssetId(assetId)
                request.setSortTransactions(sortWrapper)

                client.info(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('setWalletAsset', response.toObject())
                        resolve(response.toObject())
                    }
                })
            })
        },

        getWalletAssetsList({commit}) {
            const result = [];
            commit('setWalletAssetsList', []);

            return new Promise((resolve, reject) => {
                const request = new Empty;

                const stream = client.assetsAll(request, {"authorization": localStorage.getItem("access_token")})

                stream.on('data', function(response) {
                    result.push(response.toObject())
                    resolve(response.toObject())
                });

                stream.on('status', (status) => {
                    if (status.code !== 0) reject(status);
                    if (status.code === 0) {
                        commit('setWalletAssetsList', result)
                    }
                });
            })
        },

        getWalletAssetTransactionGet({commit}, transactionId) {
            return new Promise((resolve, reject) => {
                const request = new Wallet.Asset.Transaction.ID();

                request.setTransactionId(transactionId);
                // stream

                client.assetTransactionGet(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('setWalletTransaction', response.toObject())
                        resolve(response.toObject())
                    }
                })
            })
        },

        getWalletTransactionList({ commit }, { stepTransactionId, assetId}) {
            const result = [];
            commit('setWalletTransactions', []);

            return new Promise((resolve) => {
                const request = new WalletAssetTransactionListRequest();

                request.setStepTransactionId(stepTransactionId);
                request.setAssetId(assetId);

                const stream = client.assetTransactionList(request, {"authorization": localStorage.getItem("access_token")})

                stream.on('data', function(response) {
                    result.push(response.toObject())
                    resolve(response.toObject())
                });

                stream.on('status', (status) => {
                    if (status.code === 0) {
                        commit('setWalletTransactions', result)
                    }
                });
            })
        },

        getWalletTransactionsPage({state, commit}, { assetId, stepTransactionId }) {
            const result = state.walletTransactions.length > 0 ? [...state.walletTransactions] : [];
            // const result = [];
            commit('setWalletTransactions', result);

            return new Promise((resolve, reject) => {
                const request = new WalletAssetTransactionListRequest();

                request.setStepTransactionId(stepTransactionId);
                request.setAssetId(assetId);

                const stream = client.assetTransactionList(request, {"authorization": localStorage.getItem("access_token")})

                stream.on('data', function(response) {
                    result.push(response.toObject());
                });

                stream.on('status', (status) => {
                    if (status.code === 0) {
                      resolve(result);
                      commit('setWalletTransactions', result)
                    } else if (status.code !== 0) reject(status);
                });
            })
          },

        getWalletBalanceHistory({ commit }, { assetId }) {
            return new Promise((resolve, reject) => {
                const request = new WalletAssetGetRequest();

                request.setAssetId(assetId)

                client.assetBalanceHistoryGet(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('setWalletBalanceHistory', response.toObject())
                        resolve(response.toObject())
                    }
                })
            })
        },

        // getWalletAssetBalanceHistoryList({ commit }, { stepTransactionId, assetId}) {
        //     let result = [];

        //     return new Promise((resolve) => {
        //         const request = new Wallet.Asset.Transaction.ID();

        //         request.setStepTransactionId(stepTransactionId);
        //         request.setAssetId(assetId);

        //         const stream = client.assetBalanceHistoryList(request, {"authorization": localStorage.getItem("access_token")})

        //         stream.on('data', function(response) {
        //             result.push(response.toObject())
        //             resolve(response.toObject())
        //         });

        //         stream.on('status', (status) => {
        //             if (status.code === 0) {
        //                 commit('setWalletBalanceHistory', result)
        //             }
        //         });
        //     })
        // },

        getWalletProcessingByCurrency({ commit }, { processingId }) {
            return new Promise((resolve, reject) => {
                const request = new Wallet.Processing.ID();

                request.setProcessingId(processingId);

                client.processingsGet(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('stub', response.toObject())
                        resolve(response.toObject())
                    }
                })
            })
        },

        getWalletProcessingsAll({ commit }) {
            const result = [];
            commit('setProcessingList', []);

            return new Promise((resolve) => {
                const request = new Empty;

                const stream = client.processingsAll(request, {"authorization": localStorage.getItem("access_token")})

                stream.on('data', function(response) {
                    console.log(response.toObject());
                    result.push(response.toObject())
                });

                stream.on('status', (status) => {
                    if (status.code === 0) {
                        commit('setProcessingList', result)
                        resolve(result)
                    }
                });
            })
        },

        generateDepositAddress({ commit }, {processingId}) {
            return new Promise((resolve, reject) => {
                const request = new Wallet.Processing.ID();

                request.setProcessingId(processingId);

                client.depositAddress(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('setDepositAdress', response.toObject().address)
                        resolve(response.toObject())
                    }
                })
            })
        },

        getWalletValidateAddress({ commit }, { processingId, address }) {
            return new Promise((resolve, reject) => {
                const request = new WalletValidateAddressRequest();

                request.setProcessingId(processingId);
                request.setAddress(address);

                client.validateAddress(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('stub', response.toObject())
                        resolve(response.toObject())
                    }
                })
            })
        },

        withdrawalCreate({ commit }, { processingId, assetId, operationAmount, address }) {
            return new Promise((resolve, reject) => {
                const request = new WalletWithdrawalCreateRequest();

                request.setProcessingId(processingId);
                request.setAssetId(assetId);
                request.setOperationAmount(operationAmount);
                request.setAddress(address);

                client.withdrawalCreate(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        console.log(response.toObject());

                        commit('stub', response.toObject())
                        resolve(response.toObject())
                    }
                })
            })
        },
    }
}
