/**
 * @fileoverview gRPC-Web generated client stub for mlmbox.client.finance.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.15.8
// source: mlmbox/client/finance/v1/wallet.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var mlmbox_type_confirmation_pb = require('../../../../mlmbox/type/confirmation_pb.js')

var mlmbox_type_sort_pb = require('../../../../mlmbox/type/sort_pb.js')

var mlmbox_type_wallet_pb = require('../../../../mlmbox/type/wallet_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.mlmbox = {};
proto.mlmbox.client = {};
proto.mlmbox.client.finance = {};
proto.mlmbox.client.finance.v1 = require('./wallet_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mlmbox.client.finance.v1.WalletClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mlmbox.client.finance.v1.WalletPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.mlmbox.type.Wallet>}
 */
const methodDescriptor_Wallet_Info = new grpc.web.MethodDescriptor(
  '/mlmbox.client.finance.v1.Wallet/Info',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  mlmbox_type_wallet_pb.Wallet,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_wallet_pb.Wallet.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.type.Wallet)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Wallet>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.finance.v1.WalletClient.prototype.info =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.finance.v1.Wallet/Info',
      request,
      metadata || {},
      methodDescriptor_Wallet_Info,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.type.Wallet>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.finance.v1.WalletPromiseClient.prototype.info =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.finance.v1.Wallet/Info',
      request,
      metadata || {},
      methodDescriptor_Wallet_Info);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.client.finance.v1.WalletAssetGetRequest,
 *   !proto.mlmbox.type.Wallet.Asset>}
 */
const methodDescriptor_Wallet_AssetGet = new grpc.web.MethodDescriptor(
  '/mlmbox.client.finance.v1.Wallet/AssetGet',
  grpc.web.MethodType.UNARY,
  proto.mlmbox.client.finance.v1.WalletAssetGetRequest,
  mlmbox_type_wallet_pb.Wallet.Asset,
  /**
   * @param {!proto.mlmbox.client.finance.v1.WalletAssetGetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_wallet_pb.Wallet.Asset.deserializeBinary
);


/**
 * @param {!proto.mlmbox.client.finance.v1.WalletAssetGetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.type.Wallet.Asset)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Wallet.Asset>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.finance.v1.WalletClient.prototype.assetGet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.finance.v1.Wallet/AssetGet',
      request,
      metadata || {},
      methodDescriptor_Wallet_AssetGet,
      callback);
};


/**
 * @param {!proto.mlmbox.client.finance.v1.WalletAssetGetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.type.Wallet.Asset>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.finance.v1.WalletPromiseClient.prototype.assetGet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.finance.v1.Wallet/AssetGet',
      request,
      metadata || {},
      methodDescriptor_Wallet_AssetGet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.mlmbox.type.Wallet.Asset>}
 */
const methodDescriptor_Wallet_AssetsAll = new grpc.web.MethodDescriptor(
  '/mlmbox.client.finance.v1.Wallet/AssetsAll',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  mlmbox_type_wallet_pb.Wallet.Asset,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_wallet_pb.Wallet.Asset.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Wallet.Asset>}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.finance.v1.WalletClient.prototype.assetsAll =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mlmbox.client.finance.v1.Wallet/AssetsAll',
      request,
      metadata || {},
      methodDescriptor_Wallet_AssetsAll);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Wallet.Asset>}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.finance.v1.WalletPromiseClient.prototype.assetsAll =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mlmbox.client.finance.v1.Wallet/AssetsAll',
      request,
      metadata || {},
      methodDescriptor_Wallet_AssetsAll);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.type.Wallet.Asset.Transaction.ID,
 *   !proto.mlmbox.type.Wallet.Asset.Transaction>}
 */
const methodDescriptor_Wallet_AssetTransactionGet = new grpc.web.MethodDescriptor(
  '/mlmbox.client.finance.v1.Wallet/AssetTransactionGet',
  grpc.web.MethodType.UNARY,
  mlmbox_type_wallet_pb.Wallet.Asset.Transaction.ID,
  mlmbox_type_wallet_pb.Wallet.Asset.Transaction,
  /**
   * @param {!proto.mlmbox.type.Wallet.Asset.Transaction.ID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_wallet_pb.Wallet.Asset.Transaction.deserializeBinary
);


/**
 * @param {!proto.mlmbox.type.Wallet.Asset.Transaction.ID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.type.Wallet.Asset.Transaction)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Wallet.Asset.Transaction>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.finance.v1.WalletClient.prototype.assetTransactionGet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.finance.v1.Wallet/AssetTransactionGet',
      request,
      metadata || {},
      methodDescriptor_Wallet_AssetTransactionGet,
      callback);
};


/**
 * @param {!proto.mlmbox.type.Wallet.Asset.Transaction.ID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.type.Wallet.Asset.Transaction>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.finance.v1.WalletPromiseClient.prototype.assetTransactionGet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.finance.v1.Wallet/AssetTransactionGet',
      request,
      metadata || {},
      methodDescriptor_Wallet_AssetTransactionGet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest,
 *   !proto.mlmbox.type.Wallet.Asset.Transaction>}
 */
const methodDescriptor_Wallet_AssetTransactionList = new grpc.web.MethodDescriptor(
  '/mlmbox.client.finance.v1.Wallet/AssetTransactionList',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest,
  mlmbox_type_wallet_pb.Wallet.Asset.Transaction,
  /**
   * @param {!proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_wallet_pb.Wallet.Asset.Transaction.deserializeBinary
);


/**
 * @param {!proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Wallet.Asset.Transaction>}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.finance.v1.WalletClient.prototype.assetTransactionList =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mlmbox.client.finance.v1.Wallet/AssetTransactionList',
      request,
      metadata || {},
      methodDescriptor_Wallet_AssetTransactionList);
};


/**
 * @param {!proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Wallet.Asset.Transaction>}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.finance.v1.WalletPromiseClient.prototype.assetTransactionList =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mlmbox.client.finance.v1.Wallet/AssetTransactionList',
      request,
      metadata || {},
      methodDescriptor_Wallet_AssetTransactionList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.client.finance.v1.WalletAssetGetRequest,
 *   !proto.mlmbox.type.Wallet.Asset.BalanceHistory>}
 */
const methodDescriptor_Wallet_AssetBalanceHistoryGet = new grpc.web.MethodDescriptor(
  '/mlmbox.client.finance.v1.Wallet/AssetBalanceHistoryGet',
  grpc.web.MethodType.UNARY,
  proto.mlmbox.client.finance.v1.WalletAssetGetRequest,
  mlmbox_type_wallet_pb.Wallet.Asset.BalanceHistory,
  /**
   * @param {!proto.mlmbox.client.finance.v1.WalletAssetGetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_wallet_pb.Wallet.Asset.BalanceHistory.deserializeBinary
);


/**
 * @param {!proto.mlmbox.client.finance.v1.WalletAssetGetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.type.Wallet.Asset.BalanceHistory)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Wallet.Asset.BalanceHistory>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.finance.v1.WalletClient.prototype.assetBalanceHistoryGet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.finance.v1.Wallet/AssetBalanceHistoryGet',
      request,
      metadata || {},
      methodDescriptor_Wallet_AssetBalanceHistoryGet,
      callback);
};


/**
 * @param {!proto.mlmbox.client.finance.v1.WalletAssetGetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.type.Wallet.Asset.BalanceHistory>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.finance.v1.WalletPromiseClient.prototype.assetBalanceHistoryGet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.finance.v1.Wallet/AssetBalanceHistoryGet',
      request,
      metadata || {},
      methodDescriptor_Wallet_AssetBalanceHistoryGet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest,
 *   !proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction>}
 */
const methodDescriptor_Wallet_AssetBalanceHistoryList = new grpc.web.MethodDescriptor(
  '/mlmbox.client.finance.v1.Wallet/AssetBalanceHistoryList',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest,
  mlmbox_type_wallet_pb.Wallet.Asset.BalanceHistory.Transaction,
  /**
   * @param {!proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_wallet_pb.Wallet.Asset.BalanceHistory.Transaction.deserializeBinary
);


/**
 * @param {!proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction>}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.finance.v1.WalletClient.prototype.assetBalanceHistoryList =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mlmbox.client.finance.v1.Wallet/AssetBalanceHistoryList',
      request,
      metadata || {},
      methodDescriptor_Wallet_AssetBalanceHistoryList);
};


/**
 * @param {!proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Wallet.Asset.BalanceHistory.Transaction>}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.finance.v1.WalletPromiseClient.prototype.assetBalanceHistoryList =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mlmbox.client.finance.v1.Wallet/AssetBalanceHistoryList',
      request,
      metadata || {},
      methodDescriptor_Wallet_AssetBalanceHistoryList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.type.Wallet.Processing.ID,
 *   !proto.mlmbox.type.Wallet.Processing>}
 */
const methodDescriptor_Wallet_ProcessingsGet = new grpc.web.MethodDescriptor(
  '/mlmbox.client.finance.v1.Wallet/ProcessingsGet',
  grpc.web.MethodType.UNARY,
  mlmbox_type_wallet_pb.Wallet.Processing.ID,
  mlmbox_type_wallet_pb.Wallet.Processing,
  /**
   * @param {!proto.mlmbox.type.Wallet.Processing.ID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_wallet_pb.Wallet.Processing.deserializeBinary
);


/**
 * @param {!proto.mlmbox.type.Wallet.Processing.ID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.type.Wallet.Processing)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Wallet.Processing>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.finance.v1.WalletClient.prototype.processingsGet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.finance.v1.Wallet/ProcessingsGet',
      request,
      metadata || {},
      methodDescriptor_Wallet_ProcessingsGet,
      callback);
};


/**
 * @param {!proto.mlmbox.type.Wallet.Processing.ID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.type.Wallet.Processing>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.finance.v1.WalletPromiseClient.prototype.processingsGet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.finance.v1.Wallet/ProcessingsGet',
      request,
      metadata || {},
      methodDescriptor_Wallet_ProcessingsGet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.mlmbox.type.Wallet.Processing>}
 */
const methodDescriptor_Wallet_ProcessingsAll = new grpc.web.MethodDescriptor(
  '/mlmbox.client.finance.v1.Wallet/ProcessingsAll',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  mlmbox_type_wallet_pb.Wallet.Processing,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_wallet_pb.Wallet.Processing.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Wallet.Processing>}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.finance.v1.WalletClient.prototype.processingsAll =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mlmbox.client.finance.v1.Wallet/ProcessingsAll',
      request,
      metadata || {},
      methodDescriptor_Wallet_ProcessingsAll);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Wallet.Processing>}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.finance.v1.WalletPromiseClient.prototype.processingsAll =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mlmbox.client.finance.v1.Wallet/ProcessingsAll',
      request,
      metadata || {},
      methodDescriptor_Wallet_ProcessingsAll);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.type.Wallet.Processing.ID,
 *   !proto.mlmbox.client.finance.v1.WalletDepositAddressResponse>}
 */
const methodDescriptor_Wallet_DepositAddress = new grpc.web.MethodDescriptor(
  '/mlmbox.client.finance.v1.Wallet/DepositAddress',
  grpc.web.MethodType.UNARY,
  mlmbox_type_wallet_pb.Wallet.Processing.ID,
  proto.mlmbox.client.finance.v1.WalletDepositAddressResponse,
  /**
   * @param {!proto.mlmbox.type.Wallet.Processing.ID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mlmbox.client.finance.v1.WalletDepositAddressResponse.deserializeBinary
);


/**
 * @param {!proto.mlmbox.type.Wallet.Processing.ID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.client.finance.v1.WalletDepositAddressResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.client.finance.v1.WalletDepositAddressResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.finance.v1.WalletClient.prototype.depositAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.finance.v1.Wallet/DepositAddress',
      request,
      metadata || {},
      methodDescriptor_Wallet_DepositAddress,
      callback);
};


/**
 * @param {!proto.mlmbox.type.Wallet.Processing.ID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.client.finance.v1.WalletDepositAddressResponse>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.finance.v1.WalletPromiseClient.prototype.depositAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.finance.v1.Wallet/DepositAddress',
      request,
      metadata || {},
      methodDescriptor_Wallet_DepositAddress);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.client.finance.v1.WalletValidateAddressRequest,
 *   !proto.mlmbox.client.finance.v1.WalletValidateAddressResponse>}
 */
const methodDescriptor_Wallet_ValidateAddress = new grpc.web.MethodDescriptor(
  '/mlmbox.client.finance.v1.Wallet/ValidateAddress',
  grpc.web.MethodType.UNARY,
  proto.mlmbox.client.finance.v1.WalletValidateAddressRequest,
  proto.mlmbox.client.finance.v1.WalletValidateAddressResponse,
  /**
   * @param {!proto.mlmbox.client.finance.v1.WalletValidateAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mlmbox.client.finance.v1.WalletValidateAddressResponse.deserializeBinary
);


/**
 * @param {!proto.mlmbox.client.finance.v1.WalletValidateAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.client.finance.v1.WalletValidateAddressResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.client.finance.v1.WalletValidateAddressResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.finance.v1.WalletClient.prototype.validateAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.finance.v1.Wallet/ValidateAddress',
      request,
      metadata || {},
      methodDescriptor_Wallet_ValidateAddress,
      callback);
};


/**
 * @param {!proto.mlmbox.client.finance.v1.WalletValidateAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.client.finance.v1.WalletValidateAddressResponse>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.finance.v1.WalletPromiseClient.prototype.validateAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.finance.v1.Wallet/ValidateAddress',
      request,
      metadata || {},
      methodDescriptor_Wallet_ValidateAddress);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest,
 *   !proto.mlmbox.type.Confirmation>}
 */
const methodDescriptor_Wallet_WithdrawalCreate = new grpc.web.MethodDescriptor(
  '/mlmbox.client.finance.v1.Wallet/WithdrawalCreate',
  grpc.web.MethodType.UNARY,
  proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest,
  mlmbox_type_confirmation_pb.Confirmation,
  /**
   * @param {!proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_confirmation_pb.Confirmation.deserializeBinary
);


/**
 * @param {!proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.type.Confirmation)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Confirmation>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.finance.v1.WalletClient.prototype.withdrawalCreate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.finance.v1.Wallet/WithdrawalCreate',
      request,
      metadata || {},
      methodDescriptor_Wallet_WithdrawalCreate,
      callback);
};


/**
 * @param {!proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.type.Confirmation>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.finance.v1.WalletPromiseClient.prototype.withdrawalCreate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.finance.v1.Wallet/WithdrawalCreate',
      request,
      metadata || {},
      methodDescriptor_Wallet_WithdrawalCreate);
};


module.exports = proto.mlmbox.client.finance.v1;

