<template>
  <button class="copy-string" :class="{copy: referralCopy}"
    @click="copyValue(body)">
    <input ref="mylink" type="text" :disabled="referralCopy === true" :value="`${shortString}`" />
    <span>{{shortString}}</span>
    <div class="copy-string__dialog" :class="{copy: referralCopy}">{{t('base.copy')}}</div> 
  </button>
</template>

<script>
import { useI18n } from "vue-i18n";
import { mapState } from 'vuex';

export default {
  props: [
    'body',
  ],

  data() {
    return {
      referralCopy: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.account.user.info,
    }),

    shortString() {
      return this.body !== undefined ? `${this.body.slice(0, 8)}...` : 'copy-string';
    }
  },

  methods: {
    copyValue(refLink) {
      var Url = this.$refs.mylink;
      Url.value = refLink ?? 'test';
      Url.select();
      const success = document.execCommand("copy");
      this.referralCopy = true;

      if (success) {
        document.activeElement.blur();
        Url.value = refLink ?? 'test';
        setTimeout(() => (this.referralCopy = false), 980);
      }
    },

    getDomain() {
      return window.location.origin;
    }
  },

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

    return { t };
  },
}
</script>

<style scoped lang="scss">
.copy-string {
  z-index: 5;
  position: relative;
  padding: 0;

  &:hover span {
    opacity: 1;
  }
  
  span {
    cursor: pointer;
    opacity: 0.7;
    transition: opacity  .2s ease-in-out;
  }

  input, &__dialog {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }

  input {
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
  }

  &__dialog {
    top: -3.8em;
    left: 50%;
    transform: translateX(-50%);
    width: 125%;
    color: #ffffff;
    background: #341e5a;
    padding: .5em .8em;
    border-radius: .2em;
    transition: opacity .2s ease-in-out;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.copy &__dialog {
    opacity: 1;
    transition: opacity .2s ease-in-out;
  }

}
.mobileScreen .button-refferal {
  z-index: 9;
}
</style>
