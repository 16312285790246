<template>
  <li class="list__item">

    <div class="list__item_head" 
      @click="showPartners"
      :class="{active: partnersOpen}" v-if="partnersHeight !== '0px'"
      >
      <div class="list__item_hash">{{ item.hash }}</div>
      <div class="list__item_tokens">
        <span class="list__item_token">
          {{ item.tokens }}
        </span>
      </div>
      <div class="list__item_revenue green">${{ setFloor(item.revenue) }}</div>
      <div class="list__item_type">
        <div>
          {{ item.tx_type }}
        </div>
      </div>
    </div>

    <div class="list__item_details_wrap" 
      :class="{active: partnersOpen}"
      :style="`height: ${getPartnersHeight};`"
      >
      <div class="list__item_details" 
        :ref="`hiddenPatner-${item.hash}`"
        >
        <div>
          <div class="list__item_detail">MEV TYPE</div>
          <div class="list__item_detail">{{ item.tx_type }}</div>
        </div>
        <div>
          <div class="list__item_detail">Transaction Hash</div>
          <div class="list__item_detail">{{ item.hash }}</div>
        </div>
        <div>
          <div class="list__item_detail">From</div>
          <div class="list__item_detail">{{ item.from }}</div>
        </div>
        <div>
          <div class="list__item_detail">Contract(To)</div>
          <div class="list__item_detail">{{ item.to }}</div>
        </div>
        <div>
          <div class="list__item_detail">Profit</div>
          <div class="list__item_detail">${{ setFloor(item.profit) }}</div>
        </div>
        <div>
          <div class="list__item_detail">Cost</div>
          <div class="list__item_detail">${{ setFloor(item.cost) }}</div>
        </div>
        <div>
          <div class="list__item_detail">Revenue</div>
          <div class="list__item_detail">${{ setFloor(item.revenue) }}</div>
        </div>
        <div>
          <div class="list__item_detail">BlockNumber</div>
          <div class="list__item_detail">{{ item.block_height }}</div>
        </div>
      </div>

    </div>
  </li>

</template>

<script>
import { transactions } from "@/mixins/transactions";

export default {
  name: "PartnersItem",
  props: ["item", "index", "partner", "partnersActive", "levelProp"],


  mixins: [transactions],

  data() {
    return {
      partnersOpen: false,
      partners: [],
      partnersHeight: "",
      level: 1,
    };
  },

  computed: {
    getPartnersHeight() {
      return this.partnersOpen === true ? this.partnersHeight + "px" : "0px";
    },

    depositValue() {
      return this.partner?.selfStatistics?.total?.turnover ?? 0;
    },
  },

  methods: {
    setFloor(n) {
      return Math.abs(n.toFixed(2))
    },

    showPartners() {
      this.partnersOpen = !this.partnersOpen;
    },

    childOpen(childHeight) {
      this.partnersHeight += childHeight;
    },

    childClose(childHeight) {
      this.partnersHeight -= childHeight;
    },

    updatePartnersHeight() {
      const currentElem = this.$refs[`hiddenPatner-${this.item.hash}`];
      this.partnersHeight = currentElem.offsetHeight;
    },

    getPartners() {
      if (this.partner.partnersStatistics.quantity > 0) {
        this.$store
          .dispatch("getTeamPartnerList", {
            partnerLogin: this.partner.login,
            referralLine: 10,
          })
          .then((response) => {
            this.partners = response;
          });
      }
    },
  },

  created() {
    if (this.levelProp !== undefined) {
      this.level = this.levelProp + 1;
    }
  },

  watch: {
    partnersOpen(value) {
      if (value === true) {
        this.updatePartnersHeight();
        this.$emit("childOpen", this.partnersHeight);
      } else {
        this.$emit("childClose", this.partnersHeight);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.green {
  color: #01ffc2;
}

.list {
  width: 100%;
  color: #ffffff;

  &__wrap {
    width: 100%;
  }

  &__item {
    width: 100%;
    margin-bottom: 1em;
    border-bottom: 0.1em solid #673ab7;
    color: #ffffff;
    display: flex;
    flex-direction: column;

    &_hash {
      color: #01ffc2;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 15em;
      padding-right: .3em;
    }

    &_token {
      padding-right: .3em;
      img {
        width: 1em;
        height: 1em;
        margin-right: 0.2em;
      }
    }

    &_type {
      padding-right: .3em;
      text-align: right;
    }

    &_head {
      width: 100%;
      display: grid;
      grid-template-columns: 25% 25% 25% 25%;
      transition: background .2s ease-in-out;
      padding: .7em .8em;
      cursor: pointer;

      &:hover {
        background: rgba(103, 58, 182, 0.2);
      }

      &.active {
        background: rgba(103, 58, 182, 0.2);
      }
    }

    &_details {
      background: rgba(103, 58, 182, 0.2);
      
      &_wrap {
        margin-top: .7em;
        overflow: hidden;
        transition: height .2s ease-in-out;
      }
      
      & > div {
        display: grid;
        grid-template-columns: 50% 50%;
        padding: .7em .8em;
        border-bottom: 0.1em solid #673ab7;

        &:last-child {
          border: 0;
        }
      }
    }

    &_detail {
      width: 50%;
    }

    &_token {
      padding: 0 1.1em;
      word-break: break-all;
      display: flex;
      justify-content: flex-start;
      line-height: 120%;
    }
  }
}
</style>
