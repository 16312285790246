<template>
  <div class="welcome">
    <header class="header visible" ref="heading1">
      <div class="container">
        <a
          class="header__logo header-item anim-fade"
          style="animation-delay: 0.2s"
          href="#"
          ><img src="img/welcome/logo.png" alt=""
        /></a>
        <div class="header__menu_block">
          <ul class="header__menu">
            <li>
              <a
                class="header__menu_item header-item anim-fade"
                style="animation-delay: 0.4s"
                href="#"
                @click.prevent="scrollBehavior('#about')"
                >{{ t('landing.menu["About Us"]') }}</a
              >
            </li>
            <li>
              <a
                class="header__menu_item header-item anim-fade"
                style="animation-delay: 0.6s"
                href="#"
                @click.prevent="scrollBehavior('#tokenomic')"
                >{{ t('landing.menu["Tokenomic"]') }}</a
              >
            </li>
            <li>
              <a
                class="header__menu_item header-item anim-fade"
                style="animation-delay: 0.8s"
                href="#"
                @click.prevent="scrollBehavior('#roadmap')"
                >{{ t('landing.menu["Roadmap"]') }}</a
              >
            </li>
            <li>
              <a
                class="header__menu_item header-item anim-fade"
                style="animation-delay: 1s"
                href="#"
                @click.prevent="scrollBehavior('#whitepaper')"
                >{{ t('landing.menu["Whitepaper"]') }}</a
              >
            </li>
          </ul>
        </div>

        <LanguageSwitcher />

        <button
          class="line__btn anim-fade"
          style="animation-delay: 0.8s"
          @click="$emit('openPopup', { name: 'PopupAuth' })"
        >
          <span v-html="t('welcome.signIn_signUp')"></span>
        </button>

        <!-- <div class="header__item header-item anim-fade"> <img src="img/welcome/header/logo-1.png" alt=""></div> -->
      </div>
    </header>
    <section class="line" :class="{ 'anim-fade': animations.anim1 === true }">
      <div class="container">
        <div
          class="line__text"
          :class="{ 'anim-fade': animations.anim1 === true }"
          style="animation-delay: 0.6s"
        >
          <span>{{ t('landing["alert"]') }}</span>
        </div>
        <button
          class="line__btn"
          :class="{ 'anim-fade': animations.anim1 === true }"
          style="animation-delay: 0.8s"
          @click.prevent="redirect"
        >
          <span>{{ t('landing["alertBtn"]') }}</span>
        </button>
      </div>
    </section>
    <section class="section-1">
      <div class="container">
        <div
          class="section-1__text_1"
          ref="heading2"
          :class="{ 'anim-fade': animations.anim1 === true }"
          style="animation-delay: 0.4s"
        >
          <span v-html="t('landing.mainHeader')"> </span>
        </div>
        <div class="section-1__apps_block">
          <div
            class="section-1__apps_link"
            :class="{ 'anim-fade': animations.anim2 === true }"
            style="animation-delay: 0.2s"
          >
            <div class="section-1__apps_link_img">
              <img src="img/welcome/section-1/google-play.svg" alt="" />
            </div>
            <div class="section-1__apps_link_inner">
              <span>Download on the</span><span>Google Play</span>
            </div>
          </div>
          <div
            class="section-1__apps_link"
            :class="{ 'anim-fade': animations.anim2 === true }"
            style="animation-delay: 0.4s"
          >
            <div class="section-1__apps_link_img apple">
              <img src="img/welcome/section-1/app-store.svg" alt="" />
            </div>
            <div class="section-1__apps_link_inner">
              <span>Get in on</span><span>App Store</span>
            </div>
          </div>
        </div>
        <div class="section-1__links_items">
          <!-- <div
            class="section-1__dop_soc"
            :class="{ 'anim-fade': animations.anim1 === true }"
            style="animation-delay: 0.2s"
          >
            <a class="section-1__link_item" href="#"
              ><img src="img/welcome/section-1/dd.svg" alt=""
            /></a>
          </div> -->
          <div
            class="section-1__dop_soc"
            :class="{ 'anim-fade': animations.anim1 === true }"
            style="animation-delay: 0.4s"
          >
            <a class="section-1__link_item" href="https://t.me/tokenza_io"
              ><img src="img/welcome/section-1/tl.svg" alt=""
            /></a>
          </div>
          <!-- <div
            class="section-1__dop_soc"
            :class="{ 'anim-fade': animations.anim1 === true }"
            style="animation-delay: 0.6s"
          >
            <a class="section-1__link_item" href="#"
              ><img src="img/welcome/section-1/tw.svg" alt=""
            /></a>
          </div> -->
          <!-- <div
            class="section-1__dop_soc"
            :class="{ 'anim-fade': animations.anim1 === true }"
            style="animation-delay: 0.8s"
          >
            <a class="section-1__link_item" href="#"
              ><img src="img/welcome/section-1/fc.svg" alt=""
            /></a>
          </div> -->
          <div
            class="section-1__dop_soc"
            :class="{ 'anim-fade': animations.anim1 === true }"
            style="animation-delay: 1s"
          >
            <a class="section-1__link_item" href="https://www.instagram.com/tokenza_io/"
              ><img src="img/welcome/section-1/in.svg" alt=""
            /></a>
          </div>
          <div
            class="section-1__dop_soc"
            :class="{ 'anim-fade': animations.anim1 === true }"
            style="animation-delay: 1.2s"
          >
            <a class="section-1__link_item" href="https://www.youtube.com/channel/UCBJ-ryUpMX93aZvjOZuLMPA"
              ><img src="img/welcome/section-1/you.svg" alt=""
            /></a>
          </div>
        </div>
        <div class="section-1__galaxy">
          <img
            class="first-bg-1"
            src="img/welcome/section-1/galaxy.png"
            alt=""
          /><img
            class="first-bg-2"
            src="img/welcome/section-1/galaxy.png"
            alt=""
          />
        </div>
        <div class="section-1__phone">
          <img
            src="img/welcome/section-1/phone.png"
            alt=""
            :class="{ 'anim-fade': animations.anim2 === true }"
          />
        </div>
        <div class="section-1__lines">
          <img
            src="img/welcome/section-1/lines.png"
            alt=""
            :class="{ 'anim-glow': animations.anim2 === true }"
            style="animation-delay: 0.6s"
          />
        </div>
      </div>
    </section>
    <section class="section-2" id="about">
      <div class="container">
        <div class="section-2__heading vis_on_scroll" ref="heading3">
          <span>Tokenza </span><span>mission</span>
        </div>
        <div class="section-2__text_block_1">
          <div
            class="section-2__text_1_1 section-2-item-text"
            :class="{ 'anim-fade': animations.anim3 === true }"
            style="animation-delay: 0.2s"
          >
            <span>{{t('landing.mission.desc_1')}}</span>
          </div>
          <div
            class="section-2__text_1_1 section-2-item-text"
            :class="{ 'anim-fade': animations.anim3 === true }"
            style="animation-delay: 0.4s"
          >
            <span>{{t('landing.mission.desc_2')}}</span>
          </div>
        </div>
        <div
          class="section-2__text_block_2 section-2-item-text"
          :class="{ 'anim-fade': animations.anim3 === true }"
          style="animation-delay: 0.6s"
        >
          <span>
            <span>Tokenza </span>- {{t('landing.mission.desc_3')}}</span>
        </div>
        <div class="section-2__text_block_3">
          <div
            class="section-2__text_3_1 section-2-item-text"
            :class="{ 'anim-fade': animations.anim3 === true }"
            style="animation-delay: 0.8s"
          >
            <span>{{t('landing.mission.desc_4')}}</span>
          </div>
          <div
            class="section-2__text_3_1 section-2-item-text"
            :class="{ 'anim-fade': animations.anim3 === true }"
            style="animation-delay: 1s"
          >
            <span>{{t('landing.mission.desc_4_1')}}</span>
          </div>
        </div>
      </div>
    </section>
    <section class="section-3">
      <div class="container">
        <div class="section-3__text_block_1">
          <div
            class="section-3__text_item_1"
            :class="{ 'anim-fade': animations.anim4 === true }"
            style="animation-delay: 0.2s"
          >
            <div class="section-3__text_number"><span>1</span></div>
            <div class="section-3__text_white">
              <span v-html="t('landing.mission.desc_5')"></span>
            </div>
          </div>
          <div
            class="section-3__text_item_1"
            :class="{ 'anim-fade': animations.anim4 === true }"
            style="animation-delay: 0.4s"
          >
            <div class="section-3__text_number"><span>2</span></div>
            <div class="section-3__text_white">
              <span v-html="t('landing.mission.desc_6')"></span>
            </div>
          </div>
        </div>
        <div class="section-3__text_block_2">
          <div
            class="section-3__text_item_1"
            :class="{ 'anim-fade': animations.anim4 === true }"
            style="animation-delay: 0.6s"
          >
            <div class="section-3__text_number"><span>3</span></div>
            <div class="section-3__text_white">
              <span v-html="t('landing.mission.desc_7')"></span>
            </div>
          </div>
          <div
            class="section-3__text_item_1"
            :class="{ 'anim-fade': animations.anim4 === true }"
            style="animation-delay: 0.8s"
          >
            <div class="section-3__text_number"><span>4</span></div>
            <div class="section-3__text_white">
              <span v-html="t('landing.mission.desc_8')"></span>
            </div>
          </div>
          <div
            class="section-3__text_item_1"
            :class="{ 'anim-fade': animations.anim4 === true }"
            style="animation-delay: 1s"
          >
            <div class="section-3__text_number"><span>5</span></div>
            <div class="section-3__text_white">
              <span v-html="t('landing.mission.desc_9')"></span>
            </div>
          </div>
        </div>
        <div class="section-3__galaxy">
          <img
            class="first-bg-1"
            src="img/welcome/section-3/galaxy.png"
            alt=""
          /><img
            class="first-bg-1"
            src="img/welcome/section-3/galaxy.png"
            alt=""
          />
        </div>
        <div class="section-3__phones vis_on_scroll" ref="heading4">
          <img src="img/welcome/section-3/phones.png" alt="" />
        </div>
        <div class="section-3__lines">
          <img
            src="img/welcome/section-3/lines.png"
            alt=""
            :class="{ 'anim-glow': animations.anim4 === true }"
            style="animation-delay: 0.6s"
          />
        </div>
      </div>
    </section>
    <section class="section-4">
      <div class="container">
        <div class="section-4__heading vis_on_scroll" ref="heading5">
          <span v-html="t('landing.mission.desc_10')"></span>
        </div>
        <div
          class="section-4__text_1"
          :class="{ 'anim-fade': animations.anim5 === true }"
        >
          <span v-html="t('landing.mission.desc_11')"></span>
        </div>
        <div
          class="section-4__text_2"
          :class="{ 'anim-fade': animations.anim5 === true }"
          style="animation-delay: 0.4s"
        >
          <span v-html="t('landing.mission.desc_12')"></span>
        </div>
        <div
          class="section-4__galaxy"
          :class="{ 'anim-fade': animations.anim5 === true }"
          style="animation-delay: 0.4s"
        >
          <img
            class="first-bg-1"
            src="img/welcome/section-4/galaxy.png"
            alt=""
          /><img
            class="first-bg-2"
            src="img/welcome/section-4/galaxy.png"
            alt=""
          />
        </div>
        <div class="section-4__phone">
          <img
            src="img/welcome/section-4/phone.png"
            alt=""
            :class="{ 'anim-fade': animations.anim5 === true }"
            style="animation-delay: 0.4s"
          />
        </div>
        <div class="section-4__lines">
          <img
            src="img/welcome/section-4/lines.png"
            alt=""
            :class="{ 'anim-glow': animations.anim5 === true }"
            style="animation-delay: 0.4s"
          />
        </div>
      </div>
    </section>
    <section class="section-5" id="tokenomic">
      <div class="container">
        <div class="section-5__heading vis_on_scroll" ref="heading6">
          <span>Tokenomics</span>
        </div>
        <div class="section-5__text_block">
          <div class="section-5__left_text_block">
            <div
              class="section-5__left_text_1 section-5-text-item"
              :class="{ 'anim-fade': animations.anim6 === true }"
              style="animation-delay: 0.1s"
            >
              <img src="img/welcome/section-5/tkz.png" alt="" /><span>TKZ</span>
            </div>
            <div
              class="section-5__left_text_2 section-5-text-item"
              :class="{ 'anim-fade': animations.anim6 === true }"
              style="animation-delay: 0.2s"
            >
              <span v-html="t('landing.tokenomics.desc_1')"></span>
            </div>
            <div
              class="section-5__left_text_3 section-5-text-item"
              :class="{ 'anim-fade': animations.anim6 === true }"
              id="whitepaper"
              style="animation-delay: 0.3s"
            >
              <span>9,800,000,000</span><span>total supply</span>
            </div>
            <div
              class="section-5__left_text_3 section-5-text-item"
              :class="{ 'anim-fade': animations.anim6 === true }"
              style="animation-delay: 0.4s"
            >
              <span>$5,850,000</span
              ><span>Total Usd raised in the seed round</span>
            </div>
            <div
              class="section-5__left_text_3 section-5-text-item"
              :class="{ 'anim-fade': animations.anim6 === true }"
              style="animation-delay: 0.5s"
            >
              <span>$ 1</span><span>listing price</span>
            </div>
            <div
              class="section-5__download_text section-5-text-item"
              :class="{ 'anim-fade': animations.anim6 === true }"
              style="animation-delay: 0.6s"
            >
              <span v-html="t('landing.tokenomics.desc_2')"></span>
            </div>
            <a
              class="section-5__download_btn section-5-text-item"
              :class="{ 'anim-fade': animations.anim6 === true }"
              style="animation-delay: 0.7s"
              target="_blank"
              href="presentations/WP Tokenza.pdf"
              ><img src="img/welcome/section-5/doc-icon.svg" alt="" /><span
                >White Paper</span
              ></a
            >
          </div>
          <div class="section-5__right_text_block">
            <div
              class="section-5__right_text_1 section-5-text-item"
              :class="{ 'anim-fade': animations.anim6 === true }"
              style="animation-delay: 0.8s"
            >
              <span>Tron <br />blockchain <br />(TRc20)</span>
            </div>
            <div
              class="section-5__right_text_2 section-5-text-item"
              :class="{ 'anim-fade': animations.anim6 === true }"
              style="animation-delay: 0.9s"
            >
              <span v-html="t('landing.tokenomics.desc_3')"></span>
            </div>
          </div>
          <div class="section-5__arr_left">
            <img
              src="img/welcome/section-5/arr-left.svg"
              alt=""
              :class="{ 'anim-glow': animations.anim6 === true }"
              style="animation-delay: 0.6s"
            />
          </div>
          <div class="section-5__arr_right">
            <img
              src="img/welcome/section-5/arr-left.svg"
              alt=""
              :class="{ 'anim-glow': animations.anim6 === true }"
              style="animation-delay: 1.4s"
            />
          </div>
          <div class="section-5__galaxy">
            <img
              class="first-bg-1"
              src="img/welcome/section-5/galaxy.png"
              alt=""
            /><img
              class="first-bg-2"
              src="img/welcome/section-5/galaxy.png"
              alt=""
            />
          </div>
          <div class="section-5__coin">
            <img
              src="img/welcome/section-5/coin.png"
              alt=""
              :class="{ 'anim-fade': animations.anim6 === true }"
              style="animation-delay: 1.6s"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="section-6">
      <div class="container">
        <div class="section-6__block">
          <div class="section-6__line_top"></div>
          <div class="section-6__heading vis_on_scroll" ref="heading7">
            <span v-html="t('landing.item_1')"></span>
          </div>
          <div
            class="section-6__text"
            :class="{ 'anim-fade': animations.anim7 === true }"
            style="animation-delay: 0.6s"
          >
            <span v-html="t('landing.item_2')"></span>
          </div>
          <div class="section-6__line_bottom"></div>
        </div>
      </div>
    </section>
    <section class="section-7">
      <div class="container">
        <div class="section-7__heading vis_on_scroll" ref="heading8">
            <span v-html="t('landing.item_0')"></span>
        </div>
        <div class="section-7__text_block">
          <div
            class="section-7__text_item section-7-item-text"
            :class="{ 'anim-fade': animations.anim8 === true }"
            style="animation-delay: 0.2s"
          >
            <span v-html="t('landing.item_3')"></span>
            <span v-html="t('landing.item_4')"></span>
          </div>
          <div
            class="section-7__text_item section-7-item-text"
            :class="{ 'anim-fade': animations.anim8 === true }"
            style="animation-delay: 0.4s"
          >
            <span v-html="t('landing.item_5')"></span>
            <span v-html="t('landing.item_6')"></span>
          </div>
          <div
            class="section-7__text_item section-7-item-text"
            :class="{ 'anim-fade': animations.anim8 === true }"
            style="animation-delay: 0.6s"
          >
            <span v-html="t('landing.item_7')"></span>
            <span v-html="t('landing.item_8')"></span>
          </div>
          <div
            class="section-7__text_item section-7-item-text"
            :class="{ 'anim-fade': animations.anim8 === true }"
            style="animation-delay: 0.8s"
          >
            <span v-html="t('landing.item_9')"></span>
            <span v-html="t('landing.item_10')"></span>
          </div>
          <div
            class="section-7__text_item section-7-item-text"
            :class="{ 'anim-fade': animations.anim8 === true }"
            style="animation-delay: 1s"
          >
            <span v-html="t('landing.item_11')"></span>
            <span v-html="t('landing.item_12')"></span>
          </div>
          <div
            class="section-7__text_item section-7-item-text"
            :class="{ 'anim-fade': animations.anim8 === true }"
            style="animation-delay: 1.2s"
          >
            <span v-html="t('landing.item_13')"></span>
            <span v-html="t('landing.item_14')"></span>
          </div>
        </div>
        <div
          class="section-7__download_text section-7-item-text"
          :class="{ 'anim-fade': animations.anim8 === true }"
          style="animation-delay: 1.4s"
        >
          <span v-html="t('landing.item_15')"></span>
        </div>

        <a
          class="section-5__download_btn section-7-item-text"
          :class="{ 'anim-fade': animations.anim8 === true }"
          style="animation-delay: 0.2s"
          target="_blank"
          :href="`presentations/Tokenza ${$i18n.locale}.pdf`"
          ><img src="img/welcome/section-5/doc-icon.svg" alt="" />
            <span v-html="t('landing.item_16')"></span>
          </a
        >
      </div>
    </section>
    <section class="section-8">
      <div class="container">
        <div class="section-8__heading vis_on_scroll" ref="heading11">
          <span>Ambassadors</span>
        </div>
        <div class="section-8__items">
          <div
            class="section-8__item vis_on_scroll"
            :class="{ visible: animations.anim11 === true }"
          >
            <div class="section-8__item_photo">
              <img src="img/welcome/section-8/photo-1.png" alt="" />
            </div>
            <div class="section-8__item_name"><span>Yan Calman</span></div>
            <div class="section-8__item_pos"><span>- CEO</span></div>
            <div class="section-8__item_block">
              <div class="section-8__item_block_line"></div>
              <div class="section-8__item_block_text">
                <span v-html="t('landing.users.user_1')"></span>
              </div>
            </div>
            <!-- <div class="section-8__soc_block"> <a class="section-8__soc_item" href="#"><img src="img/welcome/section-1/tw.svg" alt=""></a><a class="section-8__soc_item" href="#"><img src="img/welcome/section-1/in.svg" alt=""></a></div> -->
          </div>
          <div
            class="section-8__item vis_on_scroll"
            :class="{ visible: animations.anim11 === true }"
            style="animation-delay: 0.2s"
          >
            <div class="section-8__item_photo">
              <img src="img/welcome/section-8/photo-2.png" alt="" />
            </div>
            <div class="section-8__item_name"><span>Michael Pether</span></div>
            <div class="section-8__item_pos">
              <span>- CTO<br />- Co-founder<br />- Blockchain developer</span>
            </div>
            <div class="section-8__item_block">
              <div class="section-8__item_block_line"></div>
              <div class="section-8__item_block_text">
                <span v-html="t('landing.users.user_2')"></span>
              </div>
            </div>
            <!-- <div class="section-8__soc_block"> <a class="section-8__soc_item" href="#"><img src="img/welcome/section-1/tw.svg" alt=""></a><a class="section-8__soc_item" href="#"><img src="img/welcome/section-1/tl.svg" alt=""></a></div> -->
          </div>
          <div
            class="section-8__item vis_on_scroll"
            :class="{ visible: animations.anim11 === true }"
            style="animation-delay: 0.4s"
          >
            <!-- <div class="section-8__item_photo">
              <img src="img/welcome/section-8/photo-3.png" alt="" />
            </div> -->
            <div class="section-8__item_name"><span>Matt Horn</span></div>
            <div class="section-8__item_pos">
              <span>- CSO<br />- Advisor</span>
            </div>
            <div class="section-8__item_block">
              <div class="section-8__item_block_line"></div>
              <div class="section-8__item_block_text">
                <span v-html="t('landing.users.user_3')"></span>
              </div>
            </div>
            <!-- <div class="section-8__soc_block"> <a class="section-8__soc_item" href="#"><img src="img/welcome/section-1/tw.svg" alt=""></a><a class="section-8__soc_item" href="#"><img src="img/welcome/section-1/tl.svg" alt=""></a></div> -->
          </div>
          <div
            class="section-8__item vis_on_scroll"
            :class="{ visible: animations.anim11 === true }"
            style="animation-delay: 0.6s"
          >
            <!-- <div class="section-8__item_photo">
              <img src="img/welcome/section-8/photo-4.png" alt="" />
            </div> -->
            <div class="section-8__item_name"><span>Ahmed Kilich</span></div>
            <div class="section-8__item_pos"><span>- CBDO</span></div>
            <div class="section-8__item_block">
              <div class="section-8__item_block_line"></div>
              <div class="section-8__item_block_text">
                <span v-html="t('landing.users.user_4')"></span>
              </div>
            </div>
            <!-- <div class="section-8__soc_block"> <a class="section-8__soc_item" href="#"><img src="img/welcome/section-1/tw.svg" alt=""></a><a class="section-8__soc_item" href="#"><img src="img/welcome/section-1/tl.svg" alt=""></a></div> -->
          </div>
          <div
            class="section-8__item vis_on_scroll"
            :class="{ visible: animations.anim11 === true }"
            style="animation-delay: 0.8s"
          >
            <!-- <div class="section-8__item_photo">
              <img src="img/welcome/section-8/photo-5.png" alt="" />
            </div> -->
            <div class="section-8__item_name"><span>Petter Dupont</span></div>
            <div class="section-8__item_pos"><span>- CMO</span></div>
            <div class="section-8__item_block">
              <div class="section-8__item_block_line"></div>
              <div class="section-8__item_block_text">
                <span v-html="t('landing.users.user_5')"></span>
              </div>
            </div>
            <!-- <div class="section-8__soc_block"> <a class="section-8__soc_item" href="#"><img src="img/welcome/section-1/tl.svg" alt=""></a><a class="section-8__soc_item" href="#"><img src="img/welcome/section-1/in.svg" alt=""></a></div> -->
          </div>
          <div
            class="section-8__item vis_on_scroll"
            :class="{ visible: animations.anim11 === true }"
            style="animation-delay: 1s"
          >
            <!-- <div class="section-8__item_photo">
              <img src="img/welcome/section-8/photo-6.png" alt="" />
            </div> -->
            <div class="section-8__item_name"><span>Luca Rossi</span></div>
            <div class="section-8__item_pos"><span>- CCO</span></div>
            <div class="section-8__item_block">
              <div class="section-8__item_block_line"></div>
              <div class="section-8__item_block_text">
                <span v-html="t('landing.users.user_6')"></span>
              </div>
            </div>
            <!-- <div class="section-8__soc_block"> <a class="section-8__soc_item" href="#"><img src="img/welcome/section-1/fc.svg" alt=""></a><a class="section-8__soc_item" href="#"><img src="img/welcome/section-1/you.svg" alt=""></a></div> -->
          </div>
          <div
            class="section-8__item vis_on_scroll"
            :class="{ visible: animations.anim11 === true }"
            style="animation-delay: 1.2s"
          >
            <!-- <div class="section-8__item_photo">
              <img src="img/welcome/section-8/photo-7.png" alt="" />
            </div> -->
            <div class="section-8__item_name"><span>Emma Muller</span></div>
            <div class="section-8__item_pos"><span>- CPO</span></div>
            <div class="section-8__item_block">
              <div class="section-8__item_block_line"></div>
              <div class="section-8__item_block_text">
                <span v-html="t('landing.users.user_7')"></span>
              </div>
            </div>
            <!-- <div class="section-8__soc_block"> <a class="section-8__soc_item" href="#"><img src="img/welcome/section-1/fc.svg" alt=""></a><a class="section-8__soc_item" href="#"><img src="img/welcome/section-1/tl.svg" alt=""></a><a class="section-8__soc_item" href="#"><img src="img/welcome/section-1/tw.svg" alt=""></a></div> -->
          </div>
          <div
            class="section-8__item vis_on_scroll"
            :class="{ visible: animations.anim11 === true }"
            style="animation-delay: 1.4s"
          >
            <!-- <div class="section-8__item_photo">
              <img src="img/welcome/section-8/photo-8.png" alt="" />
            </div> -->
            <div class="section-8__item_name"><span>Anna Pathinan</span></div>
            <div class="section-8__item_pos"><span>- Product Design</span></div>
            <div class="section-8__item_block">
              <div class="section-8__item_block_line"></div>
              <div class="section-8__item_block_text">
                <span v-html="t('landing.users.user_8')"></span>
              </div>
            </div>
            <!-- <div class="section-8__soc_block"> <a class="section-8__soc_item" href="#"><img src="img/welcome/section-1/you.svg" alt=""></a><a class="section-8__soc_item" href="#"><img src="img/welcome/section-1/tl.svg" alt=""></a><a class="section-8__soc_item" href="#"><img src="img/welcome/section-1/in.svg" alt=""></a></div> -->
          </div>
          <div
            class="section-8__item vis_on_scroll"
            :class="{ visible: animations.anim11 === true }"
            style="animation-delay: 1.6s"
          >
            <!-- <div class="section-8__item_photo">
              <img src="img/welcome/section-8/photo-9.png" alt="" />
            </div> -->
            <div class="section-8__item_name"><span>Juan Garcia</span></div>
            <div class="section-8__item_pos">
              <span>- Project Manager</span>
            </div>
            <div class="section-8__item_block">
              <div class="section-8__item_block_line"></div>
              <div class="section-8__item_block_text">
                <span v-html="t('landing.users.user_9')"></span>
              </div>
            </div>
            <!-- <div class="section-8__soc_block"> <a class="section-8__soc_item" href="#"><img src="img/welcome/section-1/you.svg" alt=""></a><a class="section-8__soc_item" href="#"><img src="img/welcome/section-1/tl.svg" alt=""></a></div> -->
          </div>
          <div
            class="section-8__item vis_on_scroll"
            :class="{ visible: animations.anim11 === true }"
            style="animation-delay: 1.8s"
          >
            <!-- <div class="section-8__item_photo">
              <img src="img/welcome/section-8/photo-10.png" alt="" />
            </div> -->
            <div class="section-8__item_name"><span>Martin Fletcher</span></div>
            <div class="section-8__item_pos">
              <span>- Head of Support</span>
            </div>
            <div class="section-8__item_block">
              <div class="section-8__item_block_line"></div>
              <div class="section-8__item_block_text">
                <span v-html="t('landing.users.user_10')"></span>
              </div>
            </div>
            <!-- <div class="section-8__soc_block"> <a class="section-8__soc_item" href="#"><img src="img/welcome/section-1/you.svg" alt=""></a><a class="section-8__soc_item" href="#"><img src="img/welcome/section-1/tl.svg" alt=""></a></div> -->
          </div>
          <div
            class="section-8__item vis_on_scroll"
            :class="{ visible: animations.anim11 === true }"
            style="animation-delay: 2s"
          >
            <!-- <div class="section-8__item_photo">
              <img src="img/welcome/section-8/photo-11.png" alt="" />
            </div> -->
            <div class="section-8__item_name"><span>Helena Mazur</span></div>
            <div class="section-8__item_pos">
              <span>- Mob app developer</span>
            </div>
            <div class="section-8__item_block">
              <div class="section-8__item_block_line"></div>
              <div class="section-8__item_block_text">
                <span v-html="t('landing.users.user_11')"></span>
              </div>
            </div>
            <!-- <div class="section-8__soc_block"> <a class="section-8__soc_item" href="#"><img src="img/welcome/section-1/you.svg" alt=""></a><a class="section-8__soc_item" href="#"><img src="img/welcome/section-1/tl.svg" alt=""></a></div> -->
          </div>
        </div>
      </div>
    </section>
    <section class="section-10" id="roadmap">
      <div class="container">
        <div class="section-10__heading vis_on_scroll" ref="heading10" >
          <span>Roadmap</span>
        </div>
        <div class="section-10__main_block">
          <div class="section-10__line_block">
            <div class="section-10__line"></div>
            <div class="section-10__arr">
              <img src="img/welcome/section-10/arr.svg" alt="" />
            </div>
            <div
              class="section-10__year_block year-2022 section-10-item"
              :class="{ 'anim-fade': animations.anim10 === true }"
              style="animation-delay: 0.1s"
            >
              <span>2022</span>
            </div>
            <div class="section-10__line_item">
              <div
                class="section-10__line_item_text_top section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 0.2s"
              >
                <span>Seed round TKZ</span>
              </div>
              <div
                class="section-10__line_item_circle section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 0.3s"
              >
                <span>Q1</span>
              </div>
            </div>
            <div class="section-10__line_item">
              <div
                class="section-10__line_item_text_top section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 0.4s"
              >
                <span>Organization of mining pools</span>
              </div>
              <div
                class="section-10__line_item_circle section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 0.5s"
              >
                <span>Q2</span>
              </div>
            </div>
            <div class="section-10__line_item">
              <div
                class="section-10__line_item_text_top section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 0.6s"
              >
                <span>Release Tokenza Al v1.0</span>
              </div>
              <div
                class="section-10__line_item_circle section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 0.7s"
              >
                <span>Q2</span>
              </div>
            </div>
            <div class="section-10__line_item">
              <div
                class="section-10__line_item_text_top section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 0.8s"
              >
                <span>MVP Tokenza Wallet</span>
              </div>
              <div
                class="section-10__line_item_circle section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 0.9s"
              >
                <span>Q3</span>
              </div>
            </div>
            <div class="section-10__line_item">
              <div
                class="section-10__line_item_text_top section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 1s"
              >
                <span>
                  Ai v1.1 (improved protection algorithm for high market
                  volatility)</span
                >
              </div>
              <div
                class="section-10__line_item_circle section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 1.1s"
              >
                <span>Q4</span>
              </div>
            </div>
            <div class="section-10__line_izgib_1"></div>
            <div class="section-10__line_izgib_2"></div>
          </div>
          <div class="section-10__line_block second">
            <div class="section-10__line second"></div>
            <div class="section-10__arr arr-right">
              <img src="img/welcome/section-10/arr.svg" alt="" />
            </div>
            <div
              class="section-10__year_block year-2023 section-10-item"
              :class="{ 'anim-fade': animations.anim10 === true }"
              style="animation-delay: 1.2s"
            >
              <span>2023</span>
            </div>
            <!-- <div class="section-10__line_item">
              <div class="section-10__line_item_text_top section-10-item" :class="{ 'anim-fade': animations.anim10 === true }" style="animation-delay: 1.3s"> <span>Tokenza Swap</span></div>
              <div class="section-10__line_item_circle section-10-item" :class="{ 'anim-fade': animations.anim10 === true }" style="animation-delay: 1.4s"><span>Q1</span></div>
            </div> -->
            <div class="section-10__line_item">
              <div
                class="section-10__line_item_text_top section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 1.5s"
              >
                <span>Platform release Tokenza iO</span>
              </div>
              <div
                class="section-10__line_item_circle section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 1.6s"
              >
                <span>Q3</span>
              </div>
            </div>
            <div class="section-10__line_item">
              <div
                class="section-10__line_item_text_top section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 1.7s"
              >
                <span>AirDrop TKZ</span>
              </div>
              <div
                class="section-10__line_item_circle section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 1.8s"
              >
                <span>Q3</span>
              </div>
            </div>
            <div class="section-10__line_item">
              <div
                class="section-10__line_item_text_top section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 1.9s"
              >
                <span>Tokenza Wallet update 1.1 (Staking support)</span>
              </div>
              <div
                class="section-10__line_item_circle section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 2s"
              >
                <span>Q3</span>
              </div>
            </div>
            <div class="section-10__line_item">
              <div
                class="section-10__line_item_text_top section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 2.1s"
              >
                <span>Tokenza Life</span>
              </div>
              <div
                class="section-10__line_item_circle section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 2.2s"
              >
                <span>Q4</span>
              </div>
            </div>
            <div class="section-10__line_izgib_3"></div>
            <div class="section-10__line_izgib_4"></div>
          </div>
          <div class="section-10__line_block third">
            <div class="section-10__line third"></div>
            <div class="section-10__arr arr-third">
              <img src="img/welcome/section-10/arr-purp.svg" alt="" />
            </div>
            <div
              class="section-10__year_block year-2024 section-10-item"
              :class="{ 'anim-fade': animations.anim10 === true }"
              style="animation-delay: 2.3s"
            >
              <span>2024</span>
            </div>
            <div class="section-10__line_item">
              <div
                class="section-10__line_item_text_top section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 2.4s"
              >
                <span>Private Sale round TKZ</span>
              </div>
              <div
                class="section-10__line_item_circle section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 2.5s"
              >
                <span>Q4</span>
              </div>
            </div>
            <div class="section-10__line_item">
              <div
                class="section-10__line_item_text_top section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 2.6s"
              >
                <span>Tokenza Swap</span>
              </div>
              <div
                class="section-10__line_item_circle purp section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 2.7s"
              >
                <span>Q1</span>
              </div>
            </div>
            <div class="section-10__line_item">
              <div
                class="section-10__line_item_text_top section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 2.6s"
              >
                <span
                  >Tokenza Wallet 1.2 (Implementation of Tokenza Swap in a
                  mobile app)</span
                >
              </div>
              <div
                class="section-10__line_item_circle purp section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 2.7s"
              >
                <span>Q1</span>
              </div>
            </div>
            <div class="section-10__line_item">
              <div
                class="section-10__line_item_text_top section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 2.8s"
              >
                <span>Public Sale TKZ</span>
              </div>
              <div
                class="section-10__line_item_circle purp section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 2.9s"
              >
                <span>Q3</span>
              </div>
            </div>
            <div class="section-10__line_item">
              <div
                class="section-10__line_item_text_top section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 3s"
              >
                <span>Public placement TKZ</span>
              </div>
              <div
                class="section-10__line_item_circle purp section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 3.1s"
              >
                <span>Q4</span>
              </div>
            </div>
            <!-- <div class="section-10__line_item">
              <div class="section-10__line_item_text_top section-10-item" :class="{ 'anim-fade': animations.anim10 === true }" style="animation-delay: 3.2s"> <span>Integration Tokenza Ai with Tokenza Wallett</span></div>
              <div class="section-10__line_item_circle purp section-10-item" :class="{ 'anim-fade': animations.anim10 === true }" style="animation-delay: 3.3s"><span>Q4</span></div>
            </div> -->
            <div class="section-10__line_izgib_1 purp"></div>
            <div class="section-10__line_izgib_2 purp"></div>
          </div>
          <div class="section-10__line_block fourth">
            <div class="section-10__line fourth"></div>
            <div class="section-10__arr arr-fourth">
              <img src="img/welcome/section-10/arr-purp.svg" alt="" />
            </div>
            <div
              class="section-10__year_block year-2025 section-10-item"
              :class="{ 'anim-fade': animations.anim10 === true }"
              style="animation-delay: 3.4s"
            >
              <span>2025</span>
            </div>
            <div class="section-10__arr arr-fifth">
              <img src="img/welcome/section-10/arr-purp.svg" alt="" />
            </div>
            <div
              class="section-10__year_block year-2026 section-10-item"
              :class="{ 'anim-fade': animations.anim10 === true }"
              style="animation-delay: 3.5s"
            >
              <span>2026</span>
            </div>

            <div class="section-10__line_item">
              <div
                class="section-10__line_item_text_top section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 3.2s"
              >
                <span>Integration Tokenza Ai with Tokenza Wallett</span>
              </div>
              <div
                class="section-10__line_item_circle purp section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 3.3s"
              >
                <span>Q4</span>
              </div>
            </div>
            <div class="section-10__line_item">
              <div
                class="section-10__line_item_text_top section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 3.6s"
              >
                <span>Tokenza Proof-of-Burn 1-st</span>
              </div>
              <div
                class="section-10__line_item_circle purp section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 3.7s"
              >
                <span>Q1</span>
              </div>
            </div>
            <div class="section-10__line_item">
              <div
                class="section-10__line_item_text_top section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 3.8s"
              >
                <span>NFT Collection</span>
              </div>
              <div
                class="section-10__line_item_circle purp section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 3.9s"
              >
                <span>Q3</span>
              </div>
            </div>
            <div class="section-10__line_item">
              <div
                class="section-10__line_item_text_top section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 4s"
              >
                <span>Launch Launchpad Tokenzat</span>
              </div>
              <div
                class="section-10__line_item_circle purp section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 4.1s"
              >
                <span>Q4</span>
              </div>
            </div>
            <div class="section-10__line_item">
              <div
                class="section-10__line_item_text_top section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 4.2s"
              >
                <span
                  >Launch of Tokenza Messenger with end-to-end encryption</span
                >
              </div>
              <div
                class="section-10__line_item_circle purp section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 4.3s"
              >
                <span>Q1</span>
              </div>
            </div>
            <div class="section-10__line_izgib_3 purp"></div>
            <div class="section-10__line_izgib_4 purp"></div>
          </div>
          <div class="section-10__line_block last">
            <div class="section-10__line last"></div>
            <div class="section-10__arr arr-third">
              <img src="img/welcome/section-10/arr-purp.svg" alt="" />
            </div>
            <div class="section-10__arr arr-last">
              <img src="img/welcome/section-10/arr-purp.svg" alt="" />
            </div>
            <div
              class="section-10__year_block year-2027 section-10-item"
              :class="{ 'anim-fade': animations.anim10 === true }"
              style="animation-delay: 4.6s"
            >
              <span>2027</span>
            </div>

            <div class="section-10__line_item">
              <div
                class="section-10__line_item_text_top section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 4.4s"
              >
                <span>Launch of TKZ Market</span>
              </div>
              <div
                class="section-10__line_item_circle purp section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 4.5s"
              >
                <span>Q3</span>
              </div>
            </div>
            <div class="section-10__line_item">
              <div
                class="section-10__line_item_text_top section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 4.7s"
              >
                <span
                  >TKZ Gaming placement platform DeFi projects and games</span
                >
              </div>
              <div
                class="section-10__line_item_circle purp section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 4.8s"
              >
                <span>Q4</span>
              </div>
            </div>
            <div class="section-10__line_item last">
              <div
                class="section-10__line_item_text_top section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 4.9s"
              >
                <span>Q-2 Tokenza Metaverse</span>
              </div>
              <div
                class="section-10__line_item_circle purp section-10-item"
                :class="{ 'anim-fade': animations.anim10 === true }"
                style="animation-delay: 5s"
              >
                <span>Q2</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-9">
      <div class="container">
        <div class="section-9__heading vis_on_scroll" ref="heading9">
          <span>Our Partners</span>
        </div>
        <div class="section-9__items">
          <div
            class="section-9__item"
            :class="{ 'anim-fade': animations.anim9 === true }"
            style="animation-delay: 0.2s"
          >
            <img src="img/welcome/payments/item-1.png" alt="" />
          </div>
          <div
            class="section-9__item"
            :class="{ 'anim-fade': animations.anim9 === true }"
            style="animation-delay: 0.4s"
          >
            <img src="img/welcome/payments/item-2.png" alt="" />
          </div>
          <div
            class="section-9__item"
            :class="{ 'anim-fade': animations.anim9 === true }"
            style="animation-delay: 0.6s"
          >
            <img src="img/welcome/payments/item-3.png" alt="" />
          </div>
          <div
            class="section-9__item"
            :class="{ 'anim-fade': animations.anim9 === true }"
            style="animation-delay: 0.8s"
          >
            <img src="img/welcome/payments/item-4.png" alt="" />
          </div>
          <div
            class="section-9__item"
            :class="{ 'anim-fade': animations.anim9 === true }"
            style="animation-delay: 1s"
          >
            <img src="img/welcome/payments/item-5.png" alt="" />
          </div>
          <div
            class="section-9__item"
            :class="{ 'anim-fade': animations.anim9 === true }"
            style="animation-delay: 1.2s"
          >
            <img src="img/welcome/payments/item-6.png" alt="" />
          </div>
          <div
            class="section-9__item"
            :class="{ 'anim-fade': animations.anim9 === true }"
            style="animation-delay: 1.4s"
          >
            <img src="img/welcome/payments/item-7.png" alt="" />
          </div>
          <div
            class="section-9__item"
            :class="{ 'anim-fade': animations.anim9 === true }"
            style="animation-delay: 1.6s"
          >
            <img src="img/welcome/payments/item-8.png" alt="" />
          </div>
        </div>
      </div>
    </section>

    <footer class="footer">
      <div class="container">
        <div class="footer__left_side">
          <div
            class="footer__left_side_text_1 vis_on_scroll visible"
            ref="heading12"
          >
            <span v-html="t('welcome.make_first_steps')"></span>
          </div>
          <div class="footer__left_side_text_2 footer-text-1 anim-fade">
            <span v-html="t('welcome.reg_and_get_case')"></span>
          </div>
          <button
            class="footer__left_side_btn footer-text-1 anim-fade"
            @click.prevent="
              $emit('openPopup', { name: 'PopupAuth', register: true })
            "
          >
            <span v-html="t('welcome.go_register')"></span>
            <div class="footer__gradient"></div>
          </button>
        </div>
        <div class="footer__right_side" ref="heading13">
          <!-- <div class="footer__right_side_text_1 vis_on_scroll visible" ref="heading13">
            <span>Tartu maakond, Tartu linn, Tartu linn,<br>Ranna tee 1b-3, 51008</span>
          </div> -->
          <a
            class="footer__right_side_text_2 footer-text-2 anim-fade"
            href="mailto:contact@tokenza.io"
            ><span>contact@tokenza.io</span></a
          >
          <div class="footer__right_side_soc">
            <a
              class="footer__right_side_soc_item footer-text-2 anim-fade"
              href="https://instagram.com/tokenza_io"
              ><img src="img//soc-1.svg" alt="" /><span>Instagram</span></a
            >
            <a
              class="footer__right_side_soc_item footer-text-2 anim-fade"
              href="https://t.me/tokenza_io"
              ><img src="img//soc-2.svg" alt="" /><span>Telegram</span></a
            >
            <a
              class="footer__right_side_soc_item footer-text-2 anim-fade"
              href="https://www.youtube.com/channel/UCBJ-ryUpMX93aZvjOZuLMPA"
              ><img src="img//soc-3.svg" alt="" /><span>YouTube</span></a
            >
          </div>
        </div>
        <!-- <div class="footer__copy">
          <div class="footer__date main-page-date footer-text-2 anim-fade"> <span>© 2023</span></div>
          <div class="footer__copyights main-page-copyrights footer-text-2 anim-fade"><span>tokenza.io Все права защищены</span></div>
        </div> -->
      </div>
      <div class="footer__container">
        <div class="footer__date main-page-date anim-fade">
          <span>© 2023</span>
        </div>
        <div class="footer__copyights main-page-copyrights anim-fade">
          <span>
            {{ t("footer.copyright") }}
          </span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import LanguageSwitcher from "../../components/Language/LanguageSwitcher.vue";
import VueScrollTo from 'vue-scrollto';

export default {
  components: { LanguageSwitcher },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

    return { t };
  },

  data() {
    return {
      animations: {},
      elements: {},
      isMobileScreen: true,
    };
  },

  methods: {
    redirect() {
      window.location = "https://airdrop.tokenza.io/#/"
      // window.location = "http://demo.promo/pa/outsource/tokenza_airdrop/#/";
    },

    handleScroll() {
      Object.values(this.elements).forEach((element) => {
        const t = window.innerHeight - window.innerHeight / 6;
        const rect = element.getBoundingClientRect();

        if (!element.classList.contains("visible") && t > rect.top) {
          element.classList.add("visible");
        }
      });
    },

    animateElements(elementKeys) {
      elementKeys.forEach((elementKey) => {
        if (this.animations[elementKey] === false)
          this.animations[elementKey] = true;
      });
    },

    animateAfterAnimationEnd(eventElement, animateElements) {
      eventElement.addEventListener("animationend", () => {
        this.animateElements(animateElements); // array
      });
    },

    afterHeadingAnim() {
      Object.keys(this.elements).forEach((key, index) => {
        const element = this.$refs[`heading${index + 1}`];
        if (element === undefined) return;

        this.animateAfterAnimationEnd(element, [`anim${index + 1}`]);
      });
    },

    elementsInit() {
      for (let i = 1; i <= 11; i++) {
        this.elements[`heading${i}`] = this.$refs[`heading${i}`];
        this.animations[`anim${i}`] = false;
      }
    },

    updateScreenStyles() {
      this.$store.dispatch("updateMobileScreen", this.isMobileScreen);
      localStorage.setItem("mobileScreen", this.isMobileScreen);
    },

    checkLoginBuyRoute() {
      if (this.$route.query.login === null) {
        this.$emit("openPopup", { name: "PopupAuth", register: true });
      }
    },

    scrollBehavior(to) {
    // scrollBehavior(to, from, savedPosition) {
      VueScrollTo.scrollTo(to, 700);
      // console.log(to)
      //   if (to.hash) {
      //       return { selector: to.hash }
      //   } else if (savedPosition) {
      //       return savedPosition;
      //   } else {
      //       return { x: 0, y: 0 }
      //   }
    }
  },

  created() {
    this.checkLoginBuyRoute();

    window.addEventListener("scroll", this.handleScroll);
  },

  mounted() {
    this.elementsInit();
    this.afterHeadingAnim();
    this.$emit("langingPage", true);
    this.updateScreenStyles();
  },

  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/abstracts/_functions";
@import "@/assets/scss/layout/welcome/layout";

button {
  font-size: 1em;
}

.welcome {
  width: 100%;
  font-size: 16px;
  font-family: Montserrat, sans-serif;

  .header {
    padding-top: 1.875em;
    &__menu {
      padding: 0;
      justify-content: space-between;
      background: transparent !important;
      margin-left: 0;
      width: 100%;
      display: flex !important;

      &_item {
        margin: 0;
        color: #5e636e;

        &:hover {
          color: #fff;
        }
      }
    }

    &__languages {
      width: 10em;
      margin: 0;
      display: flex !important;
    }
  }

  .header,
  .section-1,
  .line,
  .section-9,
  .section-10,
  .footer {
    & .container {
      width: 87em;
    }
  }

  .footer__container {
    width: 87em;

    .footer__date.main-page-date,
    .footer__copyights.main-page-copyrights {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .section {
    &-6 {
      margin-bottom: 0;
    }

    &-7,
    &-8 {
      margin-top: 12.5em;
      margin-bottom: 0;
    }

    &-9 {
      margin-top: 13.75em;
    }

    &-10 {
      margin-top: 8.125em;
      margin-bottom: 0;
    }

    &-8 {
      &__item_pos {
        height: 4em;
      }
    }
  }

  .section-9__items {
    width: 100%;
  }

  @media (max-width: 1600px) {
    font-size: 12.8px;
  }

  @media (max-width: 1200px) {
    font-size: 10.72px;
  }

  @media (max-width: 1024px) and (orientation: landscape) {
    font-size: 1vw;
  }

  @media (max-width: 1024px) and (orientation: portrait) {
    font-size: 1vw !important;

    .header {
      font-size: 3em;

      &__menu_block {
        margin-bottom: 1em;
      }

      &__languages {
        top: -.5em;
        left: 0;
        font-size: 4rem !important;
      }
    }

    // div.header__languages {
    //   font-size: 3rem !important;
    // }

    .line {
      font-size: 2.4em;
    }

    .section-1__text_1 {
      font-size: 1.6em;
    }

    .section-1__apps_block {
      font-size: 2.8em;
    }

    .section-1__links_items {
      font-size: 2.2em;
    }

    .section-1__galaxy,
    .section-1__phone {
      font-size: 1.8em;
    }

    .section-1__lines {
      font-size: 2.1em;
    }

    .section-2 {
      font-size: 2em;
    }

    .section-2__text_block_1,
    .section-2__text_block_3 {
      font-size: 1.2em;
    }

    .section-2__text_block_2 {
      font-size: 1.4em;
    }

    .section-3__text_block_1,
    .section-3__text_block_2 {
      font-size: 2.2em;
    }

    .section-3__galaxy,
    .section-3__lines {
      font-size: 2em;
    }

    .section-3__phones {
      font-size: 1.85em;
    }

    .section-4__heading,
    .section-4__phone,
    .section-4__lines {
      font-size: 2em;
    }

    .section-4__phone {
      top: unset !important;
      bottom: 26em !important;
    }
    .section-4__lines {
      top: unset !important;
      bottom: 24em !important;
    }

    .welcome .section-4__galaxy {
      top: unset !important;
      bottom: 101em !important;
    }

    .section-4__text_1,
    .section-4__text_2 {
      font-size: 3.4em;
    }

    .section-4__galaxy {
      font-size: 1.8em;
      margin-left: -37.25em;
    }

    .section-5__heading {
      font-size: 2em;
    }

    .section-5__left_text_block,
    .section-5__right_text_block {
      font-size: 3.4em;
    }

    .section-5__arr_left,
    .section-5__arr_right {
      font-size: 3.6em;
    }

    .section-5__galaxy {
      font-size: 3em;
    }

    .section-5__coin {
      font-size: 2.4em;
    }

    .section-6 {
      font-size: 2.4em;
    }

    .section-6__heading {
      font-size: 0.75em;
    }

    .section-6__text {
      font-size: 1.26em;
    }

    .section-7__heading {
      font-size: 2em;
    }

    .section-7__text_block {
      font-size: 3em;
    }

    .section-7__download_text {
      font-size: 4.2em;
    }

    .section-7 .section-5__download_btn {
      font-size: 4em;
    }

    .section-8__heading,
    .section-10__heading,
    .section-9__heading {
      font-size: 2em;
    }

    .section-8__items {
      font-size: 3.5em;
    }

    .section-9__items {
      font-size: 1.8em;
    }

    .footer {
      font-size: 1em;
    }

    .footer__left_side_text_1,
    .footer__left_side_text_2 {
      font-size: 2em;
    }

    .footer__right_side_text_1,
    .footer__right_side_text_2 {
      font-size: 2.2em;
    }

    .footer__right_side_soc {
      font-size: 3em;
    }

    .footer__copyights.main-page-copyrights {
      font-size: 2.5em;
      margin-top: 1.6em;
    }

    .footer__date.main-page-date {
      font-size: 3.1rem;
      margin-top: 1em !important;
    }

    & .container {
      width: 90% !important;
    }

    .section {
      &-8 {
        &__item_pos {
          height: auto;
        }
      }
    }
  }
}
</style>
