<template>
  <div class="settings__item_active_session tab-content" id="tab_4">
    <div class="settings__item_pass_heading">
      <span>Your telegram bot token:</span>
    </div>

    <ButtonRefferal class="button" :linkBody="user?.notificationSettings?.token" :linkTitle="user?.notificationSettings?.token" />

  </div>
</template>

<script>
import {mapState} from 'vuex'
import ButtonRefferal from '../UI/ButtonRefferal.vue'

export default {
  components: { ButtonRefferal },

  computed: {
    ...mapState({
      user: state => state?.account?.user,
      session: state => state?.session?.session,
    })
  },
}
</script>

<style lang="scss" scoped>
.tab-content {
  min-height: 21.3em;
}

.button {
  width: 100%;
  max-width: 27.8em;
  text-align: left;
  padding-left: 1.45em;
  margin-top: 2.8em;
}

@media (max-width: 1024px) {
  .mobileScreen {
    .tab-content {
      min-height: auto;
    }
    
    .button {
      font-size: .89em;
      max-width: 100%;
    }
  }
}
</style>