// source: mlmbox/type/farming.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var mlmbox_type_bonus_pb = require('../../mlmbox/type/bonus_pb.js');
goog.object.extend(proto, mlmbox_type_bonus_pb);
var mlmbox_type_payment_pb = require('../../mlmbox/type/payment_pb.js');
goog.object.extend(proto, mlmbox_type_payment_pb);
var mlmbox_type_time_point_pb = require('../../mlmbox/type/time_point_pb.js');
goog.object.extend(proto, mlmbox_type_time_point_pb);
goog.exportSymbol('proto.mlmbox.type.Farming', null, global);
goog.exportSymbol('proto.mlmbox.type.Farming.Account', null, global);
goog.exportSymbol('proto.mlmbox.type.Farming.Account.Asset', null, global);
goog.exportSymbol('proto.mlmbox.type.Farming.Account.Asset.State', null, global);
goog.exportSymbol('proto.mlmbox.type.Farming.Contract', null, global);
goog.exportSymbol('proto.mlmbox.type.Farming.Contract.ID', null, global);
goog.exportSymbol('proto.mlmbox.type.Farming.Package', null, global);
goog.exportSymbol('proto.mlmbox.type.Farming.Package.ID', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Farming = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Farming, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Farming.displayName = 'proto.mlmbox.type.Farming';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Farming.Package = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Farming.Package, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Farming.Package.displayName = 'proto.mlmbox.type.Farming.Package';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Farming.Package.ID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Farming.Package.ID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Farming.Package.ID.displayName = 'proto.mlmbox.type.Farming.Package.ID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Farming.Contract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Farming.Contract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Farming.Contract.displayName = 'proto.mlmbox.type.Farming.Contract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Farming.Contract.ID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.type.Farming.Contract.ID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Farming.Contract.ID.displayName = 'proto.mlmbox.type.Farming.Contract.ID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Farming.Account = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.type.Farming.Account.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.type.Farming.Account, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Farming.Account.displayName = 'proto.mlmbox.type.Farming.Account';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Farming.Account.Asset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.type.Farming.Account.Asset.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.type.Farming.Account.Asset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Farming.Account.Asset.displayName = 'proto.mlmbox.type.Farming.Account.Asset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.type.Farming.Account.Asset.State = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.type.Farming.Account.Asset.State.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.type.Farming.Account.Asset.State, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.type.Farming.Account.Asset.State.displayName = 'proto.mlmbox.type.Farming.Account.Asset.State';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Farming.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Farming.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Farming} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Farming.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Farming}
 */
proto.mlmbox.type.Farming.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Farming;
  return proto.mlmbox.type.Farming.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Farming} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Farming}
 */
proto.mlmbox.type.Farming.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Farming.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Farming.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Farming} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Farming.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Farming.Package.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Farming.Package.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Farming.Package} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Farming.Package.toObject = function(includeInstance, msg) {
  var f, obj = {
    packageId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    packageTitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    farmingPercent: jspb.Message.getFieldWithDefault(msg, 3, ""),
    farmingDuration: jspb.Message.getFieldWithDefault(msg, 4, 0),
    lockupDuration: (f = msg.getLockupDuration()) && mlmbox_type_time_point_pb.DurationPoint.toObject(includeInstance, f),
    bonusDirectPercent: jspb.Message.getFieldWithDefault(msg, 6, ""),
    bonusBinaryPercent: jspb.Message.getFieldWithDefault(msg, 7, ""),
    bonusBinaryWeekLimit: jspb.Message.getFieldWithDefault(msg, 8, ""),
    paymentPriceRange: (f = msg.getPaymentPriceRange()) && mlmbox_type_payment_pb.PaymentPriceRange.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Farming.Package}
 */
proto.mlmbox.type.Farming.Package.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Farming.Package;
  return proto.mlmbox.type.Farming.Package.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Farming.Package} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Farming.Package}
 */
proto.mlmbox.type.Farming.Package.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPackageId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFarmingPercent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFarmingDuration(value);
      break;
    case 5:
      var value = new mlmbox_type_time_point_pb.DurationPoint;
      reader.readMessage(value,mlmbox_type_time_point_pb.DurationPoint.deserializeBinaryFromReader);
      msg.setLockupDuration(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBonusDirectPercent(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBonusBinaryPercent(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBonusBinaryWeekLimit(value);
      break;
    case 9:
      var value = new mlmbox_type_payment_pb.PaymentPriceRange;
      reader.readMessage(value,mlmbox_type_payment_pb.PaymentPriceRange.deserializeBinaryFromReader);
      msg.setPaymentPriceRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Farming.Package.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Farming.Package.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Farming.Package} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Farming.Package.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPackageId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPackageTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFarmingPercent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFarmingDuration();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getLockupDuration();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      mlmbox_type_time_point_pb.DurationPoint.serializeBinaryToWriter
    );
  }
  f = message.getBonusDirectPercent();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBonusBinaryPercent();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBonusBinaryWeekLimit();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPaymentPriceRange();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      mlmbox_type_payment_pb.PaymentPriceRange.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Farming.Package.ID.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Farming.Package.ID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Farming.Package.ID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Farming.Package.ID.toObject = function(includeInstance, msg) {
  var f, obj = {
    packageId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Farming.Package.ID}
 */
proto.mlmbox.type.Farming.Package.ID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Farming.Package.ID;
  return proto.mlmbox.type.Farming.Package.ID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Farming.Package.ID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Farming.Package.ID}
 */
proto.mlmbox.type.Farming.Package.ID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPackageId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Farming.Package.ID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Farming.Package.ID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Farming.Package.ID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Farming.Package.ID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPackageId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 package_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Farming.Package.ID.prototype.getPackageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Farming.Package.ID} returns this
 */
proto.mlmbox.type.Farming.Package.ID.prototype.setPackageId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 package_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Farming.Package.prototype.getPackageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Farming.Package} returns this
 */
proto.mlmbox.type.Farming.Package.prototype.setPackageId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string package_title = 2;
 * @return {string}
 */
proto.mlmbox.type.Farming.Package.prototype.getPackageTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Farming.Package} returns this
 */
proto.mlmbox.type.Farming.Package.prototype.setPackageTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string farming_percent = 3;
 * @return {string}
 */
proto.mlmbox.type.Farming.Package.prototype.getFarmingPercent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Farming.Package} returns this
 */
proto.mlmbox.type.Farming.Package.prototype.setFarmingPercent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 farming_duration = 4;
 * @return {number}
 */
proto.mlmbox.type.Farming.Package.prototype.getFarmingDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Farming.Package} returns this
 */
proto.mlmbox.type.Farming.Package.prototype.setFarmingDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional DurationPoint lockup_duration = 5;
 * @return {?proto.mlmbox.type.DurationPoint}
 */
proto.mlmbox.type.Farming.Package.prototype.getLockupDuration = function() {
  return /** @type{?proto.mlmbox.type.DurationPoint} */ (
    jspb.Message.getWrapperField(this, mlmbox_type_time_point_pb.DurationPoint, 5));
};


/**
 * @param {?proto.mlmbox.type.DurationPoint|undefined} value
 * @return {!proto.mlmbox.type.Farming.Package} returns this
*/
proto.mlmbox.type.Farming.Package.prototype.setLockupDuration = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Farming.Package} returns this
 */
proto.mlmbox.type.Farming.Package.prototype.clearLockupDuration = function() {
  return this.setLockupDuration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Farming.Package.prototype.hasLockupDuration = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string bonus_direct_percent = 6;
 * @return {string}
 */
proto.mlmbox.type.Farming.Package.prototype.getBonusDirectPercent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Farming.Package} returns this
 */
proto.mlmbox.type.Farming.Package.prototype.setBonusDirectPercent = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string bonus_binary_percent = 7;
 * @return {string}
 */
proto.mlmbox.type.Farming.Package.prototype.getBonusBinaryPercent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Farming.Package} returns this
 */
proto.mlmbox.type.Farming.Package.prototype.setBonusBinaryPercent = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string bonus_binary_week_limit = 8;
 * @return {string}
 */
proto.mlmbox.type.Farming.Package.prototype.getBonusBinaryWeekLimit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Farming.Package} returns this
 */
proto.mlmbox.type.Farming.Package.prototype.setBonusBinaryWeekLimit = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional PaymentPriceRange payment_price_range = 9;
 * @return {?proto.mlmbox.type.PaymentPriceRange}
 */
proto.mlmbox.type.Farming.Package.prototype.getPaymentPriceRange = function() {
  return /** @type{?proto.mlmbox.type.PaymentPriceRange} */ (
    jspb.Message.getWrapperField(this, mlmbox_type_payment_pb.PaymentPriceRange, 9));
};


/**
 * @param {?proto.mlmbox.type.PaymentPriceRange|undefined} value
 * @return {!proto.mlmbox.type.Farming.Package} returns this
*/
proto.mlmbox.type.Farming.Package.prototype.setPaymentPriceRange = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Farming.Package} returns this
 */
proto.mlmbox.type.Farming.Package.prototype.clearPaymentPriceRange = function() {
  return this.setPaymentPriceRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Farming.Package.prototype.hasPaymentPriceRange = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Farming.Contract.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Farming.Contract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Farming.Contract} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Farming.Contract.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    packageId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    assetId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    packageTitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    farmingPercent: jspb.Message.getFieldWithDefault(msg, 5, ""),
    farmingDuration: jspb.Message.getFieldWithDefault(msg, 6, 0),
    lockupDuration: (f = msg.getLockupDuration()) && mlmbox_type_time_point_pb.DurationPoint.toObject(includeInstance, f),
    lockupInitBodyValue: jspb.Message.getFieldWithDefault(msg, 8, ""),
    lockupValue: jspb.Message.getFieldWithDefault(msg, 9, ""),
    earnedValue: jspb.Message.getFieldWithDefault(msg, 10, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 11, 0),
    activated: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    activatedAt: jspb.Message.getFieldWithDefault(msg, 13, 0),
    closed: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    closedAt: jspb.Message.getFieldWithDefault(msg, 15, 0),
    nextAt: jspb.Message.getFieldWithDefault(msg, 16, 0),
    incomePerDay: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Farming.Contract}
 */
proto.mlmbox.type.Farming.Contract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Farming.Contract;
  return proto.mlmbox.type.Farming.Contract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Farming.Contract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Farming.Contract}
 */
proto.mlmbox.type.Farming.Contract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setContractId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPackageId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFarmingPercent(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFarmingDuration(value);
      break;
    case 7:
      var value = new mlmbox_type_time_point_pb.DurationPoint;
      reader.readMessage(value,mlmbox_type_time_point_pb.DurationPoint.deserializeBinaryFromReader);
      msg.setLockupDuration(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLockupInitBodyValue(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLockupValue(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setEarnedValue(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCreatedAt(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActivated(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActivatedAt(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setClosed(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setClosedAt(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNextAt(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setIncomePerDay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Farming.Contract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Farming.Contract.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Farming.Contract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Farming.Contract.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPackageId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getAssetId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getPackageTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFarmingPercent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFarmingDuration();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getLockupDuration();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      mlmbox_type_time_point_pb.DurationPoint.serializeBinaryToWriter
    );
  }
  f = message.getLockupInitBodyValue();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLockupValue();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getEarnedValue();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getActivated();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getActivatedAt();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getClosed();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getClosedAt();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getNextAt();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getIncomePerDay();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Farming.Contract.ID.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Farming.Contract.ID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Farming.Contract.ID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Farming.Contract.ID.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Farming.Contract.ID}
 */
proto.mlmbox.type.Farming.Contract.ID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Farming.Contract.ID;
  return proto.mlmbox.type.Farming.Contract.ID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Farming.Contract.ID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Farming.Contract.ID}
 */
proto.mlmbox.type.Farming.Contract.ID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setContractId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Farming.Contract.ID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Farming.Contract.ID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Farming.Contract.ID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Farming.Contract.ID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 contract_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Farming.Contract.ID.prototype.getContractId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Farming.Contract.ID} returns this
 */
proto.mlmbox.type.Farming.Contract.ID.prototype.setContractId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 contract_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Farming.Contract.prototype.getContractId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Farming.Contract} returns this
 */
proto.mlmbox.type.Farming.Contract.prototype.setContractId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 package_id = 2;
 * @return {number}
 */
proto.mlmbox.type.Farming.Contract.prototype.getPackageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Farming.Contract} returns this
 */
proto.mlmbox.type.Farming.Contract.prototype.setPackageId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 asset_id = 3;
 * @return {number}
 */
proto.mlmbox.type.Farming.Contract.prototype.getAssetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Farming.Contract} returns this
 */
proto.mlmbox.type.Farming.Contract.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string package_title = 4;
 * @return {string}
 */
proto.mlmbox.type.Farming.Contract.prototype.getPackageTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Farming.Contract} returns this
 */
proto.mlmbox.type.Farming.Contract.prototype.setPackageTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string farming_percent = 5;
 * @return {string}
 */
proto.mlmbox.type.Farming.Contract.prototype.getFarmingPercent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Farming.Contract} returns this
 */
proto.mlmbox.type.Farming.Contract.prototype.setFarmingPercent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 farming_duration = 6;
 * @return {number}
 */
proto.mlmbox.type.Farming.Contract.prototype.getFarmingDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Farming.Contract} returns this
 */
proto.mlmbox.type.Farming.Contract.prototype.setFarmingDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional DurationPoint lockup_duration = 7;
 * @return {?proto.mlmbox.type.DurationPoint}
 */
proto.mlmbox.type.Farming.Contract.prototype.getLockupDuration = function() {
  return /** @type{?proto.mlmbox.type.DurationPoint} */ (
    jspb.Message.getWrapperField(this, mlmbox_type_time_point_pb.DurationPoint, 7));
};


/**
 * @param {?proto.mlmbox.type.DurationPoint|undefined} value
 * @return {!proto.mlmbox.type.Farming.Contract} returns this
*/
proto.mlmbox.type.Farming.Contract.prototype.setLockupDuration = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Farming.Contract} returns this
 */
proto.mlmbox.type.Farming.Contract.prototype.clearLockupDuration = function() {
  return this.setLockupDuration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Farming.Contract.prototype.hasLockupDuration = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string lockup_init_body_value = 8;
 * @return {string}
 */
proto.mlmbox.type.Farming.Contract.prototype.getLockupInitBodyValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Farming.Contract} returns this
 */
proto.mlmbox.type.Farming.Contract.prototype.setLockupInitBodyValue = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string lockup_value = 9;
 * @return {string}
 */
proto.mlmbox.type.Farming.Contract.prototype.getLockupValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Farming.Contract} returns this
 */
proto.mlmbox.type.Farming.Contract.prototype.setLockupValue = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string earned_value = 10;
 * @return {string}
 */
proto.mlmbox.type.Farming.Contract.prototype.getEarnedValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Farming.Contract} returns this
 */
proto.mlmbox.type.Farming.Contract.prototype.setEarnedValue = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional uint32 created_at = 11;
 * @return {number}
 */
proto.mlmbox.type.Farming.Contract.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Farming.Contract} returns this
 */
proto.mlmbox.type.Farming.Contract.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional bool activated = 12;
 * @return {boolean}
 */
proto.mlmbox.type.Farming.Contract.prototype.getActivated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mlmbox.type.Farming.Contract} returns this
 */
proto.mlmbox.type.Farming.Contract.prototype.setActivated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional uint32 activated_at = 13;
 * @return {number}
 */
proto.mlmbox.type.Farming.Contract.prototype.getActivatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Farming.Contract} returns this
 */
proto.mlmbox.type.Farming.Contract.prototype.setActivatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional bool closed = 14;
 * @return {boolean}
 */
proto.mlmbox.type.Farming.Contract.prototype.getClosed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mlmbox.type.Farming.Contract} returns this
 */
proto.mlmbox.type.Farming.Contract.prototype.setClosed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional uint32 closed_at = 15;
 * @return {number}
 */
proto.mlmbox.type.Farming.Contract.prototype.getClosedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Farming.Contract} returns this
 */
proto.mlmbox.type.Farming.Contract.prototype.setClosedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional uint32 next_at = 16;
 * @return {number}
 */
proto.mlmbox.type.Farming.Contract.prototype.getNextAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Farming.Contract} returns this
 */
proto.mlmbox.type.Farming.Contract.prototype.setNextAt = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string income_per_day = 17;
 * @return {string}
 */
proto.mlmbox.type.Farming.Contract.prototype.getIncomePerDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Farming.Contract} returns this
 */
proto.mlmbox.type.Farming.Contract.prototype.setIncomePerDay = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.type.Farming.Account.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Farming.Account.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Farming.Account.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Farming.Account} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Farming.Account.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetsList: jspb.Message.toObjectList(msg.getAssetsList(),
    proto.mlmbox.type.Farming.Account.Asset.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Farming.Account}
 */
proto.mlmbox.type.Farming.Account.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Farming.Account;
  return proto.mlmbox.type.Farming.Account.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Farming.Account} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Farming.Account}
 */
proto.mlmbox.type.Farming.Account.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.type.Farming.Account.Asset;
      reader.readMessage(value,proto.mlmbox.type.Farming.Account.Asset.deserializeBinaryFromReader);
      msg.addAssets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Farming.Account.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Farming.Account.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Farming.Account} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Farming.Account.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mlmbox.type.Farming.Account.Asset.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.type.Farming.Account.Asset.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Farming.Account.Asset.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Farming.Account.Asset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Farming.Account.Asset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Farming.Account.Asset.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    state: (f = msg.getState()) && proto.mlmbox.type.Farming.Account.Asset.State.toObject(includeInstance, f),
    contractsList: jspb.Message.toObjectList(msg.getContractsList(),
    proto.mlmbox.type.Farming.Contract.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Farming.Account.Asset}
 */
proto.mlmbox.type.Farming.Account.Asset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Farming.Account.Asset;
  return proto.mlmbox.type.Farming.Account.Asset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Farming.Account.Asset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Farming.Account.Asset}
 */
proto.mlmbox.type.Farming.Account.Asset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetId(value);
      break;
    case 2:
      var value = new proto.mlmbox.type.Farming.Account.Asset.State;
      reader.readMessage(value,proto.mlmbox.type.Farming.Account.Asset.State.deserializeBinaryFromReader);
      msg.setState(value);
      break;
    case 3:
      var value = new proto.mlmbox.type.Farming.Contract;
      reader.readMessage(value,proto.mlmbox.type.Farming.Contract.deserializeBinaryFromReader);
      msg.addContracts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Farming.Account.Asset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Farming.Account.Asset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Farming.Account.Asset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Farming.Account.Asset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getState();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mlmbox.type.Farming.Account.Asset.State.serializeBinaryToWriter
    );
  }
  f = message.getContractsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.mlmbox.type.Farming.Contract.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.type.Farming.Account.Asset.State.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.type.Farming.Account.Asset.State.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.type.Farming.Account.Asset.State.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.type.Farming.Account.Asset.State} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Farming.Account.Asset.State.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractsQuantityTotal: jspb.Message.getFieldWithDefault(msg, 1, 0),
    contractsQuantityActivated: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lockupInitBodyTotalValue: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lockupTotalValue: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bonusesList: jspb.Message.toObjectList(msg.getBonusesList(),
    mlmbox_type_bonus_pb.Bonus.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.type.Farming.Account.Asset.State}
 */
proto.mlmbox.type.Farming.Account.Asset.State.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.type.Farming.Account.Asset.State;
  return proto.mlmbox.type.Farming.Account.Asset.State.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.type.Farming.Account.Asset.State} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.type.Farming.Account.Asset.State}
 */
proto.mlmbox.type.Farming.Account.Asset.State.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setContractsQuantityTotal(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setContractsQuantityActivated(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLockupInitBodyTotalValue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLockupTotalValue(value);
      break;
    case 5:
      var value = new mlmbox_type_bonus_pb.Bonus;
      reader.readMessage(value,mlmbox_type_bonus_pb.Bonus.deserializeBinaryFromReader);
      msg.addBonuses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.type.Farming.Account.Asset.State.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.type.Farming.Account.Asset.State.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.type.Farming.Account.Asset.State} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.type.Farming.Account.Asset.State.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractsQuantityTotal();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getContractsQuantityActivated();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getLockupInitBodyTotalValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLockupTotalValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBonusesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      mlmbox_type_bonus_pb.Bonus.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 contracts_quantity_total = 1;
 * @return {number}
 */
proto.mlmbox.type.Farming.Account.Asset.State.prototype.getContractsQuantityTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Farming.Account.Asset.State} returns this
 */
proto.mlmbox.type.Farming.Account.Asset.State.prototype.setContractsQuantityTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 contracts_quantity_activated = 2;
 * @return {number}
 */
proto.mlmbox.type.Farming.Account.Asset.State.prototype.getContractsQuantityActivated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Farming.Account.Asset.State} returns this
 */
proto.mlmbox.type.Farming.Account.Asset.State.prototype.setContractsQuantityActivated = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string lockup_init_body_total_value = 3;
 * @return {string}
 */
proto.mlmbox.type.Farming.Account.Asset.State.prototype.getLockupInitBodyTotalValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Farming.Account.Asset.State} returns this
 */
proto.mlmbox.type.Farming.Account.Asset.State.prototype.setLockupInitBodyTotalValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string lockup_total_value = 4;
 * @return {string}
 */
proto.mlmbox.type.Farming.Account.Asset.State.prototype.getLockupTotalValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.type.Farming.Account.Asset.State} returns this
 */
proto.mlmbox.type.Farming.Account.Asset.State.prototype.setLockupTotalValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Bonus bonuses = 5;
 * @return {!Array<!proto.mlmbox.type.Bonus>}
 */
proto.mlmbox.type.Farming.Account.Asset.State.prototype.getBonusesList = function() {
  return /** @type{!Array<!proto.mlmbox.type.Bonus>} */ (
    jspb.Message.getRepeatedWrapperField(this, mlmbox_type_bonus_pb.Bonus, 5));
};


/**
 * @param {!Array<!proto.mlmbox.type.Bonus>} value
 * @return {!proto.mlmbox.type.Farming.Account.Asset.State} returns this
*/
proto.mlmbox.type.Farming.Account.Asset.State.prototype.setBonusesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.mlmbox.type.Bonus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.Bonus}
 */
proto.mlmbox.type.Farming.Account.Asset.State.prototype.addBonuses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.mlmbox.type.Bonus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.type.Farming.Account.Asset.State} returns this
 */
proto.mlmbox.type.Farming.Account.Asset.State.prototype.clearBonusesList = function() {
  return this.setBonusesList([]);
};


/**
 * optional uint32 asset_id = 1;
 * @return {number}
 */
proto.mlmbox.type.Farming.Account.Asset.prototype.getAssetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.type.Farming.Account.Asset} returns this
 */
proto.mlmbox.type.Farming.Account.Asset.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional State state = 2;
 * @return {?proto.mlmbox.type.Farming.Account.Asset.State}
 */
proto.mlmbox.type.Farming.Account.Asset.prototype.getState = function() {
  return /** @type{?proto.mlmbox.type.Farming.Account.Asset.State} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.type.Farming.Account.Asset.State, 2));
};


/**
 * @param {?proto.mlmbox.type.Farming.Account.Asset.State|undefined} value
 * @return {!proto.mlmbox.type.Farming.Account.Asset} returns this
*/
proto.mlmbox.type.Farming.Account.Asset.prototype.setState = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.type.Farming.Account.Asset} returns this
 */
proto.mlmbox.type.Farming.Account.Asset.prototype.clearState = function() {
  return this.setState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.type.Farming.Account.Asset.prototype.hasState = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Contract contracts = 3;
 * @return {!Array<!proto.mlmbox.type.Farming.Contract>}
 */
proto.mlmbox.type.Farming.Account.Asset.prototype.getContractsList = function() {
  return /** @type{!Array<!proto.mlmbox.type.Farming.Contract>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.type.Farming.Contract, 3));
};


/**
 * @param {!Array<!proto.mlmbox.type.Farming.Contract>} value
 * @return {!proto.mlmbox.type.Farming.Account.Asset} returns this
*/
proto.mlmbox.type.Farming.Account.Asset.prototype.setContractsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.mlmbox.type.Farming.Contract=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.Farming.Contract}
 */
proto.mlmbox.type.Farming.Account.Asset.prototype.addContracts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.mlmbox.type.Farming.Contract, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.type.Farming.Account.Asset} returns this
 */
proto.mlmbox.type.Farming.Account.Asset.prototype.clearContractsList = function() {
  return this.setContractsList([]);
};


/**
 * repeated Asset assets = 1;
 * @return {!Array<!proto.mlmbox.type.Farming.Account.Asset>}
 */
proto.mlmbox.type.Farming.Account.prototype.getAssetsList = function() {
  return /** @type{!Array<!proto.mlmbox.type.Farming.Account.Asset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.type.Farming.Account.Asset, 1));
};


/**
 * @param {!Array<!proto.mlmbox.type.Farming.Account.Asset>} value
 * @return {!proto.mlmbox.type.Farming.Account} returns this
*/
proto.mlmbox.type.Farming.Account.prototype.setAssetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mlmbox.type.Farming.Account.Asset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.Farming.Account.Asset}
 */
proto.mlmbox.type.Farming.Account.prototype.addAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mlmbox.type.Farming.Account.Asset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.type.Farming.Account} returns this
 */
proto.mlmbox.type.Farming.Account.prototype.clearAssetsList = function() {
  return this.setAssetsList([]);
};


goog.object.extend(exports, proto.mlmbox.type);
