import { FarmingClient } from "../../../../apis/mlmbox/client/farming/v1/farming_grpc_web_pb";
import { PackageBuyRequest } from "../../../../apis/mlmbox/client/farming/v1/farming_pb";
import { Farming } from "../../../../apis/mlmbox/type/farming_pb";
import { Empty } from 'google-protobuf/google/protobuf/empty_pb.js'
import { API_URL } from '@/api';

const client = new FarmingClient(API_URL, null, {
    withCredentials: true
});

export default {
    state: {
        farmingPackages: [],
        package: {},
        contract: {},
        info: {},
    },
  
    mutations: {
        setPackage(state, farmingPackage) {
            state.farmingPackages.push(farmingPackage);
        },
        // setAllPackages(state, allPackages) {
        //     state.allPackages = allPackages;
        // },
        setContract(state, contract) {
            state.contract = contract;
        },
        setInfo(state, info) {
            state.info = info;
        },
    },

    actions: {
        getFarmingPackage({ commit }, packageId) {
            return new Promise((resolve, reject) => {
                const request = new Farming.Package.ID();

                request.setPackageId(packageId)
      
                client.packageGet(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('setPackage', response.toObject())
                        resolve(response.toObject())
                    }
                })
            })
        },
        
        buyFarmingPackage({ commit }, {packageId, assetId, priceValue}) {
            return new Promise((resolve, reject) => {
                const request = new PackageBuyRequest();

                request.setPackageId(packageId)
                request.setAssetId(assetId)
                request.setPriceValue(priceValue)
      
                client.packageBuy(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('setContract', response.toObject())
                        resolve(response.toObject())
                    }
                })
            })
        },

        getAllFarmingPackages({commit}) {
            return new Promise((resolve) => {
                const request = new Empty;
                const stream = client.packagesAll(request, {"authorization": localStorage.getItem("access_token")})

                stream.on('data', function(response) {
                    resolve(response.toObject())
                    commit('setPackage', response.toObject())
                });
                
            })
        },
 
        getFarmingInfo({commit}) {
            return new Promise((resolve, reject) => {
                const request = new Empty;
      
                client.info(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('setInfo', response.toObject())
                        resolve(response.toObject())
                    }
                })
            })
        },

        getFarmingContract({commit}, contractId) {
            return new Promise((resolve, reject) => {
                const request = new Farming.Contract.ID();

                request.setContractId(contractId)
      
                client.contractGet(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('setContract', response.toObject())
                        resolve(response.toObject())
                    }
                })
            })
        },

        farmingContractDeactivate({commit}, contractId) {
            return new Promise((resolve, reject) => {
                const request = new Farming.Contract.ID();

                request.setContractId(contractId)
      
                client.contractDeactivate(request, {"authorization": localStorage.getItem("access_token")}, (err, response) => {
                    if (err !== null) {
                        reject(err)
                    } else {
                        commit('stub')
                        resolve(response.toObject())
                    }
                })
            })
        },

    }
}