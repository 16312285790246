/**
 * @fileoverview gRPC-Web generated client stub for mlmbox.client.binary.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.15.8
// source: mlmbox/client/binary/v1/binary.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var mlmbox_type_binary_pb = require('../../../../mlmbox/type/binary_pb.js')

var mlmbox_type_limit_pb = require('../../../../mlmbox/type/limit_pb.js')
const proto = {};
proto.mlmbox = {};
proto.mlmbox.client = {};
proto.mlmbox.client.binary = {};
proto.mlmbox.client.binary.v1 = require('./binary_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mlmbox.client.binary.v1.BinaryClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mlmbox.client.binary.v1.BinaryPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.client.binary.v1.InfoRequest,
 *   !proto.mlmbox.type.Binary>}
 */
const methodDescriptor_Binary_Info = new grpc.web.MethodDescriptor(
  '/mlmbox.client.binary.v1.Binary/Info',
  grpc.web.MethodType.UNARY,
  proto.mlmbox.client.binary.v1.InfoRequest,
  mlmbox_type_binary_pb.Binary,
  /**
   * @param {!proto.mlmbox.client.binary.v1.InfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_binary_pb.Binary.deserializeBinary
);


/**
 * @param {!proto.mlmbox.client.binary.v1.InfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.type.Binary)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Binary>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.binary.v1.BinaryClient.prototype.info =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.binary.v1.Binary/Info',
      request,
      metadata || {},
      methodDescriptor_Binary_Info,
      callback);
};


/**
 * @param {!proto.mlmbox.client.binary.v1.InfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.type.Binary>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.binary.v1.BinaryPromiseClient.prototype.info =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.binary.v1.Binary/Info',
      request,
      metadata || {},
      methodDescriptor_Binary_Info);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.client.binary.v1.SearchRequest,
 *   !proto.mlmbox.type.Binary.Position>}
 */
const methodDescriptor_Binary_Search = new grpc.web.MethodDescriptor(
  '/mlmbox.client.binary.v1.Binary/Search',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.mlmbox.client.binary.v1.SearchRequest,
  mlmbox_type_binary_pb.Binary.Position,
  /**
   * @param {!proto.mlmbox.client.binary.v1.SearchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_binary_pb.Binary.Position.deserializeBinary
);


/**
 * @param {!proto.mlmbox.client.binary.v1.SearchRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Binary.Position>}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.binary.v1.BinaryClient.prototype.search =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mlmbox.client.binary.v1.Binary/Search',
      request,
      metadata || {},
      methodDescriptor_Binary_Search);
};


/**
 * @param {!proto.mlmbox.client.binary.v1.SearchRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Binary.Position>}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.binary.v1.BinaryPromiseClient.prototype.search =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/mlmbox.client.binary.v1.Binary/Search',
      request,
      metadata || {},
      methodDescriptor_Binary_Search);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.client.binary.v1.SettingsSideSetRequest,
 *   !proto.mlmbox.type.Binary.Position.Settings>}
 */
const methodDescriptor_Binary_SettingsSideSet = new grpc.web.MethodDescriptor(
  '/mlmbox.client.binary.v1.Binary/SettingsSideSet',
  grpc.web.MethodType.UNARY,
  proto.mlmbox.client.binary.v1.SettingsSideSetRequest,
  mlmbox_type_binary_pb.Binary.Position.Settings,
  /**
   * @param {!proto.mlmbox.client.binary.v1.SettingsSideSetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  mlmbox_type_binary_pb.Binary.Position.Settings.deserializeBinary
);


/**
 * @param {!proto.mlmbox.client.binary.v1.SettingsSideSetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.type.Binary.Position.Settings)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.type.Binary.Position.Settings>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.binary.v1.BinaryClient.prototype.settingsSideSet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.binary.v1.Binary/SettingsSideSet',
      request,
      metadata || {},
      methodDescriptor_Binary_SettingsSideSet,
      callback);
};


/**
 * @param {!proto.mlmbox.client.binary.v1.SettingsSideSetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.type.Binary.Position.Settings>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.binary.v1.BinaryPromiseClient.prototype.settingsSideSet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.binary.v1.Binary/SettingsSideSet',
      request,
      metadata || {},
      methodDescriptor_Binary_SettingsSideSet);
};


module.exports = proto.mlmbox.client.binary.v1;

