// source: mlmbox/client/network/v1/network.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var mlmbox_type_account_pb = require('../../../../mlmbox/type/account_pb.js');
goog.object.extend(proto, mlmbox_type_account_pb);
var mlmbox_type_limit_pb = require('../../../../mlmbox/type/limit_pb.js');
goog.object.extend(proto, mlmbox_type_limit_pb);
var mlmbox_type_sort_pb = require('../../../../mlmbox/type/sort_pb.js');
goog.object.extend(proto, mlmbox_type_sort_pb);
goog.exportSymbol('proto.mlmbox.client.network.v1.AccountsSearchRequest', null, global);
goog.exportSymbol('proto.mlmbox.client.network.v1.TeamPartnerListRequest', null, global);
goog.exportSymbol('proto.mlmbox.client.network.v1.TeamRequest', null, global);
goog.exportSymbol('proto.mlmbox.client.network.v1.TeamResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.client.network.v1.AccountsSearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.client.network.v1.AccountsSearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.client.network.v1.AccountsSearchRequest.displayName = 'proto.mlmbox.client.network.v1.AccountsSearchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.client.network.v1.TeamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.client.network.v1.TeamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.client.network.v1.TeamRequest.displayName = 'proto.mlmbox.client.network.v1.TeamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.client.network.v1.TeamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.client.network.v1.TeamResponse.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.client.network.v1.TeamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.client.network.v1.TeamResponse.displayName = 'proto.mlmbox.client.network.v1.TeamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.client.network.v1.TeamPartnerListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.client.network.v1.TeamPartnerListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.client.network.v1.TeamPartnerListRequest.displayName = 'proto.mlmbox.client.network.v1.TeamPartnerListRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.client.network.v1.AccountsSearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.client.network.v1.AccountsSearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.client.network.v1.AccountsSearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.network.v1.AccountsSearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    loginPrefix: jspb.Message.getFieldWithDefault(msg, 1, ""),
    limit: (f = msg.getLimit()) && mlmbox_type_limit_pb.Limit.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.client.network.v1.AccountsSearchRequest}
 */
proto.mlmbox.client.network.v1.AccountsSearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.client.network.v1.AccountsSearchRequest;
  return proto.mlmbox.client.network.v1.AccountsSearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.client.network.v1.AccountsSearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.client.network.v1.AccountsSearchRequest}
 */
proto.mlmbox.client.network.v1.AccountsSearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoginPrefix(value);
      break;
    case 2:
      var value = new mlmbox_type_limit_pb.Limit;
      reader.readMessage(value,mlmbox_type_limit_pb.Limit.deserializeBinaryFromReader);
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.client.network.v1.AccountsSearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.client.network.v1.AccountsSearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.client.network.v1.AccountsSearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.network.v1.AccountsSearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLoginPrefix();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      mlmbox_type_limit_pb.Limit.serializeBinaryToWriter
    );
  }
};


/**
 * optional string login_prefix = 1;
 * @return {string}
 */
proto.mlmbox.client.network.v1.AccountsSearchRequest.prototype.getLoginPrefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.client.network.v1.AccountsSearchRequest} returns this
 */
proto.mlmbox.client.network.v1.AccountsSearchRequest.prototype.setLoginPrefix = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional mlmbox.type.Limit limit = 2;
 * @return {?proto.mlmbox.type.Limit}
 */
proto.mlmbox.client.network.v1.AccountsSearchRequest.prototype.getLimit = function() {
  return /** @type{?proto.mlmbox.type.Limit} */ (
    jspb.Message.getWrapperField(this, mlmbox_type_limit_pb.Limit, 2));
};


/**
 * @param {?proto.mlmbox.type.Limit|undefined} value
 * @return {!proto.mlmbox.client.network.v1.AccountsSearchRequest} returns this
*/
proto.mlmbox.client.network.v1.AccountsSearchRequest.prototype.setLimit = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.client.network.v1.AccountsSearchRequest} returns this
 */
proto.mlmbox.client.network.v1.AccountsSearchRequest.prototype.clearLimit = function() {
  return this.setLimit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.client.network.v1.AccountsSearchRequest.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.client.network.v1.TeamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.client.network.v1.TeamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.client.network.v1.TeamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.network.v1.TeamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    viewPartner: (f = msg.getViewPartner()) && mlmbox_type_account_pb.Account.ID.toObject(includeInstance, f),
    partnersSort: (f = msg.getPartnersSort()) && mlmbox_type_sort_pb.Sort.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.client.network.v1.TeamRequest}
 */
proto.mlmbox.client.network.v1.TeamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.client.network.v1.TeamRequest;
  return proto.mlmbox.client.network.v1.TeamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.client.network.v1.TeamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.client.network.v1.TeamRequest}
 */
proto.mlmbox.client.network.v1.TeamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new mlmbox_type_account_pb.Account.ID;
      reader.readMessage(value,mlmbox_type_account_pb.Account.ID.deserializeBinaryFromReader);
      msg.setViewPartner(value);
      break;
    case 2:
      var value = new mlmbox_type_sort_pb.Sort;
      reader.readMessage(value,mlmbox_type_sort_pb.Sort.deserializeBinaryFromReader);
      msg.setPartnersSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.client.network.v1.TeamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.client.network.v1.TeamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.client.network.v1.TeamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.network.v1.TeamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getViewPartner();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      mlmbox_type_account_pb.Account.ID.serializeBinaryToWriter
    );
  }
  f = message.getPartnersSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      mlmbox_type_sort_pb.Sort.serializeBinaryToWriter
    );
  }
};


/**
 * optional mlmbox.type.Account.ID view_partner = 1;
 * @return {?proto.mlmbox.type.Account.ID}
 */
proto.mlmbox.client.network.v1.TeamRequest.prototype.getViewPartner = function() {
  return /** @type{?proto.mlmbox.type.Account.ID} */ (
    jspb.Message.getWrapperField(this, mlmbox_type_account_pb.Account.ID, 1));
};


/**
 * @param {?proto.mlmbox.type.Account.ID|undefined} value
 * @return {!proto.mlmbox.client.network.v1.TeamRequest} returns this
*/
proto.mlmbox.client.network.v1.TeamRequest.prototype.setViewPartner = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.client.network.v1.TeamRequest} returns this
 */
proto.mlmbox.client.network.v1.TeamRequest.prototype.clearViewPartner = function() {
  return this.setViewPartner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.client.network.v1.TeamRequest.prototype.hasViewPartner = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional mlmbox.type.Sort partners_sort = 2;
 * @return {?proto.mlmbox.type.Sort}
 */
proto.mlmbox.client.network.v1.TeamRequest.prototype.getPartnersSort = function() {
  return /** @type{?proto.mlmbox.type.Sort} */ (
    jspb.Message.getWrapperField(this, mlmbox_type_sort_pb.Sort, 2));
};


/**
 * @param {?proto.mlmbox.type.Sort|undefined} value
 * @return {!proto.mlmbox.client.network.v1.TeamRequest} returns this
*/
proto.mlmbox.client.network.v1.TeamRequest.prototype.setPartnersSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.client.network.v1.TeamRequest} returns this
 */
proto.mlmbox.client.network.v1.TeamRequest.prototype.clearPartnersSort = function() {
  return this.setPartnersSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.client.network.v1.TeamRequest.prototype.hasPartnersSort = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.client.network.v1.TeamResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.client.network.v1.TeamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.client.network.v1.TeamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.client.network.v1.TeamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.network.v1.TeamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: (f = msg.getAccount()) && mlmbox_type_account_pb.Account.FullInfo.toObject(includeInstance, f),
    partnersList: jspb.Message.toObjectList(msg.getPartnersList(),
    mlmbox_type_account_pb.Account.FullInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.client.network.v1.TeamResponse}
 */
proto.mlmbox.client.network.v1.TeamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.client.network.v1.TeamResponse;
  return proto.mlmbox.client.network.v1.TeamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.client.network.v1.TeamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.client.network.v1.TeamResponse}
 */
proto.mlmbox.client.network.v1.TeamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new mlmbox_type_account_pb.Account.FullInfo;
      reader.readMessage(value,mlmbox_type_account_pb.Account.FullInfo.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 2:
      var value = new mlmbox_type_account_pb.Account.FullInfo;
      reader.readMessage(value,mlmbox_type_account_pb.Account.FullInfo.deserializeBinaryFromReader);
      msg.addPartners(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.client.network.v1.TeamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.client.network.v1.TeamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.client.network.v1.TeamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.network.v1.TeamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      mlmbox_type_account_pb.Account.FullInfo.serializeBinaryToWriter
    );
  }
  f = message.getPartnersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      mlmbox_type_account_pb.Account.FullInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional mlmbox.type.Account.FullInfo account = 1;
 * @return {?proto.mlmbox.type.Account.FullInfo}
 */
proto.mlmbox.client.network.v1.TeamResponse.prototype.getAccount = function() {
  return /** @type{?proto.mlmbox.type.Account.FullInfo} */ (
    jspb.Message.getWrapperField(this, mlmbox_type_account_pb.Account.FullInfo, 1));
};


/**
 * @param {?proto.mlmbox.type.Account.FullInfo|undefined} value
 * @return {!proto.mlmbox.client.network.v1.TeamResponse} returns this
*/
proto.mlmbox.client.network.v1.TeamResponse.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.client.network.v1.TeamResponse} returns this
 */
proto.mlmbox.client.network.v1.TeamResponse.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.client.network.v1.TeamResponse.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated mlmbox.type.Account.FullInfo partners = 2;
 * @return {!Array<!proto.mlmbox.type.Account.FullInfo>}
 */
proto.mlmbox.client.network.v1.TeamResponse.prototype.getPartnersList = function() {
  return /** @type{!Array<!proto.mlmbox.type.Account.FullInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, mlmbox_type_account_pb.Account.FullInfo, 2));
};


/**
 * @param {!Array<!proto.mlmbox.type.Account.FullInfo>} value
 * @return {!proto.mlmbox.client.network.v1.TeamResponse} returns this
*/
proto.mlmbox.client.network.v1.TeamResponse.prototype.setPartnersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.mlmbox.type.Account.FullInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.type.Account.FullInfo}
 */
proto.mlmbox.client.network.v1.TeamResponse.prototype.addPartners = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.mlmbox.type.Account.FullInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.client.network.v1.TeamResponse} returns this
 */
proto.mlmbox.client.network.v1.TeamResponse.prototype.clearPartnersList = function() {
  return this.setPartnersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.client.network.v1.TeamPartnerListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.client.network.v1.TeamPartnerListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.client.network.v1.TeamPartnerListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.network.v1.TeamPartnerListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    viewPartner: (f = msg.getViewPartner()) && mlmbox_type_account_pb.Account.ID.toObject(includeInstance, f),
    stepReferralLine: jspb.Message.getFieldWithDefault(msg, 2, 0),
    partnersSort: (f = msg.getPartnersSort()) && mlmbox_type_sort_pb.Sort.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.client.network.v1.TeamPartnerListRequest}
 */
proto.mlmbox.client.network.v1.TeamPartnerListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.client.network.v1.TeamPartnerListRequest;
  return proto.mlmbox.client.network.v1.TeamPartnerListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.client.network.v1.TeamPartnerListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.client.network.v1.TeamPartnerListRequest}
 */
proto.mlmbox.client.network.v1.TeamPartnerListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new mlmbox_type_account_pb.Account.ID;
      reader.readMessage(value,mlmbox_type_account_pb.Account.ID.deserializeBinaryFromReader);
      msg.setViewPartner(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStepReferralLine(value);
      break;
    case 3:
      var value = new mlmbox_type_sort_pb.Sort;
      reader.readMessage(value,mlmbox_type_sort_pb.Sort.deserializeBinaryFromReader);
      msg.setPartnersSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.client.network.v1.TeamPartnerListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.client.network.v1.TeamPartnerListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.client.network.v1.TeamPartnerListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.network.v1.TeamPartnerListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getViewPartner();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      mlmbox_type_account_pb.Account.ID.serializeBinaryToWriter
    );
  }
  f = message.getStepReferralLine();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPartnersSort();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      mlmbox_type_sort_pb.Sort.serializeBinaryToWriter
    );
  }
};


/**
 * optional mlmbox.type.Account.ID view_partner = 1;
 * @return {?proto.mlmbox.type.Account.ID}
 */
proto.mlmbox.client.network.v1.TeamPartnerListRequest.prototype.getViewPartner = function() {
  return /** @type{?proto.mlmbox.type.Account.ID} */ (
    jspb.Message.getWrapperField(this, mlmbox_type_account_pb.Account.ID, 1));
};


/**
 * @param {?proto.mlmbox.type.Account.ID|undefined} value
 * @return {!proto.mlmbox.client.network.v1.TeamPartnerListRequest} returns this
*/
proto.mlmbox.client.network.v1.TeamPartnerListRequest.prototype.setViewPartner = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.client.network.v1.TeamPartnerListRequest} returns this
 */
proto.mlmbox.client.network.v1.TeamPartnerListRequest.prototype.clearViewPartner = function() {
  return this.setViewPartner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.client.network.v1.TeamPartnerListRequest.prototype.hasViewPartner = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 step_referral_line = 2;
 * @return {number}
 */
proto.mlmbox.client.network.v1.TeamPartnerListRequest.prototype.getStepReferralLine = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.client.network.v1.TeamPartnerListRequest} returns this
 */
proto.mlmbox.client.network.v1.TeamPartnerListRequest.prototype.setStepReferralLine = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional mlmbox.type.Sort partners_sort = 3;
 * @return {?proto.mlmbox.type.Sort}
 */
proto.mlmbox.client.network.v1.TeamPartnerListRequest.prototype.getPartnersSort = function() {
  return /** @type{?proto.mlmbox.type.Sort} */ (
    jspb.Message.getWrapperField(this, mlmbox_type_sort_pb.Sort, 3));
};


/**
 * @param {?proto.mlmbox.type.Sort|undefined} value
 * @return {!proto.mlmbox.client.network.v1.TeamPartnerListRequest} returns this
*/
proto.mlmbox.client.network.v1.TeamPartnerListRequest.prototype.setPartnersSort = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.client.network.v1.TeamPartnerListRequest} returns this
 */
proto.mlmbox.client.network.v1.TeamPartnerListRequest.prototype.clearPartnersSort = function() {
  return this.setPartnersSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.client.network.v1.TeamPartnerListRequest.prototype.hasPartnersSort = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.mlmbox.client.network.v1);
