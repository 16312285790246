<template>
  <div  class="background-rive__wrapper">
    <canvas ref="canvas" width="400" height="400" class="background-rive"></canvas>
  </div>
</template>

<script>
import {Rive, Layout, Fit, Alignment} from '@rive-app/canvas';

let riveAnimation = null;

export default {
  name: 'BackgroundRive',

  props: {
    src: String,
    fit: String,
    alignment: String
  },
  
  mounted() {
    riveAnimation = new Rive({
      canvas: this.$refs.canvas,
      src: "rive/new_file.riv",
      layout: new Layout({
        fit: Fit.Cover,
        alignment: Alignment.TopCenter,
      }),
      autoplay: true,
      onLoad: () => {
        riveAnimation.resizeDrawingSurfaceToCanvas()
      },
    });
  },

  unmounted() {
    if (riveAnimation !== undefined && riveAnimation !== null) {
      // console.log(riveAnimation);
      riveAnimation.cleanupInstances(); // Остановить анимацию
    }
  }
}
</script>
<style lang="scss" scoped>
.background-rive {
  width: 100%;
  
  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: max-content;
    right: 0;
    z-index: -1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    &::after {
      content: '';
      display: block;
      width: 12em;
      height: 12em;
      background: #20062f;
      border-radius: 50%;
      transform: translate(6em, -16em);
    }
  }
}
</style>