// source: mlmbox/client/finance/v1/wallet.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var mlmbox_type_confirmation_pb = require('../../../../mlmbox/type/confirmation_pb.js');
goog.object.extend(proto, mlmbox_type_confirmation_pb);
var mlmbox_type_sort_pb = require('../../../../mlmbox/type/sort_pb.js');
goog.object.extend(proto, mlmbox_type_sort_pb);
var mlmbox_type_wallet_pb = require('../../../../mlmbox/type/wallet_pb.js');
goog.object.extend(proto, mlmbox_type_wallet_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.mlmbox.client.finance.v1.WalletAssetGetRequest', null, global);
goog.exportSymbol('proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest', null, global);
goog.exportSymbol('proto.mlmbox.client.finance.v1.WalletDepositAddressResponse', null, global);
goog.exportSymbol('proto.mlmbox.client.finance.v1.WalletValidateAddressRequest', null, global);
goog.exportSymbol('proto.mlmbox.client.finance.v1.WalletValidateAddressResponse', null, global);
goog.exportSymbol('proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.client.finance.v1.WalletAssetGetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.client.finance.v1.WalletAssetGetRequest.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.client.finance.v1.WalletAssetGetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.client.finance.v1.WalletAssetGetRequest.displayName = 'proto.mlmbox.client.finance.v1.WalletAssetGetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest.displayName = 'proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.client.finance.v1.WalletDepositAddressResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.client.finance.v1.WalletDepositAddressResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.client.finance.v1.WalletDepositAddressResponse.displayName = 'proto.mlmbox.client.finance.v1.WalletDepositAddressResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.client.finance.v1.WalletValidateAddressRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.client.finance.v1.WalletValidateAddressRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.client.finance.v1.WalletValidateAddressRequest.displayName = 'proto.mlmbox.client.finance.v1.WalletValidateAddressRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.client.finance.v1.WalletValidateAddressResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.client.finance.v1.WalletValidateAddressResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.client.finance.v1.WalletValidateAddressResponse.displayName = 'proto.mlmbox.client.finance.v1.WalletValidateAddressResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest.displayName = 'proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.client.finance.v1.WalletAssetGetRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.client.finance.v1.WalletAssetGetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.client.finance.v1.WalletAssetGetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.client.finance.v1.WalletAssetGetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.finance.v1.WalletAssetGetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sortTransactions: (f = msg.getSortTransactions()) && mlmbox_type_sort_pb.Sort.toObject(includeInstance, f),
    filterKindIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.client.finance.v1.WalletAssetGetRequest}
 */
proto.mlmbox.client.finance.v1.WalletAssetGetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.client.finance.v1.WalletAssetGetRequest;
  return proto.mlmbox.client.finance.v1.WalletAssetGetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.client.finance.v1.WalletAssetGetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.client.finance.v1.WalletAssetGetRequest}
 */
proto.mlmbox.client.finance.v1.WalletAssetGetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetId(value);
      break;
    case 2:
      var value = new mlmbox_type_sort_pb.Sort;
      reader.readMessage(value,mlmbox_type_sort_pb.Sort.deserializeBinaryFromReader);
      msg.setSortTransactions(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.mlmbox.type.Wallet.Asset.Transaction.Kind.ID>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFilterKindIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.client.finance.v1.WalletAssetGetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.client.finance.v1.WalletAssetGetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.client.finance.v1.WalletAssetGetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.finance.v1.WalletAssetGetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getSortTransactions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      mlmbox_type_sort_pb.Sort.serializeBinaryToWriter
    );
  }
  f = message.getFilterKindIdsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
};


/**
 * optional uint32 asset_id = 1;
 * @return {number}
 */
proto.mlmbox.client.finance.v1.WalletAssetGetRequest.prototype.getAssetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.client.finance.v1.WalletAssetGetRequest} returns this
 */
proto.mlmbox.client.finance.v1.WalletAssetGetRequest.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional mlmbox.type.Sort sort_transactions = 2;
 * @return {?proto.mlmbox.type.Sort}
 */
proto.mlmbox.client.finance.v1.WalletAssetGetRequest.prototype.getSortTransactions = function() {
  return /** @type{?proto.mlmbox.type.Sort} */ (
    jspb.Message.getWrapperField(this, mlmbox_type_sort_pb.Sort, 2));
};


/**
 * @param {?proto.mlmbox.type.Sort|undefined} value
 * @return {!proto.mlmbox.client.finance.v1.WalletAssetGetRequest} returns this
*/
proto.mlmbox.client.finance.v1.WalletAssetGetRequest.prototype.setSortTransactions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.client.finance.v1.WalletAssetGetRequest} returns this
 */
proto.mlmbox.client.finance.v1.WalletAssetGetRequest.prototype.clearSortTransactions = function() {
  return this.setSortTransactions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.client.finance.v1.WalletAssetGetRequest.prototype.hasSortTransactions = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated mlmbox.type.Wallet.Asset.Transaction.Kind.ID filter_kind_ids = 3;
 * @return {!Array<!proto.mlmbox.type.Wallet.Asset.Transaction.Kind.ID>}
 */
proto.mlmbox.client.finance.v1.WalletAssetGetRequest.prototype.getFilterKindIdsList = function() {
  return /** @type {!Array<!proto.mlmbox.type.Wallet.Asset.Transaction.Kind.ID>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.mlmbox.type.Wallet.Asset.Transaction.Kind.ID>} value
 * @return {!proto.mlmbox.client.finance.v1.WalletAssetGetRequest} returns this
 */
proto.mlmbox.client.finance.v1.WalletAssetGetRequest.prototype.setFilterKindIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.mlmbox.type.Wallet.Asset.Transaction.Kind.ID} value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.client.finance.v1.WalletAssetGetRequest} returns this
 */
proto.mlmbox.client.finance.v1.WalletAssetGetRequest.prototype.addFilterKindIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.client.finance.v1.WalletAssetGetRequest} returns this
 */
proto.mlmbox.client.finance.v1.WalletAssetGetRequest.prototype.clearFilterKindIdsList = function() {
  return this.setFilterKindIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stepTransactionId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sortTransactions: (f = msg.getSortTransactions()) && mlmbox_type_sort_pb.Sort.toObject(includeInstance, f),
    filterKindIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest}
 */
proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest;
  return proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest}
 */
proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStepTransactionId(value);
      break;
    case 3:
      var value = new mlmbox_type_sort_pb.Sort;
      reader.readMessage(value,mlmbox_type_sort_pb.Sort.deserializeBinaryFromReader);
      msg.setSortTransactions(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.mlmbox.type.Wallet.Asset.Transaction.Kind.ID>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFilterKindIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getStepTransactionId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSortTransactions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      mlmbox_type_sort_pb.Sort.serializeBinaryToWriter
    );
  }
  f = message.getFilterKindIdsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
};


/**
 * optional uint32 asset_id = 1;
 * @return {number}
 */
proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest.prototype.getAssetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest} returns this
 */
proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 step_transaction_id = 2;
 * @return {number}
 */
proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest.prototype.getStepTransactionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest} returns this
 */
proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest.prototype.setStepTransactionId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional mlmbox.type.Sort sort_transactions = 3;
 * @return {?proto.mlmbox.type.Sort}
 */
proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest.prototype.getSortTransactions = function() {
  return /** @type{?proto.mlmbox.type.Sort} */ (
    jspb.Message.getWrapperField(this, mlmbox_type_sort_pb.Sort, 3));
};


/**
 * @param {?proto.mlmbox.type.Sort|undefined} value
 * @return {!proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest} returns this
*/
proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest.prototype.setSortTransactions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest} returns this
 */
proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest.prototype.clearSortTransactions = function() {
  return this.setSortTransactions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest.prototype.hasSortTransactions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated mlmbox.type.Wallet.Asset.Transaction.Kind.ID filter_kind_ids = 4;
 * @return {!Array<!proto.mlmbox.type.Wallet.Asset.Transaction.Kind.ID>}
 */
proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest.prototype.getFilterKindIdsList = function() {
  return /** @type {!Array<!proto.mlmbox.type.Wallet.Asset.Transaction.Kind.ID>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.mlmbox.type.Wallet.Asset.Transaction.Kind.ID>} value
 * @return {!proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest} returns this
 */
proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest.prototype.setFilterKindIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.mlmbox.type.Wallet.Asset.Transaction.Kind.ID} value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest} returns this
 */
proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest.prototype.addFilterKindIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest} returns this
 */
proto.mlmbox.client.finance.v1.WalletAssetTransactionListRequest.prototype.clearFilterKindIdsList = function() {
  return this.setFilterKindIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.client.finance.v1.WalletDepositAddressResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.client.finance.v1.WalletDepositAddressResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.client.finance.v1.WalletDepositAddressResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.finance.v1.WalletDepositAddressResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.client.finance.v1.WalletDepositAddressResponse}
 */
proto.mlmbox.client.finance.v1.WalletDepositAddressResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.client.finance.v1.WalletDepositAddressResponse;
  return proto.mlmbox.client.finance.v1.WalletDepositAddressResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.client.finance.v1.WalletDepositAddressResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.client.finance.v1.WalletDepositAddressResponse}
 */
proto.mlmbox.client.finance.v1.WalletDepositAddressResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.client.finance.v1.WalletDepositAddressResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.client.finance.v1.WalletDepositAddressResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.client.finance.v1.WalletDepositAddressResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.finance.v1.WalletDepositAddressResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.mlmbox.client.finance.v1.WalletDepositAddressResponse.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.client.finance.v1.WalletDepositAddressResponse} returns this
 */
proto.mlmbox.client.finance.v1.WalletDepositAddressResponse.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.client.finance.v1.WalletValidateAddressRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.client.finance.v1.WalletValidateAddressRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.client.finance.v1.WalletValidateAddressRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.finance.v1.WalletValidateAddressRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    processingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    address: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.client.finance.v1.WalletValidateAddressRequest}
 */
proto.mlmbox.client.finance.v1.WalletValidateAddressRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.client.finance.v1.WalletValidateAddressRequest;
  return proto.mlmbox.client.finance.v1.WalletValidateAddressRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.client.finance.v1.WalletValidateAddressRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.client.finance.v1.WalletValidateAddressRequest}
 */
proto.mlmbox.client.finance.v1.WalletValidateAddressRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProcessingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.client.finance.v1.WalletValidateAddressRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.client.finance.v1.WalletValidateAddressRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.client.finance.v1.WalletValidateAddressRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.finance.v1.WalletValidateAddressRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessingId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 processing_id = 1;
 * @return {number}
 */
proto.mlmbox.client.finance.v1.WalletValidateAddressRequest.prototype.getProcessingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.client.finance.v1.WalletValidateAddressRequest} returns this
 */
proto.mlmbox.client.finance.v1.WalletValidateAddressRequest.prototype.setProcessingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string address = 2;
 * @return {string}
 */
proto.mlmbox.client.finance.v1.WalletValidateAddressRequest.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.client.finance.v1.WalletValidateAddressRequest} returns this
 */
proto.mlmbox.client.finance.v1.WalletValidateAddressRequest.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.client.finance.v1.WalletValidateAddressResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.client.finance.v1.WalletValidateAddressResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.client.finance.v1.WalletValidateAddressResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.finance.v1.WalletValidateAddressResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 1, ""),
    validated: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.client.finance.v1.WalletValidateAddressResponse}
 */
proto.mlmbox.client.finance.v1.WalletValidateAddressResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.client.finance.v1.WalletValidateAddressResponse;
  return proto.mlmbox.client.finance.v1.WalletValidateAddressResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.client.finance.v1.WalletValidateAddressResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.client.finance.v1.WalletValidateAddressResponse}
 */
proto.mlmbox.client.finance.v1.WalletValidateAddressResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValidated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.client.finance.v1.WalletValidateAddressResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.client.finance.v1.WalletValidateAddressResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.client.finance.v1.WalletValidateAddressResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.finance.v1.WalletValidateAddressResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValidated();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.mlmbox.client.finance.v1.WalletValidateAddressResponse.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.client.finance.v1.WalletValidateAddressResponse} returns this
 */
proto.mlmbox.client.finance.v1.WalletValidateAddressResponse.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool validated = 2;
 * @return {boolean}
 */
proto.mlmbox.client.finance.v1.WalletValidateAddressResponse.prototype.getValidated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mlmbox.client.finance.v1.WalletValidateAddressResponse} returns this
 */
proto.mlmbox.client.finance.v1.WalletValidateAddressResponse.prototype.setValidated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    processingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    assetId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    operationAmount: jspb.Message.getFieldWithDefault(msg, 3, ""),
    address: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest}
 */
proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest;
  return proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest}
 */
proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProcessingId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperationAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessingId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAssetId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getOperationAmount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional uint32 processing_id = 1;
 * @return {number}
 */
proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest.prototype.getProcessingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest} returns this
 */
proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest.prototype.setProcessingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 asset_id = 2;
 * @return {number}
 */
proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest.prototype.getAssetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest} returns this
 */
proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string operation_amount = 3;
 * @return {string}
 */
proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest.prototype.getOperationAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest} returns this
 */
proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest.prototype.setOperationAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string address = 4;
 * @return {string}
 */
proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest} returns this
 */
proto.mlmbox.client.finance.v1.WalletWithdrawalCreateRequest.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


goog.object.extend(exports, proto.mlmbox.client.finance.v1);
