/**
 * @fileoverview gRPC-Web generated client stub for mlmbox.client.session.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.15.8
// source: mlmbox/client/session/v1/session.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var mlmbox_type_session_pb = require('../../../../mlmbox/type/session_pb.js')

var mlmbox_type_time_point_pb = require('../../../../mlmbox/type/time_point_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.mlmbox = {};
proto.mlmbox.client = {};
proto.mlmbox.client.session = {};
proto.mlmbox.client.session.v1 = require('./session_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mlmbox.client.session.v1.SessionClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.mlmbox.client.session.v1.SessionPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.mlmbox.client.session.v1.SessionStateGetResponse>}
 */
const methodDescriptor_Session_StateGet = new grpc.web.MethodDescriptor(
  '/mlmbox.client.session.v1.Session/StateGet',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.mlmbox.client.session.v1.SessionStateGetResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.mlmbox.client.session.v1.SessionStateGetResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.mlmbox.client.session.v1.SessionStateGetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.mlmbox.client.session.v1.SessionStateGetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.session.v1.SessionClient.prototype.stateGet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.session.v1.Session/StateGet',
      request,
      metadata || {},
      methodDescriptor_Session_StateGet,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.mlmbox.client.session.v1.SessionStateGetResponse>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.session.v1.SessionPromiseClient.prototype.stateGet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.session.v1.Session/StateGet',
      request,
      metadata || {},
      methodDescriptor_Session_StateGet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.type.Session.ID,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Session_Deactivate = new grpc.web.MethodDescriptor(
  '/mlmbox.client.session.v1.Session/Deactivate',
  grpc.web.MethodType.UNARY,
  mlmbox_type_session_pb.Session.ID,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.mlmbox.type.Session.ID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.mlmbox.type.Session.ID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.session.v1.SessionClient.prototype.deactivate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.session.v1.Session/Deactivate',
      request,
      metadata || {},
      methodDescriptor_Session_Deactivate,
      callback);
};


/**
 * @param {!proto.mlmbox.type.Session.ID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.session.v1.SessionPromiseClient.prototype.deactivate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.session.v1.Session/Deactivate',
      request,
      metadata || {},
      methodDescriptor_Session_Deactivate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Session_DeactivateAll = new grpc.web.MethodDescriptor(
  '/mlmbox.client.session.v1.Session/DeactivateAll',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.session.v1.SessionClient.prototype.deactivateAll =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.session.v1.Session/DeactivateAll',
      request,
      metadata || {},
      methodDescriptor_Session_DeactivateAll,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.session.v1.SessionPromiseClient.prototype.deactivateAll =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.session.v1.Session/DeactivateAll',
      request,
      metadata || {},
      methodDescriptor_Session_DeactivateAll);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Session_DeactivateOther = new grpc.web.MethodDescriptor(
  '/mlmbox.client.session.v1.Session/DeactivateOther',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.session.v1.SessionClient.prototype.deactivateOther =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.session.v1.Session/DeactivateOther',
      request,
      metadata || {},
      methodDescriptor_Session_DeactivateOther,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.session.v1.SessionPromiseClient.prototype.deactivateOther =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.session.v1.Session/DeactivateOther',
      request,
      metadata || {},
      methodDescriptor_Session_DeactivateOther);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Session_DeactivateCurrent = new grpc.web.MethodDescriptor(
  '/mlmbox.client.session.v1.Session/DeactivateCurrent',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.session.v1.SessionClient.prototype.deactivateCurrent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.session.v1.Session/DeactivateCurrent',
      request,
      metadata || {},
      methodDescriptor_Session_DeactivateCurrent,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.session.v1.SessionPromiseClient.prototype.deactivateCurrent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.session.v1.Session/DeactivateCurrent',
      request,
      metadata || {},
      methodDescriptor_Session_DeactivateCurrent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.mlmbox.type.DurationPoint,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Session_ExpireDurationSet = new grpc.web.MethodDescriptor(
  '/mlmbox.client.session.v1.Session/ExpireDurationSet',
  grpc.web.MethodType.UNARY,
  mlmbox_type_time_point_pb.DurationPoint,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.mlmbox.type.DurationPoint} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.mlmbox.type.DurationPoint} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.mlmbox.client.session.v1.SessionClient.prototype.expireDurationSet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/mlmbox.client.session.v1.Session/ExpireDurationSet',
      request,
      metadata || {},
      methodDescriptor_Session_ExpireDurationSet,
      callback);
};


/**
 * @param {!proto.mlmbox.type.DurationPoint} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.mlmbox.client.session.v1.SessionPromiseClient.prototype.expireDurationSet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/mlmbox.client.session.v1.Session/ExpireDurationSet',
      request,
      metadata || {},
      methodDescriptor_Session_ExpireDurationSet);
};


module.exports = proto.mlmbox.client.session.v1;

