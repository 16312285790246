<template>
  <div class="body-wrap" :class="{mobileScreen: isMobileScreen, animated: isAuth}">
    <div class="content">
      <MainHeader v-if="loaded && !isLandingPage" @openPopup="openPopup" :canIRerenderComponent="canIRerenderHeader" />
    
      <router-view @openPopup="openPopup"
        @rerenderHeader="rerenderHeader"
        @langingPage="value => isLandingPage = value"
        @pageUpdate="value => loaded = value"></router-view>

      <MainFooter v-if="loaded && !isLandingPage" />

      <PopupWrapper v-if="loaded || isLandingPage" :popupName="popupName" 
        :data="popupData"
        :popupOpen="popupOpen" 
        @openPopup="openPopupInPopup" 
        @closePopup="closePopup"
        @rerenderHeader="rerenderHeader" />
    </div>

    <BackgroundRive v-if="isAuth" />
  </div>
</template>

<script>
import MainHeader from "@/components/Header/MainHeader";
import MainFooter from "@/components/Footer/MainFooter";
import PopupWrapper from "@/components/Popups/PopupWrapper";

import {auth} from '@/mixins/auth'
import {session} from '@/mixins/session'
import { mapState } from 'vuex';
import BackgroundRive from '@/components/RiveAnimations/BackgroundRive.vue';

export default {
  name: "App",

  components: {
    MainHeader,
    MainFooter,
    PopupWrapper,
    BackgroundRive,
  }, 

  mixins: [
    auth,
    session
  ],

  data() {
    return {
      popupName: '',
      popupOpen: false,
      canIRerenderHeader: false,
      popupData: {},

      loaded: false,
      isLandingPage: false,
      isAuth: false,
    }
  },
  
  computed: {
    ...mapState({
      isMobileScreen: state => state.mobileScreen,
      // authStateChecked: state => state.auth.authStateChecked
    })
  },

  methods: {
    pageLoaded() {
      this.loaded = true;
    },
    
    closePopup() {
      this.popupName = ''
      this.popupOpen = false
    },

    openPopup(data) {
      this.popupOpen = false
      this.popupOpen = true

      this.popupName = data.name;
      this.popupData = {...data}
    },

    openPopupInPopup(data) {
      this.popupName = data.name;
      this.popupData = {...data}
    },

    rerenderHeader() {
      this.canIRerenderHeader = true;
      this.canIRerenderHeader = false;
    },

    updateReferral() {
      let params = (new URL(document.location)).searchParams;

      if (params.has("ref")) {
        localStorage.setItem('referral', params.get("ref"))
      }
    }
  },

  created() {
    this.isAuth = this.getAuthState();

    // this.tokenValidate()
  },

  updated() {
    this.isAuth = this.getAuthState();
  },

  mounted() {
    this.updateReferral() 
  },
};
</script>

<style lang="scss">
#app {
  // height: auto;
  height: 100%;
}

.body-wrap {
  z-index: 1;

  &.animated {
    background: #20062f;
  }
}

.content {
  width: 100%;
  height: 100%;
}

button {
  background: transparent;
  border: 0;
  font-size: 1em;
  cursor: pointer;
}

img {
  font-size: 1em;
}

.error-item{
    position: absolute;
    left: 0;
    bottom: em(-28);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    opacity: 0;
    pointer-events: none;
    user-select: none;
    img{
        width: em(22);
        pointer-events: none;
        user-select: none;
    }
    span{
        top: em(2,14);
        position: relative;
        display: inline-block;
        margin-left: em(5);
        line-height: 1;
        font-size: em(14);
        color: #c6bba7;
        font-family: 'Visby CF Demi';
        font-weight: 600;
    }
    &.active{
        opacity: 1;
        pointer-events: unset;
        user-select: unset;
    }
}

.welcome {
  .section-1__text_1 , .section-3__text_white, .section-4__heading, .section-4__text_1, .section-7__heading   {
    span span {
      color: #01ffc2;
    }
  }
}

.roadmap b {
  font-weight: 700;
}
</style>
