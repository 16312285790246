<template>
  <div class="plans-bot-rive__container">
    <canvas ref="canvas" width="400" height="400" class="plans-bot-rive"></canvas>
  </div>
</template>

<script>
import {Rive, Layout, Fit, Alignment} from '@rive-app/canvas';

let riveAnimation;

export default {
  name: 'PlansBotRive',

  props: {
    src: String,
    fit: String,
    alignment: String
  },
  
  mounted() {
    riveAnimation = new Rive({
      canvas: this.$refs.canvas,
      src: "rive/wonderdynamics.riv",
      layout: new Layout({
        fit: Fit.Contain,
        alignment: Alignment.TopCenter,
      }),
      autoplay: true,
      onLoad: () => {
        riveAnimation.resizeDrawingSurfaceToCanvas()
      },
    });
  },

  unmounted() {
    if (riveAnimation !== undefined && riveAnimation !== null) {
      // console.log(riveAnimation);
      riveAnimation.cleanupInstances(); // Остановить анимацию
      
      // console.log(riveAnimation);
      riveAnimation = null; // Очистить ссылку на анимацию
    }
  }
}
</script>
<style lang="scss" scoped>
.plans-bot-rive {
  min-width: 33em;
  // height: 15.375em;
  display: block;
  // position: absolute;
  // right: -4em;
  // top: -11em;
  pointer-events: none;

  &__container {
    width: 17em;
    height: 23em;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-left: -4em;
    margin-top: -8em;
    
    @media (max-width: 1024px) {
      margin-top: -3em;
    }
  }

}
</style>